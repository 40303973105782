import { Currency } from 'components/currency-input'
import React, { useContext, useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { Button } from '../../../../../../components/button'
import { Input } from '../../../../../../components/input'
import styles from './index.module.scss'
// import { is_visible, is_editable, navigate } from '../../requested-products.helper'
import { is_visible, navigate } from '../../requested-products.helper'
import { filter_number } from 'helpers/string.helper'
import { BsCircleFill } from 'react-icons/bs'
import format_currency from 'services/formatter.service'
import { RiNumbersLine } from 'react-icons/ri'
import { UserContext } from 'contexts/user'
import ProductModel from 'models/product.model'
import { FaRegCopy } from 'react-icons/fa'
import OfferModel from 'models/offer.model'
import OfferService from 'services/offer.service'
import { Snackbar } from 'components/snackbar'
import { useSnackbar } from 'react-simple-snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { mask_currency } from 'helpers/mask.helper'
import IconReact from 'components/icons'
import { PiCrossDuotone, PiCrosshairDuotone } from 'react-icons/pi'
import { CurrencySafari } from 'components/currency-input-Safary'
import './styles.scss'
import { useTheme } from 'contexts/whiteLabel/context'

export const OfferItemRowComponentMobile = ({
    isSafari,
    data,
    index,
    is_editable,
}: any) => {
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const { theme } = useTheme()
    const {
        request,
        setup_offer,
        products,
        setCurrentOffer,
        seller_id,
        setCurrentProduct,
        save,
        save_obs,
        remove_offer,
        setShowPreviousProductModalVisible,
        save_quantity,
        open_previous_offers_modal,
        setDerivativeProductsModalVisible,
    } = data

    //const [prod, setProd] = useState<ProductModel>(products[index])
    const [prod, setProd] = useState<ProductModel>(products)
    const { can } = useContext(UserContext)

    const [visiblePricisionInput, setVisiblePricisionInput] = useState<any>([])

    //  useEffect(() => {
    //      setProd(products[index])
    //  }, [data, products, index])
    useEffect(() => {
        setProd(products)
    }, [data, products])

    const get_last_price_text_class = (prod: ProductModel) => {
        if (prod.is_previous_offer_winner == null || !prod.last_offer_price) {
            return ''
        } else if (!prod.is_previous_offer_winner) {
            return 'lower'
        } else {
            return 'higher'
        }
    }
    const get_last_price_text_classMobile = (prod: ProductModel) => {
        if (prod.is_previous_offer_winner == null || !prod.last_offer_price) {
            return ''
        } else if (!prod.is_previous_offer_winner) {
            return styles.lower
        } else {
            return styles.higher
        }
    }

    const copy_price = async (deriv, main) => {
        deriv.new_offer_price = deriv.last_offer_price
        const offer = OfferModel.from_product(deriv, request).with_price(
            deriv.new_offer_price
        )
        offer.generic = main && main.is_generic
        offer.offer_quantity = offer.generic ? offer.offer_quantity : 1
        offer.purchase_unity = main?.purchase_unity
        offer.seller_id = seller_id
        offer.display_price = mask_currency(deriv.last_offer_price)
        offer.changed = true

        OfferService.save(offer?.to_request()).then(() => {
            deriv.saved = true
            openSnackbar(
                <Snackbar icon="attach_money">
                    Ultima oferta copiada com sucesso {offer?.display_price}
                </Snackbar>
            )
        })
    }

    const get_last_price_text = (prod: ProductModel) => {
        if (prod.is_previous_offer_winner == null || !prod.last_offer_price) {
            return ''
        } else if (!prod.is_previous_offer_winner) {
            return 'Preço não vencedor na ultima cotação'
        } else {
            return 'Preço vencedor na ultima cotação'
        }
    }

    const wrap_inputs = (deriv: ProductModel, prod: ProductModel) => {
        return prod.is_generic ? (
            <>
                <div style={{ width: 60 }}>
                    <Input
                        type="tel"
                        value={deriv.new_offer_quantity}
                        className={`wrap-amount thin ${styles.itemUnity} ${
                            deriv.qtd_saved && styles.saved
                        }`}
                        style={{ textAlign: 'center' }}
                        disabled={
                            !is_editable(request) ||
                            !prod.is_generic ||
                            (request.owner_type &&
                                request.owner_type !== 'PROVIDER')
                                ? 'disabled '
                                : false
                        }
                        onBlur={() =>
                            save_quantity(() => {
                                deriv.qtd_saved = true
                                deriv.offer.quantity = deriv.new_offer_quantity
                            })
                        }
                        onKeyDown={(e: any) =>
                            navigate(e, deriv, { save: null, save_obs: null })
                        }
                        name="offer-quantityMobile"
                        onChange={(evt: any) => {
                            const parsed = parseInt(
                                filter_number(evt.target.value)
                            )
                            deriv.new_offer_quantity = isNaN(parsed)
                                ? 0
                                : parsed
                            setup_offer({
                                floatValue: deriv.new_offer_price,
                                deriv,
                                main: prod,
                            })
                        }}
                    />
                </div>
                <div style={{}}>
                    <Input
                        defaultValue={prod.purchase_unity}
                        tabIndex="-1"
                        readOnly="readonly"
                        className={`wrap-description thin ${styles.itemUnity} `}
                    />
                </div>
            </>
        ) : (
            <>
                {/* <div style={{ width: 60 }}></div> */}
                <div style={{}}>
                    <Input
                        defaultValue={prod.packing}
                        tabIndex="-1"
                        readOnly="readonly"
                        className={`wrap-description thin ${styles.itemUnity}`}
                    />
                </div>
            </>
        )
    }

    function handleOpenModalDerivated() {
        setCurrentProduct(prod)
        setDerivativeProductsModalVisible(true)
    }
    function handleOpenModalPrevious(deriv) {
        open_previous_offers_modal(deriv)
        setShowPreviousProductModalVisible(true)
    }

    function handleSetFourDecimals(identifier) {
        let actualIdentifiers = [...visiblePricisionInput]
        if (actualIdentifiers.includes(identifier)) {
            actualIdentifiers = actualIdentifiers.filter(
                (item) => item != identifier
            )
        } else {
            actualIdentifiers.push(identifier)
        }
        setVisiblePricisionInput(actualIdentifiers)
    }

    function isPrecisionValue(value) {
        if (value) {
            var stringfied = value.toString()

            if (stringfied.indexOf('.') !== -1) {
                var partes = stringfied.split('.')
                if (partes[1].length > 2) {
                    return true
                }
            }
            return false
        } else {
            return false
        }
    }

    return (
        <>
            {false ? (
                <div key={index} className={styles.wrapperMobile}>
                    <div
                        key={prod.id}
                        className={`row product-header bg-light-gray ${styles.main}`}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '85%',
                            }}
                        >
                            <div
                                data-label="Código"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <div className={`${styles.codigoOffer} `}>
                                    <p>{prod.ean}</p>
                                </div>
                                <div className={`${styles.circle} `}>
                                    <p
                                        className={
                                            prod.is_generic
                                                ? styles.showbrands
                                                : styles.showbrandsDisabled
                                        }
                                        data-toggle="modal"
                                        //disabled={!prod.is_generic}
                                        data-target="#derivatives-modal"
                                        onClick={() => {
                                            prod.is_generic
                                                ? handleOpenModalDerivated()
                                                : {}
                                        }}
                                    >
                                        Ver Marcas
                                    </p>
                                    {
                                        //  <BsCircleFill />
                                    }
                                </div>
                            </div>
                            <div
                                className="TdtabelaDigitacaoPreco"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                    }}
                                >
                                    <span
                                        className={`${styles.prodNameOffer} `}
                                        style={{}}
                                    >
                                        {prod.name}
                                    </span>
                                </div>

                                <div
                                    className={`${styles.infOfferItem}  text-lowercase`}
                                >
                                    {prod.is_generic ? (
                                        <p>
                                            Informe o preço do(a):{' '}
                                            {prod.purchase_unity}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {!prod.is_generic ? (
                                        <p>Informe apenas seu preço</p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>

                        <div
                            style={{ width: '15%' }}
                            className=" requested-quantity"
                        >
                            <p className="w-100">
                                Qtd {prod.requested_quantity}
                            </p>
                        </div>
                    </div>
                    {prod.derivatives &&
                        prod.derivatives
                            .filter(is_visible)
                            .map((deriv: any, idx) => (
                                <div
                                    key={`${deriv.id}-deriv`}
                                    className={`row product-deriv ${styles.brand}`}
                                >
                                    {<div style={{ width: 60 }}></div>}
                                    <div className="col">
                                        {prod.is_generic ? (
                                            <IconReact
                                                type="trash"
                                                title="Remover digitação"
                                                onClick={() => {
                                                    is_editable(request) &&
                                                        remove_offer(deriv)
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {prod.is_generic ? (
                                            <div
                                                className={`brand ${styles.hearth}`}
                                            >
                                                {deriv.starred ? (
                                                    <AiFillHeart title="Marca preferencial" />
                                                ) : (
                                                    ''
                                                )}
                                                {!deriv.starred ? (
                                                    <div className="on flex">
                                                        <AiOutlineHeart />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {deriv.brand}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div style={{ width: 400 }}>
                                        <div style={{}}>
                                            <div
                                                style={{ display: 'flex' }}
                                                className="wrap"
                                            >
                                                {wrap_inputs(deriv, prod)}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: 10,
                                                display: 'flex',
                                            }}
                                        >
                                            {isSafari ? (
                                                <CurrencySafari
                                                    id={
                                                        index +
                                                        'set-currencyMobile' +
                                                        idx
                                                    }
                                                    value={
                                                        deriv.new_offer_price
                                                    }
                                                    name={'set-currencyMobile'}
                                                    onKeyDown={(e: any) =>
                                                        navigate(e, deriv, {
                                                            save,
                                                            save_obs,
                                                        })
                                                    }
                                                    disabled={
                                                        (prod.is_generic &&
                                                            deriv.new_offer_quantity <
                                                                1) ||
                                                        !is_editable(request) ||
                                                        (request.owner_type &&
                                                            request.owner_type !==
                                                                'PROVIDER')
                                                            ? 'disabled '
                                                            : false
                                                    }
                                                    register={(el: any) =>
                                                        deriv.ref
                                                            ? (deriv.ref.current =
                                                                  el)
                                                            : el
                                                    }
                                                    onFocus={() =>
                                                        setCurrentOffer(
                                                            undefined
                                                        )
                                                    }
                                                    //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                                                    className={`${
                                                        styles.currencyOffer
                                                    } ${
                                                        deriv.saved &&
                                                        styles.saved
                                                    }`}
                                                    //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                                                    onChange={(
                                                        maskedvalue: string,
                                                        floatValue: number
                                                    ) => {
                                                        deriv.offer.price =
                                                            floatValue
                                                        deriv.new_offer_price =
                                                            floatValue
                                                        setup_offer({
                                                            maskedvalue,
                                                            floatValue,
                                                            deriv,
                                                            main: prod,
                                                        })
                                                    }}
                                                    onBlur={() =>
                                                        save(
                                                            () =>
                                                                (deriv.saved =
                                                                    true)
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <Currency
                                                    id={
                                                        index +
                                                        'set-currencyMobile' +
                                                        idx
                                                    }
                                                    value={
                                                        deriv.new_offer_price
                                                    }
                                                    name={'set-currencyMobile'}
                                                    // onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                                                    onKeyDown={(e: any) =>
                                                        navigate(
                                                            e,
                                                            deriv,
                                                            { save, save_obs },
                                                            idx,
                                                            index,
                                                            length
                                                        )
                                                    }
                                                    disabled={
                                                        (prod.is_generic &&
                                                            deriv.new_offer_quantity <
                                                                1) ||
                                                        !is_editable(request) ||
                                                        (request.owner_type &&
                                                            request.owner_type !==
                                                                'PROVIDER')
                                                            ? 'disabled '
                                                            : false
                                                    }
                                                    register={(el: any) =>
                                                        deriv.ref
                                                            ? (deriv.ref.current =
                                                                  el)
                                                            : el
                                                    }
                                                    onFocus={() =>
                                                        setCurrentOffer(
                                                            undefined
                                                        )
                                                    }
                                                    //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                                                    className={`${
                                                        styles.currencyOffer
                                                    } ${
                                                        deriv.saved &&
                                                        styles.saved
                                                    }`}
                                                    //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                                                    onChange={(
                                                        maskedvalue: string,
                                                        floatValue: number
                                                    ) => {
                                                        deriv.offer.price =
                                                            floatValue
                                                        deriv.new_offer_price =
                                                            floatValue
                                                        setup_offer({
                                                            maskedvalue,
                                                            floatValue,
                                                            deriv,
                                                            main: prod,
                                                        })
                                                    }}
                                                    onBlur={() =>
                                                        save(
                                                            () =>
                                                                (deriv.saved =
                                                                    true)
                                                        )
                                                    }
                                                />
                                            )}
                                            <div
                                                className={
                                                    styles['price-actions']
                                                }
                                            >
                                                {can(
                                                    'READ_HISTORY',
                                                    'REQUEST_PRODUCT'
                                                ) &&
                                                    deriv.last_offer_price >
                                                        0 && (
                                                        <IconReact
                                                            type="graphVertical"
                                                            title="Histórico de ofertas"
                                                            className={
                                                                styles.previous
                                                            }
                                                            // onClick={() => open_previous_offers_modal(deriv)} />
                                                            onClick={() =>
                                                                handleOpenModalPrevious(
                                                                    deriv
                                                                )
                                                            }
                                                        />
                                                    )}
                                                {
                                                    // is_editable(request) && deriv.last_offer_price > 0 &&
                                                    // (deriv.new_offer_quantity > 0 || !prod.is_generic) &&
                                                    // (is_editable(request) && deriv.last_offer_price > 0 && prod.is_generic) ? <IconReact type='fileCopy' className={styles.copy}
                                                    is_editable(request) &&
                                                    deriv.last_offer_price >
                                                        0 ? (
                                                        <IconReact
                                                            type="fileCopy"
                                                            className={
                                                                styles.copy
                                                            }
                                                            onClick={() =>
                                                                copy_price(
                                                                    deriv,
                                                                    prod
                                                                )
                                                            }
                                                            title="Copiar ultimo preço ofertado"
                                                        />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{ width: 85 }}
                                        className={styles.lastPriceMobile}
                                    >
                                        <span
                                            title={get_last_price_text(deriv)}
                                            className={get_last_price_text_classMobile(
                                                deriv
                                            )}
                                        >
                                            {format_currency(
                                                deriv.last_offer_price
                                            )}
                                        </span>
                                    </div>
                                    <div
                                        style={{ width: 140 }}
                                        className=" text-right"
                                    >
                                        <Input
                                            value={deriv.observations || ''}
                                            placeholder="..."
                                            name="offer-obsMobile"
                                            className={` ${styles.offerObs}${
                                                deriv.obs_saved && styles.saved
                                            }`}
                                            disabled={
                                                !is_editable(request) ||
                                                deriv.new_offer_price == 0
                                                    ? 'disabled '
                                                    : false
                                            }
                                            register={(el: any) =>
                                                deriv.obs_ref
                                                    ? (deriv.obs_ref.current =
                                                          el)
                                                    : el
                                            }
                                            onKeyDown={(e: any) =>
                                                navigate(e, deriv, {
                                                    save,
                                                    save_obs,
                                                })
                                            }
                                            onChange={(evt: any) => {
                                                deriv.observations =
                                                    evt.target.value
                                                setup_offer({
                                                    deriv,
                                                    obs: evt.target.value,
                                                })
                                            }}
                                            onBlur={() =>
                                                save_obs(
                                                    () =>
                                                        (deriv.obs_saved = true)
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                </div>
            ) : (
                <tr key={index} className={styles.wrapperMobile}>
                    <td className="firstTdMobile" data-label="">
                        <div className="headerMobileTablelancar">
                            <p
                                style={{
                                    color: `${
                                        theme?.colors?.primary || '#ff7110'
                                    }`,
                                    textAlign: 'left',
                                }}
                            >
                                {prod.name}
                            </p>
                            <span>#{prod.ean}</span>
                        </div>
                    </td>
                    {/* <td className={styles.itemNameMobileTable} data-label=''>{prod.name}</td> */}
                    {/* <td
            style={{ paddingRight: 18 }}
            data-label='Código' className={prod.is_generic ? styles.dataLabelCenterCodigo : styles.dataLabelCenterCodigoMobile}  >
            <div className={styles.showBrandsAndNAmeDiv}>
              <p className={styles.prodIdTableMobile}>
                {prod.ean}
              </p>
              {prod.is_generic ?
                <p className={prod.is_generic ? styles.showbrands : styles.showbrandsDisabled}
                  data-toggle="modal"
                  //disabled={!prod.is_generic}
                  data-target="#derivatives-modal"
                  onClick={() => { prod.is_generic ? handleOpenModalDerivated() : {} }
                  }>
                  Ver Marcas
                </p> :
                null}
            </div>
          </td> */}
                    <td style={{ paddingRight: 20 }} data-label="">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '5px 10px',
                            }}
                        >
                            {prod.is_generic ? (
                                <p
                                    className={
                                        prod.is_generic
                                            ? styles.showbrands
                                            : styles.showbrandsDisabled
                                    }
                                    data-toggle="modal"
                                    style={{
                                        boxShadow:
                                            '1px 1px 2px   rgba(0,0,0,0.2)',
                                    }}
                                    //disabled={!prod.is_generic}
                                    data-target="#derivatives-modal"
                                    onClick={() => {
                                        prod.is_generic
                                            ? handleOpenModalDerivated()
                                            : {}
                                    }}
                                >
                                    Ver Marcas
                                </p>
                            ) : (
                                <div></div>
                            )}{' '}
                            <p>
                                Qtd requisitada:{' '}
                                <span style={{ fontWeight: 400 }}>
                                    {prod.requested_quantity}
                                </span>
                            </p>
                        </div>
                    </td>
                    {/* {prod.is_generic ?
            <td style={{ paddingRight: 20 }} data-label=' Informe o preço do(a): '>
              {prod.purchase_unity}
            </td>
            :
            <td style={{ paddingRight: 5 }} data-label=' Informe apenas seu preço'>
              <p>-</p>
            </td>
          } */}
                    {/* <td data-label=' Und Compra '>
                        {prod.is_generic ? <p>Informe o preço do(a): {prod.purchase_unity}</p> : ''}
                        {!prod.is_generic ? <p>Informe apenas seu preço</p> : ''}
                    </td> */}

                    {prod.derivatives &&
                        prod.derivatives
                            .filter(is_visible)
                            .map((deriv: any, idx) => (
                                <>
                                    {/* {prod.is_generic ? */}
                                    {true ? (
                                        <td
                                            style={{}}
                                            data-label=""
                                            className="tdMapProds"
                                        >
                                            {prod.is_generic ? (
                                                <div className="mapProdItem">
                                                    <div className="divMarcaMapitem">
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                gap: 20,
                                                            }}
                                                        >
                                                            {prod.is_generic ? (
                                                                <IconReact
                                                                    color={
                                                                        'red'
                                                                    }
                                                                    size={20}
                                                                    type="trash"
                                                                    title="Remover digitação"
                                                                    onClick={() => {
                                                                        is_editable(
                                                                            request
                                                                        ) &&
                                                                            remove_offer(
                                                                                deriv
                                                                            )
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {prod.is_generic && (
                                                                <div
                                                                    className={`${styles.brand} ${styles.hearth}`}
                                                                >
                                                                    {deriv.starred ? (
                                                                        <AiFillHeart
                                                                            size={
                                                                                22
                                                                            }
                                                                            color="red"
                                                                            title="Marca preferencial"
                                                                        />
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {!deriv.starred ? (
                                                                        <div className="on flex">
                                                                            <AiOutlineHeart
                                                                                size={
                                                                                    22
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <span>
                                                            {deriv.brand}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div
                                                className={
                                                    styles.divLastOfferMobile
                                                }
                                            >
                                                <p>
                                                    Último Preço:
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                        title={get_last_price_text(
                                                            deriv
                                                        )}
                                                        className={get_last_price_text_classMobile(
                                                            deriv
                                                        )}
                                                    >
                                                        {/* className={get_last_price_text_class(deriv)}> */}
                                                        {format_currency(
                                                            deriv.last_offer_price
                                                        )}
                                                    </span>
                                                </p>
                                                <div
                                                    className={
                                                        styles['price-actions']
                                                    }
                                                >
                                                    {can(
                                                        'READ_HISTORY',
                                                        'REQUEST_PRODUCT'
                                                    ) &&
                                                        deriv.last_offer_price >
                                                            0 && (
                                                            <div className="buttonActionMobileTable">
                                                                <IconReact
                                                                    type="graphVertical"
                                                                    title="Histórico de ofertas"
                                                                    className={
                                                                        styles.previous
                                                                    }
                                                                    // onClick={() => open_previous_offers_modal(deriv)} />
                                                                    onClick={() =>
                                                                        handleOpenModalPrevious(
                                                                            deriv
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    {is_editable(request) &&
                                                    deriv.last_offer_price >
                                                        0 ? (
                                                        <div className="buttonActionMobileTable">
                                                            <IconReact
                                                                type="fileCopy"
                                                                className={
                                                                    styles.copy
                                                                }
                                                                onClick={() =>
                                                                    copy_price(
                                                                        deriv,
                                                                        prod
                                                                    )
                                                                }
                                                                title="Copiar ultimo preço ofertado"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>

                                            {/* //?-------------------------------------- */}
                                            <div
                                                className={
                                                    styles.divWrapInputsMobile
                                                }
                                                style={{}}
                                            >
                                                {wrap_inputs(deriv, prod)}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 5,
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'buttonActionMobileTable'
                                                        }
                                                        title="Utilizar 4 casas decimais "
                                                    >
                                                        <PiCrosshairDuotone
                                                            size={21}
                                                            onClick={() =>
                                                                handleSetFourDecimals(
                                                                    'offermobile' +
                                                                        idx
                                                                )
                                                            }
                                                            color={
                                                                visiblePricisionInput.includes(
                                                                    'offermobile' +
                                                                        idx
                                                                ) ||
                                                                isPrecisionValue(
                                                                    deriv.new_offer_price
                                                                )
                                                                    ? `${
                                                                          theme
                                                                              ?.colors
                                                                              ?.primary ||
                                                                          '#ff7110'
                                                                      }`
                                                                    : '#c7c7c7'
                                                            }
                                                        />
                                                    </div>

                                                    {isSafari ? (
                                                        <CurrencySafari
                                                            type="tel"
                                                            id={
                                                                index +
                                                                'set-currencyMobile' +
                                                                idx
                                                            }
                                                            name="set-currencyMobile"
                                                            value={
                                                                deriv.new_offer_price
                                                            }
                                                            precision={
                                                                visiblePricisionInput.includes(
                                                                    'offermobile' +
                                                                        idx
                                                                ) ||
                                                                isPrecisionValue(
                                                                    deriv.new_offer_price
                                                                )
                                                                    ? 4
                                                                    : 2
                                                            }
                                                            // onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                                                            onKeyDown={(
                                                                e: any
                                                            ) =>
                                                                navigate(
                                                                    e,
                                                                    deriv,
                                                                    {
                                                                        save,
                                                                        save_obs,
                                                                    },
                                                                    idx,
                                                                    index,
                                                                    length
                                                                )
                                                            }
                                                            disabled={
                                                                (prod.is_generic &&
                                                                    deriv.new_offer_quantity <
                                                                        1) ||
                                                                !is_editable(
                                                                    request
                                                                ) ||
                                                                (request.owner_type &&
                                                                    request.owner_type !==
                                                                        'PROVIDER')
                                                                    ? 'disabled '
                                                                    : false
                                                            }
                                                            register={(
                                                                el: any
                                                            ) =>
                                                                deriv.ref
                                                                    ? (deriv.ref.current =
                                                                          el)
                                                                    : el
                                                            }
                                                            onFocus={() =>
                                                                setCurrentOffer(
                                                                    undefined
                                                                )
                                                            }
                                                            //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                                                            className={`${
                                                                styles.currencyOffer
                                                            } ${
                                                                deriv.saved &&
                                                                styles.saved
                                                            }`}
                                                            //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                                                            onChange={(
                                                                maskedvalue: string,
                                                                floatValue: number
                                                            ) => {
                                                                deriv.offer.price =
                                                                    floatValue
                                                                deriv.new_offer_price =
                                                                    floatValue
                                                                setup_offer({
                                                                    maskedvalue,
                                                                    floatValue,
                                                                    deriv,
                                                                    main: prod,
                                                                })
                                                            }}
                                                            onBlur={() =>
                                                                save(
                                                                    () =>
                                                                        (deriv.saved =
                                                                            true)
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <Currency
                                                            type="tel"
                                                            name="set-currencyMobile"
                                                            id={
                                                                index +
                                                                'set-currencyMobile' +
                                                                idx
                                                            }
                                                            value={
                                                                deriv.new_offer_price
                                                            }
                                                            precision={
                                                                visiblePricisionInput.includes(
                                                                    'offermobile' +
                                                                        idx
                                                                ) ||
                                                                isPrecisionValue(
                                                                    deriv.new_offer_price
                                                                )
                                                                    ? 4
                                                                    : 2
                                                            }
                                                            // onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                                                            onKeyDown={(
                                                                e: any
                                                            ) =>
                                                                navigate(
                                                                    e,
                                                                    deriv,
                                                                    {
                                                                        save,
                                                                        save_obs,
                                                                    },
                                                                    idx,
                                                                    index,
                                                                    length
                                                                )
                                                            }
                                                            disabled={
                                                                (prod.is_generic &&
                                                                    deriv.new_offer_quantity <
                                                                        1) ||
                                                                !is_editable(
                                                                    request
                                                                ) ||
                                                                (request.owner_type &&
                                                                    request.owner_type !==
                                                                        'PROVIDER')
                                                                    ? 'disabled '
                                                                    : false
                                                            }
                                                            register={(
                                                                el: any
                                                            ) =>
                                                                deriv.ref
                                                                    ? (deriv.ref.current =
                                                                          el)
                                                                    : el
                                                            }
                                                            onFocus={() =>
                                                                setCurrentOffer(
                                                                    undefined
                                                                )
                                                            }
                                                            //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                                                            className={`${
                                                                styles.currencyOffer
                                                            } ${
                                                                deriv.saved &&
                                                                styles.saved
                                                            }`}
                                                            //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                                                            onChange={(
                                                                maskedvalue: string,
                                                                floatValue: number
                                                            ) => {
                                                                deriv.offer.price =
                                                                    floatValue
                                                                deriv.new_offer_price =
                                                                    floatValue
                                                                setup_offer({
                                                                    maskedvalue,
                                                                    floatValue,
                                                                    deriv,
                                                                    main: prod,
                                                                })
                                                            }}
                                                            onBlur={() =>
                                                                save(
                                                                    () =>
                                                                        (deriv.saved =
                                                                            true)
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {/* //?-------------------------------------- */}

                                            {/* //?-------------------------------------- */}

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    marginTop: 5,
                                                    paddingLeft: 5,
                                                }}
                                                className=""
                                            >
                                                <p>obs:</p>
                                                <Input
                                                    value={
                                                        deriv.observations || ''
                                                    }
                                                    placeholder="..."
                                                    name="offer-obsMobile"
                                                    className={` ${
                                                        styles.offerObs
                                                    }${
                                                        deriv.obs_saved &&
                                                        styles.saved
                                                    }`}
                                                    disabled={
                                                        !is_editable(request) ||
                                                        deriv.new_offer_price ==
                                                            0
                                                            ? 'disabled '
                                                            : false
                                                    }
                                                    register={(el: any) =>
                                                        deriv.obs_ref
                                                            ? (deriv.obs_ref.current =
                                                                  el)
                                                            : el
                                                    }
                                                    onKeyDown={(e: any) =>
                                                        navigate(e, deriv, {
                                                            save,
                                                            save_obs,
                                                        })
                                                    }
                                                    onChange={(evt: any) => {
                                                        deriv.observations =
                                                            evt.target.value
                                                        setup_offer({
                                                            deriv,
                                                            obs: evt.target
                                                                .value,
                                                        })
                                                    }}
                                                    onBlur={() =>
                                                        save_obs(
                                                            () =>
                                                                (deriv.obs_saved =
                                                                    true)
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                    ) : null}

                                    {/* <td data-label=''
                >
                  <div className={styles.TdEmbePreco}
                  >
                    <p>Qtd. Emb.</p>
                    <p>Preço</p>
                  </div>
                </td> */}

                                    {/* <td data-label='' style={{}} className={styles.dataLabelCenter}>
                <div
                  className={styles.divWrapInputsMobile}
                  style={{}}>
                  {wrap_inputs(deriv, prod)}
                  <div className={styles.crosshairContainer} title='Utilizar 4 casas decimais '>
                    <PiCrosshairDuotone
                      size={21}
                      onClick={() => handleSetFourDecimals('offermobile' + idx)}
                      color={visiblePricisionInput.includes('offermobile' + idx) ||
                        isPrecisionValue(deriv.new_offer_price)
                        ?
                        '#ff7110' : '#c7c7c7'
                      } />
                  </div>

                  {isSafari ?
                    <CurrencySafari
                      type='tel'
                      id={index + 'set-currencyMobile' + idx}
                      name="set-currencyMobile"
                      value={deriv.new_offer_price}
                      precision={visiblePricisionInput.includes('offermobile' + idx) || isPrecisionValue(deriv.new_offer_price) ? 4 : 2}
                      // onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                      onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, idx, index, length)}
                      disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
                        'disabled ' : false}
                      register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                      onFocus={() => setCurrentOffer(undefined)}
                      //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                      className={`${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                      //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                      onChange={(maskedvalue: string, floatValue: number) => {

                        deriv.offer.price = floatValue
                        deriv.new_offer_price = floatValue
                        setup_offer({ maskedvalue, floatValue, deriv, main: prod })
                      }}
                      onBlur={() => save(() => deriv.saved = true)} />
                    :
                    <Currency
                      type='tel'
                      name="set-currencyMobile"
                      id={index + 'set-currencyMobile' + idx}
                      value={deriv.new_offer_price}
                      precision={visiblePricisionInput.includes('offermobile' + idx) || isPrecisionValue(deriv.new_offer_price) ? 4 : 2}
                      // onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                      onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, idx, index, length)}
                      disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
                        'disabled ' : false}
                      register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                      onFocus={() => setCurrentOffer(undefined)}
                      //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                      className={`${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                      //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                      onChange={(maskedvalue: string, floatValue: number) => {

                        deriv.offer.price = floatValue
                        deriv.new_offer_price = floatValue
                        setup_offer({ maskedvalue, floatValue, deriv, main: prod })
                      }}
                      onBlur={() => save(() => deriv.saved = true)} />
                  }
                </div>
              </td> */}

                                    {/* <td style={{}} data-label=''>
                <div className={styles.divLastOfferMobile}>
                  <p>Último Preço</p>
                  <span title={get_last_price_text(deriv)}
                    className={get_last_price_text_classMobile(deriv)}>

                    {format_currency(deriv.last_offer_price)}
                  </span>
                  <div className={styles['price-actions']}>
                    {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 &&
                      <IconReact
                        type='graphVertical'
                        title="Histórico de ofertas"
                        className={styles.previous}

                        onClick={() => handleOpenModalPrevious(deriv)} />
                    }
                    {

                      (is_editable(request) && deriv.last_offer_price > 0) ? <IconReact type='fileCopy' className={styles.copy}
                        onClick={() => copy_price(deriv, prod)}
                        title="Copiar ultimo preço ofertado" />
                        :
                        <></>

                    }
                  </div>
                </div>
              </td> */}

                                    {/* <td data-label='Ult.Preço' className={styles.dataLabelCenter} style={{}} >

                                    <div style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20 }} >
                                        <Currency
                                            value={deriv.new_offer_price}
                                            onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                                            disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
                                                'disabled ' : false}
                                            register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                                            onFocus={() => setCurrentOffer(undefined)}
                                            //className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                                            className={`${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                                            //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                                            onChange={(maskedvalue: string, floatValue: number) => {
                                                deriv.offer.price = floatValue
                                                deriv.new_offer_price = floatValue
                                                setup_offer({ maskedvalue, floatValue, deriv, main: prod })
                                            }}
                                            onBlur={() => save(() => deriv.saved = true)} />
                                        <div className={styles['price-actions']}>
                                            {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 &&
                                                <IconReact
                                                    type='graphVertical'
                                                    title="Histórico de ofertas"
                                                    className={styles.previous}
                                                    // onClick={() => open_previous_offers_modal(deriv)} />
                                                    onClick={() => handleOpenModalPrevious(deriv)} />
                                            }
                                            {
                                                // is_editable(request) && deriv.last_offer_price > 0 &&
                                                // (deriv.new_offer_quantity > 0 || !prod.is_generic) &&
                                                (is_editable(request) && deriv.last_offer_price > 0 && prod.is_generic) ? <IconReact type='fileCopy' className={styles.copy}
                                                    onClick={() => copy_price(deriv, prod)}
                                                    title="Copiar ultimo preço ofertado" />
                                                    :
                                                    <></>

                                            }
                                        </div>

                                    </div>
                                </td> */}

                                    {/* <td style={{}} data-label='ult preço '>
                                    <span title={get_last_price_text(deriv)}
                                        className={get_last_price_text_class(deriv)}>
                                        {format_currency(deriv.last_offer_price)}
                                    </span>
                                </td> */}
                                    {/* <td
                style={{ paddingRight: 18 }}
                className={styles.dataLabelCenterObsMobile}
                data-label='Observação'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: 10 }} className=" text-center">
                  <Input value={deriv.observations || ''}
                    placeholder="..."
                    name="offer-obsMobile"
                    className={` ${styles.offerObs}${deriv.obs_saved && styles.saved}`}
                    disabled={(!is_editable(request) || deriv.new_offer_price == 0) ? 'disabled ' : false}
                    register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
                    onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
                    onChange={(evt: any) => {
                      deriv.observations = evt.target.value
                      setup_offer({ deriv, obs: evt.target.value })
                    }}
                    onBlur={() => save_obs(() => deriv.obs_saved = true)} />
                </div>
              </td> */}
                                </>
                            ))}
                </tr>
            )}
        </>
    )

    //return prod ? (
    //    <div style={style} className={styles.wrapper}>
    //        <div key={prod.id} className={`row product-header bg-light-gray ${styles.main}`}>
    //            <div className="col">{prod.ean}</div>
    //            <div className={`${styles.circle} col`}>
    //                <p className={styles.showbrands}
    //                    data-toggle="modal"
    //                    //disabled={!prod.is_generic}
    //                    data-target="#derivatives-modal"
    //                    onClick={() => {prod.is_generic ? setCurrentProduct(prod): {}}
    //                }>
    //                    Ver Marcas
    //                </p>
    //                <BsCircleFill />
    //                <span style={{ width: "50px" }}>{prod.name}</span>
    //            </div>
    //            <div className="col">
    //            </div>
    //            <div className="col text-lowercase">
    //                {!prod.is_generic ? 'Informe apenas seu preço' : ''}
    //                {prod.is_generic ? `Informe o preço do(a): ${prod.purchase_unity}` : ''}
    //            </div>
    //            <div className="col requested-quantity"><p className="w-100">Qtd {prod.requested_quantity}</p></div>
    //        </div>
    //        {prod.derivatives && prod.derivatives.filter(is_visible).map((deriv: any) =>
    //            <div key={`${deriv.id}-deriv`} className={`row product-deriv ${styles.brand}`}>
    //                <div className="col">

    //                </div>
    //                <div className="col">
    //                    {prod.is_generic ? <FiTrash title="Remover digitação"
    //                        onClick={() => { is_editable(request) && remove_offer(deriv) }} /> : ''}
    //                    {prod.is_generic ? <div className={`brand ${styles.hearth}`}>
    //                        {deriv.starred ? <AiFillHeart title="Marca preferencial" /> : ''}
    //                        {!deriv.starred ? <div className="on flex"><AiOutlineHeart /></div> : ''}
    //                        {deriv.brand}
    //                    </div> : ''}
    //                </div>
    //                <div className="col">
    //                    <div className="wrap">
    //                        {wrap_inputs(deriv, prod)}
    //                    </div>
    //                </div>
    //                <div className="col">
    //                    <Currency value={deriv.new_offer_price}
    //                        onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
    //                        disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
    //                            'disabled ' : false}
    //                        register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
    //                        onFocus={() => setCurrentOffer(undefined)}
    //                        className={`offer-amount thin ${deriv.saved && styles.saved}`}
    //                        onChange={(maskedvalue: string, floatValue: number) => {
    //                            deriv.offer.price = floatValue
    //                            deriv.new_offer_price = floatValue
    //                            setup_offer({ maskedvalue, floatValue, deriv, main: prod })
    //                        }}
    //                        onBlur={() => save(() => deriv.saved = true)} />
    //                    <div className={styles['price-actions']}>
    //                        {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 && <RiNumbersLine
    //                            title="Histórico de ofertas"
    //                            className={styles.previous} onClick={() => open_previous_offers_modal(deriv)} />}
    //                        {is_editable(request) && deriv.last_offer_price > 0 &&
    //                            (deriv.new_offer_quantity > 0 || !prod.is_generic) &&
    //                            <FaRegCopy className={styles.copy}
    //                                onClick={() => copy_price(deriv, prod)}
    //                                title="Copiar ultimo preço ofertado" />}
    //                    </div>
    //                </div>
    //                <div className="col text-center last-price">
    //                    <span title={get_last_price_text(deriv)}
    //                        className={get_last_price_text_class(deriv)}>
    //                        {format_currency(deriv.last_offer_price)}
    //                    </span>
    //                </div>
    //                <div className="col text-right">
    //                    <Input value={deriv.observations || ''}
    //                        placeholder="..."
    //                        name="offer-obs"
    //                        className={`thin ${deriv.obs_saved && styles.saved}`}
    //                        disabled={(!is_editable(request) || deriv.new_offer_price == 0) ? 'disabled ' : false}
    //                        register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
    //                        onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
    //                        onChange={(evt: any) => {
    //                            deriv.observations = evt.target.value
    //                            setup_offer({ deriv, obs: evt.target.value })
    //                        }}
    //                        onBlur={() => save_obs(() => deriv.obs_saved = true)} />
    //                </div>
    //            </div>
    //        )}
    //    </div>) : ''
}
