/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import ButtonV3 from 'components/Buttonv3'
import ModalComponent from 'components/modal-v2'
import * as $ from 'jquery'
import React, { useEffect, useState } from 'react'
import ProviderService from 'services/v3/ProviderService'
import UserService from 'services/v3/UserService'
import { v4 as generateUUID } from 'uuid'
import './index.scss'
import { ReactComponent as Cardadd } from '../../../../../assets/cardadd.svg'
import { ReactComponent as Docadd } from '../../../../../assets/add-doc.svg'
import { ReactComponent as Dateadd } from '../../../../../assets/Vectordateadd.svg'
import { Input } from 'components/input'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { IoMdInformationCircle } from 'react-icons/io'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { BiSend, BiTrash } from 'react-icons/bi'
import { HiPencil } from 'react-icons/hi'
import { SalesTaxesService } from 'services/v2/sales.taxes'
import { ToastContainer, toast } from 'react-toastify'
import { MdAddCard } from 'react-icons/md'
import { BsCalendarDate } from 'react-icons/bs'

export const WeekdayModalV2 = ({ isVisible, onCloseCallback, user }: any) => {
    const [weekdays, setWeekdays] = useState<any[]>([])
    const [settings, setSettings] = useState<any>({ before: '5', after: '8' })
    const [blacklist, setBlacklist] = useState<any[]>([])
    const [whitelist, setWhitelist] = useState<any[]>([])
    const [salesTaxes, setSalesTaxes] = useState<any>()
    const [visibleView, setVisibleView] = useState('DATES')
    const [reload, setReload] = useState(false)
    const [, updateState] = React.useState<any>()

    const defaultparams = {
        description: '',
        value: '',
        value_type: '%',
        id: null,
    }

    const [formParams, setFormParams] = useState(defaultparams)

    useEffect(() => {
        handleGetTaxes()
    }, [reload])

    const forceUpdate = React.useCallback(() => updateState({}), [])

    const days = {
        0: { label: 'Domingo', value: 'sun' },
        1: { label: 'Segunda', value: 'mon' },
        2: { label: 'Terça', value: 'tue' },
        3: { label: 'Quarta', value: 'wed' },
        4: { label: 'Quinta', value: 'thu' },
        5: { label: 'Sexta', value: 'fri' },
        6: { label: 'Sábado', value: 'sat' },
    }

    const modal = {
        styles: { maxWidth: '80vw' },
        id: 'weekday-modal-mine',
        title: `Parâmetros de venda direta`,
    }

    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
        loadRegisters()
    }, [isVisible])

    const loadRegisters = async () => {
        try {
            let data = await ProviderService.deliveryWeekdays(
                user.providerId,
                user.id
            )
            setWeekdays(data)

            const BEFORE_KEY = 'DAYS_TO_DELIVER_BEFORE_HOUR_LIMIT'
            const AFTER_KEY = 'DAYS_TO_DELIVER_AFTER_HOUR_LIMIT'
            user.id != undefined && (data = await UserService.settings(user.id))
            const before =
                data.find((d: any) => d.key === BEFORE_KEY)?.value || '2'
            const after =
                data.find((d: any) => d.key === AFTER_KEY)?.value || '3'
            setSettings({ before, after })

            data = await ProviderService.deliveryDatesBlacklist(
                user.providerId,
                user.id
            )
            setBlacklist(data)

            data = await ProviderService.deliveryDatesWhitelist(
                user.providerId,
                user.id
            )
            setWhitelist(data)
        } catch (e) {
            console.log('Error to load register', e)
        }
    }

    function handleChangeWeekday(event, weekday) {
        weekday.hour = event.target.value
        forceUpdate()
    }

    function maskHour(weekday) {
        if (typeof weekday.hour == 'number')
            return weekday.hour.toFixed(2).replace('.', ':')
        else if (typeof weekday.hour == 'string') {
            weekday.hour = weekday.hour.replace('.', ':')
            weekday.hour = weekday.hour.replace(',', ':')
            weekday.hour = weekday.hour.replace(';', ':')
            if (weekday.hour.length == 0 || weekday.hour.length > 5)
                return '00:00'
            else if (weekday.hour.length == 1) return '0' + weekday.hour + ':00'
            else if (weekday.hour.length == 2) return weekday.hour + ':00'
            else if (weekday.hour.length == 3)
                return (
                    weekday.hour[0] +
                    weekday.hour[1] +
                    ':' +
                    weekday.hour[2] +
                    '0'
                )
            else if (weekday.hour.length == 4)
                return (
                    weekday.hour[0] +
                    weekday.hour[1] +
                    ':' +
                    weekday.hour[2] +
                    weekday.hour[3]
                )
        }
        return weekday.hour
    }

    const editWeekDay = (weekday: any, value: boolean) => {
        weekday.edit = value
        forceUpdate()
        return true
    }

    const saveWeekDays = async (weekday) => {
        editWeekDay(weekday, false)
        await ProviderService.saveDeliveryWeekdays(
            user.providerId,
            weekdays,
            user.id
        )
    }

    const editSettingBefore = (value) => {
        setSettings({
            before: settings.before,
            after: settings.after,
            beforeEdit: value,
        })
    }

    const editSettingAfter = (value) => {
        setSettings({
            before: settings.before,
            after: settings.after,
            afterEdit: value,
        })
    }

    const changeSettingsEditBefore = (event) => {
        setSettings({
            before: event.target.value,
            after: settings.after,
            beforeEdit: settings.beforeEdit,
        })
    }

    const changeSettingsEditAfter = (event) => {
        setSettings({
            before: settings.before,
            after: event.target.value,
            afterEdit: settings.afterEdit,
        })
    }

    const saveSettingsBefore = async () => {
        editSettingBefore(false)
        const payload = [
            {
                key: 'DAYS_TO_DELIVER_BEFORE_HOUR_LIMIT',
                value: settings.before,
            },
            {
                key: 'DAYS_TO_DELIVER_AFTER_HOUR_LIMIT',
                value: settings.after,
            },
        ]
        await UserService.updateSettings(user.id, payload)
    }

    const onAddDate = () => {
        setBlacklist((bl) => [
            ...bl,
            { id: generateUUID(), date: '2022-01-01' },
        ])
    }

    const onAddWhiteDate = () => {
        setWhitelist((bl) => [
            ...bl,
            { id: generateUUID(), date: '2022-01-01' },
        ])
    }

    const setEditDate = (date, value) => {
        date.isEdit = value
        forceUpdate()
    }

    const editdate = (event, date) => {
        date.date = event.target.value
        forceUpdate()
    }

    const onRemoveDate = async (id: string) => {
        const newList = blacklist.filter((b) => b.id !== id)
        setBlacklist(newList)
        const payload: any = []
        for await (const num of newList) {
            payload.push(num.date)
        }
        ProviderService.saveDeliveryDatesBlacklist(
            user.providerId,
            payload,
            user.id
        )
    }

    const onRemoveWhiteDate = async (id: string) => {
        const newList = whitelist.filter((b) => b.id !== id)
        setWhitelist(newList)
        const payload: any = []
        for await (const num of newList) {
            payload.push(num.date)
        }
        ProviderService.saveDeliveryDatesWhitelist(
            user.providerId,
            payload,
            user.id
        )
    }

    const saveDates = async (date) => {
        const payload: any = []
        for await (const num of blacklist) {
            payload.push(num.date)
        }
        ProviderService.saveDeliveryDatesBlacklist(
            user.providerId,
            payload,
            user.id
        )
        if (date) return setEditDate(date, false)
    }

    const saveWhiteDates = async (date) => {
        const payload: any = []
        for await (const num of whitelist) {
            payload.push(num.date)
        }
        ProviderService.saveDeliveryDatesWhitelist(
            user.providerId,
            payload,
            user.id
        )
        if (date) return setEditDate(date, false)
    }
    const closeModal = () => {
        onCloseCallback()
        setTimeout(() => {
            setVisibleView('DATES')
        }, 1000)
    }

    useEffect(() => {
        if (visibleView == 'SALESTAXES') {
            handleGetTaxes()
        }
    }, [visibleView])
    async function handleGetTaxes() {
        let salesTaxesdata = await SalesTaxesService.getSalesTaxes()
        setSalesTaxes(salesTaxesdata)
    }
    async function handleCreateTaxes(data) {
        await SalesTaxesService.createSalesTaxes(data)
            .then((res) => {
                setVisibleView('SALESTAXES')
                toast.success('Acréscimo criado')
            })
            .catch((error) => toast.error(error.response.data.message))
    }
    async function handledeleteTaxes(id) {
        await SalesTaxesService.deleteSalesTaxes(id)
            .then((res) => setReload(!reload))
            .catch((error) => toast.error(error.response.data.message))
    }
    function handleEditViewTaxes(data) {
        setFormParams(data)
        setVisibleView('ADDSALES')
    }
    async function handleEditSalesTaxes(data) {
        await SalesTaxesService.editSalesTaxes(data.id, data)
            .then((res) => {
                setVisibleView('SALESTAXES')
                toast.success('Acréscimo editado')
            })
            .catch((error) => toast.error(error.response.data.message))
    }
    function handleViewCreateTaxes() {
        setFormParams(defaultparams)
        setVisibleView('ADDSALES')
    }
    return (
        <ModalComponent
            modal={modal}
            show={isVisible}
            close={closeModal}
            className={'modal-dialog'}
            footerNull
        >
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="" style={{ overflow: 'auto', padding: 20 }}>
                <div className="rowtabs">
                    <div
                        className="nav nav-tabs"
                        style={{ borderBottom: 'none' }}
                    >
                        <a
                            onClick={() => setVisibleView('DATES')}
                            className={
                                visibleView == 'DATES'
                                    ? 'nav-item ,nav-link, active'
                                    : 'nav-item ,nav-link'
                            }
                        >
                            <BsCalendarDate />
                            Datas e horários
                        </a>
                        <a
                            onClick={() => setVisibleView('SALESTAXES')}
                            className={
                                visibleView == 'SALESTAXES' ||
                                visibleView == 'ADDSALES'
                                    ? 'nav-item ,nav-link, active'
                                    : 'nav-item ,nav-link'
                            }
                        >
                            <MdAddCard size={18} />
                            Acréscimos na venda
                        </a>
                    </div>

                    <div>
                        {visibleView == 'SALESTAXES' ? (
                            <ButtonV3
                                filled
                                Icon={
                                    <Docadd className="iconButtonV3"></Docadd>
                                }
                                title="Adicionar"
                                onClick={() => handleViewCreateTaxes()}
                            />
                        ) : null}
                    </div>
                </div>
                {visibleView == 'DATES' ? (
                    <div>
                        <div className="modalv2-section-title">
                            Horário máximo para realização do pedido (0h - 23h)
                        </div>
                        <div className="modalv2-section-content">
                            {weekdays.map((weekday) => (
                                <div
                                    className="modalv2-editweek-containear"
                                    key={weekday.id}
                                >
                                    <div className="modalv2-editweek-content">
                                        <div className="modalv2-editweek-content-weekday">
                                            {days[weekday.day]?.label}
                                        </div>
                                        {!weekday.edit ? (
                                            <div className="modalv2-editweek-content-hour">
                                                {weekday.hour}
                                            </div>
                                        ) : (
                                            <input
                                                className="modalv2-editweek-content-hour-edit"
                                                type="text"
                                                value={weekday.hour}
                                                onChange={(e) =>
                                                    handleChangeWeekday(
                                                        e,
                                                        weekday
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    {!weekday.edit ? (
                                        <div
                                            className="modalv2-editweek-button"
                                            onClick={() =>
                                                editWeekDay(weekday, true)
                                            }
                                        >
                                            Editar
                                        </div>
                                    ) : (
                                        <div
                                            className="modalv2-editweek-save"
                                            onClick={() =>
                                                saveWeekDays(weekday)
                                            }
                                        >
                                            Salvar
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="modalv2-section-title">
                            Quantidade mínima de dias para realizar entrega
                        </div>
                        <div className="modalv2-section-content">
                            <div className="modalv2-editweek-containear modalv2-editminday">
                                <div className="modalv2-editweek-content">
                                    <div className="modalv2-editweek-content-weekday">
                                        Antes do horário limite
                                    </div>
                                    {!settings.beforeEdit ? (
                                        <div className="modalv2-editweek-content-hour">
                                            {settings.before}
                                        </div>
                                    ) : (
                                        <input
                                            className="modalv2-editweek-content-hour-edit"
                                            type="text"
                                            value={settings.before}
                                            onChange={(e) =>
                                                changeSettingsEditBefore(e)
                                            }
                                        />
                                    )}
                                </div>
                                {!settings.beforeEdit ? (
                                    <div
                                        className="modalv2-editweek-button"
                                        onClick={() => editSettingBefore(true)}
                                    >
                                        Editar
                                    </div>
                                ) : (
                                    <div
                                        className="modalv2-editweek-save"
                                        onClick={() => saveSettingsBefore()}
                                    >
                                        Salvar
                                    </div>
                                )}
                            </div>
                            <div className="modalv2-editweek-containear modalv2-editminday">
                                <div className="modalv2-editweek-content">
                                    <div className="modalv2-editweek-content-weekday">
                                        Depois do horário limite
                                    </div>
                                    {!settings.afterEdit ? (
                                        <div className="modalv2-editweek-content-hour">
                                            {settings.after}
                                        </div>
                                    ) : (
                                        <input
                                            className="modalv2-editweek-content-hour-edit"
                                            type="text"
                                            value={settings.after}
                                            onChange={(e) =>
                                                changeSettingsEditAfter(e)
                                            }
                                        />
                                    )}
                                </div>
                                {!settings.afterEdit ? (
                                    <div
                                        className="modalv2-editweek-button"
                                        onClick={() => editSettingAfter(true)}
                                    >
                                        Editar
                                    </div>
                                ) : (
                                    <div
                                        className="modalv2-editweek-save"
                                        onClick={() => saveSettingsBefore()}
                                    >
                                        Salvar
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modalv2-row">
                            <div className="modalv2-section-title">
                                Lista de datas bloqueadas
                            </div>
                            <div
                                className="modalv2-section-addicon-containear"
                                onClick={() => onAddDate()}
                            >
                                <span className="material-icons modalv2-section-addicon">
                                    add_circle_outline
                                </span>
                            </div>
                        </div>
                        <div className="modalv2-section-content">
                            {blacklist.map((date) => (
                                <div
                                    className="modalv2-editweek-containear"
                                    key={date.id}
                                >
                                    <div className="modalv2-editweek-content">
                                        {!date.isEdit ? (
                                            <input
                                                className="modalv2-editweek-content-date"
                                                type="date"
                                                value={date.date}
                                            />
                                        ) : (
                                            <input
                                                className="modalv2-editweek-content-date-edit"
                                                type="date"
                                                value={date.date}
                                                onChange={(e) =>
                                                    editdate(e, date)
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="modalv2-button-row">
                                        {!date.isEdit ? (
                                            <div
                                                className="modalv2-editdate-button"
                                                onClick={() =>
                                                    setEditDate(date, true)
                                                }
                                            >
                                                Editar
                                            </div>
                                        ) : (
                                            <div
                                                className="modalv2-editdate-save"
                                                onClick={() => saveDates(date)}
                                            >
                                                Salvar
                                            </div>
                                        )}
                                        <span
                                            className="material-icons modalv2-editdate-delete"
                                            onClick={() =>
                                                onRemoveDate(date.id)
                                            }
                                        >
                                            delete
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="modalv2-row">
                            <div className="modalv2-section-title">
                                Lista de datas liberadas
                            </div>
                            <div
                                className="modalv2-section-addicon-containear"
                                onClick={() => onAddWhiteDate()}
                            >
                                <span className="material-icons modalv2-section-addicon">
                                    add_circle_outline
                                </span>
                            </div>
                        </div>
                        <div className="modalv2-section-content">
                            {whitelist.map((date) => (
                                <div
                                    className="modalv2-editweek-containear"
                                    key={date.id}
                                >
                                    <div className="modalv2-editweek-content">
                                        {!date.isEdit ? (
                                            <input
                                                className="modalv2-editweek-content-date"
                                                type="date"
                                                value={date.date}
                                            />
                                        ) : (
                                            <input
                                                className="modalv2-editweek-content-date-edit"
                                                type="date"
                                                value={date.date}
                                                onChange={(e) =>
                                                    editdate(e, date)
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="modalv2-button-row">
                                        {!date.isEdit ? (
                                            <div
                                                className="modalv2-editdate-button"
                                                onClick={() =>
                                                    setEditDate(date, true)
                                                }
                                            >
                                                Editar
                                            </div>
                                        ) : (
                                            <div
                                                className="modalv2-editdate-save"
                                                onClick={() =>
                                                    saveWhiteDates(date)
                                                }
                                            >
                                                Salvar
                                            </div>
                                        )}
                                        <span
                                            className="material-icons modalv2-editdate-delete"
                                            onClick={() =>
                                                onRemoveWhiteDate(date.id)
                                            }
                                        >
                                            delete
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
                {visibleView == 'SALESTAXES' ? (
                    <div style={{ minHeight: 400 }}>
                        <div className="NewlistAddSales">
                            <table className="newTableAddSales">
                                <thead>
                                    <tr>
                                        <th className="">Nome do acréscimo</th>
                                        <th>Dado (Valor decimal)</th>
                                        <th className="">Tipo</th>
                                        <th className="text-center">Ações</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {salesTaxes &&
                                        salesTaxes.data.length > 0 &&
                                        salesTaxes.data.map((item: any) => (
                                            <tr>
                                                <td
                                                    data-label={
                                                        'Nome do acréscimo'
                                                    }
                                                    className=""
                                                >
                                                    {item.description}
                                                </td>
                                                <td
                                                    data-label={
                                                        'Dado (Valor decimal)'
                                                    }
                                                    className=""
                                                >
                                                    {item.value}
                                                </td>
                                                <td
                                                    data-label={'Tipo'}
                                                    className=""
                                                >
                                                    {item.value_type === '%'
                                                        ? 'Percentual'
                                                        : 'Valor'}
                                                </td>
                                                <td
                                                    data-label={'Ações'}
                                                    className="text-center"
                                                >
                                                    <div className="divactions">
                                                        <HiPencil
                                                            onClick={() =>
                                                                handleEditViewTaxes(
                                                                    item
                                                                )
                                                            }
                                                            size={22}
                                                            color={'#606060'}
                                                        />
                                                        <BiTrash
                                                            onClick={() =>
                                                                handledeleteTaxes(
                                                                    item.id
                                                                )
                                                            }
                                                            size={22}
                                                            color={'#972111'}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : null}
                {visibleView == 'ADDSALES' ? (
                    <div style={{ marginTop: 30 }}>
                        <div className="formContainer">
                            <div style={{ flex: 1 }}>
                                <Input
                                    infoIconNull={false}
                                    description="Nome do acréscimo"
                                    value={formParams.description}
                                    onChange={(e) =>
                                        setFormParams({
                                            ...formParams,
                                            description: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Input
                                    infoIconNull={false}
                                    description="Dado(Valor decimal)"
                                    value={formParams.value}
                                    onChange={(e) =>
                                        setFormParams({
                                            ...formParams,
                                            value: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="checkboxInput">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p className="c-label">Tipo</p>
                                    <IoMdInformationCircle className="icon" />
                                </div>
                                <div className="checkboxContainer">
                                    <CheckBoxCustom
                                        preventUncheck
                                        description="Valor"
                                        checked={formParams.value_type == '$'}
                                        change={() =>
                                            setFormParams({
                                                ...formParams,
                                                value_type: '$',
                                            })
                                        }
                                    ></CheckBoxCustom>
                                    <CheckBoxCustom
                                        preventUncheck
                                        description="Percentual"
                                        checked={formParams.value_type == '%'}
                                        // change={() => formParams.value_type == '%' ? null : setFormParams({ ...formParams, value_type: '%' })}
                                        change={() =>
                                            setFormParams({
                                                ...formParams,
                                                value_type: '%',
                                            })
                                        }
                                    ></CheckBoxCustom>
                                </div>
                            </div>
                        </div>
                        <div className="formButonsRow">
                            <ButtonV3
                                style={{ padding: '14px 50px' }}
                                title="Voltar"
                                onClick={() => setVisibleView('SALESTAXES')}
                            />
                            <ButtonV3
                                filled
                                style={{ padding: '14px 50px' }}
                                title={formParams.id ? 'Editar' : 'Salvar'}
                                onClick={
                                    formParams.id
                                        ? () => handleEditSalesTaxes(formParams)
                                        : () => handleCreateTaxes(formParams)
                                }
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </ModalComponent>
    )
}
