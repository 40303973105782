import IconReact from 'components/icons'
import styled from 'styled-components'

export const Container = styled.div``

interface editButtonsProps {
    isOdd?: boolean
}

export const ButtonsEdit = styled.div<editButtonsProps>`
    background-color: ${(props) => (props.isOdd ? '#fff' : '#f7f7f7')};
    border-radius: 8px;

    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    gap: 5px;

    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    animation: 0.5s ease 0s 1 slideInFromRight;

    section {
        /* padding: ; */
        cursor: pointer;
        svg {
        }
    }

    :hover {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
`

export const IconEdit = styled(IconReact)`
    cursor: pointer;

    /* Chrome, Safari, Edge, Opera */
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @media only screen and (max-width: 740px) {
        flex-direction: column;
        margin-bottom: 10px;
    }
`
export const EditTd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 5px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    @media only screen and (max-width: 740px) {
        justify-content: flex-end;
        /* width: 100%;
    */

        flex: 1;
        /* width: 100%; */
    }
`

export const ActionTd = styled.td`
    width: 160px;

    @media only screen and (max-width: 740px) {
        width: 100%;
        justify-content: flex-end;
        flex: 1;
    }
`

export const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: space-between;
    align-items: flex-end;
    .DeliveryRouteFilter {
    }
    section {
        display: flex;
        flex-direction: row;
        gap: 25px;
    }
    @media screen and (max-width: 900px) {
        flex-direction: column;
        .DeliveryRouteFilter {
            width: 100%;
        }
        section {
        }
    }
`

export const OpenOrdersButton = styled.div`
    cursor: pointer;
    background-color: var(--primary-opacity);
    padding: 4px 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);

    span {
        font-weight: 500;
    }

    @media only screen and (max-width: 900px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        float: right;
    }
`
