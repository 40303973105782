import RequestService from '../../services/RequestService'
import { ResponseApi } from '../../interfaces/responseApi'
import { RequestDetail } from '../../interfaces/request-detail.model'
import { Request } from '../../interfaces/request'

// TYPES
export const LOAD_REQUESTS_SUCCESS = 'LOAD_REQUESTS_SUCCESS'
export const LOAD_STATUS_LIST = 'LOAD_STATUS_LIST'
export const LOAD_REQUEST_DETAIL = 'LOAD_REQUEST_DETAIL'
export const SET_REQUEST = 'SET_REQUEST'
// Reducer
const initialState = {
    status_list: [
        { status_nome: 'Digitada', status_id: 'TYPED' },
        { status_nome: 'Não Digitada', status_id: 'NOT_TYPED' },
        { status_nome: 'Sem Pedido', status_id: 'NOT_ORDERED' },
        { status_nome: 'Pedido Gerado', status_id: 'ORDERED' },
        { status_nome: 'Sem oferta', status_id: 'NOT_OFFERED' },
        { status_nome: 'Congelada', status_id: 'FROZEN' },
    ],
    states: [
        { name: 'Acre', value: 'AC', est_id: 1 },
        { name: 'Alagoas', value: 'AL', est_id: 2 },
        { name: 'Amazonas', value: 'AM', est_id: 3 },
        { name: 'Amapá', value: 'AP', est_id: 4 },
        { name: 'Bahia', value: 'BA', est_id: 5 },
        { name: 'Ceará', value: 'CE', est_id: 6 },
        { name: 'Distrito Federal', value: 'DF', est_id: 7 },
        { name: 'Espírito Santo', value: 'ES', est_id: 8 },
        { name: 'Goiás', value: 'GO', est_id: 9 },
        { name: 'Maranhão', value: 'MA', est_id: 10 },
        { name: 'Minas Gerais', value: 'MG', est_id: 11 },
        { name: 'Mato Grosso do Sul', value: 'MS', est_id: 12 },
        { name: 'Mato Grosso', value: 'MT', est_id: 13 },
        { name: 'Pará', value: 'PA', est_id: 14 },
        { name: 'Paraíba', value: 'PB', est_id: 15 },
        { name: 'Pernambuco', value: 'PE', est_id: 16 },
        { name: 'Piauí', value: 'PI', est_id: 17 },
        { name: 'Paraná', value: 'PR', est_id: 18 },
        { name: 'Rio de Janeiro', value: 'RJ', est_id: 19 },
        { name: 'Rio Grande do Norte', value: 'RN', est_id: 20 },
        { name: 'Rondônia', value: 'RO', est_id: 21 },
        { name: 'Roraima', value: 'RR', est_id: 22 },
        { name: 'Rio Grande do Sul', value: 'RS', est_id: 23 },
        { name: 'Santa Catarina', value: 'SC', est_id: 24 },
        { name: 'Sergipe', value: 'SE', est_id: 25 },
        { name: 'São Paulo', value: 'SP', est_id: 26 },
        { name: 'Tocantins', value: 'TO', est_id: 27 },
    ],
}

export default function requests_state(state = initialState, action: any) {
    if (action.type === LOAD_REQUESTS_SUCCESS) {
        return { ...state, requests: action.requests }
    }
    if (action.type === LOAD_STATUS_LIST) {
        return { ...state, status_list: action.status_list }
    }
    if (action.type === LOAD_REQUEST_DETAIL) {
        return { ...state, buyer: action.buyer }
    }
    if (action.type === SET_REQUEST) {
        return { ...state, request: action.request }
    }
    return state
}

// ACTIONS
export const load_requests = (params: any) => async (dispatch: any) => {
    try {
        const response = await RequestService.getAll(params)
        dispatch(loadOrdersSuccess(response as ResponseApi))
    } catch {
        dispatch(loadOrdersSuccess(null))
    }
}

export const load_others_requests = (params: any) => (dispatch: any) =>
    RequestService.load_others_request(params)
        .then((response) => dispatch(set_request(response)))
        .catch(() => dispatch(set_request(null)))

export const load_request_products =
    (id: number, seller_id: number) => (dispatch: any) =>
        RequestService.load_request_products(id, seller_id)
            .then((response) => dispatch(set_buyer(response)))
            .catch(() => dispatch(set_buyer(null)))

export const load_request = (id: number) => (dispatch: any) =>
    RequestService.load_request(id)
        .then((response) => dispatch(set_request(response)))
        .catch(() => dispatch(set_request(null)))

const loadOrdersSuccess = (requests: ResponseApi | null) => {
    return { type: LOAD_REQUESTS_SUCCESS, requests }
}

const set_buyer = (buyer: RequestDetail | null) => {
    return { type: LOAD_REQUEST_DETAIL, buyer }
}

const set_request = (request: Request | null) => {
    return { type: SET_REQUEST, request }
}
