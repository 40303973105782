import React, { FC, useState } from 'react'
import DateService from '../../../../services/DateService'
import Utils from '../../../../utils'
import { Product } from '../../../../interfaces/product'
import styles from '../modal-details/index.module.scss'
import {
    Status,
    STATUS_CONFIRMED,
    STATUS_NOT_CONFIRMED,
} from 'components/status'
import { Description } from 'components/PaginationCustomV2/styles'
import { DescriptionRow } from 'components/descriptionRow'
import { DescriptionRowOrderDetail } from './components/descriptionRow'
import './index.scss'
import ButtonV3 from 'components/Buttonv3'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import logo from '../../../../assets/images/logo-sign-in.jpg'
import { mask_cnpj } from 'helpers/mask.helper'
import { useTheme } from 'contexts/whiteLabel/context'

export const OrderDetailsPdf: FC<any> = ({
    isMobileView,
    order,
    order_type,
    pdfRef,
}: any) => {
    const [showFilters, setShowFilters] = useState(false)
    const { theme } = useTheme()

    const get_unit_price = (prod: Product) => {
        let result = prod.unitPrice
        if (order_type === 1 && !prod.main_product_id) {
            result =
                result /
                (prod.main_product_id
                    ? prod.typingQuantity
                    : parseInt(prod.packing.split(' ')[1]))
        }
        return Utils.formatPrice(result)
    }

    const get_packing_price = (prod: Product) => {
        let result =
            prod.unitPrice * (prod.main_product_id ? prod.typingQuantity : 1)
        if (order_type === 0) {
            result =
                result *
                (prod.main_product_id
                    ? 1
                    : parseInt(prod.packing.split(' ')[1]))
        }
        return Utils.formatPrice(result)
    }
    const toogleFilters = () => setShowFilters(!showFilters)
    function getEmbalagemSplit2(string: any) {
        const [un, quant] = string.split(' ')
        return quant
    }
    function getEmbalagemSplit1(string: any) {
        const [un, quant] = string.split(' ')
        return un
    }

    return (
        <div
            ref={pdfRef}
            className="mainContainerModalDetailOrderPdf"
            style={{ padding: 5 }}
        >
            <div className="containerHeaderOrderDetailModal">
                <div className={`${styles.logo}`}>
                    <img src={theme?.imgs?.img_logo || logo} alt="logo" />
                </div>
                <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <strong>Status:</strong>
                        </div>
                        <div>
                            <Status
                                status={
                                    order.status === 'C'
                                        ? STATUS_CONFIRMED
                                        : STATUS_NOT_CONFIRMED
                                }
                            />
                        </div>
                    </div>

                    <DescriptionRowOrderDetail
                        title="Pedido:"
                        value={order.id}
                    />
                    <DescriptionRowOrderDetail
                        title="Solicitação:"
                        value={order.quotationId}
                    />
                    <DescriptionRowOrderDetail
                        title="Data:"
                        value={DateService.formatToDisplayWithTime(
                            new Date(order.date)
                        )}
                    />
                </div>
            </div>
            <div className="containerOrderDetailModalInfosParent">
                <div className="containerOrderDetailModalInfos">
                    <strong className="OrderDetailModalInfosTitle">
                        DADOS DO FORNECEDOR
                    </strong>
                    <p className="titleNameOrderDetailModal">
                        {order.provider && order.provider.socialReason}
                    </p>
                    <p className="subtitleNameOrderDetailModal">
                        {order.provider && order.provider.fantasyName}
                    </p>
                    <DescriptionRowOrderDetail
                        title="CNPJ:"
                        value={order.provider && mask_cnpj(order.provider.cnpj)}
                    />
                    <DescriptionRowOrderDetail
                        title="Telefone:"
                        value={order.provider && order.provider.phone}
                    />
                    <DescriptionRowOrderDetail
                        title="Email:"
                        value={
                            (order.provider &&
                                order.provider.email &&
                                order.provider.email
                                    .split(';')
                                    .map((email: any, k: number) => {
                                        return (
                                            <React.Fragment key={k + email}>
                                                {k > 0 && <br />}
                                                {email}
                                            </React.Fragment>
                                        )
                                    })) ||
                            ''
                        }
                    />
                    <DescriptionRowOrderDetail
                        title="Bairro:"
                        value={order.provider && order.provider.neighborhood}
                    />
                    <DescriptionRowOrderDetail
                        title="Endereço:"
                        value={order.provider && order.provider.address}
                    />
                    <DescriptionRowOrderDetail
                        title="CEP:"
                        value={order.provider && order.provider.cep}
                    />
                </div>

                <div className="containerOrderDetailModalInfos">
                    <strong className="OrderDetailModalInfosTitle">
                        DADOS DO CLIENTE
                    </strong>
                    <p className="titleNameOrderDetailModal">
                        {order.customer && order.customer.socialReason}
                    </p>
                    <p className="subtitleNameOrderDetailModal">
                        {order.nomeFantasia && order.nomeFantasia}
                    </p>

                    <DescriptionRowOrderDetail
                        title="CNPJ:"
                        value={order.customer && mask_cnpj(order.customer.cnpj)}
                    />

                    <div className="divRowClientOrderDetail">
                        <DescriptionRowOrderDetail
                            title="Telefone:"
                            value={order.customer && order.customer.phone}
                        />
                        <DescriptionRowOrderDetail
                            title="Email:"
                            value={order.customer && order.customer.email}
                        />
                    </div>

                    <DescriptionRowOrderDetail
                        title="Cidade:"
                        value={order.customer && order.customer.city.name}
                    />
                    <DescriptionRowOrderDetail
                        title="Bairro:"
                        value={order.customer && order.customer.neighborhood}
                    />

                    <DescriptionRowOrderDetail
                        title="Endereço:"
                        value={order.customer && order.customer.address}
                    />
                    <DescriptionRowOrderDetail
                        title="CEP:"
                        value={order.customer && order.customer.cep}
                    />
                </div>
            </div>

            {order.delivery && (
                <div style={{ marginTop: 12 }}>
                    <div>
                        <div
                            style={{
                                borderBottom: '1px solid #e7e7e7',
                                width: '100%',
                                padding: '5px 0px',
                            }}
                        >
                            <strong className="OrderDetailModalInfosTitle">
                                OBSERVAÇÃO FORNECEDOR
                            </strong>
                        </div>
                        <div className="containerOrderDetailModalInfosRowObs">
                            <DescriptionRow
                                title="Plano de pagamento: "
                                value={
                                    order.delivery && order.delivery.paymentWay
                                }
                            />
                            <DescriptionRow
                                title="Data Prevista da Entrega: "
                                value={
                                    order.delivery &&
                                    DateService.formatToDisplay(
                                        new Date(order.delivery.date)
                                    )
                                }
                            />
                            <DescriptionRow
                                title="Observação: "
                                value={
                                    order.delivery &&
                                    order.delivery.observations
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
            <div style={{ marginTop: 12 }}>
                <strong className="OrderDetailModalInfosTitle">
                    PRODUTOS DO PEDIDO
                </strong>
            </div>
            <div className="NewlistModalOrderDetailsPDF">
                <table className={'newTableModalOrderDetailsPDF'}>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Observação</th>
                            <th>Embalagem</th>
                            <th>Quantidade</th>
                            <th>Un. Compra</th>
                            <th>Preço EMB</th>
                            <th>Preço UN</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.products &&
                            order.products.map(
                                (product: Product, i: number) => {
                                    return (
                                        <tr key={`o-prod-${i}`}>
                                            <td data-label="Código">
                                                {product.ean}
                                            </td>
                                            <td
                                                data-label="Nome"
                                                className={styles['prod-name']}
                                            >
                                                <span>{product.name}</span>
                                                {product.main_product_id && (
                                                    <strong>
                                                        #{product.brand_name}
                                                    </strong>
                                                )}
                                            </td>
                                            <td data-label="Observação">
                                                <span
                                                    className={
                                                        styles['prod-obs']
                                                    }
                                                >
                                                    {product.observations
                                                        ? product.observations
                                                        : '- -'}
                                                </span>
                                            </td>
                                            <td data-label="Embalagem">
                                                {product.main_product_id
                                                    ? product.typingQuantity
                                                    : getEmbalagemSplit2(
                                                          product.packing
                                                      )}
                                            </td>{' '}
                                            <td data-label="Quantidade">
                                                {product.quantity}
                                            </td>
                                            <td data-label="Un. Compra">
                                                {product.prin_pro_unidade_compra
                                                    ? product.prin_pro_unidade_compra
                                                    : getEmbalagemSplit1(
                                                          product.packing
                                                      )}
                                            </td>
                                            <td data-label="Preço EMB">
                                                <span>
                                                    {get_packing_price(product)}
                                                </span>
                                            </td>
                                            <td data-label="Preço UN">
                                                <span>
                                                    {get_unit_price(product)}
                                                </span>
                                            </td>
                                            <td data-label="Total">
                                                <span>
                                                    {Utils.formatPrice(
                                                        parseFloat(
                                                            product.totalPrice
                                                                .toString()
                                                                .replace(
                                                                    ',',
                                                                    ''
                                                                )
                                                        )
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                    </tbody>
                </table>
            </div>
            <div className="containerOrderDetailModalfooter">
                <DescriptionRow
                    title="Total do pedido:"
                    value={Utils.formatPrice(order.value)}
                />
                <DescriptionRow title="Vendedor:" value={order.salesmanName} />
                <DescriptionRow
                    title="Observação:"
                    value={order.observations}
                />
            </div>
        </div>
    )
    // return (
    //     <div>
    //         <div className={ `row mb-3 shadow p-3 ${styles.container}` }>
    //             <div className={ `col col-9 mb-2 ${styles.logo}` }>
    //                 <img src="/images/logo-sign-in.jpg" alt="logo" />
    //             </div>
    //             <div className="col">
    //                 <table className={ `table ${styles.resume}` }>
    //                     <tbody>
    //                         <tr>
    //                             <td><strong>Status:</strong></td>
    //                             <td>
    //                                 <Status status={ order.status === 'C'
    //                                     ? STATUS_CONFIRMED
    //                                     : STATUS_NOT_CONFIRMED }
    //                                 />
    //                             </td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Pedido:</strong></td>
    //                             <td>{ order.id }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Solicitação:</strong></td>
    //                             <td>{ order.quotationId }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Data:</strong></td>
    //                             <td>{ DateService.formatToDisplayWithTime(new Date(order.date)) }</td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div>
    //             <div className="col col-6">
    //                 <table className={ `table ${styles.provider}` }>
    //                     <thead>
    //                         <tr>
    //                             <th colSpan={ 2 } className={ styles.title }>Dados do Fornecedor</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         <tr>
    //                             <td colSpan={ 2 }>{ order.provider && order.provider.socialReason }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>CNPJ</strong></td>
    //                             <td>{ order.provider && order.provider.cnpj }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Telefone</strong></td>
    //                             <td>{ order.provider && order.provider.phone }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Email: </strong> </td>
    //                             <td>
    //                                 { order.provider && order.provider.email &&
    //                                 order.provider.email.split(';').map((email: any, k: number) => {
    //                                     return <>{ k > 0 && <br key={ k+email } /> }{ email }</>
    //                                 }) }
    //                             </td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Bairro</strong></td>
    //                             <td>{ order.provider && order.provider.neighborhood }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Endereço</strong></td>
    //                             <td>{ order.provider && order.provider.address }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>CEP</strong></td>
    //                             <td>{ order.provider && order.provider.cep }</td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div>
    //             <div className="col col-6">
    //                 <table className={ `table ${styles.client}` }>
    //                     <thead>
    //                         <tr>
    //                             <th colSpan={ 2 } className={ styles.title }>Cliente</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         <tr>
    //                             <td width="61%"><strong style={ { textDecoration: 'underline' } }>{ order.customer && order.customer.socialReason }</strong></td>
    //                             <td width="39%"><strong>CNPJ</strong>: { order.customer && order.customer.cnpj }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Telefone</strong>: { order.customer && order.customer.phone }</td>
    //                             <td><strong>Email</strong>: { order.customer && order.customer.email }</td>
    //                         </tr>
    //                         <tr>
    //                             <td><strong>Cidade:</strong></td>
    //                             <td>{ order.customer && order.customer.city.name }</td>
    //                         </tr>
    //                         <tr>
    //                             <td colSpan={ 2 }>
    //                                 <strong>Endereço: </strong>
    //                                 { order.customer && order.customer.address }
    //                             </td>
    //                         </tr>
    //                         <tr>
    //                             <td>
    //                                 <strong>Bairro: </strong> { order.customer && order.customer.neighborhood }
    //                             </td>
    //                             <td>
    //                                 <strong>CEP</strong> { order.customer && order.customer.cep } - &nbsp;
    //                                 { order.customer && order.customer.state.name }
    //                             </td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div>
    //             { order.delivery && <div className="col col-12">
    //                 <table className={ `table ${styles.obs}` }>
    //                     <thead>
    //                         <tr>
    //                             <th colSpan={ 2 } className={ styles.title }>Observação Fornecedor</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         <tr>
    //                             <td>
    //                                 <strong>Plano de pagamento</strong>: { order.delivery && order.delivery.paymentWay }
    //                             </td>
    //                             <td>
    //                                 <strong>Data Prevista da Entrega</strong>:
    //                                 { order.delivery && DateService.formatToDisplay(new Date(order.delivery.date)) }
    //                             </td>
    //                             <td>
    //                                 <strong>Observação</strong>: { order.delivery && order.delivery.observations }
    //                             </td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div> }
    //             <div className="col col-12">
    //                 <table className={ `table ${styles.products}` }>
    //                     <thead>
    //                         <tr>
    //                             <th colSpan={ 7 } className={ styles.title }>Produtos do Pedido</th>
    //                         </tr>
    //                         <tr>
    //                             <th>Código</th>
    //                             <th>Nome</th>
    //                             <th className="text-center">Observação</th>
    //                             <th>Embalagem</th>
    //                             <th>Quantidade</th>
    //                             <th>Preço EMB</th>
    //                             <th>Preço UN</th>
    //                             <th>Total</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         { order.products && order.products.map((product: Product, i: number) => {
    //                             return (
    //                                 <tr key={ `o-prod-${i}` }>
    //                                     <td>{ product.ean }</td>
    //                                     <td className={ styles['prod-name'] }>
    //                                         <span>{ product.name }</span>
    //                                         { product.main_product_id && <strong>#{ product.brand_name }</strong> }
    //                                     </td>
    //                                     <td>
    //                                         <span className={ styles['prod-obs'] }>{ product.observations }</span>
    //                                     </td>
    //                                     <td>{ product.main_product_id ? product.typingQuantity : product.packing }</td>
    //                                     <td>{ product.quantity }</td>
    //                                     <td><span>{ get_packing_price(product) }</span></td>
    //                                     <td><span>{ get_unit_price(product) }</span></td>
    //                                     <td>
    //                                         <span>
    //                                             { Utils.formatPrice(
    //                                                 parseFloat(product.totalPrice.toString().replace(',', ''))
    //                                             ) }
    //                                         </span>
    //                                     </td>
    //                                 </tr>
    //                             )
    //                         }) }
    //                     </tbody>
    //                 </table>
    //             </div>
    //             <div className="col col-12">
    //                 <table className={ `table ${styles.summary}` }>
    //                     <tbody>
    //                         <tr>
    //                             <td><strong>Total do pedido</strong>: { Utils.formatPrice(order.value) }</td>
    //                             <td><strong>Vendedor</strong>: { order.salesmanName }</td>
    //                             <td><strong>Observação</strong>:</td>
    //                             <td>{ order.observations }</td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>
    //     </div>
    // )
}
