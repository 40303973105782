import Constants from '../constants'
import { City } from '../interfaces/city'
import { Provider, ProviderConditions, Route } from '../interfaces/provider'
import { State } from '../interfaces/state'
import CityService from './CityService'
import HttpClient from './HttpClient'
import StateService from './StateService'

export default class ProviderService {
    static parse = (providerFromApi: any): Provider => ({
        id: providerFromApi?.for_id,
        name: providerFromApi?.for_nome,
        email: providerFromApi?.for_email,
        cnpj: providerFromApi?.for_cnpj,
        socialReason: providerFromApi?.for_razaoSocial,
        phone: providerFromApi?.for_telefone,
        website: providerFromApi?.for_site,
        login: providerFromApi?.usu_login,
        contact: providerFromApi?.for_contato,
        storeId: providerFromApi?.loj_id,
        neighborhood: providerFromApi?.loj_bairro,
        cep: providerFromApi?.loj_cep,
        address: providerFromApi?.loj_endereco,
        addressComplement: providerFromApi?.loj_complemento,
        state: StateService.parse(providerFromApi),
        city: CityService.parse(providerFromApi),
        revendaId: providerFromApi?.for_idrevenda,
        minimum_billing: providerFromApi?.minimum_billing,
        cover_file_name: providerFromApi?.cover_file_name,
        pix_gateway: providerFromApi?.pix_gateway,
        fantasyName: providerFromApi?.for_nomeFantasia,
    })

    static parseCondition = (providerFromApi: any): ProviderConditions => ({
        minimumDeliveryBilling: providerFromApi.fat_min_entrega,
        minimumWithdrawalBilling: providerFromApi.fat_min_retirada,
        delivery: providerFromApi.realiza_entrega,
        route: providerFromApi.rota_id,
        level: providerFromApi.level,
        seller_id: providerFromApi.vendedor_id,
        fractional_purchase: providerFromApi.compra_fracionada,
        group_id: providerFromApi.grupo_id,
        is_accepting_pix: providerFromApi.is_accepting_pix,
        taxesCondition: providerFromApi.taxesCondition,
        has_field_control: providerFromApi.has_field_control,
        id: providerFromApi.id,
        cpf: providerFromApi.CNPJ,
        name: providerFromApi.nome_fantasia,
        social: providerFromApi.razao_social,
        cep: providerFromApi.cep,
        district: providerFromApi.bairro,
        address: providerFromApi.endereco,
        number: providerFromApi.numero,
        complement: providerFromApi.complemento,
        state: providerFromApi.cod_estado,
        city: providerFromApi.cod_cidade,
        isCpf: providerFromApi.is_cpf,
        seg_id: providerFromApi.seg_id,
        nome_contato: providerFromApi.nome_contato,
    })

    static parseRoute = (routeFromApi: any): Route => ({
        id: routeFromApi.id,
        provider_id: routeFromApi.for_id,
        description: routeFromApi.descricao,
    })

    static getClientCondition = (
        providerID?: number,
        clientID?: number
    ): Promise<any> =>
        HttpClient.get(
            `${Constants.URL_API_PROVIDER}/${providerID}/condition/${clientID}`
        ).then((response) => {
            const conditions: ProviderConditions[] = []
            response.condicao.forEach((value: any) =>
                conditions.push(ProviderService.parseCondition(value))
            )
            return conditions[0]
        })

    static getProviders = (params: any): Promise<any> =>
        HttpClient.get(`/v2/providers`, params).then((response) => {
            return response
        })

    static register = (params: any): Promise<any> =>
        HttpClient.post('/v2/external/sellers', params).then((response) => {
            return response
        })

    static phoneExists = (params: any): Promise<any> =>
        HttpClient.get(`/v2/external/users/exists`, params).then((response) => {
            return response
        })

    static getProviderRoutes = (providerID?: number): Promise<any> =>
        HttpClient.get(`/v2/providers/${providerID}/routes`).then(
            (response) => {
                const result: Route[] = []
                response.rotas.forEach((value: any) =>
                    result.push(ProviderService.parseRoute(value))
                )
                return result
            }
        )
    static getProviderRoutesLocales = (providerID?: number): Promise<any> =>
        HttpClient.get(`/v2/providers/${providerID}/routes`).then(
            (response) => {
                const result = response.locais

                // response.locais.forEach((value: any) =>
                //     result.push(ProviderService.parseRoute(value))
                // )
                return result
            }
        )

    static getProviderLocalesWithheld = (loj_id: number): Promise<any> =>
        HttpClient.get(
            `${Constants.URL_API_PROVIDER_v3}/local/retirada?loj_id=${loj_id}`
        ).then((response) => {
            return response
        })

    static postClientCondition = (
        providerID?: number,
        clientID?: number,
        data?: any
    ): Promise<any> =>
        HttpClient.post(
            `/v2/providers/${providerID}/condition/${clientID}`,
            data
        )

    static putClientCondition = (
        providerID?: number,
        clientID?: number,
        data?: any
    ): Promise<any> =>
        HttpClient.put(
            `/v2/providers/${providerID}/condition/${clientID}`,
            data
        )

    static getProviderLevelsAll = (): Promise<any> =>
        HttpClient.get(Constants.URL_API_PROVIDER_LEVELS_ALL).then(
            (response) => {
                return response
            }
        )
    URL_API_PROVIDER_LEVELS_ALL

    static getLoggedProvider = (): Promise<any> =>
        HttpClient.get(Constants.URL_API_GET_LOGGED_PROVIDER).then(
            (response) => {
                const cities: City[] = []
                response.cidades.forEach((city: any) =>
                    cities.push(CityService.parse(city))
                )
                const states: State[] = []
                response.estados.forEach((state: any) =>
                    states.push(StateService.parse(state))
                )
                const provider: Provider = ProviderService.parse(
                    response.fornecedor
                )
                return { cities, states, provider }
            }
        )

    static edit = (provider: Provider, loggedUserId: string): Promise<any> =>
        HttpClient.post(Constants.URL_API_UPDATE_LOGGED_PROVIDER, {
            fornecedor: {
                for_id: provider.id,
                for_nome: provider.name,
                for_cnpj: provider.cnpj,
                for_razaoSocial: provider.socialReason,
                for_telefone: provider.phone,
                for_email: provider.email,
                for_contato: provider.contact,
                for_site: provider.website,
                minimum_billing: provider.minimum_billing,
                loj_id: provider.storeId,
                loj_bairro: provider.neighborhood,
                loj_endereco: provider.address,
                loj_complemento: provider.addressComplement,
                pix_gateway: provider.pix_gateway,

                cid_id: provider.city?.id,
                est_id: provider.state?.id,
                usu_id: loggedUserId,
                usu_login: provider.login,
            },
        })
    static editImage = (providerid, data): Promise<any> =>
        HttpClient.post(
            `${Constants.URL_API_UPDATE_PROVIDER_COVER}/providers/${providerid}/cover`,
            { cover: data }
        )

    static requestAccess = (data: any) =>
        HttpClient.post(`/v2/sellers/leads`, data)
}
