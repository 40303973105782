import React, { FC, useEffect, useState } from 'react'
import { Datepicker } from '../../../../components/datepicker'
import { Input } from '../../../../components/input'
import { Select } from '../../../../components/select'
import { useForm } from 'react-hook-form'
import './index.scss'
import { ErrorText } from '../../../../components/field-error'
import DeliveryService from '../../../../services/DeliveryService'
import DateService from '../../../../services/DateService'
import SnackBarSuccessStyle from '../../../../styles/snackbar-success.style'
import { useSnackbar } from 'react-simple-snackbar'
import { Button } from '../../../../components/button'
import { Loader } from '../../../../components/loader'
import { Snackbar } from '../../../../components/snackbar'
import { Currency } from 'components/currency-input'
import ButtonV3 from 'components/Buttonv3'
import { ErrorTextV2 } from 'components/errorTextV2'
import { CurrencySafari } from 'components/currency-input-Safary'
import { useTheme } from 'contexts/whiteLabel/context'

export const RequestSellerComercialInfoComponent: FC<any> = ({
    isSafari,
    handleGotoProduts,
    delivery,
    setDelivery,
    payment_types,
    request,
    editableRequest,
}: any) => {
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [loading, setLoading] = useState<boolean>(false)
    const [custom_errors, setCustomErrors] = useState<any>({})
    const { theme } = useTheme()
    const { register, handleSubmit, control, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    useEffect(() => {}, [])
    const save = (data: any) => {
        if (!Object.keys(custom_errors).length) {
            setLoading(true)
            data.data_entrega = DateService.formatDayValueToRequest(
                data.data_entrega
            )
            data.faturamento_minimo = delivery?.minimumBilling
            data.numerocotacao = request.id
            data.old = false
            data.seller_id = request.seller_id
            setDelivery({ ...delivery, old: false })
            DeliveryService.save_delivery(data)
                .then(() => {
                    setLoading(false)
                    openSnackbar(
                        <Snackbar icon="thumb_up">
                            Dados da entrega salvos com sucesso!
                        </Snackbar>
                    )
                    // const nav = document.getElementById("products-nav-tab")
                    // if (nav) {
                    //     nav.click()
                    // }
                    handleGotoProduts()
                })
                .catch((error) => {
                    setLoading(false)
                    if (error.response.data.mensagem) {
                        openSnackbar(error.response.data.mensagem)
                    } else {
                        openSnackbar(
                            'Ops! Houve um erro ao savar os dados da entrega!'
                        )
                    }
                })
        }
    }

    const is_editable = () => {
        return (
            request &&
            ![
                'CONGELADA',
                'PEDIDO GERADO',
                'COTACAO GERADA',
                'SEM PEDIDO',
            ].includes(request.status) &&
            !is_expired()
        )
    }

    const is_expired = () => {
        return new Date(request.date) <= new Date()
    }

    useEffect(() => {
        const err: any = {}

        if (!delivery || !delivery.minimumBilling) {
            err.faturamento_minimo = {
                message: 'Valor deve ser maior que zero',
            }
        }

        if (
            !delivery ||
            new Date(delivery.date).getTime() < new Date().getTime()
        ) {
            err.date = {
                message: 'Data não pode ser anterior',
            }
        }

        setCustomErrors(err)
    }, [delivery, errors])

    return (
        <section className="seller-comercial-conditions">
            {
                //<p className="title">Suas Condições Comerciais</p>
            }
            <form
                className="form-row"
                onSubmit={handleSubmit(save)}
                style={{ gap: 23, alignItems: 'flex-end' }}
            >
                <input
                    type="hidden"
                    ref={register}
                    defaultValue={delivery?.quotationId}
                    name="numerocotacao"
                />
                <div>
                    <Select
                        options={payment_types}
                        label_key={'name'}
                        value_key={'name'}
                        name="forma_pagamento"
                        register={register}
                        disabled={!is_editable() ? 'disabled ' : false}
                        onChange={(evt: any) =>
                            setDelivery({
                                ...delivery,
                                paymentWay: evt.target.value,
                            })
                        }
                        value={delivery?.paymentWay}
                        description="Plano de Pagamento"
                    />
                    {errors.forma_pagamento && (
                        <ErrorTextV2>Campo obrigatório</ErrorTextV2>
                    )}
                </div>
                <div style={{ position: 'relative' }}>
                    {isSafari ? (
                        <CurrencySafari
                            description="Fat. Mínimo (R$)"
                            name="faturamento_minimo"
                            register={register}
                            onChange={(
                                maskedvalue: string,
                                floatValue: number
                            ) =>
                                setDelivery({
                                    ...delivery,
                                    minimumBilling: floatValue,
                                })
                            }
                            readOnly={!is_editable() ? 'readOnly' : false}
                            value={delivery?.minimumBilling}
                        />
                    ) : (
                        <Currency
                            description="Fat. Mínimo (R$)"
                            name="faturamento_minimo"
                            register={register}
                            onChange={(
                                maskedvalue: string,
                                floatValue: number
                            ) =>
                                setDelivery({
                                    ...delivery,
                                    minimumBilling: floatValue,
                                })
                            }
                            readOnly={!is_editable() ? 'readOnly' : false}
                            value={delivery?.minimumBilling}
                        />
                    )}
                    {custom_errors.faturamento_minimo && (
                        <ErrorTextV2>
                            {custom_errors.faturamento_minimo.message}
                        </ErrorTextV2>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        paddingBottom: 2,
                    }}
                >
                    {is_editable() ? (
                        <div style={{ marginBottom: -3 }}>
                            <Datepicker
                                value={delivery?.date}
                                disabledFormControlClass={true}
                                callendarIcon
                                name="data_entrega"
                                register={register}
                                pos="bottom"
                                control={control}
                                readOnly={!is_editable() ? 'readOnly' : false}
                                onChange={(date: string) =>
                                    setDelivery({ ...delivery, date: date })
                                }
                                description="Previsão de entrega"
                            />
                            {errors.data_entrega && (
                                <ErrorTextV2>Campo obrigatório</ErrorTextV2>
                            )}
                            {
                                <ErrorTextV2>
                                    {custom_errors.date &&
                                        custom_errors.date.message}
                                </ErrorTextV2>
                            }
                        </div>
                    ) : (
                        <Input
                            description="Data prevista para entrega"
                            readOnly={true}
                            value={DateService.formatToDisplayWithTime(
                                new Date(delivery?.date)
                            )}
                        />
                    )}
                </div>
                <div>
                    <Input
                        description="Observações"
                        name="observacao"
                        register={register}
                        readOnly={!is_editable() ? 'readOnly' : false}
                        onChange={(evt: any) =>
                            setDelivery({
                                ...delivery,
                                observations: evt.target.value,
                            })
                        }
                        value={delivery?.observations || ''}
                    />
                </div>

                <div>
                    {
                        !loading && is_editable() && (
                            <ButtonV3
                                title="Salvar"
                                type={is_editable() ? 'submit' : 'button'}
                                style={{
                                    backgroundColor: 'var(--primary-opacity)',
                                    padding: '10px 35px',
                                    border: '0.59375px solid #E5E5E5',
                                }}
                                textStyle={{
                                    color: `${
                                        theme?.colors?.primary || '#FF7110'
                                    }`,
                                }}
                            />
                        )

                        //    <Button className="c-btn-save" disabled={!is_editable() ? 'disabled ' : ''}>
                        //        Salvar
                        //    </Button>
                    }

                    {loading && <Loader type="inline" size="30px" />}
                </div>
            </form>
        </section>
    )
}
