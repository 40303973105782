import React, { FC, useState, useRef, useEffect } from 'react'
import { Label } from '../label'
import InputMask from 'react-input-mask'
import './index.scss'
import { IoMdInformationCircle } from 'react-icons/io'

export const InputAutoComplete: FC<any> = ({
    mask,
    mode,
    register,
    infoIconNull,
    options = [],
    value_key = 'id',
    label_key = 'name',
    blank_label = 'Selecione uma opção',
    description,
    onChange,
    clear,
    ...props
}) => {
    const [filteredOptions, setFilteredOptions] = useState<any[]>(options) // Inicializa com todas as opções
    const [showOptions, setShowOptions] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const wrapperRef = useRef<HTMLDivElement>(null)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setInputValue(value)

        // Filtrar opções com base no `label_key`
        const filtered = options.filter((option) =>
            option[label_key].toLowerCase().includes(value.toLowerCase())
        )
        setFilteredOptions(filtered)
        setShowOptions(filtered.length > 0)
    }

    useEffect(() => {
        if (clear) {
            setInputValue('') // Limpa o campo quando a prop `clear` é verdadeira
        }
    }, [clear])

    const handleOptionClick = (option: any) => {
        setInputValue(option[label_key]) // Define o texto da opção no input
        setShowOptions(false)
        if (onChange) {
            // Chama o `onChange` com o valor do `value_key`
            onChange({ target: { value: option[value_key] } })
        }
    }

    // Fecha as opções ao clicar fora do componente
    const handleClickOutside = (event: MouseEvent) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
        ) {
            setShowOptions(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div
            ref={wrapperRef}
            className={mode === 'search' ? 'c-search-group' : 'default'}
            style={{ position: 'relative', width: '100%' }}
        >
            {description && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    <Label
                        style={{ marginBottom: '0px !important' }}
                        className="c-label"
                    >
                        {description}
                    </Label>
                    {infoIconNull === false && (
                        <section
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 3,
                            }}
                        >
                            <IoMdInformationCircle className="icon" />
                        </section>
                    )}
                </div>
            )}
            {mask ? (
                <InputMask mask={mask} {...props}>
                    {(inputProps) => (
                        <input
                            type="text"
                            ref={register}
                            {...inputProps}
                            className={`c-input form-control ${props.className}`}
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={() => {
                                setFilteredOptions(options)
                                setShowOptions(true)
                            }}
                        />
                    )}
                </InputMask>
            ) : (
                <input
                    type="text"
                    {...props}
                    className={`c-input form-control ${props.className}`}
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => {
                        setFilteredOptions(options)
                        setShowOptions(true)
                    }}
                />
            )}

            {showOptions && (
                <ul className="autocomplete-options">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            className="autocomplete-option"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option[label_key]}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
