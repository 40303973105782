import CustomerService from '../../services/CustomerService'
import { ResponseApi } from '../../interfaces/responseApi'
import ClientService from 'services/v3/clients.service'

// TYPES
export const LOAD_CUSTOMER_SUCCESS = 'LOAD_CUSTOMER_SUCCESS'
export const LOAD_BUYING_COMPANIES_SUCCESS = 'LOAD_BUYING_COMPANIES_SUCCESS'
export const LOAD_CLIENTS_CONDITION_SUCCESS = 'LOAD_CLIENTS_CONDITION_SUCCESS'

// Reducer
const initialState = {}

export default function customers(state = initialState, action: any) {
    switch (action.type) {
        case LOAD_CUSTOMER_SUCCESS:
            return { ...state, clients: action.customers }
        case LOAD_BUYING_COMPANIES_SUCCESS:
            return { ...state, companies: action.buyingCompanies }
        case LOAD_CLIENTS_CONDITION_SUCCESS:
            return { ...state, clientsCondition: action.clientsCondition }
        default:
            return state
    }
}

// ACTIONS
export const loadCustomers = (page = 1) => {
    return (dispatch: any) =>
        CustomerService.getAll(page)
            .then((response: ResponseApi) => {
                dispatch(loadCustomerSuccess(response))
            })
            .catch(() => {
                dispatch(loadCustomerSuccess(null))
            })
}

export const load_stores = (params: any) => {
    return (dispatch: any) =>
        ClientService.load_stores(params)
            .then((response: ResponseApi) => {
                dispatch(loadCustomerSuccess(response))
                return response
            })
            .catch((error) => {
                dispatch(loadCustomerSuccess(null))
                throw error
            })
}

export const load_clients_condition = (params: any) => {
    return (dispatch: any) => {
        return ClientService.load_clients_condition(params)
            .then((response: any) => {
                dispatch(loadClientsConditionSuccess(response))
                return response
            })
            .catch((error) => {
                console.error('Erro ao carregar clientes condition:', error)
                dispatch(loadClientsConditionSuccess(null))
                throw error
            })
    }
}

const loadCustomerSuccess = (customers: ResponseApi | null) => {
    return { type: LOAD_CUSTOMER_SUCCESS, customers }
}

const loadClientsConditionSuccess = (clientsCondition: any | null) => {
    return { type: LOAD_CLIENTS_CONDITION_SUCCESS, clientsCondition }
}

export const loadBuyingCompanies = (
    page: number,
    customerId: string,
    status: string[],
    states: string,
    cnpj: string,
    provider_id: number
) => {
    return (dispatch: any) =>
        CustomerService.getBuyingCompanies(
            page,
            customerId,
            status,
            states,
            cnpj,
            provider_id
        )
            .then((response: ResponseApi) => {
                dispatch(loadBuyingCompaniesSuccess(response))
            })
            .catch(() => {
                dispatch(loadBuyingCompaniesSuccess(null))
            })
}

const loadBuyingCompaniesSuccess = (buyingCompanies: ResponseApi | null) => {
    return { type: LOAD_BUYING_COMPANIES_SUCCESS, buyingCompanies }
}
