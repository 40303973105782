import './index.scss'

interface ButtonIsActiveProps {
    isActive: boolean
    onClick: () => void
    icon?: React.ReactNode
    children: React.ReactNode
}
const ButtonIsActive = ({
    isActive,
    onClick,
    icon,
    children,
}: ButtonIsActiveProps) => {
    return (
        <button
            className={`custom-button ${isActive ? 'active' : ''}`}
            onClick={onClick}
        >
            {icon && <span className="button-icon">{icon}</span>}
            {children}
        </button>
    )
}

export default ButtonIsActive
