import React, { FC, useEffect } from 'react'
import { useState } from 'react'
import NewModalComponent from 'components/new-modal'
import { Select } from 'components/select'
import { ErrorText } from 'components/field-error'
import { useForm } from 'react-hook-form'
import { Button } from 'components/button'
import { Datepicker } from 'components/datepicker'
import { useSelector } from 'react-redux'
import { Loader } from 'components/loader'
import DirectSaleOrderService from 'services/DirectSaleOrderService'
import { useSnackbar } from 'react-simple-snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { Snackbar } from 'components/snackbar'
import './index.scss'
import * as $ from 'jquery'
import ButtonV3 from 'components/Buttonv3'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StatusService from 'services/v3/changeStatusService'
import ProviderService from 'services/ProviderService'
import { useTheme } from 'contexts/whiteLabel/context'

export const EditDirectPurchaseModalComponent: FC<any> = ({
    data,
    routes,
    onUpdate,
    onCloseCallback,
}: any) => {
    const [modal, setModal] = useState<any>({})
    const { control, register, handleSubmit, errors } = useForm()
    const sellers = useSelector((state: any) => state.salesmen)
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [order, setOrder] = useState(data)
    const [loading, setLoading] = useState(false)
    const [locales, setLocales] = useState<any[]>()
    const [deliveryType, setDeliveryType] = useState<string | null>(
        order.receiving_method
    )
    const statusOptions = [
        // { id: 'CONFIRMADO', name: 'Confirmado' },
        { id: 'CANCELADO', name: 'Cancelar' },
        { id: 'AGUARDANDO_CONFIRMACAO', name: 'Aguardando Confirmação' },
        // { id: 'INICIADO', name: 'Iniciado' },
    ]

    const MySwal = withReactContent(Swal)
    const { theme } = useTheme()

    const save = (data: any) => {
        data.pedido_id = order.id
        data.delivery_date = order.deliveryDate
        data.tipo_recebimento = deliveryType

        if (deliveryType === 'RETIRADA') {
            data.id_local_retirada = order.id_local_retirada
        }

        setLoading(true)

        const updateStatusPromise = data.status
            ? StatusService.changeStatus({
                  pedido_id: order.id,
                  status: data.status,
              })
            : Promise.resolve()

        updateStatusPromise
            .then(() => {
                return DirectSaleOrderService.save(data)
            })
            .then(() => {
                setLoading(false)
                openSnackbar(
                    <Snackbar icon="thumb_up">
                        Pedido salvo com sucesso!
                    </Snackbar>
                )
                onCloseCallback()
                onUpdate()
            })
            .catch((error) => {
                setLoading(false)
                openSnackbar(
                    <Snackbar icon="error">
                        Ocorreu um erro ao salvar o pedido.
                    </Snackbar>
                )
                console.error(error)
            })
    }

    const close_modal = () => {
        $('#' + modal.id).removeClass('fadeIn')
        $('#' + modal.id).addClass('fadeOutUp')
        setTimeout(() => {
            $('#' + modal.id).modal('hide')
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
        }, 700)
    }

    useEffect(() => setOrder(data), [data])

    useEffect(() => {
        setModal({
            title: `Pedido #${order?.id}`,
            subtitle: `${order?.storeName}`,
            id: 'edit-direct-purchase-modal',
            icon: 'category',
            styles: {
                maxWidth: '910px',
            },
            body_styles: {
                overflowY: 'visible',
            },
        })
    }, [order])

    async function handleRemove(id) {
        MySwal.fire({
            title: `Deseja cancelar o pedido #${id}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                DirectSaleOrderService.cancelOrder(id).then(
                    onCloseCallback(),
                    onUpdate()
                )
            }
        })
    }

    useEffect(() => {
        loadLocalesRetida()
    }, [])

    const loadLocalesRetida = () => {
        const id = data.loj_id
        ProviderService.getProviderLocalesWithheld(id).then((result) =>
            setLocales(result)
        )
    }
    return (
        <div style={{ padding: '32px 25px' }}>
            {order && !loading ? (
                <form onSubmit={handleSubmit(save)}>
                    <div
                        style={{
                            width: '90%',
                            margin: '0 auto',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            {modal.subtitle && (
                                <div style={{ marginBottom: 32 }}>
                                    <p className="modalEditTittle">
                                        {modal.subtitle}
                                    </p>
                                </div>
                            )}
                            <div>
                                {order.status == 'AGUARDANDO_CONFIRMACAO' && (
                                    <ButtonV3
                                        style={{
                                            height: 41,
                                            padding: '14px 25px',
                                            borderRadius: 8,
                                            borderColor: '#C50000',
                                        }}
                                        textStyle={{ color: '#C50000' }}
                                        title="Cancelar Pedido"
                                        onClick={() => {
                                            handleRemove(order.id)
                                        }}
                                        type={'button'}
                                    ></ButtonV3>
                                )}
                            </div>
                        </div>

                        <div className="InputsDivEditModal">
                            <div
                                style={{
                                    minWidth: 230,
                                    width: '30%',
                                }}
                            >
                                <Datepicker
                                    callendarIcon={true}
                                    name="delivery_date"
                                    // control={control}
                                    description="Data de entrega"
                                    onChange={(date: string) =>
                                        setOrder({
                                            ...order,
                                            deliveryDate: date,
                                        })
                                    }
                                    value={order.deliveryDate}
                                    register={register}
                                />
                            </div>
                            <div
                                style={{
                                    minWidth: 230,
                                    width: '30%',
                                }}
                            >
                                <Select
                                    options={routes}
                                    label_key={'description'}
                                    value_key={'id'}
                                    name="route_id"
                                    register={register}
                                    defaultValue={order?.route.id}
                                    description="Rota"
                                />
                                {errors.forma_pagamento && (
                                    <ErrorText>Campo obrigatório</ErrorText>
                                )}
                            </div>
                            <div
                                style={{
                                    minWidth: 230,
                                    width: '30%',
                                }}
                            >
                                <Select
                                    name="seller_id"
                                    description="Vendedor"
                                    blank_label="Selecione um vendedor"
                                    register={register}
                                    defaultValue={order.seller.id}
                                    options={sellers}
                                    value_key="id"
                                    label_key="name"
                                />
                                {errors.forma_pagamento && (
                                    <ErrorText>Campo obrigatório</ErrorText>
                                )}
                            </div>

                            {order.status === 'CONFIRMADO' && (
                                <div
                                    style={{
                                        minWidth: 230,
                                        width: '30%',
                                    }}
                                >
                                    <Select
                                        name="status"
                                        description="Alterar Status"
                                        blank_label="Selecione um status"
                                        register={register}
                                        // defaultValue={order.status}
                                        options={statusOptions}
                                        value_key="id"
                                        label_key="name"
                                    />
                                </div>
                            )}

                            <div
                                style={{
                                    minWidth: 230,
                                    width: '30%',
                                }}
                            >
                                <Select
                                    description="Tipo de Entrega"
                                    blank_label="Escolha o tipo"
                                    onChange={(e: any) => {
                                        setDeliveryType(e.target.value)
                                        setOrder({
                                            ...order,
                                            tipo_recebimento: e.target.value,
                                        })
                                    }}
                                    options={[
                                        { name: 'Entrega', value: 'ENTREGA' },
                                        { name: 'Retirada', value: 'RETIRADA' },
                                    ]}
                                    register={register}
                                    defaultValue={order.receiving_method}
                                    value_key="value"
                                    label_key="name"
                                />
                            </div>
                            {deliveryType === 'RETIRADA' && (
                                <div
                                    style={{
                                        minWidth: 230,
                                        width: '30%',
                                    }}
                                >
                                    <Select
                                        description="Local de retirada"
                                        blank_label="Selecione um local de retirada"
                                        options={locales}
                                        onChange={(e: any) =>
                                            setOrder({
                                                ...order,
                                                id_local_retirada:
                                                    e.target.value,
                                            })
                                        }
                                        register={register}
                                        defaultValue={order.id_local_retirada}
                                        value_key="id"
                                        label_key="descricao"
                                    />
                                </div>
                            )}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end',
                                marginRight: -5,
                                marginTop: 50,
                                gap: 15,
                            }}
                        >
                            <div>
                                <ButtonV3
                                    style={{
                                        height: 41,
                                        padding: '14px 53px',
                                        borderColor: `${
                                            theme?.colors?.primary || '#ff7110'
                                        }`,
                                        borderRadius: 4,
                                    }}
                                    textStyle={{
                                        color: `${
                                            theme?.colors?.primary || '#ff7110'
                                        }`,
                                    }}
                                    title="Cancelar"
                                    type="button"
                                    onClick={onCloseCallback}
                                />
                            </div>
                            <div>
                                <ButtonV3
                                    filled
                                    style={{
                                        height: 41,
                                        padding: '14px 53px',
                                        borderRadius: 4,
                                    }}
                                    title="Salvar"
                                    type={'submit'}
                                />
                                {
                                    //    <Button type="submit" className="c-btn-save">
                                    //        Salvar
                                    //    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            ) : (
                <Loader type="inline" />
            )}
        </div>
    )
}
//return (
//    <NewModalComponent modal={ modal }>
//        <div className="row">
//            { order && !loading ?
//                <form onSubmit={ handleSubmit(save) } className="container-fluid">
//                    <div className="row">
//                        <div className="col">
//                            <Datepicker
//                                name="delivery_date"
//                                control={ control }
//                                description="Data de entrega"
//                                onChange={ (date: string) => setOrder({ ...order, deliveryDate: date }) }
//                                value={ order.deliveryDate }
//                                register={ register }
//                            />
//                        </div>
//                        <div className="col">
//                            <Select options={ routes }
//                                label_key={ 'description' } value_key={ 'id' }
//                                name="route_id"
//                                register={ register }
//                                defaultValue={ order?.route.id }
//                                description="Plano de Pagamento" />
//                            { errors.forma_pagamento && <ErrorText>Campo obrigatório</ErrorText> }
//                        </div>
//                        <div className="col">
//                            <Select
//                                name="seller_id"
//                                description="Vendedor"
//                                blank_label="Selecione um vendedor"
//                                register={ register }
//                                defaultValue={ order.seller.id }
//                                options={ sellers }
//                                value_key="id"
//                                label_key="name"
//                            />
//                            { errors.forma_pagamento && <ErrorText>Campo obrigatório</ErrorText> }
//                        </div>
//                        <div className="col d-flex align-items-end">
//                            <Button type="submit" className="c-btn-save">
//                                    Salvar
//                            </Button>
//                        </div>
//                    </div>
//                </form> : <Loader type="inline" /> }
//        </div>
//    </NewModalComponent>
//)
