import React, { FC, useContext, useEffect, useState } from 'react'
import { Input } from 'components/input'
import { Select } from 'components/select'
import SalesmanService from 'services/SalesmanService'
import ReactDatepicker, {
    Day,
    DayValue,
} from 'react-modern-calendar-datepicker'
import './index.scss'
import { Checkbox } from 'components/checkbox'
import { FilterComponent } from 'components/filter'
import { UserContext } from 'contexts/user'
import { Datepicker } from 'components/datepicker'
import { Button } from 'react-bootstrap'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai'
import { MultipleSelect } from 'components/MultipleSelect'

import NotificationBadge from 'react-notification-badge'
import ButtonV3 from 'components/Buttonv3'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import InputV2 from 'components/InputV2'
import DateService from 'services/DateService'
import { useTheme } from 'contexts/whiteLabel/context'

export const RequestsFilterComponent: FC<any> = ({
    initialParams,
    status_list,
    states,
    onChange,
    onOpenRegisterModal,
    registerAmount,
    extraActionButtonText,
    ramosList,
    set_filters,
}: any) => {
    const [salesmen, setSalesmen] = useState<any>([])
    const [params, setParams] = useState<any>(initialParams)
    const { user, can } = useContext(UserContext)
    const { theme } = useTheme()

    const update = (params: any) => {
        onChange(params)
    }

    const clearFilters = () => {
        setParams(initialParams)
        const endDate: Date = new Date()
        const initialDate: Date = DateService.removeDaysToDate(
            new Date(endDate),
            10
        )
        onChange({ page: 1, dataIni: initialDate, just_mine: true })
    }

    const is_empty = (collection: any[]) => {
        return !collection || collection.length === 0
    }

    const updateDate = (date: string) => {
        if (date) {
            setParams({
                ...params,
                dataIni: new Date(date),
            })
        }
    }

    const updateExpDate = (date: string) => {
        if (date) {
            setParams({
                ...params,
                exp_date: new Date(date),
            })
        }
    }

    useEffect(() => {
        setParams(initialParams)
    }, [initialParams])

    const get_salesman_id = (val: any) => {
        return can('READ_ALL', 'REQUEST') ? val : user?.id
    }

    useEffect(() => {
        // console.log('effect requesfilter')
        is_empty(salesmen) &&
            SalesmanService.getAll().then((data) => setSalesmen(data))
    }, [])

    // useEffect(() => {
    //     console.log('effect requesfilter')
    //     is_empty(salesmen) &&
    //         SalesmanService.getAll().then(data => setSalesmen(data))
    // }, [salesmen, params])

    function getMultiSelectPlaceholder() {
        let text = params.status
            ? params.status.split(',').length + ' Selecionados'
            : 'Selecione um status'

        if (params.status && params.status.split(',').length > 1) {
            text = params.status.split(',').length + ' Selecionados'
        } else if (params.status && params.status.split(',').length == 1) {
            let el = status_list.find((el) => el.status_id == params.status)
            text = el.status_nome
        } else {
            text = 'Selecione um status'
        }

        return text
    }
    function getMultiSelectPlaceholderRamo() {
        let text = params.ramo
            ? params.ramo.split(',').length + ' Selecionados'
            : 'Selecione um ramo'

        if (params.ramo && params.ramo.split(',').length > 1) {
            text = params.ramo.split(',').length + ' Selecionados'
        } else if (params.ramo && params.ramo.split(',').length == 1) {
            let el = ramosList.find((el) => el.ramo_id == params.ramo)
            text = el.ramo_descricao
        } else {
            text = 'Selecione um ramo'
        }

        return text
    }

    return (
        <div>
            {
                //    <FilterComponent title="Filtrar Solicitações"
                //        register={onOpenRegisterModal} registerAmount={registerAmount}
                //        extraActionButtonText={extraActionButtonText}
                //        filter={() => update(params)} clear={clearFilters}>
            }

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    marginBottom: 10,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',

                        flexWrap: 'wrap',
                    }}
                >
                    <div
                        style={{
                            width: 200,
                            marginRight: 20,
                            marginBottom: 10,
                        }}
                    >
                        <Datepicker
                            callendarIcon
                            description="Emitidas apartir de"
                            value={params.dataIni}
                            onChange={(date: string) => updateDate(date)}
                        />
                    </div>
                    <div
                        style={{
                            width: 145,
                            marginBottom: 10,
                            marginRight: 20,
                        }}
                    >
                        <Datepicker
                            callendarIcon
                            description="Data Final"
                            value={params.exp_date}
                            onChange={(date: string) => updateExpDate(date)}
                        />
                    </div>
                    <div
                        style={{
                            width: 250,
                            marginRight: 20,
                            marginBottom: 10,
                        }}
                    >
                        <Select
                            id="state"
                            description="Estado"
                            value_key="value"
                            label_key="name"
                            blank_label="Selecione um estado"
                            options={states}
                            value={params.uf}
                            onChange={(e: any) =>
                                setParams({ ...params, uf: e.target.value })
                            }
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',

                        flexWrap: 'wrap',
                        // 'marginBottom': 10
                    }}
                >
                    <div style={{ marginRight: 20, marginBottom: 10 }}>
                        <ButtonV3
                            title={
                                extraActionButtonText ||
                                'Solicitações de Cadastro'
                            }
                            onClick={() => onOpenRegisterModal()}
                            Icon={
                                <IoIosAddCircleOutline className="iconButtonV3"></IoIosAddCircleOutline>
                            }
                        >
                            <NotificationBadge
                                count={registerAmount}
                                containerStyle={{ width: 'auto' }}
                                style={{
                                    marginTop: -28,
                                    right: -15,
                                    backgroundColor: `${
                                        theme?.colors?.primary || '#ff7110'
                                    }`,
                                }}
                            />
                        </ButtonV3>
                    </div>
                    <div style={{ marginRight: 20, marginBottom: 10 }}>
                        <ButtonV3
                            Icon={
                                <AiOutlineCloseCircle className="iconButtonV3" />
                            }
                            title="Limpar filtros"
                            onClick={() => clearFilters()}
                        />
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <ButtonV3
                            filled
                            title="Filtrar"
                            onClick={() => update(params)}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',

                    flexWrap: 'wrap',
                    marginBottom: 10,
                }}
            >
                <div style={{ width: 250, marginRight: 20, marginBottom: 10 }}>
                    {
                        //   <Input
                        //       id="offerId"
                        //       name="offerId"
                        //       description="Buscar por Solicitação"
                        //       type="text"
                        //       className="search form-control"
                        //       placeholder="Solicitação..."
                        //       onChange={(e: any) => setParams({ ...params, numerocotacao: e.target.value })}
                        //       mode="search"
                        //   />
                    }
                    <InputV2
                        infoIconNull
                        icon={
                            <AiOutlineSearch color="#898989"></AiOutlineSearch>
                        }
                        mask={undefined}
                        id={'offerId'}
                        label={'Buscar por Cotação'}
                        value={params.numerocotacao}
                        type={'text'}
                        placeholder={'Cotação...'}
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                numerocotacao: e.target.value,
                            })
                        }
                    />
                </div>
                <div style={{ width: 250, marginRight: 20, marginBottom: 10 }}>
                    {
                        //   <Input
                        //       id="client"
                        //       description="Cliente"
                        //       placeholder="Nome, CNPJ ou Razao"
                        //       blank_label="Selecione um cliente"
                        //       value={params.name}
                        //       onChange={(e: any) => setParams({ ...params, name: e.target.value })}
                        //   />
                    }
                    <InputV2
                        infoIconNull
                        icon={
                            <AiOutlineSearch color="#898989"></AiOutlineSearch>
                        }
                        mask={undefined}
                        id={'client'}
                        label={'Cliente'}
                        value={params.name}
                        type={'text'}
                        placeholder={'Nome, CNPJ ou Razao'}
                        onChange={(e: any) =>
                            setParams({ ...params, name: e.target.value })
                        }
                    />
                </div>
                <div style={{ width: 200, marginRight: 20, marginBottom: 10 }}>
                    {/* <Select
                        id="status"
                        description="Status"
                        value_key="status_id"
                        label_key="status_nome"
                        blank_label="Selecione um status"
                        value={params.status}
                        options={status_list}
                        onChange={(e: any) => setParams({ ...params, status: e.target.value })}>
                    </Select>  */}
                    <MultipleSelect
                        label="Status"
                        value_key="status_id"
                        label_key="status_nome"
                        blank_label={getMultiSelectPlaceholder()}
                        options={status_list}
                        onChange={(e: any) =>
                            setParams({ ...params, status: e.toString() })
                        }
                    ></MultipleSelect>
                </div>
                <div style={{ width: 200, marginRight: 20, marginBottom: 10 }}>
                    <MultipleSelect
                        label="Ramo de atividade"
                        value_key="ramo_id"
                        label_key="ramo_descricao"
                        blank_label={getMultiSelectPlaceholderRamo()}
                        options={ramosList}
                        onChange={(e: any) =>
                            setParams({ ...params, ramo: e.toString() })
                        }
                    ></MultipleSelect>
                </div>
                <div style={{ width: 230, marginRight: 20, marginBottom: 10 }}>
                    <Select
                        id="saler"
                        value={get_salesman_id(params.vendedor)}
                        value_key="id"
                        description="Vendedor"
                        label_key="name"
                        disabled={!can('READ_ALL', 'REQUEST')}
                        blank_label="Selecione um vendedor"
                        options={salesmen}
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                vendedor: get_salesman_id(e.target.value),
                            })
                        }
                    ></Select>
                </div>
                <div style={{ width: 165, marginRight: 20, marginBottom: 10 }}>
                    <Select
                        id="entrega"
                        value={params.delivery_type_id}
                        value_key="value"
                        description="Tipo de entrega"
                        label_key="name"
                        disabled={!can('READ_ALL', 'REQUEST')}
                        blank_label="Tipo de entrega"
                        options={[
                            {
                                name: 'CIF',
                                value: 1,
                            },
                            {
                                name: 'CIF/FOB',
                                value: 2,
                            },
                        ]}
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                delivery_type_id: e.target.value,
                            })
                        }
                    ></Select>
                </div>
                {
                    //    <div style={{ 'width': 250, 'marginRight': 20, 'marginBottom': 10 }}>
                    //        <Select id="state"
                    //            description="Estado"
                    //            value_key="value"
                    //            label_key="name"
                    //            blank_label="Selecione um estado"
                    //            options={states}
                    //            value={params.uf}
                    //            onChange={(e: any) => setParams({ ...params, uf: e.target.value })} />
                    //    </div>
                }
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap',
                    marginBottom: 10,
                }}
            >
                <div style={{ marginRight: 20, marginBottom: 10 }}>
                    {can('READ_OTHERS', 'REQUEST') && (
                        <CheckBoxCustom
                            value={params.just_mine}
                            change={(evt: any, stats: any) => {
                                setParams({ ...params, just_mine: stats })
                            }}
                            checked={params.just_mine}
                            description={'Apenas minhas solicitações'}
                        ></CheckBoxCustom>
                    )}

                    {
                        //  <Checkbox
                        //      description="Apenas minhas solicitações"
                        //      value={params.just_mine}
                        //      checked={params.just_mine}
                        //      disabled={!can('READ_OTHERS', 'REQUEST')}
                        //      onChange={(evt: any) => {
                        //
                        //          setParams({ ...params, just_mine: evt.target.checked })
                        //      }}
                        //  />
                    }
                </div>
                <div style={{ marginRight: 20, marginBottom: 10 }}>
                    <CheckBoxCustom
                        value={params.active_only}
                        change={(evt: any, stats: any) => {
                            setParams({ ...params, active_only: stats ? 1 : 0 })
                        }}
                        checked={params.active_only == 1}
                        description={'Apenas ativas'}
                    ></CheckBoxCustom>
                    {
                        //   <Checkbox
                        //       description="Apenas ativas"
                        //       value={params.active_only}
                        //       checked={params.active_only == 1}
                        //       onChange={(evt: any) => {
                        //           setParams({ ...params, active_only: evt.target.checked ? 1 : 0 })
                        //       }}
                        //   />
                    }
                </div>
            </div>
            {
                //</FilterComponent>
            }
        </div>
    )
}
