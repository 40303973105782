import styled from 'styled-components'

const getCssVariable = (name: string) =>
    getComputedStyle(document.documentElement).getPropertyValue(name)

export const Container = styled.div`
    display: flex;
    gap: 15px;
    padding: 10px 0px;
`
export const LinkContainer = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
        color: ${() => getCssVariable('--primary') || '#ff7110'};
    }
`
export const Link = styled.p`
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik, sans-serif;
    color: #ababab;

    cursor: pointer;
    &:hover {
        color: ${() => {
            const primaryColor = getCssVariable('--primary')
            // Verifica se a variável existe e não é uma string vazia
            return primaryColor &&
                primaryColor !== 'undefined' &&
                primaryColor !== 'null'
                ? primaryColor
                : '#ff7110'
        }};
    }
`
