import { useEffect } from 'react'
import { useTheme } from 'contexts/whiteLabel/context'

export function useApplyTheme() {
    const { theme } = useTheme()

    useEffect(() => {
        const root = document.documentElement

        // Definindo cores do tema
        if (!theme?.colors?.primary) {
            root.style.setProperty('--primary', '#ff7110') // Laranja (fallback)
            root.style.setProperty('--primary-header', '#ff9954') // header (fallback)
            root.style.setProperty('--primary-opacity', '#fdf0e9') // cor com opacidade (fallback)
        } else {
            Object.entries(theme.colors).forEach(([key, value]) => {
                if (typeof value === 'string') {
                    root.style.setProperty(`--${key}`, value)
                }
                if (key === 'primary') {
                    root.style.setProperty('--primary-opacity', `${value}27`)
                    root.style.setProperty('--primary-header', value as string)
                }
            })
        }

        // Atualiza o título da página
        document.title = theme?.providers?.text_window ?? 'Club da Cotação'

        // Atualiza o favicon da página
        if (theme?.imgs?.img_logo) {
            let link = document.querySelector(
                "link[rel~='icon']"
            ) as HTMLLinkElement

            if (!link) {
                link = document.createElement('link')
                link.rel = 'icon'
                document.head.appendChild(link)
            }

            // Adicionando timestamp para evitar cache
            link.href = `${theme.imgs.img_logo}?t=${new Date().getTime()}`
        }
    }, [theme])
}
