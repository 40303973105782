import './index.scss'

import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import { useEffect, useState } from 'react'

import ProductService from 'services/ProductService'
import { DirectSaleProduct } from 'interfaces/directSaleProduct'
import { Loader } from 'components/loader'

import ButtonV3 from 'components/Buttonv3'
import { Datepicker } from 'components/datepicker'

import { BsSearch } from 'react-icons/bs'
import { FeedbackError } from 'components/feedback/error'
import { IoMdEye } from 'react-icons/io'
import { BaseModal } from 'components/BaseModal'
import { InfoAnalytics } from '../infoAnalytics'
import ButtonIsActive from 'components/ButtonIsActive'
import { MdEditCalendar } from 'react-icons/md'

type ItemExtractProps = {
    isVisible: boolean
    onClose: () => void
    product?: DirectSaleProduct
    user?: any
}

interface IItemExtractInfo {
    data: string
    total: number
    total_pedidos: number
    total_quantidade: number
}

export const ItemExtractProducts = ({
    isVisible,
    onClose,
    product,
    user,
}: ItemExtractProps) => {
    const [loadingTable, setLoadingTable] = useState(false)
    const [isAnalyticsVisible, setIsAnalyticsVisible] = useState(false)
    const [itemExtract, setItemExtract] = useState<IItemExtractInfo[]>([])
    const [paramsDate, setParamsDate] = useState()
    const [params, setParams] = useState({
        start_date: '',
        end_date: '',
    })
    const [selectedInterval, setSelectedInterval] = useState(7)

    const modal = {
        icon: <GrGroup color="white" />,
        styles: { maxWidth: '80vw' },
        id: 'item-extract-products',
        title: `Extrato do item`,
    }
    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')

            loadExtractItem(7)
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')

            setItemExtract([])
        }
    }, [isVisible, user, product])

    useEffect(() => {
        const currentDate = new Date()
        const startDate = new Date()
        startDate.setDate(currentDate.getDate() - 7)

        setParams({
            start_date: startDate.toISOString().split('T')[0],
            end_date: currentDate.toISOString().split('T')[0],
        })
    }, [])

    const loadExtractItem = (interval?: number) => {
        if (!user || !product) return

        setLoadingTable(true)
        setSelectedInterval(interval || 7)

        ProductService.getItemExtract(user.providerId, product.id, interval)
            .then((result) => {
                setItemExtract(result.data)
                setLoadingTable(false)
            })
            .catch((error) => {
                console.error('Erro ao carregar extrato:', error)
                setLoadingTable(false)
            })
    }

    const buttonsValue = [
        { descricao: 'Últimos 7 dias', valor: 7 },
        { descricao: 'Últimos 15 dias', valor: 15 },
        { descricao: 'Últimos 30 dias', valor: 30 },
        { descricao: 'Últimos 45 dias', valor: 45 },
        { descricao: 'Últimos 60 dias', valor: 60 },
        { descricao: 'Últimos 90 dias', valor: 90 },
    ]

    const handleButtonClick = (interval: number) => {
        loadExtractItem(interval)
    }

    const handleDateChange = (
        field: 'start_date' | 'end_date',
        date: string
    ) => {
        setParams((prevParams) => ({
            ...prevParams,
            [field]: date,
        }))
    }

    const handleSearchDate = () => {
        setLoadingTable(true)
        if (!user || !product) {
            return
        }

        const formattedStartDate = params.start_date
            ? new Date(params.start_date).toISOString().split('T')[0]
            : null
        const formattedEndDate = params.end_date
            ? new Date(params.end_date).toISOString().split('T')[0]
            : null

        setSelectedInterval(0)
        ProductService.getItemExtract(
            user.providerId,
            product.id,
            null as any,
            formattedStartDate as any,
            formattedEndDate as any
        )
            .then((result) => {
                setItemExtract(result.data)
                setLoadingTable(false)
            })
            .catch((error) => {
                console.error('Erro ao buscar dados:', error)
                setLoadingTable(false)
            })
    }

    const handleOpenAnalyticsModal = (date) => {
        setParamsDate(date)
        setIsAnalyticsVisible(true)
    }

    return (
        <div
            style={{
                display: 'flex',
                padding: '10px 30px 30px',
                flexDirection: 'column',
                minHeight: '25vh',
                justifyContent: 'space-between',
                margin: '0 auto',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <p className={'titleProductOnGroupsChoose'}>
                    #{product?.ean} - {product?.description}
                </p>
            </div>

            <section>
                <div>
                    <div className="content_buttons">
                        {buttonsValue.map((botao, index) => (
                            <ButtonIsActive
                                key={index}
                                isActive={selectedInterval === botao.valor}
                                onClick={() => handleButtonClick(botao.valor)}
                                icon={<MdEditCalendar />}
                            >
                                {botao.descricao}
                            </ButtonIsActive>
                        ))}
                    </div>

                    <div className="filtro_data_container">
                        <div className="inputs_data">
                            <Datepicker
                                callendarIcon
                                value={params.start_date}
                                description="Data Início"
                                onChange={(date) =>
                                    handleDateChange('start_date', date)
                                }
                            />
                            <Datepicker
                                callendarIcon
                                value={params.end_date}
                                description="Data Fim"
                                onChange={(date) =>
                                    handleDateChange('end_date', date)
                                }
                            />
                        </div>
                        <ButtonV3
                            filled
                            title="Pesquisar"
                            onClick={handleSearchDate}
                            Icon={
                                <BsSearch
                                    className="iconButtonV3"
                                    color="#fff"
                                />
                            }
                        />
                    </div>
                    <div className="NewListItemExtract">
                        {loadingTable ? (
                            <Loader type="inline" />
                        ) : (
                            <>
                                {itemExtract.length > 0 ? (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Data</th>
                                                <th>Qtd. total</th>
                                                <th>Valor Total</th>
                                                <th>Total Pedidos</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemExtract.map((item, index) => (
                                                <tr key={index}>
                                                    <td data-label="Data">
                                                        {item.data}
                                                    </td>
                                                    <td data-label="Quantidade Total">
                                                        {item.total_quantidade}
                                                    </td>
                                                    <td data-label="Valor Total">
                                                        R${' '}
                                                        {item.total.toFixed(2)}
                                                    </td>
                                                    <td data-label="Total Pedidos">
                                                        {item.total_pedidos}
                                                    </td>
                                                    <td
                                                        data-label="Ações"
                                                        align="center"
                                                    >
                                                        <IoMdEye
                                                            size={24}
                                                            color="#6391da"
                                                            title="Visualizar Analítico"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() =>
                                                                handleOpenAnalyticsModal(
                                                                    item.data
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan={1}
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Totais:
                                                </td>
                                                <td
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {itemExtract.reduce(
                                                        (sum, item) =>
                                                            sum +
                                                            item.total_quantidade,
                                                        0
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    R${' '}
                                                    {itemExtract
                                                        .reduce(
                                                            (sum, item) =>
                                                                sum +
                                                                item.total,
                                                            0
                                                        )
                                                        .toFixed(2)}
                                                </td>
                                                <td
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {itemExtract.reduce(
                                                        (sum, item) =>
                                                            sum +
                                                            item.total_pedidos,
                                                        0
                                                    )}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                ) : (
                                    <FeedbackError
                                        message={
                                            'Nenhum item foi encontrado nesse período!'
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '98%',
                    marginTop: 10,
                }}
            >
                <ButtonV3
                    filled
                    title="Voltar"
                    onClick={() => {
                        onClose()
                    }}
                />
            </div>
            <BaseModal
                onCloseCallback={() => setIsAnalyticsVisible(false)}
                children={
                    <InfoAnalytics
                        user={user}
                        product={product}
                        date={paramsDate}
                        isVisible={isAnalyticsVisible}
                        onClose={() => setIsAnalyticsVisible(false)}
                    />
                }
                title={`Informações Analíticas - ${paramsDate}`}
                user={user}
                product={product}
                isVisible={isAnalyticsVisible}
                onClose={() => setIsAnalyticsVisible(false)}
            />
        </div>
    )
}
