import React, { useEffect, useRef, useState } from 'react'
import {
    Container,
    CreateContainer,
    HomePreviewContainer,
    CreateHighlightContainer,
    HeaderPreview,
    StepsContainer,
    StepType,
    StepTypeButton,
    StepSize,
    StepMetadata,
    YoutubeTypeStep,
    BannerTypeStep,
    StepSizeButton,
    StepMetadataButton,
    PriorityContainer,
    FooterButtons,
    VideoContainer,
    PreviewCardsContainer,
    ImageContainer,
    NewRowBlockContainer,
    RowBlock,
    ButtonAdd,
    CreateBlockSettingsContainer,
    ItemConfig,
    ButtonCancelSave,
    AddItemToBlockButton,
    EmptyBlock,
    BlockCard,
    ContainerUploadImageDescription,
    TextsConfigsBlockSetup,
    AlignTitleSetupContainer,
    AlignPreview,
    BlockTitleContainer,
    BlockTitle,
    BlockSubtitle,
    ActionContainer,
    ShowSelectedProdContainer,
} from './styles'
import thumb from '../../../../../assets/images/thumbexample.png'
import banner from '../../../../../assets/images/bannerexample.jpeg'
import bannerwide from '../../../../../assets/images/wideexample.png'
import bannermedium from '../../../../../assets/images/sizeexample1.png'
import bannerlarge from '../../../../../assets/images/sizeexample2.png'
import ButtonV3 from 'components/Buttonv3'
import {
    IoIosAdd,
    IoLogoYoutube,
    IoMdAdd,
    IoMdAddCircle,
    IoMdArrowBack,
    IoMdCheckmarkCircleOutline,
    IoMdCloseCircleOutline,
    IoMdCloudUpload,
    IoMdSad,
    IoMdSave,
    IoMdText,
} from 'react-icons/io'
import {
    MdAdd,
    MdArrowUpward,
    MdArrowDownward,
    MdClose,
    MdRemove,
} from 'react-icons/md'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { Input } from 'components/input'
import InputV2 from 'components/InputV2'
import highlightsService from 'services/v3/HighlightsConfig.service copy'

import Carousel from 'pages/provider/edit/components/whitelabelHighlights/Carousel'

import { BiTrash } from 'react-icons/bi'
import { BsArrowUp, BsList } from 'react-icons/bs'
import { Loader } from 'components/loader'
import ColorPickerInput from '../whitelabelTheme/ColorPickerInput'
import { FiEdit } from 'react-icons/fi'
import { ToastContainer } from 'react-toastify'
import { BaseModal } from 'components/BaseModal'
import SelectProductModal from './SelectProductModal'
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity'
import { useTheme } from 'contexts/whiteLabel/context'

interface props {
    closeVisibleContent: () => void
}

export default function WhitelabelHighlights({ closeVisibleContent }: props) {
    const { theme } = useTheme()
    interface Imetadata {
        tipo: '' | 'video' | 'img'
        modelo: '' | 'large' | 'default' | 'vertical' | 'horizontal'
        title: string | undefined
        action: number
        status: 1
    }
    interface IblockConfig {
        modelo: 'carousel-banner' | 'carousel-large' | 'carousel-static'
        text_align: 'left' | 'center' | 'right'
        autoplay: boolean
        indicadores: boolean
        title: string | undefined
        action: 0 | 1
        status: boolean
        descricao: string | undefined
        subtitulo: string | undefined
        cor_descricao: string | undefined
        cor_subtitulo: string | undefined
    }

    const defaultMetadata: Imetadata = {
        tipo: '',
        modelo: '',
        title: '',
        action: 0,
        status: 1,
    }

    const [createHighlight, setCreateHighlight] = useState(false)
    const [step, setStep] = useState(1)
    const [stepBlock, setStepBlock] = useState(1)
    const [highlightMetadata, setHighlightMetadata] =
        useState<any>(defaultMetadata)
    const [embedCode, setEmbedCode] = useState('')
    const [highlightCards, setHighlightCards] = useState<any>([])
    const [highlightCarousels, setHighlightCarousels] = useState<any>([])
    const [isCreatingBlock, setIsCreatingBlock] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [visiblePicker, setVisiblePicker] = useState('')
    const [selectProductModalVisible, setSelectProductModalVisible] =
        useState(false)
    const [SelectProduct, setSelectProduct] = useState<any>(null)
    const [adviceText, setAdviceText] = useState('')

    const [link, setLink] = useState<any>(null)
    const [linkMobile, setLinkMobile] = useState<any>(null)

    const defaultBlockConfig: IblockConfig = {
        modelo: 'carousel-large',
        text_align: 'left',
        descricao: '',
        subtitulo: '',
        autoplay: true,
        indicadores: true,
        title: undefined,
        action: 0,
        status: true,
        cor_descricao: undefined,
        cor_subtitulo: undefined,
    }

    const [blockConfig, setBlockConfig] = useState<any>(defaultBlockConfig)
    const [editingBlock, setEditingBlock] = useState<any>()
    const [editingTitle, setEditingTitle] = useState<any>()

    function handleSetHighlightMetadata(valor, step) {
        setHighlightMetadata({ ...highlightMetadata, ...valor })
        setStep(step)
    }
    function handleSetBlockConfig(valor) {
        setBlockConfig({ ...blockConfig, ...valor })
    }
    async function handleRemoveBlock(id) {
        await highlightsService.removeBLock(id).then((res) => getHiglights())
    }

    useEffect(() => {
        getHiglights()
    }, [])

    async function getHiglights() {
        setIsLoading(true)
        // await highlightsService.getProviderHighlightsCarousels().then(res => {
        //   setHighlightCarousels(res.carousels)
        //   console.log('highlightCarousels', res.carousels)
        // })
        await highlightsService.getProviderHighlights().then((res) => {
            setHighlightCards(res)
        })
        setIsLoading(false)
    }
    async function reorderRows(row, id, index, direction) {
        if (direction == 'up') {
            let prevBLock = highlightCards.hightlights[index - 1].id
            let actualBLock = id

            // let prevBLock = highlightCards.hightlights.filter(item => item.row == (row - 1))[0].id
            // let actualBLock = id
            const prev = highlightsService.reorderBlock(prevBLock, index)
            const actual = highlightsService.reorderBlock(
                actualBLock,
                index - 1
            )

            await Promise.all([prev, actual]).then((res) => getHiglights())
        } else {
            // let searchForNext = highlightCards.hightlights.filter(item => item.row == (row + 1))
            // if (searchForNext.length == 0) {
            //     searchForNext = highlightCards.hightlights.filter(item => item.row == (row + 2))
            // }

            let nextBLock = highlightCards.hightlights[index + 1].id
            // let nextBLock = highlightCards.hightlights.filter(item => item.row == (row + 1))[0].id
            let actualBLock = id

            const next = highlightsService.reorderBlock(nextBLock, index)
            const actual = highlightsService.reorderBlock(
                actualBLock,
                index + 1
            )

            await Promise.all([next, actual]).then((res) => getHiglights())
        }
    }

    async function handleCreateBlock() {
        let data = { ...blockConfig }
        if (highlightCards && highlightCards.hightlights.length > 0) {
            data = {
                ...blockConfig,
                row: highlightCards.hightlights.length + 1,
            }
        } else {
            data = { ...blockConfig, row: 1 }
        }

        await highlightsService.createHighlightBlock(data).then((res) => {
            setIsCreatingBlock(false)
            setBlockConfig(defaultBlockConfig)
            setStep(1)
            getHiglights()
        })
    }

    function handleBack() {
        if (step == 1) {
            closeVisibleContent()
        } else {
            setStep(step - 1)
        }
    }
    function handleCancelCreate() {
        if (createHighlight) {
            setStep(1)
            setHighlightMetadata(defaultMetadata)
            setCreateHighlight(false)
            setEmbedCode('')
            setLink(null)
            setLinkMobile(null)
            setSelectProduct(null)
        } else {
            setCreateHighlight(true)
        }
    }

    function handleCancelBlockCreation() {
        setIsCreatingBlock(false)
        setBlockConfig(defaultBlockConfig)
    }

    function addHighlightToBlock(block) {
        setEditingBlock(block)
        if (block.modelo == 'carousel-banner') {
            handleSetHighlightMetadata({ tipo: 'img', modelo: 'horizontal' }, 3)
        }

        setCreateHighlight(true)
    }

    async function handleSaveNewHighlight() {
        if (highlightMetadata.tipo != 'video') {
            let data = { ...highlightMetadata }

            const formData = new FormData()

            for (let key in data) {
                formData.append(key, data[key])
            }
            formData.append('img', link)
            formData.append('img_mobile', linkMobile)

            if (SelectProduct && SelectProduct.id) {
                formData.append('pro_id', SelectProduct.id)
            }

            await highlightsService
                .createHighlight(editingBlock.id, formData)
                .then((res) => {
                    setCreateHighlight(false)
                    setStep(1)
                    setHighlightMetadata(defaultMetadata)
                    getHiglights()
                    setLink(null)
                    setLinkMobile(null)
                    setEmbedCode('')
                    setAdviceText('')
                })
        } else {
            let data = { ...highlightMetadata }

            const formData = new FormData()

            for (let key in data) {
                formData.append(key, data[key])
            }
            formData.append('img', embedCode)

            await highlightsService
                .createHighlight(editingBlock.id, formData)
                .then((res) => {
                    setCreateHighlight(false)
                    setStep(1)
                    setHighlightMetadata(defaultMetadata)
                    getHiglights()
                    setLink(undefined)
                    setSelectProduct(null)
                })
        }
    }
    function getColorCheckmark(type) {
        let color
        if (type == 'mobile') {
            if (linkMobile && linkMobile.size) {
                linkMobile.size > 2000000 ? (color = 'red') : (color = 'green')
            } else {
                color = '#cecece'
            }
        } else {
            if (link && link.size) {
                link.size > 2000000 ? (color = 'red') : (color = 'green')
            } else {
                color = '#cecece'
            }
        }
        return color
    }

    function getStep() {
        switch (step) {
            case 1:
                return (
                    <StepType>
                        <StepTypeButton
                            onClick={() =>
                                handleSetHighlightMetadata({ tipo: 'video' }, 2)
                            }
                        >
                            <h6>Video do Youtube</h6>
                            <YoutubeTypeStep>
                                <img src={thumb}></img>
                                <IoLogoYoutube></IoLogoYoutube>
                            </YoutubeTypeStep>
                        </StepTypeButton>
                        <StepTypeButton
                            onClick={() =>
                                handleSetHighlightMetadata({ tipo: 'img' }, 2)
                            }
                        >
                            <h6>Banner Promocional</h6>
                            <BannerTypeStep>
                                <img src={banner}></img>
                            </BannerTypeStep>
                        </StepTypeButton>
                    </StepType>
                )
                break
            case 2:
                return (
                    <StepSize>
                        <StepSizeButton
                            onClick={() =>
                                handleSetHighlightMetadata(
                                    { modelo: 'card-large' },
                                    3
                                )
                            }
                        >
                            <h6>Padrão</h6>
                            {highlightMetadata.tipo == 'video' ? (
                                <div
                                    style={{ position: 'absolute', zIndex: 2 }}
                                >
                                    <IoLogoYoutube
                                        size={45}
                                        color="red"
                                    ></IoLogoYoutube>
                                </div>
                            ) : null}
                            <div>
                                <img
                                    style={{ width: 200, height: 200 }}
                                    src={bannerlarge}
                                ></img>
                            </div>
                        </StepSizeButton>
                        {/* {highlightMetadata.tipo == 'video' ?
                        <StepSizeButton onClick={() => handleSetHighlightMetadata({ modelo: 'card-large' }, 3)}>
                            <h6>Grande</h6>
                            {highlightMetadata.tipo == 'video' ?
                                <div style={{ position: 'absolute', zIndex: 2 }}>
                                    <IoLogoYoutube size={45} color='red' ></IoLogoYoutube>
                                </div>
                                : null}
                            <div >
                                <img style={{ width: 280, height: 280 }} src={bannerlarge}></img>
                            </div>
                        </StepSizeButton >
                        :
                        <StepSizeButton onClick={() => handleSetHighlightMetadata({ modelo: 'card-vertical' }, 3)} >
                            <h6>Vertical</h6>
                            {highlightMetadata.tipo == 'video' ?
                                <div style={{ position: 'absolute', zIndex: 2 }}>
                                    <IoLogoYoutube size={45} color='red' ></IoLogoYoutube>
                                </div>
                                : null}
                            <div>
                                <img style={{ width: 200, height: 300 }} src={bannermedium}></img>
                            </div>
                        </StepSizeButton>
                    } */}
                        {/* {highlightMetadata.tipo != 'video' ?
                        <StepSizeButton onClick={() => handleSetHighlightMetadata({ modelo: 'card-horizontal' }, 3)}>
                            <h6>Horizontal</h6>
                            <div>
                                <img style={{ width: 320, height: 140 }} src={bannerwide}></img>

                            </div>
                        </StepSizeButton>

                        : null} */}
                    </StepSize>
                )
                break
            case 3:
                return (
                    <StepMetadata>
                        {highlightMetadata.tipo != 'video' ? (
                            <StepMetadataButton>
                                {editingBlock.modelo == 'carousel-banner' ? (
                                    <>
                                        <ContainerUploadImageDescription>
                                            <h5>
                                                Imagem para visualização no
                                                desktop
                                            </h5>
                                            <p>
                                                O banner irá ocupar toda a
                                                largura de tela disponível, para
                                                uma melhor experiência visual
                                                para seus clientes o ideal é uma
                                                imagem{' '}
                                                <strong>16:9 ou 21:9</strong>
                                            </p>
                                            <p>
                                                <strong>
                                                    Exemplo de resolução:
                                                    1200x400 pixels
                                                </strong>{' '}
                                            </p>

                                            <section>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e: any) =>
                                                        setLink(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                    // onChange={(e) => { set(e.target.files[0]) }}
                                                />
                                                <IoMdCheckmarkCircleOutline
                                                    color={getColorCheckmark(
                                                        'desktop'
                                                    )}
                                                    size={30}
                                                />
                                            </section>
                                        </ContainerUploadImageDescription>
                                        <ContainerUploadImageDescription>
                                            <h5>
                                                Imagem para visualização no
                                                mobile
                                            </h5>

                                            <p>
                                                O banner irá se limitar à
                                                largura de tela disponível, e a
                                                altura irá se adaptar às
                                                dimensões da imagem
                                            </p>
                                            <p>
                                                <strong>
                                                    {' '}
                                                    Exemplo de resolução:
                                                    800x400 pixels
                                                </strong>{' '}
                                            </p>
                                            <section>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e: any) =>
                                                        setLinkMobile(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                />
                                                <IoMdCheckmarkCircleOutline
                                                    color={getColorCheckmark(
                                                        'mobile'
                                                    )}
                                                    size={30}
                                                />
                                            </section>
                                        </ContainerUploadImageDescription>
                                    </>
                                ) : (
                                    <section>
                                        <ContainerUploadImageDescription>
                                            <p>
                                                A dimensão ideal da imagem é
                                                290x290 ( para a melhor
                                                experiência visual, selecione
                                                imagens com a altura e largura
                                                iguais )
                                            </p>
                                            <p>
                                                <strong>
                                                    {' '}
                                                    Exemplos de resoulução:
                                                    290x290, 320x320 pixels
                                                </strong>{' '}
                                            </p>
                                            <section>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e: any) =>
                                                        setLink(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                    // onChange={(e) => { set(e.target.files[0]) }}
                                                />
                                                <IoMdCheckmarkCircleOutline
                                                    color={getColorCheckmark(
                                                        'desktop'
                                                    )}
                                                    size={30}
                                                />
                                            </section>
                                        </ContainerUploadImageDescription>
                                    </section>
                                )}

                                {/* <section>
                <ButtonV3 Icon={<IoMdText color='#ff7110' style={{ marginRight: 10 }} size={20} />} title='Adicionar Titulo'></ButtonV3>
                <IoMdCheckmarkCircleOutline color='#cecece' size={30} />
              </section> */}
                                {link && link.size ? (
                                    link.size > 2000000 ? (
                                        <span style={{ color: 'red' }}>
                                            A imagem não pode ser maior que 2mb
                                            !
                                        </span>
                                    ) : null
                                ) : null}
                                {linkMobile && linkMobile.size ? (
                                    linkMobile.size > 2000000 ? (
                                        <span style={{ color: 'red' }}>
                                            A imagem não pode ser maior que 2mb
                                            !!
                                        </span>
                                    ) : null
                                ) : null}
                                <PriorityContainer>
                                    <h4>Selecione uma opção de ação</h4>
                                    <section>
                                        <CheckBoxCustom
                                            preventUncheck
                                            description="Padrão: Ao clicar o destaque é expandido."
                                            change={() =>
                                                setHighlightMetadata({
                                                    action: 0,
                                                })
                                            }
                                            checked={
                                                highlightMetadata.action == 0
                                            }
                                        ></CheckBoxCustom>
                                        <CheckBoxCustom
                                            preventUncheck
                                            description="Referência a um produto: Ao clicar o usuario é
                                    encaminhado para a lista de produtos
                                    com determindado produto selecionado."
                                            change={() =>
                                                setHighlightMetadata({
                                                    action: 1,
                                                })
                                            }
                                            checked={
                                                highlightMetadata.action == 1
                                            }
                                        ></CheckBoxCustom>
                                        {highlightMetadata.action == 1 ? (
                                            <ActionContainer>
                                                {SelectProduct ? (
                                                    <ShowSelectedProdContainer>
                                                        <img
                                                            src={
                                                                SelectProduct.picture
                                                            }
                                                        />
                                                        <p>
                                                            {
                                                                SelectProduct.description
                                                            }
                                                        </p>
                                                    </ShowSelectedProdContainer>
                                                ) : null}
                                                <ButtonV3
                                                    style={{ height: 45.5 }}
                                                    filled={
                                                        SelectProduct
                                                            ? false
                                                            : true
                                                    }
                                                    title={
                                                        SelectProduct
                                                            ? 'Alterar produto'
                                                            : 'Selecionar produto'
                                                    }
                                                    onClick={() =>
                                                        setSelectProductModalVisible(
                                                            true
                                                        )
                                                    }
                                                ></ButtonV3>
                                            </ActionContainer>
                                        ) : null}
                                        {/* <CheckBoxCustom description='PopUp: Assim que o usuário acessa a tela Home, o video é reproduzido.' change={() => { }} checked={false} ></CheckBoxCustom> */}
                                    </section>
                                </PriorityContainer>
                                {adviceText !== '' && !link && !linkMobile && (
                                    <span
                                        style={{
                                            textAlign: 'center',
                                            color: 'red',
                                        }}
                                    >
                                        {adviceText}
                                    </span>
                                )}
                                <FooterButtons>
                                    <section>
                                        <ButtonV3
                                            style={{ height: 45.5 }}
                                            title="Cancelar"
                                            onClick={handleCancelCreate}
                                        ></ButtonV3>
                                    </section>
                                    <section>
                                        <ButtonV3
                                            filled
                                            // disabled={!link && !linkMobile}
                                            style={
                                                !link && !linkMobile
                                                    ? { opacity: 0.5 }
                                                    : {}
                                            }
                                            onClick={() =>
                                                !link && !linkMobile
                                                    ? setAdviceText(
                                                          'Por favor, adicione pelo menos uma imagem para prosseguir!'
                                                      )
                                                    : handleSaveNewHighlight()
                                            }
                                            title="Salvar destaque"
                                        ></ButtonV3>
                                    </section>
                                </FooterButtons>
                            </StepMetadataButton>
                        ) : (
                            <StepMetadataButton>
                                <section>
                                    <div
                                        style={{
                                            minWidth: 100,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        <InputV2
                                            infoIconNull
                                            icon={<></>}
                                            id={'embed'}
                                            // label={'Telefone'}
                                            value={embedCode}
                                            type={'text'}
                                            placeholder={'Código incorporado'}
                                            onChange={(value) =>
                                                setEmbedCode(value.target.value)
                                            }
                                        />
                                    </div>
                                    {/* <ButtonV3 Icon={<IoMdCloudUpload color='#ff7110' style={{ marginRight: 10 }} size={20} />} title='Adicionar video'></ButtonV3> */}
                                    <IoMdCheckmarkCircleOutline
                                        color={
                                            embedCode == ''
                                                ? '#cecece'
                                                : 'green'
                                        }
                                        size={30}
                                    />
                                </section>
                                {/* <section>
                <div style={{ minWidth: 100, paddingBottom: 3 }}>

                </div>
                <ButtonV3 Icon={<IoMdText color='#ff7110' style={{ marginRight: 10 }} size={20} />} title='Adicionar Titulo'></ButtonV3>
                <IoMdCheckmarkCircleOutline color='#cecece' size={30} />
              </section> */}

                                <PriorityContainer>
                                    <h4>Selecione uma opção de ação</h4>
                                    <section>
                                        <CheckBoxCustom
                                            description="Padrão: Ao clicar o destaque é expandido."
                                            change={() => {}}
                                            checked={
                                                highlightMetadata.actionType ==
                                                'openModal'
                                            }
                                        ></CheckBoxCustom>
                                        {/* <CheckBoxCustom description='PopUp: Assim que o usuário acessa a tela Home, o video é reproduzido.' change={() => { }} checked={false} ></CheckBoxCustom> */}
                                    </section>
                                </PriorityContainer>
                                <FooterButtons>
                                    <section>
                                        <ButtonV3
                                            style={{ height: 45.5 }}
                                            title="Cancelar"
                                            onClick={handleCancelCreate}
                                        ></ButtonV3>
                                    </section>
                                    <section>
                                        teste
                                        <ButtonV3
                                            filled
                                            onClick={handleSaveNewHighlight}
                                            title="Salvar destaque"
                                        ></ButtonV3>
                                    </section>
                                </FooterButtons>
                            </StepMetadataButton>
                        )}
                    </StepMetadata>
                )
                break

            default:
                break
        }
    }

    function getStepTitle() {
        switch (step) {
            case 1:
                return <h2>Selecione o tipo do destaque !</h2>
                break
            case 2:
                return <h2>Selecione o tamanho do destaque !</h2>
                break

            default:
                return <h2>Configure seu destaque</h2>
                break
        }
    }

    function getSizeProps(modelo) {
        switch (modelo) {
            case 'card-large':
                return {
                    width: 446,
                    height: 262,
                }
                break
            case 'card-default':
                return {
                    width: 350,
                    height: 350,
                }
                break
            case 'card-horizontal':
                return {
                    width: 529,
                    height: 260,
                }
                break
            case 'card-vertical':
                return {
                    width: 260,
                    height: 529,
                }
                break

            default:
                return {
                    width: 373,
                    height: 210,
                }
                break
        }
    }

    function getCardComponent(item) {
        switch (item.tipo) {
            case 'video':
                return (
                    <VideoContainer
                        size={getSizeProps(item.modelo)}
                        // onClick={() => console.log('click')}
                        dangerouslySetInnerHTML={{ __html: item.link }}
                    ></VideoContainer>
                )
                break
            case 'img':
                return (
                    <ImageContainer size={getSizeProps(item.modelo)}>
                        <img src={item.link} alt="" />
                    </ImageContainer>
                )
                break

            default:
                break
        }
        return <p>{item.modelo}</p>
    }

    function getBlockName(blockname) {
        switch (blockname) {
            case 'carousel-static':
                return 'bloco Estático'
            case 'carousel-large':
                return 'bloco carrossel'
            case 'carousel-banner':
                return 'bloco de Banners'
            default:
                return 'bloco'
        }
    }
    function handleSetVisiblePicker(id: string) {
        visiblePicker == id ? setVisiblePicker('') : setVisiblePicker(id)
    }

    function handleEditBlock(bloco: any) {
        setEditingTitle(bloco)
    }
    async function handleSaveChanges() {
        let data = {
            descricao: editingTitle.descricao,
            cor_descricao: editingTitle.cor_descricao,
            subtitulo: editingTitle.subtitulo,
            cor_subtitulo: editingTitle.cor_subtitulo,
            text_align: editingTitle.text_align,
        }

        await highlightsService
            .updateHighlight(editingTitle.id, data)
            .then((res) => {
                setEditingTitle(null)
                getHiglights()
            })
    }
    //!! ---------------------------------------------

    //?? ---------------------------------------------

    //!! ---------------------------------------------

    //??  ---------------------------------------------

    //   useEffect(() => {
    //     console.log(editingTitle)
    //   }, [editingTitle])

    return (
        <Container>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLoading ? <Loader></Loader> : null}

            {createHighlight ? (
                <CreateHighlightContainer>
                    <HeaderPreview>
                        {getStepTitle()}
                        <section>
                            <ButtonV3
                                style={{ height: 45.5 }}
                                title="Cancelar"
                                onClick={handleCancelCreate}
                            ></ButtonV3>
                        </section>
                    </HeaderPreview>
                    <StepsContainer>{getStep()}</StepsContainer>
                </CreateHighlightContainer>
            ) : (
                <HomePreviewContainer>
                    <HeaderPreview>
                        <h2>Seus Destaques</h2>
                        <div>
                            <ButtonV3
                                Icon={
                                    <IoMdArrowBack className="iconButtonV3"></IoMdArrowBack>
                                }
                                title="Voltar"
                                onClick={handleBack}
                            />
                        </div>
                    </HeaderPreview>

                    <PreviewCardsContainer>
                        {highlightCards &&
                        highlightCards.hightlights &&
                        highlightCards.hightlights.length > 0
                            ? highlightCards.hightlights.map(
                                  (block: any, index) => (
                                      <div
                                          style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              margin: '14px 0',
                                              alignItems: 'center',
                                          }}
                                      >
                                          {block.content.length > 0 ? (
                                              <BlockCard>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          gap: 10,
                                                          width: '100%',
                                                          justifyContent:
                                                              'center',
                                                          alignItems: 'center',
                                                          marginBottom: 6,
                                                      }}
                                                  >
                                                      <AddItemToBlockButton
                                                          onClick={() =>
                                                              addHighlightToBlock(
                                                                  block
                                                              )
                                                          }
                                                      >
                                                          <IoMdAddCircle></IoMdAddCircle>
                                                          <h4>
                                                              Adicionar destaque
                                                              ao{' '}
                                                              <span>
                                                                  {getBlockName(
                                                                      block.modelo
                                                                  )}
                                                              </span>
                                                          </h4>
                                                      </AddItemToBlockButton>
                                                      {/* <p onClick={() => console.log(block)}>teste</p> */}
                                                      {!block.descricao ? (
                                                          <FiEdit
                                                              onClick={() =>
                                                                  handleEditBlock(
                                                                      block
                                                                  )
                                                              }
                                                              title="editar"
                                                              style={{
                                                                  cursor: 'pointer',
                                                              }}
                                                              size={30}
                                                              color={`${
                                                                  theme.colors
                                                                      ?.primary ||
                                                                  '#ff7110'
                                                              }`}
                                                          ></FiEdit>
                                                      ) : null}
                                                      <div
                                                          title="Remover Bloco"
                                                          style={{
                                                              padding: 5,
                                                              borderRadius:
                                                                  '50%',
                                                              backgroundColor:
                                                                  '#fff',
                                                              boxShadow:
                                                                  '1px 1px 1px 1px rgba(0,0,0,0.2)',
                                                              cursor: 'pointer',
                                                          }}
                                                      >
                                                          <BiTrash
                                                              onClick={() =>
                                                                  handleRemoveBlock(
                                                                      block.id
                                                                  )
                                                              }
                                                              color="red"
                                                              size={25}
                                                              title="Remover Bloco"
                                                          ></BiTrash>
                                                      </div>
                                                      {index > 0 ? (
                                                          <div
                                                              title="Reposicionar Bloco"
                                                              style={{
                                                                  padding: 5,
                                                                  borderRadius:
                                                                      '50%',
                                                                  backgroundColor:
                                                                      '#fff',
                                                                  boxShadow:
                                                                      '1px 1px 1px 1px rgba(0,0,0,0.2)',
                                                                  cursor: 'pointer',
                                                              }}
                                                          >
                                                              <MdArrowUpward
                                                                  onClick={() =>
                                                                      reorderRows(
                                                                          block.row,
                                                                          block.id,
                                                                          index,
                                                                          'up'
                                                                      )
                                                                  }
                                                                  color="blue"
                                                                  size={25}
                                                                  title="Reordenar"
                                                              ></MdArrowUpward>
                                                          </div>
                                                      ) : null}
                                                      {index <
                                                      highlightCards.hightlights
                                                          .length -
                                                          1 ? (
                                                          <div
                                                              title="Reposicionar Bloco"
                                                              style={{
                                                                  padding: 5,
                                                                  borderRadius:
                                                                      '50%',
                                                                  backgroundColor:
                                                                      '#fff',
                                                                  boxShadow:
                                                                      '1px 1px 1px 1px rgba(0,0,0,0.2)',
                                                                  cursor: 'pointer',
                                                              }}
                                                          >
                                                              <MdArrowDownward
                                                                  onClick={() =>
                                                                      reorderRows(
                                                                          block.row,
                                                                          block.id,
                                                                          index,
                                                                          'down'
                                                                      )
                                                                  }
                                                                  color="blue"
                                                                  size={25}
                                                                  title="Reordenar"
                                                              ></MdArrowDownward>
                                                          </div>
                                                      ) : null}
                                                  </div>
                                                  {block.descricao ||
                                                  (editingTitle &&
                                                      editingTitle.id ==
                                                          block.id) ? (
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              width: '100%',
                                                              padding:
                                                                  '5px 20px',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'space-between',
                                                              borderBottom:
                                                                  '1px solid #cecece',
                                                              marginBottom: 10,
                                                          }}
                                                      >
                                                          {editingTitle &&
                                                          editingTitle.id ==
                                                              block.id ? (
                                                              //?  edit ...........
                                                              <TextsConfigsBlockSetup
                                                                  style={{
                                                                      width: '100%',
                                                                  }}
                                                              >
                                                                  <section>
                                                                      <h6>
                                                                          Título
                                                                          (opcional)
                                                                      </h6>
                                                                      <span>
                                                                          Defina
                                                                          um
                                                                          título
                                                                          a ser
                                                                          exibido
                                                                          logo
                                                                          acima
                                                                          do
                                                                          bloco
                                                                          de
                                                                          destaques
                                                                      </span>
                                                                      <div
                                                                          style={{
                                                                              display:
                                                                                  'flex',
                                                                              alignItems:
                                                                                  'center',
                                                                          }}
                                                                      >
                                                                          <div
                                                                              style={{
                                                                                  flex: 1,
                                                                                  marginRight: 8,
                                                                              }}
                                                                          >
                                                                              <Input
                                                                                  type="text"
                                                                                  name="description"
                                                                                  // description="Titulo"
                                                                                  placeholder=""
                                                                                  onChange={(
                                                                                      e
                                                                                  ) =>
                                                                                      setEditingTitle(
                                                                                          {
                                                                                              ...editingTitle,
                                                                                              descricao:
                                                                                                  e
                                                                                                      .target
                                                                                                      .value,
                                                                                          }
                                                                                      )
                                                                                  }
                                                                                  value={
                                                                                      editingTitle.descricao
                                                                                  }
                                                                              ></Input>
                                                                          </div>
                                                                          <ColorPickerInput
                                                                              // title='Cor do título'
                                                                              leftPosition={
                                                                                  -110
                                                                              }
                                                                              onSelectPicker={() =>
                                                                                  handleSetVisiblePicker(
                                                                                      'title'
                                                                                  )
                                                                              }
                                                                              color={
                                                                                  editingTitle.cor_descricao
                                                                                      ? editingTitle.cor_descricao
                                                                                      : ''
                                                                              }
                                                                              setColor={(
                                                                                  color
                                                                              ) =>
                                                                                  setEditingTitle(
                                                                                      {
                                                                                          ...editingTitle,
                                                                                          cor_descricao:
                                                                                              color,
                                                                                      }
                                                                                  )
                                                                              }
                                                                              visible={
                                                                                  visiblePicker ==
                                                                                  'title'
                                                                              }
                                                                              tooltip="Define cor do título"
                                                                          />
                                                                      </div>
                                                                  </section>
                                                                  <section>
                                                                      <h6>
                                                                          Subtítulo
                                                                          (opcional)
                                                                      </h6>
                                                                      <span>
                                                                          Defina
                                                                          um
                                                                          subtítulo
                                                                          a ser
                                                                          exibido{' '}
                                                                      </span>
                                                                      <div
                                                                          style={{
                                                                              display:
                                                                                  'flex',
                                                                              alignItems:
                                                                                  'center',
                                                                          }}
                                                                      >
                                                                          <div
                                                                              style={{
                                                                                  flex: 1,
                                                                                  marginRight: 8,
                                                                              }}
                                                                          >
                                                                              <Input
                                                                                  type="text"
                                                                                  name="description"
                                                                                  // description="Titulo"
                                                                                  placeholder=""
                                                                                  onChange={(
                                                                                      e
                                                                                  ) =>
                                                                                      setEditingTitle(
                                                                                          {
                                                                                              ...editingTitle,
                                                                                              subtitulo:
                                                                                                  e
                                                                                                      .target
                                                                                                      .value,
                                                                                          }
                                                                                      )
                                                                                  }
                                                                                  value={
                                                                                      editingTitle.subtitulo
                                                                                  }
                                                                              ></Input>
                                                                          </div>
                                                                          <ColorPickerInput
                                                                              leftPosition={
                                                                                  -110
                                                                              }
                                                                              // title='Cor do subtítulo'
                                                                              onSelectPicker={() =>
                                                                                  handleSetVisiblePicker(
                                                                                      'subtitle'
                                                                                  )
                                                                              }
                                                                              color={
                                                                                  editingTitle.cor_subtitulo
                                                                                      ? editingTitle.cor_subtitulo
                                                                                      : ''
                                                                              }
                                                                              setColor={(
                                                                                  color
                                                                              ) =>
                                                                                  setEditingTitle(
                                                                                      {
                                                                                          ...editingTitle,
                                                                                          cor_subtitulo:
                                                                                              color,
                                                                                      }
                                                                                  )
                                                                              }
                                                                              visible={
                                                                                  visiblePicker ==
                                                                                  'subtitle'
                                                                              }
                                                                              tooltip="Define cor do subtítulo"
                                                                          />
                                                                      </div>
                                                                  </section>
                                                                  <AlignTitleSetupContainer>
                                                                      <section>
                                                                          <CheckBoxCustom
                                                                              description="Alinhado à esquerda"
                                                                              change={(
                                                                                  e,
                                                                                  stats
                                                                              ) =>
                                                                                  setEditingTitle(
                                                                                      {
                                                                                          ...editingTitle,
                                                                                          text_align:
                                                                                              e,
                                                                                      }
                                                                                  )
                                                                              }
                                                                              value={
                                                                                  'left'
                                                                              }
                                                                              checked={
                                                                                  editingTitle.text_align ==
                                                                                  'left'
                                                                              }
                                                                          ></CheckBoxCustom>
                                                                          <CheckBoxCustom
                                                                              description="Centralizado"
                                                                              change={(
                                                                                  e,
                                                                                  stats
                                                                              ) =>
                                                                                  setEditingTitle(
                                                                                      {
                                                                                          ...editingTitle,
                                                                                          text_align:
                                                                                              e,
                                                                                      }
                                                                                  )
                                                                              }
                                                                              value={
                                                                                  'center'
                                                                              }
                                                                              checked={
                                                                                  editingTitle.text_align ==
                                                                                  'center'
                                                                              }
                                                                          ></CheckBoxCustom>
                                                                          <CheckBoxCustom
                                                                              description="Alinhado à direita"
                                                                              change={(
                                                                                  e
                                                                              ) =>
                                                                                  setEditingTitle(
                                                                                      {
                                                                                          ...editingTitle,
                                                                                          text_align:
                                                                                              e,
                                                                                      }
                                                                                  )
                                                                              }
                                                                              value={
                                                                                  'right'
                                                                              }
                                                                              checked={
                                                                                  editingTitle.text_align ==
                                                                                  'right'
                                                                              }
                                                                          ></CheckBoxCustom>
                                                                      </section>
                                                                      {editingTitle.descricao ? (
                                                                          <AlignPreview
                                                                              e
                                                                              align={
                                                                                  editingTitle.text_align
                                                                              }
                                                                              colorTitle={
                                                                                  editingTitle.cor_descricao
                                                                              }
                                                                              colorSubtitle={
                                                                                  editingTitle.cor_subtitulo
                                                                              }
                                                                          >
                                                                              <h2>
                                                                                  {
                                                                                      editingTitle.descricao
                                                                                  }
                                                                              </h2>
                                                                              <h4>
                                                                                  {
                                                                                      editingTitle.subtitulo
                                                                                  }
                                                                              </h4>
                                                                          </AlignPreview>
                                                                      ) : null}
                                                                  </AlignTitleSetupContainer>

                                                                  <div
                                                                      style={{
                                                                          display:
                                                                              'flex',
                                                                          justifyContent:
                                                                              'flex-end',
                                                                          marginBottom: 10,
                                                                      }}
                                                                  >
                                                                      <ButtonV3
                                                                          onClick={() =>
                                                                              setEditingTitle(
                                                                                  null
                                                                              )
                                                                          }
                                                                          style={{
                                                                              marginRight: 15,
                                                                              minWidth: 100,
                                                                          }}
                                                                          title="Cancelar"
                                                                      ></ButtonV3>

                                                                      <ButtonV3
                                                                          style={{
                                                                              minWidth: 130,
                                                                              justifyContent:
                                                                                  'center',
                                                                          }}
                                                                          filled
                                                                          onClick={() =>
                                                                              handleSaveChanges()
                                                                          }
                                                                          title="Salvar"
                                                                      ></ButtonV3>
                                                                  </div>
                                                              </TextsConfigsBlockSetup>
                                                          ) : (
                                                              <>
                                                                  <BlockTitleContainer
                                                                      style={{
                                                                          width: '100%',
                                                                      }}
                                                                      align={
                                                                          block.text_align
                                                                      }
                                                                  >
                                                                      <BlockTitle
                                                                          titleColor={
                                                                              block.cor_descricao
                                                                          }
                                                                      >
                                                                          {
                                                                              block.descricao
                                                                          }
                                                                      </BlockTitle>
                                                                      {block.subtitulo ? (
                                                                          <BlockSubtitle
                                                                              subtitleColor={
                                                                                  block.cor_subtitulo
                                                                              }
                                                                          >
                                                                              {
                                                                                  block.subtitulo
                                                                              }
                                                                          </BlockSubtitle>
                                                                      ) : null}
                                                                  </BlockTitleContainer>

                                                                  <div>
                                                                      <FiEdit
                                                                          onClick={() =>
                                                                              handleEditBlock(
                                                                                  block
                                                                              )
                                                                          }
                                                                          title="editar"
                                                                          style={{
                                                                              cursor: 'pointer',
                                                                          }}
                                                                          size={
                                                                              30
                                                                          }
                                                                          color={`${
                                                                              theme
                                                                                  .colors
                                                                                  ?.primary ||
                                                                              '#ff7110'
                                                                          }`}
                                                                      ></FiEdit>
                                                                  </div>
                                                              </>
                                                          )}
                                                      </div>
                                                  ) : null}
                                                  <Carousel
                                                      reloadHighlights={() =>
                                                          getHiglights()
                                                      }
                                                      autoplay
                                                      //?locked autoplay
                                                      type={block.modelo}
                                                      items={block.content}
                                                  />
                                              </BlockCard>
                                          ) : (
                                              <EmptyBlock>
                                                  <AddItemToBlockButton
                                                      onClick={() =>
                                                          addHighlightToBlock(
                                                              block
                                                          )
                                                      }
                                                  >
                                                      <IoMdAddCircle></IoMdAddCircle>
                                                      <h4>
                                                          Adicionar o primeiro
                                                          destaque do{' '}
                                                          <span>
                                                              {getBlockName(
                                                                  block.modelo
                                                              )}
                                                          </span>
                                                      </h4>
                                                  </AddItemToBlockButton>
                                                  <div
                                                      style={{
                                                          marginLeft: 20,
                                                          cursor: 'pointer',
                                                      }}
                                                  >
                                                      <BiTrash
                                                          onClick={() =>
                                                              handleRemoveBlock(
                                                                  block.id
                                                              )
                                                          }
                                                          color="red"
                                                          size={25}
                                                          title="Remover Bloco"
                                                      ></BiTrash>
                                                  </div>
                                              </EmptyBlock>
                                          )}
                                      </div>
                                  )

                                  /*
                                     <AddItemToBlockButton onClick={() => addHighlightToBlock(block)} >
                                       <IoMdAddCircle></IoMdAddCircle>
                                       <h4>Adicionar<br></br>Destaque</h4>
            </AddItemToBlockButton> */
                                  // <RowBlock onClick={() => console.log(block)} key={'row' + index}>
                                  //   {block.content.length > 0 ?
                                  //     <div style={{ width: '100%' }}>
                                  //         {/*
                                  //                           <AddItemToBlockButton onClick={() => addHighlightToBlock(block)} >
                                  //                             <IoMdAddCircle></IoMdAddCircle>
                                  //                             <h4>Adicionar<br></br>Destaque</h4>
                                  //                           </AddItemToBlockButton> */}

                                  //       <Carousel
                                  //         type='carousel-large'
                                  //         items={block.content}
                                  //       />
                                  //     </div>
                                  //     : null}
                                  // </RowBlock>
                              )
                            : null}
                        {/* {highlightCards && highlightCards.hightlights && highlightCards.hightlights.length > 0 ?
              highlightCards.hightlights.map((block: any, index) =>
                <RowBlock key={'row' + index}>
                  <div style={{ width: '100%' }} onClick={() => console.log(block)}>
                    <Carousel
                      dynamicHeight={false}
                      centerSlidePercentage={80}
                      showThumbs={false}
                    //   showArrows={true}
                    //   autoPlay
                    //   interval={4000}
                    //   emulateTouch={true}
                      infiniteLoop={true}
                    //   showStatus={false}
                      centerMode={true}
                    //   showIndicators={true}
                      width={'100%'}

                      >

                      {block.content.length > 0 ? block.content.map((item: any) =>

                        <ImageContainer
                          size={getSizeProps('card-default')}
                        ><img
                            // style={{width:260,height:260}}
                            src={item.img}
                            alt=""
                          /></ImageContainer>
                      ) : null}
                      <div></div>

                    </Carousel>
                  </div>

                  <AddItemToBlockButton onClick={() => addHighlightToBlock(block)} >
                    <IoMdAddCircle></IoMdAddCircle>
                    <h4>Adicionar<br></br>Destaque</h4>
                  </AddItemToBlockButton>
                </RowBlock>
              )

              : null
            } */}
                        <NewRowBlockContainer>
                            {isCreatingBlock ? (
                                <CreateBlockSettingsContainer>
                                    <h4>Configurações do bloco</h4>
                                    <TextsConfigsBlockSetup>
                                        <section>
                                            <h6>Título (opcional)</h6>
                                            <span>
                                                Defina um título a ser exibido
                                                logo acima do bloco de destaques
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: 8,
                                                    }}
                                                >
                                                    <Input
                                                        type="text"
                                                        name="description"
                                                        // description="Titulo"
                                                        placeholder=""
                                                        onChange={(e) =>
                                                            handleSetBlockConfig(
                                                                {
                                                                    descricao:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                        value={
                                                            blockConfig.descricao
                                                        }
                                                    ></Input>
                                                </div>
                                                <ColorPickerInput
                                                    onSelectPicker={() =>
                                                        handleSetVisiblePicker(
                                                            'title'
                                                        )
                                                    }
                                                    color={
                                                        blockConfig.cor_descricao
                                                    }
                                                    setColor={(color) =>
                                                        handleSetBlockConfig({
                                                            cor_descricao:
                                                                color,
                                                        })
                                                    }
                                                    visible={
                                                        visiblePicker == 'title'
                                                    }
                                                    tooltip="Define cor do título"
                                                />
                                            </div>
                                        </section>
                                        <section>
                                            <h6>Subtítulo (opcional)</h6>
                                            <span>
                                                Defina um subtítulo a ser
                                                exibido{' '}
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: 8,
                                                    }}
                                                >
                                                    <Input
                                                        type="text"
                                                        name="description"
                                                        // description="Titulo"
                                                        placeholder=""
                                                        onChange={(e) =>
                                                            handleSetBlockConfig(
                                                                {
                                                                    subtitulo:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                        value={
                                                            blockConfig.subtitulo
                                                        }
                                                    ></Input>
                                                </div>
                                                <ColorPickerInput
                                                    onSelectPicker={() =>
                                                        handleSetVisiblePicker(
                                                            'subtitle'
                                                        )
                                                    }
                                                    color={
                                                        blockConfig.cor_subtitulo
                                                    }
                                                    setColor={(color) =>
                                                        handleSetBlockConfig({
                                                            cor_subtitulo:
                                                                color,
                                                        })
                                                    }
                                                    visible={
                                                        visiblePicker ==
                                                        'subtitle'
                                                    }
                                                    tooltip="Define cor do subtítulo"
                                                />
                                            </div>
                                        </section>
                                        <AlignTitleSetupContainer>
                                            <section>
                                                <CheckBoxCustom
                                                    description="Alinhado à esquerda"
                                                    change={(e, stats) =>
                                                        handleSetBlockConfig({
                                                            text_align: e,
                                                        })
                                                    }
                                                    value={'left'}
                                                    checked={
                                                        blockConfig.text_align ==
                                                        'left'
                                                    }
                                                ></CheckBoxCustom>
                                                <CheckBoxCustom
                                                    description="Centralizado"
                                                    change={(e, stats) =>
                                                        handleSetBlockConfig({
                                                            text_align: e,
                                                        })
                                                    }
                                                    value={'center'}
                                                    checked={
                                                        blockConfig.text_align ==
                                                        'center'
                                                    }
                                                ></CheckBoxCustom>
                                                <CheckBoxCustom
                                                    description="Alinhado à direita"
                                                    change={(e) =>
                                                        handleSetBlockConfig({
                                                            text_align: e,
                                                        })
                                                    }
                                                    value={'right'}
                                                    checked={
                                                        blockConfig.text_align ==
                                                        'right'
                                                    }
                                                ></CheckBoxCustom>
                                            </section>
                                            {blockConfig.descricao ? (
                                                <AlignPreview
                                                    style={{ width: '100%' }}
                                                    align={
                                                        blockConfig.text_align
                                                    }
                                                    colorTitle={
                                                        blockConfig.cor_descricao
                                                    }
                                                    colorSubtitle={
                                                        blockConfig.cor_subtitulo
                                                    }
                                                >
                                                    <h2>
                                                        {blockConfig.descricao}
                                                    </h2>
                                                    <h4>
                                                        {blockConfig.subtitulo}
                                                    </h4>
                                                </AlignPreview>
                                            ) : null}
                                        </AlignTitleSetupContainer>
                                    </TextsConfigsBlockSetup>
                                    <ul>
                                        <ItemConfig>
                                            <h3>Tipo de bloco</h3>
                                            <section>
                                                <section>
                                                    <CheckBoxCustom
                                                        description="Carrosel Padrão"
                                                        preventUncheck
                                                        change={() =>
                                                            handleSetBlockConfig(
                                                                {
                                                                    modelo: 'carousel-large',
                                                                }
                                                            )
                                                        }
                                                        checked={
                                                            blockConfig.modelo ==
                                                            'carousel-large'
                                                        }
                                                    ></CheckBoxCustom>
                                                    <span>
                                                        *Imagens lado a lado em
                                                        um carrossel.
                                                    </span>
                                                </section>
                                                <section>
                                                    <CheckBoxCustom
                                                        description="Carrosel De Banners"
                                                        preventUncheck
                                                        change={() =>
                                                            handleSetBlockConfig(
                                                                {
                                                                    modelo: 'carousel-banner',
                                                                }
                                                            )
                                                        }
                                                        checked={
                                                            blockConfig.modelo ==
                                                            'carousel-banner'
                                                        }
                                                    ></CheckBoxCustom>
                                                    <span>
                                                        *Imagens ocuparão toda a
                                                        largura da tela.
                                                    </span>
                                                </section>
                                                <section>
                                                    <CheckBoxCustom
                                                        description="Imagens estaticas"
                                                        preventUncheck
                                                        change={() =>
                                                            handleSetBlockConfig(
                                                                {
                                                                    modelo: 'carousel-static',
                                                                }
                                                            )
                                                        }
                                                        checked={
                                                            blockConfig.modelo ==
                                                            'carousel-static'
                                                        }
                                                    ></CheckBoxCustom>
                                                    <span>
                                                        *Imagens dividirão a
                                                        largura da tela.
                                                    </span>
                                                </section>
                                            </section>
                                        </ItemConfig>
                                        {blockConfig.modelo ==
                                            'carousel-large' ||
                                        blockConfig.modelo ==
                                            'carousel-banner' ? (
                                            <ItemConfig>
                                                <h3>Funcionalidade do bloco</h3>
                                                <section>
                                                    <CheckBoxCustom
                                                        description="Alternar imagens automaticamente"
                                                        change={(e) =>
                                                            handleSetBlockConfig(
                                                                { autoplay: e }
                                                            )
                                                        }
                                                        checked={
                                                            blockConfig.autoplay
                                                        }
                                                    ></CheckBoxCustom>
                                                    <CheckBoxCustom
                                                        description="Mostrar indicadores do carrossel"
                                                        change={(e) =>
                                                            handleSetBlockConfig(
                                                                {
                                                                    indicadores:
                                                                        e,
                                                                }
                                                            )
                                                        }
                                                        checked={
                                                            blockConfig.indicadores
                                                        }
                                                    ></CheckBoxCustom>
                                                </section>
                                            </ItemConfig>
                                        ) : null}
                                    </ul>
                                </CreateBlockSettingsContainer>
                            ) : null}
                            {!isCreatingBlock ? (
                                <ButtonAdd
                                    onClick={() =>
                                        setIsCreatingBlock(!isCreatingBlock)
                                    }
                                >
                                    <section>
                                        <IoMdAddCircle></IoMdAddCircle>
                                    </section>
                                    <p>Criar novo bloco de destaques</p>
                                </ButtonAdd>
                            ) : (
                                <div>
                                    <ButtonCancelSave
                                        style={{ marginBottom: 10 }}
                                        onClick={handleCancelBlockCreation}
                                    >
                                        <section>
                                            <IoMdCloseCircleOutline color="red"></IoMdCloseCircleOutline>
                                        </section>
                                        <p>
                                            Cancelar<br></br>
                                        </p>
                                    </ButtonCancelSave>
                                    <ButtonCancelSave
                                        onClick={handleCreateBlock}
                                    >
                                        <section>
                                            <IoMdSave color="green"></IoMdSave>
                                        </section>
                                        <p>
                                            Salvar<br></br>
                                        </p>
                                    </ButtonCancelSave>
                                </div>
                            )}
                        </NewRowBlockContainer>
                    </PreviewCardsContainer>
                </HomePreviewContainer>
            )}
            <BaseModal
                data={{}}
                title={'Selecione o produto do destaque'}
                isVisible={selectProductModalVisible}
                onCloseCallback={() => setSelectProductModalVisible(false)}
                children={
                    <SelectProductModal
                        SelectedProduct={SelectProduct}
                        closeModal={() => setSelectProductModalVisible(false)}
                        handleSelectProduct={(data) => setSelectProduct(data)}
                    />
                }
            />
        </Container>
    )
}
