import { useRef } from 'react'
import './index.scss'

interface UploadButtonProps {
    icon: React.ReactNode
    title: string
    onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const UploadButton: React.FC<UploadButtonProps> = ({
    icon,
    title,
    onFileSelect,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        fileInputRef.current?.click()
    }

    return (
        <div className="upload-button">
            <button onClick={handleClick} className="button">
                {icon}
                <span>{title}</span>
            </button>
            <input
                type="file"
                accept=".xlsx, .xls"
                ref={fileInputRef}
                onChange={onFileSelect}
                className="hidden-input"
            />
        </div>
    )
}

export default UploadButton
