import React, { createContext, useContext, useEffect, useState } from 'react'

interface Theme {
    colors: Record<string, string | null>
    providers?: {
        url?: string
        text_window?: string
    }
    imgs?: {
        img_logo?: string
    }
}

interface ThemeContextProps {
    theme: Theme
    setTheme: (theme: Theme) => void
}

export const ThemeContext = createContext<ThemeContextProps | undefined>(
    undefined
)

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [theme, setTheme] = useState<Theme>(() => {
        const storedTheme = localStorage.getItem('theme')
        return storedTheme ? JSON.parse(storedTheme) : {}
    })

    useEffect(() => {
        const url = window.location.href
        const themeRegex = new RegExp('[?&]theme=([^&#]*)')
        const themeMatch = url.match(themeRegex)
        const themeEncoded = themeMatch ? themeMatch[1] : null

        if (themeEncoded) {
            try {
                const themeObject = JSON.parse(
                    decodeURIComponent(themeEncoded.replace(/\+/g, ' '))
                )

                setTheme(themeObject)
                localStorage.setItem('theme', JSON.stringify(themeObject))
            } catch (error) {
                console.error('Erro ao processar o tema:', error)
            }
        }
    }, [])

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeContext)
    if (!context) {
        throw new Error('useTheme deve ser usado dentro de um ThemeProvider')
    }
    return context
}
