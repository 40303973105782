import { RankingProductsResponse } from 'interfaces/rankingProducts'
import Constants from '../../constants'
import HttpClient from './http.client'
import { RankingSellersResponse } from 'interfaces/rankingSeller'
import { RankingClientsResponse } from 'interfaces/rankingCients'
import { QuotationsInfoResponse } from 'interfaces/quotationsInfo'

interface RankingParams {
    page: number
    page_size?: number
    providerId: string
    year: number
    month: number
    productId?: string
    sellerId?: string
    clientId?: string
    state?: string
    city?: string
}

export default class TacticalPanelService {
    static rankingProducts = async (
        params: RankingParams
    ): Promise<RankingProductsResponse> => {
        const url = TacticalPanelService.buildUrl(
            `${Constants.URL_API_PROVIDER_v3}/board/products/${params.providerId}`,
            params
        )
        return HttpClient.get(url)
    }

    static rankingSellers = async (
        params: RankingParams
    ): Promise<RankingSellersResponse> => {
        const url = TacticalPanelService.buildUrl(
            `${Constants.URL_API_PROVIDER_v3}/board/seller/${params.providerId}`,
            params
        )
        return HttpClient.get(url)
    }

    static rankingClients = async (
        params: RankingParams
    ): Promise<RankingClientsResponse> => {
        const url = TacticalPanelService.buildUrl(
            `${Constants.URL_API_PROVIDER_v3}/board/customer/${params.providerId}`,
            params
        )
        return HttpClient.get(url)
    }

    static getQuotationsInfo = async (
        params: RankingParams
    ): Promise<QuotationsInfoResponse> => {
        const url = TacticalPanelService.buildUrl(
            `${Constants.URL_API_PROVIDER_v3}/board/quotations/${params.providerId}`,
            params
        )
        return HttpClient.get(url)
    }

    // ENPOINTS PARA FILTROS
    static getListProducts = async (providerId: number): Promise<any> => {
        const url = `${Constants.URL_API_PROVIDER_v3}/board/list_products/${providerId}`

        return HttpClient.get(url)
    }
    static getListClients = async (providerId: number): Promise<any> => {
        const url = `${Constants.URL_API_PROVIDER_v3}/board/clients/${providerId}`

        return HttpClient.get(url)
    }

    static getListSellers = async (providerId: number): Promise<any> => {
        const url = `${Constants.URL_API_PROVIDER_v3}/board/sellers/${providerId}`

        return HttpClient.get(url)
    }

    static getStates = async (providerId: number): Promise<any> => {
        const url = `${Constants.URL_API_PROVIDER_v3}/board/states/${providerId}`

        return HttpClient.get(url)
    }

    static getCity = async (providerId: number, UF: string): Promise<any> => {
        const url = `${Constants.URL_API_PROVIDER_v3}/board/cities/${providerId}?state=${UF}`

        return HttpClient.get(url)
    }

    private static buildUrl(baseUrl: string, params: RankingParams): string {
        const {
            year,
            month,
            page,
            productId,
            sellerId,
            clientId,
            page_size,
            state,
            city,
        } = params
        const urlParams = new URLSearchParams({
            year: year.toString(),
            month: month.toString(),
            page: page.toString(),
        })

        if (productId) urlParams.append('product_id', productId)
        if (sellerId) urlParams.append('seller_id', sellerId)
        if (clientId) urlParams.append('client_id', clientId)
        if (page_size) urlParams.append('page_size', page_size.toString())
        if (state) urlParams.append('state', state)
        if (city) urlParams.append('city', city)

        return `${baseUrl}?${urlParams.toString()}`
    }

    static ListProductQuotes = async (
        providerId: string,
        productId: number,
        hash: string
    ): Promise<any> => {
        const url = `${Constants.URL_API_PROVIDER_v3}/board/list_quotations/${providerId}/${productId}?hash=${hash}`

        return HttpClient.get(url)
    }
}
