import './index.scss'

import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import { useEffect, useState } from 'react'
import { Loader } from 'components/loader'
import ButtonV3 from 'components/Buttonv3'
import { FeedbackError } from 'components/feedback/error'
import { Representative } from 'interfaces/representative'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { BaseModal } from 'components/BaseModal'
import { SellerParticipationDetailsModal } from 'components/seller-participationDetailModal'
import { ParticipationBadge } from 'components/participationBadge'
import Switch from 'react-switch'
import ClientService from 'services/v3/clients.service'
import { useTheme } from 'contexts/whiteLabel/context'

type ClientsRepresentativeProps = {
    isVisible: boolean
    onClose: () => void
    user?: any
    company: any
}
export const ClientsRepresentativeModal = ({
    isVisible,
    onClose,
    user,

    company,
}: ClientsRepresentativeProps) => {
    const MySwal = withReactContent(Swal)
    const [loading, setLoading] = useState<boolean>(false)
    const [representatives, setRepresentatives] = useState<Representative[]>([])
    const [
        show_seller_participation_details,
        setShowSellerParticipationDetails,
    ] = useState<boolean>(false)
    const [seller, setSeller] = useState<Representative>()
    const { theme } = useTheme()

    const modal = {
        icon: <GrGroup color="white" />,
        styles: { maxWidth: '80vw' },
        id: 'clients-representative-modal',
        title: `Vendedores associados ao cliente`,
    }
    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible, user])

    useEffect(() => {
        getAll()
    }, [company])

    const open_participation_details = (item) => {
        setSeller(item)
        setShowSellerParticipationDetails(true)
    }
    const getAll = () => {
        setLoading(true)

        ClientService.sellers(company.id)
            .then((response) => {
                setRepresentatives(response.sellers)
            })
            .catch((error) => {
                console.error('Erro ao buscar vendedores:', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateStatus = (id: any) =>
        ClientService.deactivate(company.id, id).then(getAll)

    const changeStatus = (representative: Representative) => {
        MySwal.fire({
            title: (
                <div>
                    <p>Você irá desativar este vendedor. Deseja continuar?</p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
        }).then((res) => {
            if (res.isConfirmed) {
                updateStatus(representative.id)
                return MySwal.fire(<p>Vendedor desativado com sucesso!</p>)
            }
        })
    }

    return (
        <div
            style={{
                display: 'flex',
                padding: '10px 30px 30px',
                flexDirection: 'column',
                minHeight: '25vh',
                justifyContent: 'space-between',
                margin: '0 auto',
            }}
        >
            {loading ? (
                <div className="newListRepresentativesCLientsModal">
                    <Loader />
                </div>
            ) : representatives.length > 0 ? (
                <div className="newListRepresentativesCLientsModal">
                    <table className="newTableRepresentatives">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Login</th>
                                <th>Email</th>
                                <th>Telefone</th>
                                <th className="text-center">Part. Geral</th>
                                <th className="text-center">Part. Cliente</th>
                                <th className="text-center">Desativar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {representatives.map((r: any) => (
                                <tr key={r.id}>
                                    <td data-label="Nome">{r.name}</td>
                                    <td data-label="Login">{r.login}</td>
                                    <td data-label="Email">{r.email}</td>
                                    <td
                                        data-label="Telefone"
                                        className="tdCell"
                                    >
                                        <div>
                                            <PhoneWithWhats phone={r.phone} />
                                        </div>
                                    </td>
                                    <td
                                        data-label="Participação Geral"
                                        className="tdpart"
                                    >
                                        <div className="tdContentPart">
                                            <div>
                                                <ParticipationBadge
                                                    participation={
                                                        r.participation
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        data-label="Participação do Cliente"
                                        className="tdpart"
                                    >
                                        <div
                                            className="tdContentPart"
                                            onClick={() =>
                                                r.pivot.participation > 0 &&
                                                open_participation_details(r)
                                            }
                                            style={{
                                                cursor:
                                                    r.pivot.participation === 0
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                            }}
                                        >
                                            <div
                                                title={
                                                    r.pivot.participation === 0
                                                        ? 'Não possui histórico de participação'
                                                        : 'Ver histórico de participação'
                                                }
                                            >
                                                <ParticipationBadge
                                                    participation={
                                                        r.pivot.participation
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </td>

                                    <td
                                        className="text-center"
                                        data-label="Desativar"
                                    >
                                        <Switch
                                            className="status-switch"
                                            height={23}
                                            width={50}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            onColor={
                                                theme?.colors?.primary ||
                                                '#ff7110'
                                            }
                                            onChange={() => changeStatus(r)}
                                            checked={r.status}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div style={{ marginTop: 15 }}>
                    <FeedbackError message="Nenhum vendedor associado foi encontrado no momento." />
                </div>
            )}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '98%',
                    marginTop: 10,
                }}
            >
                <ButtonV3
                    filled
                    title="Voltar"
                    onClick={() => {
                        onClose()
                    }}
                />
            </div>
            {show_seller_participation_details && (
                <BaseModal
                    modalBodyStyle={{ height: 'unset' }}
                    title={`#${seller?.name} - ${company?.name}`}
                    isVisible={show_seller_participation_details}
                    onCloseCallback={() =>
                        setShowSellerParticipationDetails(false)
                    }
                    children={
                        <SellerParticipationDetailsModal
                            sellerId={seller?.id}
                            clientName={company?.name}
                            clientId={company?.id}
                            sellerName={company?.name}
                            setShowSellerParticipationDetails={
                                setShowSellerParticipationDetails
                            }
                        />
                    }
                />
            )}
        </div>
    )
}
