import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
`

interface menuProps {
    active: boolean
}
export const ConfigsContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* flex: 1; */

    height: 100%;
`
export const PreviewContainer = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    margin-left: 1rem;
    height: 100%;
    margin-top: 1.2rem;
`
export const CloseContainer = styled.div`
    position: absolute;
    right: 5px;
    top: 0px;
    svg {
        font-size: 20px;
        cursor: pointer;
        :hover {
            color: var(--primary);
        }
    }
`

export const MenuSelectors = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    position: relative;
    flex-wrap: wrap;
    position: relative;
`
export const ButtonSelector = styled.div<menuProps>`
    padding: 15px 1.2rem;
    cursor: pointer;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${(props) => (props.active ? '#F6F6F6' : '#fff')};
    span {
        font-size: 1rem;
        font-family: 'Ubuntu-Medium';
    }
    :hover {
        span {
            color: var(--primary);
            transition: 200ms;
        }
    }

    ::after {
        right: -20px;
        -webkit-box-shadow: -10px 0 0 0
            ${(props) => (props.active ? '#F6F6F6' : '#fff')};
        box-shadow: -10px 0 0 0
            ${(props) => (props.active ? '#F6F6F6' : '#fff')};
    }

    ::before {
        left: -20px;
        -webkit-box-shadow: 10px 0 0 0
            ${(props) => (props.active ? '#F6F6F6' : '#fff')};
        box-shadow: 10px 0 0 0 ${(props) => (props.active ? '#F6F6F6' : '#fff')};
    }
`
export const Menu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    background-color: #f6f6f6;
    padding: 1rem 2rem;
    border-radius: 5px;
    flex-wrap: wrap;
`

interface colorProps {
    themeColor: string
}
export const Preview = styled.div`
    display: flex;
    flex-direction: row;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    border-radius: 8px;

    transition: margin 100ms ease-in-out;
    animation: 0.2s ease 0s 1 slideInFromRight;

    * {
        cursor: pointer;
    }
`
export const HeaderPreview = styled.div<colorProps>`
    display: flex;
    flex-direction: column;
    width: 100px;
    min-height: 300px;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.themeColor};
    padding: 1rem 2rem;
    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
`
export const IconContainer = styled.div<colorProps>`
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 59px;
        height: 59px;
    }
`
export const Icon = styled.p<colorProps>`
    svg {
        font-size: 2rem;
        color: ${(props) => props.themeColor};
    }
`
export const TextHeader = styled.p<colorProps>`
    font-family: 'Ubuntu-Medium';
    font-size: 1rem;
    color: ${(props) => props.themeColor}; ;
`
interface bodyProps {
    theme: {}
}
export const TextsContainer = styled.div<bodyProps>`
    background-color: ${(props) => props.theme.colors.backgroundWhite};
    padding: 1rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    border: 1px solid #ccc;
    border-radius: 8px;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    h1,
    h5,
    h4,
    h6 {
    }
    h1,
    h5,
    h4 {
    }
    h1 {
        color: ${(props) => props.theme.colors.primary};
        font-size: 1.5rem;
    }
    h4 {
        color: ${(props) => props.theme.colors.textPrimary};
        font-size: 1rem;
    }
    h5 {
        color: ${(props) => props.theme.colors.textSecondary};
        font-size: 1rem;
    }
    h6 {
        color: ${(props) => props.theme.colors.textTertiary};
        font-size: 1rem;
    }
`
export const BodyPreview = styled.div<bodyProps>`
    background-color: ${(props) => props.theme.colors.background};
    width: 100%;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
`
export const SecondRowPreview = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1rem;
    width: 100%;
    flex-wrap: wrap;
`
export const Collumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const CartIconComponent = styled.div<bodyProps>`
    /* isShowPaymentMethodVisible */
    position: relative;
    padding: 0.8rem;
    border-radius: 12px;

    padding: 11px 12px 7px 12px;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    svg {
        font-size: 2rem;
        color: ${(props) => props.theme.colors.secondary};
    }
`

export const CartItemEmoji = styled.div`
    position: absolute;
    /* display: none; */
    opacity: 0;
    height: 60px;
    top: -23px;
    left: -15px;
`
export const CartCounter = styled.div<bodyProps>`
    color: ${({ theme }) => theme.textPrimary};
    display: flex;
    background-color: ${({ theme }) => theme.colors.secondary};
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: -10px;
    right: -10px;

    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);

    border-radius: 50%;
`

export const LabelText = styled.label<bodyProps>`
    font-family: 'Ubuntu-Medium', sans-serif;

    background: ${(props) => props.theme.colors.primary};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.3rem;
`

export const Input = styled.input<bodyProps>`
    background-color: ${({ theme }) => theme.colors.backgroundWhite};
    height: 2.8rem;
    width: 100%;
    border-radius: 12px;
    border: 2px solid
        ${(props) => (props.error ? props.theme.colors.danger : 'transparent')};
    padding: 0.1rem 0.8rem;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    color: #29292e;
    font-weight: 400;
    -webkit-appearance: none;

    &:disabled {
        background-color: ${({ theme }) => theme.colors.background};
        cursor: not-allowed;
    }
    &:focus {
        border: 2px solid ${(props) => props.theme.colors.primary};
    }

    &::placeholder {
        color: #c4c4cc;
        font-weight: 300;
    }
`

export const UploadImageContainer = styled.div`
    gap: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: margin 100ms ease-in-out;
    animation: 0.5s ease 0s 1 slideInFromRight;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.4rem 0.5rem;
    align-items: center;
    p {
        font-family: 'Ubuntu-Medium', sans-serif;
        font-size: 1rem;
    }
`
export const InputImage = styled.input``

const StyledButton = styled.button<bodyProps>`
    border: 0;
    padding: 0.8rem 1.6rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    gap: 0.5rem;
    /* background-color: ${(props) =>
        props.variant
            ? ({ theme }) => theme.colors.backgroundWhite
            : 'none'}; */
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    &:disabled {
        opacity: 0.6;

        cursor: not-allowed;
    }
    &:not(:disabled):hover {
        opacity: 0.9;
    }
    &:not(:disabled):active {
        opacity: 0.7;
    }
`

export const PrimaryButton = styled(StyledButton)<bodyProps>`
    width: 100%;
    margin-top: 1rem;
    background-color: ${(props) => props.theme.colors.primary};

    color: ${(props) => props.theme.colors.white};
`
export const SecondaryButton = styled(StyledButton)<bodyProps>`
    color: #323238;
`
export const ButtonSaveChanges = styled(StyledButton)<bodyProps>`
    background-color: var(--primary);
    color: #fff;
    max-width: 320px;
`

export const ButtonSaveChangesDefault = styled(StyledButton)<bodyProps>`
    background-color: #fff;
    color: var(--primary);
    max-width: 320px;
    border-color: var(--primary);
    border: 1px solid;
`
