import React, { useState, useRef, useEffect } from 'react'
import styles from './index.module.scss'
import { MdToday } from 'react-icons/md'

type DatePickerMonthYearProps = {
    label: string
    value: { year: number; month: number }
    onChange: (date: { year: number; month: number }) => void
}

export const DatePickerMonthYear: React.FC<DatePickerMonthYearProps> = ({
    label,
    value,
    onChange,
}) => {
    const currentYear = new Date().getFullYear()
    const [selectedDate, setSelectedDate] = useState<{
        year: number
        month: number
    }>(value || { year: currentYear, month: 1 })
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (value) {
            setSelectedDate(value)
        } else {
            setSelectedDate({
                year: currentYear,
                month: new Date().getMonth() + 1,
            })
        }
    }, [value])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleYearChange = (year: number) => {
        const newDate = { year, month: selectedDate.month }
        setSelectedDate(newDate)
        onChange?.(newDate)
    }

    const handleMonthChange = (month: number) => {
        const newDate = { year: selectedDate.year, month }
        setSelectedDate(newDate)
        setIsOpen(false)
        onChange?.(newDate)
    }

    return (
        <div className={styles.datePickerContainer}>
            <label className={styles.label}>{label}</label>
            <div
                className={styles.inputContainer}
                onClick={() => setIsOpen((prev) => !prev)}
                ref={inputRef}
            >
                <span className={styles.dateText}>
                    {new Date(0, selectedDate.month - 1)
                        .toLocaleString('default', { month: 'long' })
                        .charAt(0)
                        .toUpperCase() +
                        new Date(0, selectedDate.month - 1)
                            .toLocaleString('default', { month: 'long' })
                            .slice(1)}
                    {' de '}
                    {selectedDate.year}
                </span>

                <MdToday className={styles.icon} />
            </div>
            <div
                className={
                    isOpen
                        ? `${styles.dropdown} ${styles.dropdownOpen}`
                        : styles.dropdown
                }
                ref={dropdownRef}
            >
                <div className={styles.dropdownItem}>
                    <p>Ano:</p>
                    <select
                        className={styles.selectDatePicker}
                        value={selectedDate.year}
                        onChange={(e) =>
                            handleYearChange(Number(e.target.value))
                        }
                    >
                        {Array.from(
                            { length: 30 },
                            (_, i) => currentYear - 20 + i
                        ).map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.dropdownItem}>
                    <p>Mês:</p>
                    <select
                        className={styles.selectDatePicker}
                        value={selectedDate.month}
                        onChange={(e) =>
                            handleMonthChange(Number(e.target.value))
                        }
                    >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                            (month) => (
                                <option key={month} value={month}>
                                    {new Date(0, month - 1)
                                        .toLocaleString('default', {
                                            month: 'long',
                                        })
                                        .charAt(0)
                                        .toUpperCase() +
                                        new Date(0, month - 1)
                                            .toLocaleString('default', {
                                                month: 'long',
                                            })
                                            .slice(1)}
                                </option>
                            )
                        )}
                    </select>
                </div>
            </div>
        </div>
    )
}
