import React from 'react'

interface DynamicSVGProps {
    color?: string
    className: string
}

export const DynamicIconWhatsSVG: React.FC<DynamicSVGProps> = ({
    color = '#FE6700',
    className,
}) => {
    return (
        <div className={className}>
            <svg
                width="25"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15ZM24.4138 14.4259C24.4119 19.3427 20.4122 23.3433 15.4944 23.3453H15.4907C13.9979 23.3448 12.5311 22.9703 11.2283 22.2597L6.5 23.5L7.76538 18.878C6.98483 17.5253 6.57411 15.9909 6.57478 14.4189C6.57674 9.50106 10.578 5.5 15.4943 5.5C17.8804 5.50103 20.1199 6.42992 21.8039 8.11588C23.4879 9.80176 24.4148 12.0427 24.4138 14.4259Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.64155 21.381L11.4474 20.6449L11.7181 20.8056C12.8564 21.4811 14.1611 21.8385 15.4914 21.839H15.4944C19.5805 21.839 22.9062 18.5132 22.9078 14.4254C22.9086 12.4445 22.1382 10.5819 20.7385 9.18063C19.3389 7.77932 17.4775 7.00722 15.4973 7.00653C11.4081 7.00653 8.08236 10.3319 8.08073 14.4194C8.08016 15.8202 8.47211 17.1845 9.21421 18.3648L9.39048 18.6453L8.64155 21.381ZM19.6828 16.3463C19.8382 16.4214 19.9432 16.4721 19.988 16.547C20.0437 16.6399 20.0437 17.0861 19.8581 17.6068C19.6723 18.1274 18.7819 18.6025 18.3537 18.6665C17.9697 18.7239 17.4838 18.7478 16.95 18.5781C16.6263 18.4754 16.2112 18.3383 15.6794 18.1087C13.5901 17.2065 12.1781 15.1815 11.9113 14.7988C11.8926 14.772 11.8795 14.7532 11.8722 14.7435L11.8704 14.7411C11.7525 14.5837 10.9622 13.5293 10.9622 12.438C10.9622 11.4114 11.4665 10.8733 11.6986 10.6256C11.7145 10.6087 11.7291 10.5931 11.7422 10.5788C11.9465 10.3556 12.188 10.2999 12.3365 10.2999C12.4851 10.2999 12.6338 10.3012 12.7637 10.3078C12.7797 10.3086 12.7964 10.3085 12.8136 10.3084C12.9435 10.3076 13.1054 10.3066 13.2651 10.6903C13.3266 10.838 13.4165 11.0569 13.5113 11.2877C13.7031 11.7546 13.915 12.2705 13.9522 12.3451C14.008 12.4567 14.0451 12.5868 13.9708 12.7356C13.9597 12.7579 13.9494 12.779 13.9395 12.7991C13.8837 12.913 13.8427 12.9968 13.748 13.1074C13.7107 13.1508 13.6722 13.1977 13.6338 13.2446C13.5571 13.338 13.4804 13.4313 13.4137 13.4978C13.3021 13.609 13.1859 13.7295 13.3159 13.9527C13.446 14.1758 13.8932 14.9056 14.5558 15.4966C15.268 16.1318 15.887 16.4003 16.2007 16.5364C16.262 16.563 16.3116 16.5845 16.348 16.6027C16.5708 16.7143 16.7008 16.6957 16.8309 16.547C16.9609 16.3982 17.388 15.8962 17.5365 15.6731C17.6851 15.45 17.8337 15.4872 18.038 15.5615C18.2423 15.636 19.338 16.1751 19.5609 16.2866C19.6044 16.3084 19.645 16.3281 19.6828 16.3463Z"
                    fill={color}
                />
            </svg>
        </div>
    )
}
