import React, { FC, useEffect, useState } from 'react'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import { FaStoreAlt } from 'react-icons/fa'
import DateService from 'services/DateService'
import RequestService from 'services/v3/request.service'
import { Status } from 'components/request-status'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import SellerParticipationHighchartModel from 'models/seller-participation-highchart.model'
import InfoParticipationCardProps from 'components/info-participationCardProps'
import { Loader } from 'components/loader'
import { BaseModal } from 'components/BaseModal'
import { QuotationsProductsPreviewModal } from 'components/quotations-producs-preview-modal'

interface SellerParticipationDetailsModalProps {
    sellerId: number
    clientId: number
    clientName: string
    sellerName: string
    setShowSellerParticipationDetails?: any
}
export const SellerParticipationDetailsModal: FC<any> = ({
    sellerId,
    clientId,
    clientName,
    sellerName,
    setShowSellerParticipationDetails,
}: any) => {
    const [modal, setModal] = useState<any>({})
    const [requests, setRequests] = useState<any>([])
    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [showQuotationsProductsPreview, setShowQuotationsProductsPreview] =
        useState<boolean>(false)
    const [request, setRequest] = useState<any>()

    useEffect(() => {
        setLoading(true)
        setModal({
            title: (
                <span style={{ textTransform: 'uppercase' }}>
                    <b>
                        #{sellerName} - {clientName}
                    </b>
                </span>
            ),
            icon: <FaStoreAlt />,
        })
        RequestService.load_seller_participations(sellerId, clientId).then(
            (data) => {
                setRequests(data)
                setLoading(false)
            }
        )
    }, [sellerId, clientId])

    useEffect(() => {
        setData(new SellerParticipationHighchartModel(requests))
    }, [requests])

    const close = () => {
        setShowSellerParticipationDetails(false)
    }

    function countStatuses(requests: any[]) {
        const statusCounts: Record<string, number> = {
            'Não Vencedor': 0,
            Vencedor: 0,
            'Pedido Gerado': 0,
            Digitado: 0,
            'Sem Pedido': 0,
            'Não Digitado': 0,
            'Sem oferta': 0,
            Congelado: 0,
            Cancelado: 0,
        }

        requests.forEach((request) => {
            const translatedStatus = translateStatus(request.status)
            statusCounts[translatedStatus] =
                (statusCounts[translatedStatus] || 0) + 1
        })

        return statusCounts
    }

    function translateStatus(status: string) {
        switch (status) {
            case 'LOOSER':
                return 'Não Vencedor'
            case 'WINNER':
                return 'Vencedor'
            case 'ORDERED':
                return 'Pedido Gerado'
            case 'TYPED':
                return 'Digitado'
            case 'NOT_ORDERED':
                return 'Sem Pedido'
            case 'NOT_TYPED':
                return 'Não Digitado'
            case 'NOT_OFFERED':
                return 'Sem oferta'
            case 'FROZEN':
                return 'Congelado'
            default:
                return 'Cancelado'
        }
    }

    const statusCounts = countStatuses(requests)

    const cards = [
        {
            title: 'Não digitado',
            quantity: statusCounts['Não Digitado'],
            variant: 'purple',
        },
        {
            title: 'Pedido gerado',
            quantity: statusCounts['Pedido Gerado'],
            variant: 'blue',
        },
        {
            title: 'Sem pedido',
            quantity: statusCounts['Sem Pedido'],
            variant: 'black',
        },
        {
            title: 'Congelado',
            quantity: statusCounts['Congelado'],
            variant: 'gray',
        },
        {
            title: 'Digitado',
            quantity: statusCounts['Digitado'],
            variant: 'green',
        },
        {
            title: 'Sem oferta',
            quantity: statusCounts['Sem oferta'],
            variant: 'black',
        },
        { title: 'Valor total', quantity: requests.length, variant: 'orange' },
    ]

    const handleOpenModal = (request) => {
        setShowQuotationsProductsPreview(!showQuotationsProductsPreview)
        setRequest(request)
    }

    return (
        <>
            {loading ? (
                <div
                    style={{
                        height: '85vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Loader />
                </div>
            ) : (
                <div style={{ height: '85vh' }} className="container-fluid">
                    <div style={{ overflowY: 'auto' }}>
                        <div className="col col-12">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={data}
                            />
                        </div>
                        <div className={styles.cardParticipation}>
                            {cards
                                .filter((card) => card.quantity > 0)
                                .map((card, index) => (
                                    <InfoParticipationCardProps
                                        key={index}
                                        title={card.title}
                                        quantity={card.quantity}
                                        variant={card.variant}
                                    />
                                ))}
                        </div>

                        <div className={styles.listSellerParticipation}>
                            <table
                                className={`table ${styles.newTableSellerParticipation}`}
                            >
                                <thead>
                                    <tr>
                                        <th className="pl-4"></th>
                                        <th>Cotação</th>
                                        <th>Grupo</th>
                                        <th>Status</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests &&
                                        requests.map((r, k) => (
                                            <>
                                                <tr
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    key={r.request_id}
                                                >
                                                    <td>
                                                        <b>#{k + 1}</b>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className={
                                                                styles.numCotacaoCard
                                                            }
                                                            onClick={() =>
                                                                handleOpenModal(
                                                                    r
                                                                )
                                                            }
                                                        >
                                                            <p>
                                                                {r.request_id}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>{r.client_name}</td>
                                                    <td>
                                                        <Status
                                                            status={r.status}
                                                        />
                                                    </td>
                                                    <td>
                                                        {DateService.formatToDisplayWithTime(
                                                            new Date(
                                                                r.created_at
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={{}}
                                                    className={styles.trMobile}
                                                    key={r.request_id}
                                                >
                                                    <td style={{}}>
                                                        <section
                                                            className={
                                                                styles.storeNameMobile
                                                            }
                                                        >
                                                            <p>#{k + 1}</p>
                                                            <p>
                                                                {r.client_name}
                                                            </p>
                                                        </section>
                                                    </td>
                                                    <td>
                                                        <section
                                                            className={
                                                                styles.rowIdStatus
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.numCotacaoCard
                                                                }
                                                                onClick={() =>
                                                                    handleOpenModal(
                                                                        r
                                                                    )
                                                                }
                                                            >
                                                                <p>
                                                                    #
                                                                    {
                                                                        r.request_id
                                                                    }
                                                                </p>
                                                            </span>
                                                            <span>
                                                                {DateService.formatToDisplayWithTime(
                                                                    new Date(
                                                                        r.created_at
                                                                    )
                                                                )}
                                                            </span>
                                                            <span>
                                                                <Status
                                                                    status={
                                                                        r.status
                                                                    }
                                                                />
                                                            </span>
                                                        </section>
                                                    </td>
                                                    {/* <td><b>#{k + 1}</b></td> */}
                                                    {/* <td>{r.request_id}</td>
                                        <td>{r.client_name}</td>
                                        <td><Status status={r.status} /></td>
                                        <td>{DateService.formatToDisplayWithTime(new Date(r.created_at))}</td> */}
                                                </tr>
                                            </>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                //   return <ModalComponent modal={ modal } show={ true } close={ close } className={ styles['seller-participation-modal'] }>
                //       <div className="container-fluid">
                //           <div className="row header">
                //               <div className="col col-12">
                //                   <HighchartsReact
                //                       highcharts={ Highcharts }
                //                       options={ data }
                //                   />
                //               </div>
                //               <div className="col m-4 p-3 shadow">
                //                   <table className={ `table ${styles.table}` }>
                //                       <thead>
                //                           <tr>
                //                               <th className="pl-4"></th>
                //                               <th>Cotação</th>
                //                               <th>Cliente</th>
                //                               <th>Status</th>
                //                               <th>Data</th>
                //                           </tr>
                //                       </thead>
                //                       <tbody>
                //                           { requests && requests.map((r, k) => <tr key={ r.request_id }>
                //                               <td className="pl-4"><b>#{ k+1 }</b></td>
                //                               <td>{ r.request_id }</td>
                //                               <td>{ r.client_name }</td>
                //                               <td><Status status={ r.status } /></td>
                //                               <td>{ DateService.formatToDisplayWithTime(new Date(r.created_at)) }</td>
                //                           </tr>) }
                //
                //                       </tbody>
                //                   </table>
                //               </div>
                //           </div>
                //       </div>
                //   </ModalComponent>
            )}
            {requests && (
                <BaseModal
                    modalBodyStyle={{ maxHeight: '90vh' }}
                    title={`Produtos da cotação #${request?.request_id}`}
                    isVisible={showQuotationsProductsPreview}
                    onCloseCallback={() =>
                        setShowQuotationsProductsPreview(false)
                    }
                    children={
                        <QuotationsProductsPreviewModal
                            request={request}
                            setQuotationsProductsPreview={
                                showQuotationsProductsPreview
                            }
                            sellerId={sellerId}
                        />
                    }
                />
            )}
        </>
    )
}
