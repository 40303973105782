import React, {
    useEffect,
    useContext,
    useCallback,
    useLayoutEffect,
} from 'react'
import { useState } from 'react'
import Constants from '../../constants'
import { connect } from 'react-redux'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { load_others_requests, load_request } from '../../store/requests'
import { load_types } from '../../store/payments'
import { Loader } from '../../components/loader'
import './index.scss'
import { ProjectContainer } from '../../components/project-container/index'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RequestClientComercialInfo } from './components/client-comercial-info'
import { RequestedProductsComponent } from './components/requested-products'
import { RequestCompaniesComponent } from './components/companies-in'
import { RequestSellerComercialInfoComponent } from './components/seller-comercial-info'
import { Breadcrumb } from '../../components/breadcrumb'
import { RequestContext } from 'contexts/request'
import ProductModel from 'models/product.model'
import { Delivery } from 'interfaces/delivery'
import DateService from 'services/DateService'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import ButtonV3 from 'components/Buttonv3'
import IconReact from 'components/icons'
import { PaginationCustom } from 'components/pagination-custom'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { RoadMap } from 'components/roadmap'
import { ToastContainer } from 'react-toastify'
import { useTheme } from 'contexts/whiteLabel/context'

const RequestDetailPage = ({ load_types, payment_types }: any) => {
    const { id, seller_id } = useParams<{ id: any; seller_id: any }>()
    const [successMessage] = useState<string>('')
    const [error] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [contentLoading, setContentLoading] = useState<boolean>(true)
    const [VIEW, setView] = useState<string>('RESUME')
    const [products, setProducts] = useState<any>()
    const { theme } = useTheme()
    const useQuery = () => new URLSearchParams(useLocation().search)
    const others = useQuery().get('o')

    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [isMobileView, setIsMobileView] = useState(false)
    const [safariComponent, setSafariComponent] = useState(false)

    const toogleFilters = () => setShowFilters(!showFilters)

    const {
        load_request_products,
        request_products,
        request_products_pagination,
        load_last_request_seller_delivery,
        load_request,
        load_others_requests,
        request,
        load_request_seller_delivery,
        delivery,
        set_delivery,
    } = useContext(RequestContext)

    useEffect(() => {
        setLoading(true)
        setContentLoading(true)

        load_types()
        load_request_seller_delivery(id, seller_id).then(
            (delivery: Delivery) => {
                !delivery?.id &&
                    load_last_request_seller_delivery(id, seller_id).then(
                        (old: Delivery) => {
                            old.old = true
                            set_delivery(
                                !old?.id
                                    ? {
                                          date: DateService.formatToRequestWithTimezone(
                                              new Date()
                                          ),
                                      }
                                    : old
                            )
                        }
                    )
            }
        )
        if (others) {
            load_request_products(id, seller_id).then(
                load_others_requests(id).then(() => setLoading(false))
            )
        } else {
            load_request_products(id, seller_id).then(
                load_request(id).then(() => setLoading(false))
            )
        }
    }, [id, seller_id, load_types, others])

    useEffect(() => {
        setProducts(
            request_products?.map((p: ProductModel) => {
                return { ...p, visible: true }
            })
        )
        setTimeout(() => setContentLoading(false), 4000)
    }, [request_products])

    const is_editable = useCallback(() => {
        if (request.id == 636563) {
            console.log(
                '--------',
                request &&
                    ![
                        'CONGELADA',
                        'PEDIDO REALIZADO',
                        'PEDIDO GERADO',
                    ].includes(request.status) &&
                    new Date(delivery.date) >= new Date()
            )
        }
        return (
            request &&
            !['CONGELADA', 'PEDIDO REALIZADO', 'PEDIDO GERADO'].includes(
                request.status
            ) &&
            new Date(delivery.date) >= new Date()
        )
        // new Date(request.date) >= new Date()
    }, [request])

    const is_accesable = useCallback(() => {
        let minBillingHigherthanzero = delivery && delivery.minimumBilling > 0

        let acessible
        // return acessible || is_editable

        if (
            ['CONGELADA', 'PEDIDO REALIZADO', 'PEDIDO GERADO'].includes(
                request.status
            ) &&
            minBillingHigherthanzero
        ) {
            acessible = true
        } else {
            acessible = is_editable()
        }
        return acessible

        // let minBillingHigherthanzero = delivery && delivery.minimumBilling > 0

        // if (delivery.old) {
        //     if (request.id ==758766){
        //         console.log('delivery old')
        //     }
        //     return !((delivery && !delivery.old) || !is_editable())
        // } else {
        //     return is_editable() && minBillingHigherthanzero

        // }

        // return (delivery && !delivery.old) || !is_editable()
    }, [delivery, is_editable])

    // useEffect(() => {
    //     if (request && !is_editable()) {
    //         setView('PRODUCTS')
    //
    //     }
    // }, [request, is_editable])

    function handleBack() {
        navigate(Constants.ROUTE_REQUESTS)
    }

    useEffect(() => {
        function resize() {
            if (window.innerWidth < 901) {
                setIsMobileView(true)
                setShowFilters(false)
            } else {
                setIsMobileView(false)
                setShowFilters(true)
            }
        }
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    useEffect(() => {
        const userAgent = navigator.userAgent

        const isiPhone = /iPhone/i.test(userAgent)

        // Verifica se o usuário está usando o navegador Safari
        const isSafari = /^((?!Chrome|Android).)*Safari/i.test(userAgent)

        if (isiPhone || isSafari) {
            setSafariComponent(true)
        } else {
            setSafariComponent(false)
        }
    }, [])
    const navigate = useNavigate()
    return (
        <ProjectContainer>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <RoadMap
                pageLinks={[
                    {
                        title: 'Minhas Cotações',
                        onclick: () => {
                            navigate('/cotacoes')
                        },
                    },
                    {
                        title:
                            VIEW == 'RESUME'
                                ? 'Condições Comerciais'
                                : 'Digitação de Preço',
                        onclick: () => {},
                    },
                ]}
            ></RoadMap>

            {(loading || !products) && <Loader />}

            {!loading && request && products && (
                <div className="request-detail">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <PageHeaderV2
                            title={
                                VIEW == 'RESUME'
                                    ? 'Condições Comerciais'
                                    : 'Digitação de Preço'
                            }
                        />
                        {/* <ButtonV3
              style={{ 'height': 45, }}
              onClick={handleBack}
              title='Voltar'
              Icon={<IconReact className={'iconButtonV3'} type='arrowLeft' />}
            ></ButtonV3> */}
                    </div>

                    <div>
                        <div>
                            <section
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    gap: 10,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div className="firstRowButtons">
                                    <ButtonV3
                                        onClick={() => setView('RESUME')}
                                        filled={
                                            VIEW === 'RESUME' ? true : false
                                        }
                                        Icon={
                                            <IconReact
                                                className={'iconButtonV3'}
                                                type="store"
                                            />
                                        }
                                        title="Dados Comerciais"
                                        style={
                                            VIEW === 'RESUME'
                                                ? {
                                                      backgroundColor:
                                                          'var(--primary-opacity)',
                                                      border: ' 0.5px solid #E5E5E5',
                                                      height: 49,
                                                  }
                                                : { height: 49 }
                                        }
                                        textStyle={
                                            VIEW === 'RESUME'
                                                ? {
                                                      color: `${
                                                          theme?.colors
                                                              ?.primary ||
                                                          '#FF7110'
                                                      }`,
                                                      fontWeight: '600',
                                                      fontSize: 14,
                                                      letterSpacing: 1.1,
                                                  }
                                                : {
                                                      fontWeight: '600',
                                                      fontSize: 14,
                                                      letterSpacing: 1.1,
                                                  }
                                        }
                                    />

                                    <ButtonV3
                                        id="products-nav-tab"
                                        onClick={() =>
                                            !is_accesable()
                                                ? {}
                                                : setView('PRODUCTS')
                                        }
                                        Icon={
                                            <IconReact
                                                className={'iconButtonV3'}
                                                style={
                                                    !is_accesable()
                                                        ? {
                                                              opacity: 0.3,
                                                          }
                                                        : {}
                                                }
                                                type="inventory"
                                            />
                                        }
                                        // title={contentLoading ? 'Ver Produtos' : `dados comerciais`}
                                        title={
                                            contentLoading
                                                ? 'Ver Produtos'
                                                : `Ver Produtos ${
                                                      request_products_pagination
                                                          ? `(${request_products_pagination.total})`
                                                          : '...'
                                                  }`
                                        }
                                        filled={
                                            VIEW === 'PRODUCTS' ? true : false
                                        }
                                        style={
                                            VIEW === 'PRODUCTS'
                                                ? {
                                                      backgroundColor:
                                                          'var(--primary-opacity)',
                                                      border: ' 0.5px solid #E5E5E5',
                                                      height: 49,
                                                  }
                                                : { height: 49 }
                                        }
                                        textStyle={
                                            VIEW === 'PRODUCTS'
                                                ? {
                                                      color: `${
                                                          theme?.colors
                                                              ?.primary ||
                                                          '#FF7110'
                                                      }`,
                                                      fontWeight: '600',
                                                      fontSize: 14,
                                                      letterSpacing: 1.1,
                                                  }
                                                : !is_accesable()
                                                ? {
                                                      opacity: 0.3,
                                                  }
                                                : {
                                                      fontWeight: '600',
                                                      fontSize: 14,
                                                      letterSpacing: 1.1,
                                                  }
                                        }
                                    />
                                </div>
                                <div className="voltarFirstRow">
                                    <ButtonV3
                                        style={{ height: 49 }}
                                        onClick={handleBack}
                                        title="Voltar"
                                        Icon={
                                            <IconReact
                                                className={'iconButtonV3'}
                                                type="arrowLeft"
                                            />
                                        }
                                    ></ButtonV3>
                                </div>
                            </section>
                            <section
                                className="sectionToggleVoltar"
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    gap: 10,
                                }}
                            >
                                {isMobileView && VIEW === 'PRODUCTS' ? (
                                    <div className="togleFiltersContainerRequest">
                                        <ButtonV3
                                            style={{
                                                justifyContent: 'space-between',
                                                height: 49,
                                            }}
                                            title={
                                                showFilters
                                                    ? 'Ocultar Informações'
                                                    : 'Exibir Informações'
                                            }
                                            textStyle={{ fontSize: 15 }}
                                            onClick={toogleFilters}
                                            Icon={
                                                showFilters ? (
                                                    <FaChevronUp
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                        color={`${
                                                            theme?.colors
                                                                ?.primary ||
                                                            '#FF7110'
                                                        }`}
                                                    />
                                                ) : (
                                                    <FaChevronDown
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                        color={`${
                                                            theme?.colors
                                                                ?.primary ||
                                                            '#FF7110'
                                                        }`}
                                                    />
                                                )
                                            }
                                        ></ButtonV3>
                                    </div>
                                ) : null}
                                <div className="voltarSecondRow">
                                    <ButtonV3
                                        style={{ height: 49 }}
                                        onClick={handleBack}
                                        title="Voltar"
                                        Icon={
                                            <IconReact
                                                className={'iconButtonV3'}
                                                type="arrowLeft"
                                            />
                                        }
                                    ></ButtonV3>
                                </div>
                            </section>
                        </div>
                    </div>

                    {error && <FeedbackError message={error} />}

                    {successMessage && (
                        <FeedbackSuccess message={successMessage} />
                    )}

                    {!loading && request ? (
                        <div className="tab-content">
                            <div
                                className={`tab-pane fade ${
                                    VIEW === 'RESUME' ? 'active show' : ''
                                }`}
                                id="comercial-info"
                            >
                                <RequestSellerComercialInfoComponent
                                    handleGotoProduts={() =>
                                        setView('PRODUCTS')
                                    }
                                    payment_types={payment_types}
                                    delivery={delivery}
                                    request={request}
                                    setLoading={setLoading}
                                    setDelivery={set_delivery}
                                    editableRequest={is_editable}
                                    isSafari={safariComponent}
                                />

                                <RequestClientComercialInfo request={request} />

                                <RequestCompaniesComponent
                                    request={request}
                                    stores={request.stores}
                                    is_editable={is_editable}
                                />
                            </div>
                            <div
                                className={`tab-pane fade ${
                                    VIEW === 'PRODUCTS' ? 'active show' : ''
                                }`}
                                id="products-info"
                            >
                                {showFilters || !isMobileView ? (
                                    <RequestClientComercialInfo
                                        request={request}
                                        className="mt-1"
                                    />
                                ) : null}

                                {/* {products && products.length > 0 && !contentLoading ? <RequestedProductsComponent */}
                                {products && !contentLoading ? (
                                    <RequestedProductsComponent
                                        visible={VIEW === 'PRODUCTS'}
                                        showFilters={
                                            showFilters || !isMobileView
                                        }
                                        others={others}
                                        reloadProducts={load_request_products}
                                        onChange={(products: any) =>
                                            setProducts(products)
                                        }
                                        data={products}
                                        paginationData={
                                            request_products_pagination
                                        }
                                        seller_id={seller_id}
                                        request={request}
                                        isSafari={safariComponent}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </div>
                        </div>
                    ) : (
                        <FeedbackError message={Constants.MSG_DATA_NOT_FOUND} />
                    )}
                </div>
            )}
        </ProjectContainer>
    )
}

const mapStateToProps = (state: any) => ({
    request: state.requests.request,
    deliveryObj: state.requests.buyer?.delivery || {},
    payment_types: state.payments.types,
    subrequest_stores: state.requests.subrequest_stores,
})

const mapDispatchToProps = (dispatch: any) => ({
    load_request: (id: number) => dispatch(load_request(id)),
    load_types: () => dispatch(load_types()),
    load_others_requests: (id: number) => dispatch(load_others_requests(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetailPage)
