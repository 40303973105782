import ButtonV3 from 'components/Buttonv3'
import './index.scss'
import ButtonIsActive from 'components/ButtonIsActive'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { ProjectContainer } from 'components/project-container'
import { RoadMap } from 'components/roadmap'
import { MdOutlineEdit, MdOutlineFilterAlt } from 'react-icons/md'
import { TacticalPanelFilterComponent } from './components/filters'
import { useContext, useEffect, useState } from 'react'
import { CardInfos } from './components/card-infos'
import { BsBoxSeam } from 'react-icons/bs'
import { FaFileDownload, FaRegClipboard, FaUsers } from 'react-icons/fa'
import { FaShop } from 'react-icons/fa6'
import { ParticipationBadge } from 'components/participationBadge'
import { RankingPosition } from './components/ranking-position'
import TacticalPanelService from 'services/v3/tacticalPanel.service'
import { UserContext } from 'contexts/user'
import {
    RankingProduct,
    RankingProductsResponse,
} from 'interfaces/rankingProducts'
import { RankingSellersResponse } from 'interfaces/rankingSeller'
import { RankingClientsResponse } from 'interfaces/rankingCients'
import { QuotationsInfoResponse } from 'interfaces/quotationsInfo'
import { FeedbackError } from 'components/feedback/error'
import { PaginationCustomV3 } from 'components/pagination-customerV3'
import { Loader } from 'components/loader'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify'
import { BaseModal } from 'components/BaseModal'
import { QuotationModal } from './components/quotationModal'
import { IoMdInformationCircle } from 'react-icons/io'

const TacticalPanel = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingExport, setLoadingExport] = useState<boolean>(false)
    const [viewFilters, setViewFilters] = useState<boolean>(true)
    const [activeTable, setActiveTable] = useState<string>('RANKING_PRODUCTS')
    const { user } = useContext(UserContext)
    const [rankingProducts, setRankingProducts] =
        useState<RankingProductsResponse | null>()
    const [rankingSellers, setRankingSellers] =
        useState<RankingSellersResponse | null>()
    const [rankingClients, setRankingClients] =
        useState<RankingClientsResponse | null>()
    const [quotationsInfo, setQuotationsInfo] =
        useState<QuotationsInfoResponse | null>()

    const initialParams = {
        providerId: user?.providerId || '',
        page: 1,
        page_size: 15,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        productId: '',
        sellerId: '',
        clientId: '',
        state: '',
        city: '',
    }
    const [productParams, setProductParams] = useState(initialParams)
    const [sellerParams, setSellerParams] = useState(initialParams)
    const [clientParams, setClientParams] = useState(initialParams)
    const [openProductQuotationModal, setOpenProductQuotationModal] =
        useState<boolean>(false)
    const [product, setProduct] = useState<RankingProduct>()

    useEffect(() => {
        if (user?.providerId && productParams.providerId === '') {
            setParamsForAll({ providerId: user.providerId })
        }

        if (user?.providerId && productParams.providerId) {
            loadRankingProducts()
        }
    }, [user?.providerId, productParams])

    useEffect(() => {
        if (user?.providerId && productParams.providerId) {
            setLoading(true)
            loadQuotationsInfo().finally(() => setLoading(false))
        }
    }, [
        productParams.clientId,
        productParams.providerId,
        productParams.month,
        productParams.year,
        productParams.sellerId,
        productParams.productId,
        productParams.state,
        productParams.city,
    ])

    useEffect(() => {
        if (sellerParams.providerId) loadRankingSellers()
    }, [sellerParams])

    useEffect(() => {
        if (clientParams.providerId) loadRankingClients()
    }, [clientParams])

    const setParamsForAll = (newParams: any) => {
        setProductParams((prev) => ({ ...prev, ...newParams, page: 1 }))
        setSellerParams((prev) => ({ ...prev, ...newParams, page: 1 }))
        setClientParams((prev) => ({ ...prev, ...newParams, page: 1 }))
    }

    const handleFilterChange = (newFilters: any) => {
        setParamsForAll(newFilters)
    }

    const clearAllFilters = () => {
        setParamsForAll(initialParams)
        setActiveTable('RANKING_PRODUCTS')
    }

    const loadRankingProducts = async () => {
        try {
            const response = await TacticalPanelService.rankingProducts(
                productParams
            )
            setRankingProducts(response)
        } catch (error) {
            console.error('Erro ao carregar ranking de produtos:', error)
            setRankingProducts(null)
        }
    }

    const loadRankingSellers = async () => {
        try {
            const response = await TacticalPanelService.rankingSellers(
                sellerParams
            )
            setRankingSellers(response)
        } catch (error) {
            console.error('Erro ao carregar ranking de vendedores:', error)
            setRankingSellers(null)
        }
    }

    const loadRankingClients = async () => {
        try {
            const response = await TacticalPanelService.rankingClients(
                clientParams
            )
            setRankingClients(response)
        } catch (error) {
            console.error('Erro ao carregar ranking de clientes:', error)
            setRankingClients(null)
        }
    }

    const loadQuotationsInfo = async () => {
        try {
            const response = await TacticalPanelService.getQuotationsInfo(
                productParams
            )
            setQuotationsInfo(response)
        } catch (error) {
            console.error('Erro ao carregar informações de cotações:', error)
            setQuotationsInfo(null)
        }
    }

    const handleButtonClick = (table: string) => {
        setActiveTable(table)
    }

    const toggleFilters = () => {
        setViewFilters(!viewFilters)
    }

    const exportToExcel = async (activeTable: string) => {
        setLoadingExport(true)
        let response: any
        let columnTitle = ''

        switch (activeTable) {
            case 'RANKING_PRODUCTS':
                response = await TacticalPanelService.rankingProducts({
                    ...clientParams,
                    page: 1,
                    page_size: rankingProducts?.meta.total_records,
                })
                columnTitle = 'PRODUTO'
                break
            case 'RANKING_SELLERS':
                response = await TacticalPanelService.rankingSellers({
                    ...clientParams,
                    page: 1,
                    page_size: rankingSellers?.meta.total_records,
                })
                columnTitle = 'VENDEDOR'
                break
            case 'RANKING_CLIENTS':
                response = await TacticalPanelService.rankingClients({
                    ...clientParams,
                    page: 1,
                    page_size: rankingClients?.meta.total_records,
                })
                columnTitle = 'CLIENTE'
                break
            default:
                throw new Error('Tabela ativa não reconhecida.')
        }

        if (!response || response.status !== '200' || !response.data) {
            console.error('Erro ao obter dados do endpoint.')
            toast.error(
                'Erro ao exportar para Excel. Por favor, tente novamente mais tarde.'
            )
            setLoadingExport(false)
            return
        } else if (response.data.length <= 0) {
            toast.info('Nenhum dado disponível para exportação no momento.')
            setLoadingExport(false)
            return
        }

        try {
            const formattedData = response.data.map((item) => {
                const row: Record<string, any> = {
                    RANKING: item.rank,
                    [columnTitle]:
                        activeTable === 'RANKING_PRODUCTS'
                            ? item.product_name
                            : activeTable === 'RANKING_SELLERS'
                            ? item.seller_name
                            : activeTable === 'RANKING_CLIENTS'
                            ? item.client_name
                            : '',
                    'QTD Cot. Recebidas': item.total_cotacoes_recebidas,
                    'QTD Ofertas Recebidas': item.total_cotacoes_ofertadas,
                }

                if (activeTable === 'RANKING_PRODUCTS') {
                    row['QTD Cot. Perdidas'] = item.total_cotacoes_perdidas
                }

                row['QTD Cot. Ganhas'] = item.total_cotacoes_ganhas
                row['Taxa de Vitórias'] =
                    item.taxa_vitoria !== null &&
                    item.taxa_vitoria !== undefined
                        ? `${item.taxa_vitoria.toFixed(2)}%`
                        : '0%'

                return row
            })

            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.json_to_sheet(formattedData)

            // Ajuste da largura das colunas
            worksheet['!cols'] = [
                { wch: 8 }, // RANKING
                { wch: 40 }, // Nome do Produto/Vendedor/Cliente
                { wch: 20 }, // QTD Cot. Recebidas
                { wch: 20 }, // QTD Ofertas Recebidas
                { wch: 20 }, // QTD Cot. Ganhas
                { wch: 15 }, // Taxa de Vitórias
            ]

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Ranking')
            XLSX.writeFile(workbook, `${activeTable}.xlsx`)

            toast.success('Planilha exportada com sucesso.')
            setLoadingExport(false)
        } catch (error) {
            console.error('Erro ao exportar para Excel:', error)
            toast.error('Erro ao exportar para Excel.')
            setLoadingExport(false)
        }
    }

    const getFormattedMonthYear = () => {
        const month = productParams.month
        const year = productParams.year

        if (!month || !year) return 'Mês/Ano não definidos'

        const date = new Date(year, month - 1)
        const formattedMonth = date.toLocaleString('pt-BR', { month: 'long' })

        return `Dados referentes a ${
            formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)
        } de ${year}.`
    }

    const handleOpenModalProductQuotation = (product) => {
        setProduct(product)
        setOpenProductQuotationModal(true)
    }

    return (
        <ProjectContainer>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <RoadMap
                pageLinks={[
                    {
                        title: 'Painel Tático',
                        onclick: () => {},
                    },
                ]}
            ></RoadMap>

            <header>
                <PageHeaderV2 title="Painel Tático" />
            </header>

            <section className="filtersContent">
                <div className="viewFilters">
                    <p className="p">Filtros</p>
                    <ButtonV3
                        title={
                            viewFilters ? 'Ocultar Filtros' : 'Exibir Filtros'
                        }
                        Icon={
                            <MdOutlineFilterAlt className="iconButtonFilter" />
                        }
                        onClick={toggleFilters}
                    />
                </div>

                <TacticalPanelFilterComponent
                    viewFilters={viewFilters}
                    onChange={handleFilterChange}
                    cleanFilters={clearAllFilters}
                />
            </section>

            {loading ? (
                <Loader type="inline" />
            ) : (
                <>
                    {/* {quotationsInfo && (
                        <> */}
                    <section className="sectionDataDate">
                        <p className="ViewDataDate">
                            {getFormattedMonthYear()}
                        </p>
                    </section>
                    <section className="cotacoesContent">
                        <h2 className="titleSection">Cotações</h2>
                        <div className="cardsContent">
                            <div className="cards">
                                <CardInfos
                                    title={'Recebidas'}
                                    total={
                                        quotationsInfo?.received.period.total ??
                                        0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.received.period
                                                    .percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.received
                                                .period.sign as '+' | '-' | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.received
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.received
                                                .previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Sem Oferta'}
                                    total={
                                        quotationsInfo?.notOffered?.period
                                            .total ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.notOffered
                                                    ?.period.percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.notOffered
                                                ?.period.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.notOffered
                                                    ?.previous_year.percent ??
                                                0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.notOffered
                                                ?.previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Não Digitadas'}
                                    total={
                                        quotationsInfo?.notTyped?.period
                                            .total ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.notTyped?.period
                                                    .percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.notTyped
                                                ?.period.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.victory
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.notTyped
                                                ?.previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Respondidas'}
                                    total={
                                        quotationsInfo?.answered.period.total ??
                                        0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.answered.period
                                                    .percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.answered
                                                .period.sign as '+' | '-' | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.answered
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.answered
                                                .previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Ganhas'}
                                    total={
                                        quotationsInfo?.victory.period.won ?? 0
                                    }
                                    percentage={
                                        quotationsInfo?.victory.period
                                            .percent ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.victory
                                                    .previous_month.percent ??
                                                0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.victory
                                                .previous_month.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.victory
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.victory
                                                .previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </section>

                    <section className="productsContent">
                        <h2 className="titleSection">Produtos</h2>
                        <div className="cardsContent">
                            <div className="cards">
                                <CardInfos
                                    title={'Recebidas'}
                                    total={
                                        quotationsInfo?.productReceived.period
                                            .total ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.productReceived
                                                    .period.percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo
                                                ?.productReceived.period
                                                .sign as '+' | '-' | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.productReceived
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo
                                                ?.productReceived.previous_year
                                                .sign as '+' | '-' | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Não Vencidas'}
                                    total={
                                        quotationsInfo?.productLoser?.period
                                            .total ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.productLoser
                                                    ?.period.percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.productLoser
                                                ?.period.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.productLoser
                                                    ?.previous_year.percent ??
                                                0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.productLoser
                                                ?.previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Não Digitadas'}
                                    total={
                                        quotationsInfo?.productNotTyped?.period
                                            .total ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.productNotTyped
                                                    ?.period.percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo
                                                ?.productNotTyped?.period
                                                .sign as '+' | '-' | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.productNotTyped
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo
                                                ?.productNotTyped?.previous_year
                                                .sign as '+' | '-' | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Respondidas'}
                                    total={
                                        quotationsInfo?.productAnswered.period
                                            .total ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.productAnswered
                                                    .period.percent ?? 0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo
                                                ?.productAnswered.period
                                                .sign as '+' | '-' | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.productAnswered
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo
                                                ?.productAnswered.previous_year
                                                .sign as '+' | '-' | '=',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="cards">
                                <CardInfos
                                    title={'Ganhas'}
                                    total={
                                        quotationsInfo?.productVictory.period
                                            .won ?? 0
                                    }
                                    percentage={
                                        quotationsInfo?.productVictory.period
                                            .percent ?? 0
                                    }
                                    metrics={[
                                        {
                                            percentage:
                                                quotationsInfo?.productVictory
                                                    .previous_month.percent ??
                                                0,
                                            description: 'sobre o mês anterior',
                                            sign: quotationsInfo?.productVictory
                                                .previous_month.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                        {
                                            percentage:
                                                quotationsInfo?.productVictory
                                                    .previous_year.percent ?? 0,
                                            description: 'sobre o ano anterior',
                                            sign: quotationsInfo?.productVictory
                                                .previous_year.sign as
                                                | '+'
                                                | '-'
                                                | '=',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="buttonsContent">
                        <div className="toggleButtons">
                            <ButtonIsActive
                                children="Ranking Produtos"
                                isActive={activeTable === 'RANKING_PRODUCTS'}
                                icon={<BsBoxSeam />}
                                onClick={() =>
                                    handleButtonClick('RANKING_PRODUCTS')
                                }
                            />
                            <ButtonIsActive
                                children="Ranking Vendedores"
                                isActive={activeTable === 'RANKING_SELLERS'}
                                icon={<FaUsers />}
                                onClick={() =>
                                    handleButtonClick('RANKING_SELLERS')
                                }
                            />
                            <ButtonIsActive
                                children="Ranking Clientes"
                                isActive={activeTable === 'RANKING_CLIENTS'}
                                icon={<FaShop />}
                                onClick={() =>
                                    handleButtonClick('RANKING_CLIENTS')
                                }
                            />
                        </div>
                        <div className="buttonExport">
                            {!loadingExport ? (
                                <ButtonV3
                                    type="button"
                                    title={'Exportar'}
                                    Icon={
                                        <FaFileDownload className="iconButtonFilter" />
                                    }
                                    onClick={() => exportToExcel(activeTable)}
                                />
                            ) : (
                                <Loader type="inline" size="30px" />
                            )}
                        </div>
                    </section>
                    {/* </>
                    )} */}
                    <section className="tableContent">
                        <div className="NewListItemTacticalPanel">
                            {activeTable === 'RANKING_PRODUCTS' && (
                                <>
                                    {(rankingProducts?.data?.length ?? 0) >
                                    0 ? (
                                        <>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>
                                                            QTD Cot. Recebidas
                                                        </th>
                                                        <th>
                                                            QTD Ofertas
                                                            Recebidas
                                                        </th>
                                                        <th>
                                                            QTD Cot. Perdidas
                                                        </th>
                                                        <th>QTD Cot. Ganhas</th>
                                                        <th>
                                                            Taxa de Vitórias
                                                        </th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rankingProducts?.data.map(
                                                        (product) => (
                                                            <tr
                                                                key={
                                                                    product?.product_code
                                                                }
                                                            >
                                                                <td
                                                                    data-label="Produto"
                                                                    className="tdRanking"
                                                                >
                                                                    <div className="rankingContent">
                                                                        <RankingPosition
                                                                            position={
                                                                                product.rank
                                                                            }
                                                                        />
                                                                        {product?.product_name ??
                                                                            'Nome do produto indisponível'}
                                                                    </div>
                                                                </td>
                                                                <td data-label="QTD Cot. Recebidas">
                                                                    {product?.total_cotacoes_recebidas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Ofertas Recebidas">
                                                                    {product?.total_cotacoes_ofertadas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Cot. Perdidas">
                                                                    {product?.total_cotacoes_perdidas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Cot. Ganhas">
                                                                    {product?.total_cotacoes_ganhas ??
                                                                        ''}
                                                                </td>
                                                                <td
                                                                    data-label="Taxa de Vitórias"
                                                                    className="tdParticipationPanelMobile"
                                                                >
                                                                    <div className="tdParticipationPanel">
                                                                        <ParticipationBadge
                                                                            participation={
                                                                                (product?.taxa_vitoria
                                                                                    ? product.taxa_vitoria
                                                                                    : 0) /
                                                                                100
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td data-label="Ações">
                                                                    <IoMdInformationCircle
                                                                        title="Visualizar Cotações"
                                                                        size={
                                                                            24
                                                                        }
                                                                        color="#2196f3"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleOpenModalProductQuotation(
                                                                                product
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            <div style={{ marginTop: 16 }}>
                                                <PaginationCustomV3
                                                    totalItems={
                                                        rankingProducts?.meta
                                                            .total_records ?? 0
                                                    }
                                                    currentPage={
                                                        productParams.page
                                                    }
                                                    handlePageChange={(
                                                        page: number
                                                    ) =>
                                                        setProductParams(
                                                            (prev) => ({
                                                                ...prev,
                                                                page,
                                                            })
                                                        )
                                                    }
                                                    itemsPerPage={
                                                        initialParams.page_size
                                                    }
                                                    itemsName="Produtos"
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="error-message">
                                            <FeedbackError message="Nenhum produto encontrado." />
                                        </div>
                                    )}
                                </>
                            )}

                            {activeTable === 'RANKING_SELLERS' && (
                                <>
                                    {(rankingSellers?.data?.length ?? 0) > 0 ? (
                                        <>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Vendedor</th>
                                                        <th>
                                                            QTD Cot. Recebidas
                                                        </th>
                                                        <th>
                                                            Qtd. Ofertas
                                                            Recebidas
                                                        </th>
                                                        <th>QTD Cot. Ganhas</th>
                                                        <th>
                                                            Taxa de Vitórias
                                                        </th>
                                                        {/* <th>Ações</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rankingSellers?.data.map(
                                                        (seller) => (
                                                            <tr
                                                                key={
                                                                    seller.seller_code
                                                                }
                                                            >
                                                                <td
                                                                    data-label="Vendedor"
                                                                    className="tdRanking"
                                                                >
                                                                    <div className="rankingContent">
                                                                        <RankingPosition
                                                                            position={
                                                                                seller.rank
                                                                            }
                                                                        />
                                                                        {seller.seller_name ??
                                                                            ''}
                                                                    </div>
                                                                </td>
                                                                <td data-label="QTD Cot. Recebidas">
                                                                    {seller.total_cotacoes_recebidas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Ofertas Recebidas">
                                                                    {seller.total_cotacoes_ofertadas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Cot. Ganhas">
                                                                    {seller.total_cotacoes_ganhas ??
                                                                        ''}
                                                                </td>
                                                                <td
                                                                    data-label="Taxa de Vitórias"
                                                                    className="tdParticipationPanelMobile"
                                                                >
                                                                    <div className="tdParticipationPanel">
                                                                        <ParticipationBadge
                                                                            participation={
                                                                                (seller.taxa_vitoria
                                                                                    ? seller.taxa_vitoria
                                                                                    : 0) /
                                                                                100
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                {/* <td data-label="Ações">
                                                                    <MdOutlineEdit
                                                                        size={
                                                                            24
                                                                        }
                                                                    />
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            <div style={{ marginTop: 16 }}>
                                                <PaginationCustomV3
                                                    totalItems={
                                                        rankingSellers?.meta
                                                            .total_records ?? 0
                                                    }
                                                    currentPage={
                                                        sellerParams.page
                                                    }
                                                    handlePageChange={(
                                                        page: number
                                                    ) =>
                                                        setSellerParams(
                                                            (prev) => ({
                                                                ...prev,
                                                                page,
                                                            })
                                                        )
                                                    }
                                                    itemsPerPage={
                                                        initialParams.page_size
                                                    }
                                                    itemsName="Vendedores"
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="error-message">
                                            <FeedbackError message="Nenhum Vendedor encontrado." />
                                        </div>
                                    )}
                                </>
                            )}

                            {activeTable === 'RANKING_CLIENTS' && (
                                <>
                                    {(rankingClients?.data?.length ?? 0) > 0 ? (
                                        <>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Cliente</th>
                                                        <th>
                                                            QTD Cot. Recebidas
                                                        </th>
                                                        <th>
                                                            QTD Ofertas
                                                            Recebidas
                                                        </th>
                                                        <th>QTD Cot. Ganhas</th>
                                                        <th>
                                                            Taxa de Vitórias
                                                        </th>
                                                        {/* <th>Ações</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rankingClients?.data.map(
                                                        (client) => (
                                                            <tr
                                                                key={
                                                                    client.client_code
                                                                }
                                                            >
                                                                <td
                                                                    data-label="Cliente"
                                                                    className="tdRanking"
                                                                >
                                                                    <div className="rankingContent">
                                                                        <RankingPosition
                                                                            position={
                                                                                client.rank
                                                                            }
                                                                        />
                                                                        {client.client_name ??
                                                                            ''}
                                                                    </div>
                                                                </td>
                                                                <td data-label="QTD Cot. Recebidas">
                                                                    {client.total_cotacoes_recebidas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Ofertas Recebidas">
                                                                    {client.total_cotacoes_ofertadas ??
                                                                        ''}
                                                                </td>
                                                                <td data-label="QTD Cot. Ganhas">
                                                                    {client.total_cotacoes_ganhas ??
                                                                        ''}
                                                                </td>
                                                                <td
                                                                    data-label="Taxa de Vitórias"
                                                                    className="tdParticipationPanelMobile"
                                                                >
                                                                    <div className="tdParticipationPanel">
                                                                        <ParticipationBadge
                                                                            participation={
                                                                                (client.taxa_vitoria
                                                                                    ? client.taxa_vitoria
                                                                                    : 0) /
                                                                                100
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                {/* <td data-label="Ações">
                                                                    <MdOutlineEdit
                                                                        size={
                                                                            24
                                                                        }
                                                                    />
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            <div style={{ marginTop: 16 }}>
                                                <PaginationCustomV3
                                                    totalItems={
                                                        rankingClients?.meta
                                                            .total_records ?? 0
                                                    }
                                                    currentPage={
                                                        clientParams.page
                                                    }
                                                    handlePageChange={(
                                                        page: number
                                                    ) =>
                                                        setClientParams(
                                                            (prev) => ({
                                                                ...prev,
                                                                page,
                                                            })
                                                        )
                                                    }
                                                    itemsPerPage={
                                                        initialParams.page_size
                                                    }
                                                    itemsName="Clientes"
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="error-message">
                                            <FeedbackError message="Nenhum Cliente encontrado." />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </section>
                </>
            )}
            <BaseModal
                onCloseCallback={() => setOpenProductQuotationModal(false)}
                children={
                    <QuotationModal
                        user={user}
                        productId={product?.product_code as any}
                        hash={rankingProducts?.meta?.hash as string}
                        isVisible={openProductQuotationModal}
                        onClose={() => setOpenProductQuotationModal(false)}
                    />
                }
                icon={
                    <FaRegClipboard
                        size={21}
                        style={{ marginRight: 6, marginBottom: 6 }}
                    />
                }
                title={`${product?.product_name}`}
                isVisible={openProductQuotationModal}
                onClose={() => setOpenProductQuotationModal(false)}
            />
        </ProjectContainer>
    )
}

export default TacticalPanel
