import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import Constants from '../../constants'
import DateService from '../../services/DateService'
import OrderService from '../../services/OrderService'
import Utils from '../../utils'
import { connect } from 'react-redux'
import { Order } from '../../interfaces/order'
import { loadOrders } from '../../store/orders'
import { Advise } from '../../components/advise'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { Loader } from '../../components/loader'
import { OrderModalDetails } from './components/modal-details'
import { Pagination } from '../../components/pagination'
import { ProjectContainer } from '../../components/project-container/index'
import {
    Status,
    STATUS_CONFIRMED,
    STATUS_NOT_CONFIRMED,
} from '../../components/status'
import { OrderPrint } from './print'
import styles from './index.module.scss'
import { PageHeader } from '../../components/page-header'
import { OrdersFilterComponent } from './components/filters'
import { Button } from '../../components/button'
import { BsCheckAll, BsInfoCircle } from 'react-icons/bs'
import { AiFillPrinter, AiFillInfoCircle } from 'react-icons/ai'
import { useReactToPrint } from 'react-to-print'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useLocation } from 'react-router-dom'
import { UserContext } from 'contexts/user'
import { Rating } from 'components/rating'
import { RatingCommentsModal } from './components/rating-comments'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import IconReact from 'components/icons'
import { BaseModal } from 'components/BaseModal'
import { OrderDetails } from './components/order-details/order-details'
import { RoadMap } from 'components/roadmap'
import ButtonV3 from 'components/Buttonv3'
import { BiChevronDown } from 'react-icons/bi'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { GrDocumentPdf } from 'react-icons/gr'
import { IoStorefrontOutline } from 'react-icons/io5'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { OrderDetailsPdf } from './components/order-detailsPdf/order-details'
import { useTheme } from 'contexts/whiteLabel/context'

const Orders = ({ orders, findOrders }: any) => {
    const endDate: Date = new Date()
    const initialDate: Date = DateService.removeDaysToDate(
        new Date(endDate),
        10
    )

    const [successMessage, setSuccessMessage] = useState<string>('')
    const [orderDetailModalVisible, setOrderDetailModalVisible] =
        useState(false)
    const [error, setError] = useState<string>('')
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
    const [show_comments, open_comments_modal] = useState<boolean>(false)
    const [printOrder, setPrintOrder] = useState<Order | null>(null)
    const [printOrderpdf, setPrintOrderpdf] = useState<Order | null>(null)
    const [detailsOrder, setDetailsOrder] = useState<Order | null>(null)
    const [current_order, setCurrentOrder] = useState<Order | null>(null)
    const MySwal = withReactContent(Swal)
    const useQuery = () => new URLSearchParams(useLocation().search)
    const request_id = useQuery().get('request_id')
    const { user, can } = useContext(UserContext)
    const [isMobileView, setIsMobileView] = useState(false)
    const [canDownload, setCanDownload] = useState(false)

    const initialQueryParams = {
        page: 1,
        datainicial: request_id
            ? null
            : DateService.formatToRequest(initialDate),
        // datafinal: request_id ? null : DateService.formatToRequest(endDate),
        datafinal: null,
        status: null,
        cliente: null,
        vendedor: null,
        numerocotacao: request_id,
        uf: null,
    }

    const [queryParams, setQueryParams] = useState(initialQueryParams)

    const hasOrders = (orders) => orders && orders.total > 0
    const toogleFilters = () => setShowFilters(!showFilters)
    const componentRef = useRef<any>(null)
    const pdfRef = useRef<any>(null)
    const { theme } = useTheme()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const goToPage = (nextPage: number) =>
        setQueryParams({ ...queryParams, page: nextPage })

    const print = (item: Order) => {
        OrderService.getById(item.id)
            .then(async (order: Order) => {
                await setPrintOrder(order)
                handlePrint && handlePrint()
            })
            .catch(() => {
                setError(Constants.MSG_ORDER_LOAD_FAIL)
                setTimeout(() => {
                    setError('')
                }, 5000)
            })
    }
    const handleDownloadPdf = async (item) => {
        setLoadingPdf(true)

        await OrderService.getById(item.id)
            .then(async (order: Order) => {
                // console.log(order)
                await setPrintOrderpdf(order)
            })
            .then((res) => {
                // setTimeout(() => {

                downloadpdf(item.id)
                // setCanDownload(true)

                // }, 1000);
            })
            .catch((er) => setLoadingPdf(false))
    }

    async function downloadpdf(id) {
        if (pdfRef && pdfRef.current) {
            const element = pdfRef.current

            // const canvas = await html2canvas(element);
            // const data = canvas.toDataURL('image/png');

            const canvas = await html2canvas(element, { scale: 1.3 })
            const data = canvas.toDataURL('image/jpeg', 1)

            const pdf = new jsPDF()
            const imgProperties = pdf.getImageProperties(data)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width

            pdf.addImage(data, 'PNG', 3, 3, pdfWidth - 5, pdfHeight - 5)

            pdf.save(`${id}.pdf`)
            setLoadingPdf(false)

            setPrintOrderpdf(null)
        } else {
            setLoadingPdf(false)
        }
    }

    // async function getBase64Image(url) {
    //     const response = await fetch(url, { mode: 'cors' })
    //     const blob = await response.blob()
    //     return new Promise((resolve) => {
    //         const reader = new FileReader()
    //         reader.onloadend = () => resolve(reader.result)
    //         reader.readAsDataURL(blob)
    //     })
    // }

    // async function downloadpdf(id) {
    //     if (pdfRef && pdfRef.current) {
    //         const element = pdfRef.current

    //         // Se a logo vem de uma URL externa, converte para Base64 primeiro
    //         // DENIS
    //         console.log('Logo URL:', theme?.imgs?.img_logo)
    //         if (theme?.imgs?.img_logo?.startsWith('http')) {
    //             theme.imgs.img_logo = await getBase64Image(theme.imgs.img_logo)
    //         }

    //         const canvas = await html2canvas(element, {
    //             scale: 1.3,
    //             useCORS: true,
    //             allowTaint: true,
    //         })

    //         const data = canvas.toDataURL('image/jpeg', 1)
    //         const pdf = new jsPDF()
    //         const imgProperties = pdf.getImageProperties(data)
    //         const pdfWidth = pdf.internal.pageSize.getWidth()
    //         const pdfHeight =
    //             (imgProperties.height * pdfWidth) / imgProperties.width

    //         pdf.addImage(data, 'PNG', 3, 3, pdfWidth - 5, pdfHeight - 5)
    //         pdf.save(`${id}.pdf`)
    //         setLoadingPdf(false)
    //         setPrintOrderpdf(null)
    //     } else {
    //         setLoadingPdf(false)
    //     }
    // }

    const confirm = (order: Order) => {
        MySwal.fire({
            title: 'Tem certeza que quer confirmar o pedido?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                OrderService.confirm(order.id)
                    .then(() => {
                        setSuccessMessage(Constants.MSG_ORDER_CONFIRM_SUCCESS)
                        reload()
                        setTimeout(() => {
                            setSuccessMessage('')
                        }, 5000)
                    })
                    .catch(() => {
                        setError(Constants.MSG_ORDER_CONFIRM_FAIL)
                        setTimeout(() => {
                            setError('')
                        }, 5000)
                    })
            }
        })
    }

    const seeDetails = async (item: Order | null) => {
        if (item) {
            await setCurrentOrder(item)
            // console.log('currentorder', item)
            OrderService.getById(item.id)
                .then((order: Order) => {
                    setDetailsOrder(order)
                    // console.log(order)
                    //setOrderDetailModalVisible(true)
                })
                .catch(() => {
                    setError(Constants.MSG_ORDER_LOAD_FAIL)
                    setTimeout(() => {
                        setError('')
                    }, 5000)
                })
        }
    }

    const update = (params: any) => setQueryParams(params)

    const reload = useCallback(async () => {
        let params = { ...queryParams }
        if (params.numerocotacao != '') {
            params.datainicial = null
            params.datafinal = null

            // params.datafinal = ''
        }
        const searchOrders = async () =>
            await findOrders(
                can('READ_ALL', 'REQUEST_ORDER')
                    ? params
                    : { ...params, vendedor: user?.id }
            )
        // const searchOrders = async () =>
        //   await findOrders(
        //     can('READ_ALL', 'REQUEST_ORDER')
        //       ? queryParams
        //       : { ...queryParams, vendedor: user?.id }
        //   )
        setLoading(true)
        searchOrders().then(() => setLoading(false))
    }, [findOrders, can, user, queryParams])

    const open_comments = (item) => {
        setCurrentOrder(item)

        open_comments_modal(true)
    }

    useEffect(() => {
        if (user) {
            reload()
        }
    }, [user, queryParams, reload])

    useEffect(() => {
        setLoading(!orders)
    }, [orders])

    useEffect(() => {
        function resize() {
            if (window.innerWidth < 900) {
                setIsMobileView(true)
                setShowFilters(false)
            } else {
                setIsMobileView(false)
                setShowFilters(true)
            }
        }
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return (
        <ProjectContainer style={{ position: 'relative' }}>
            {loadingPdf ? <Loader /> : null}
            <div
                style={printOrder ? { overflowX: 'hidden' } : {}}
                className="orders color-gray-2"
            >
                <RoadMap
                    pageLinks={[
                        {
                            title: 'Pedidos',
                            onclick: () => {},
                        },
                    ]}
                ></RoadMap>
                <header
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <PageHeaderV2 title="Pedidos" />
                    <div>
                        {isMobileView ? (
                            <ButtonV3
                                style={{
                                    justifyContent: 'space-between',
                                    height: 49,
                                }}
                                title={
                                    showFilters
                                        ? 'Ocultar filtros'
                                        : 'Exibir filtros'
                                }
                                textStyle={{ fontSize: 15 }}
                                onClick={toogleFilters}
                                Icon={
                                    showFilters ? (
                                        <FaChevronUp
                                            style={{ marginRight: 10 }}
                                            color={`${
                                                theme.colors?.primary ||
                                                '#ff7110'
                                            }`}
                                        />
                                    ) : (
                                        <FaChevronDown
                                            style={{ marginRight: 10 }}
                                            color={`${
                                                theme.colors?.primary ||
                                                '#ff7110'
                                            }`}
                                        />
                                    )
                                }
                            ></ButtonV3>
                        ) : null}
                    </div>
                </header>
                {
                    //  <Advise icon="label_important" message={Constants.MSG_ORDER_ADVISE} />
                }
                {
                    //    <div className={styles['order-list-actions']} >
                    //        <div className="actions">
                    //            <Button onClick={() => toogleFilters()}>
                    //                {showFilters ? (
                    //                    <span className="flex items-center justify-center">
                    //                        <i className="material-icons">close</i>
                    //                        Filtros
                    //                    </span>
                    //                ) : (
                    //                    <span className="flex items-center justify-center">
                    //                        <i className="material-icons">filter_alt</i>
                    //                        Filtros
                    //                    </span>
                    //                )}
                    //            </Button>
                    //        </div>
                    //    </div>
                }
                {showFilters || !isMobileView ? (
                    <OrdersFilterComponent onChange={update} />
                ) : null}
                {error && <FeedbackError message={error} />}
                {successMessage && <FeedbackSuccess message={successMessage} />}
                {!loading ? (
                    hasOrders(orders) ? (
                        <div>
                            <div className={styles.NewListOrders}>
                                <table className={styles.newTableOrders}>
                                    <thead>
                                        <tr>
                                            <th>Ações</th>
                                            <th>Pedido</th>
                                            <th>Avaliação</th>
                                            <th>Empresa</th>
                                            <th>Data</th>
                                            <th>Cotação</th>
                                            <th>Valor do Pedido</th>
                                            <th>Status</th>
                                            <th>Vendedor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.data.map((item: Order) => (
                                            <tr
                                                className={styles.trMobile}
                                                key={item.id}
                                            >
                                                <td
                                                    style={{ padding: 0 }}
                                                    className={
                                                        styles.showOnlyInMobile
                                                    }
                                                    data-label=""
                                                >
                                                    <div
                                                        className={
                                                            styles.storeNameMobile
                                                        }
                                                    >
                                                        <IoStorefrontOutline />
                                                        <p>{item.storeName}</p>
                                                    </div>
                                                </td>
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Ações"
                                                >
                                                    <div
                                                        className={
                                                            styles.actions
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.ratingOnActions
                                                            }
                                                        >
                                                            <Rating
                                                                size={25}
                                                                rate={item.rate}
                                                                onClick={() =>
                                                                    open_comments(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        {
                                                            //   <IconReact
                                                            //       type='infoCircle'
                                                            //   />
                                                        }
                                                        {
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    seeDetails(
                                                                        item
                                                                    )
                                                                }
                                                                id={
                                                                    'order-d-' +
                                                                    item.id
                                                                }
                                                                data-toggle="modal"
                                                                data-target="#order-details"
                                                            >
                                                                <AiFillInfoCircle
                                                                    title="Ver detalhes do pedido"
                                                                    className="action info"
                                                                />
                                                            </button>
                                                        }
                                                        <GrDocumentPdf
                                                            style={{
                                                                color: 'blue',
                                                            }}
                                                            color={'blue'}
                                                            onClick={() =>
                                                                handleDownloadPdf(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                        <IconReact
                                                            type="printer"
                                                            onClick={() =>
                                                                print(item)
                                                            }
                                                        />
                                                        {
                                                            //   <AiFillPrinter
                                                            //       title="Imprimir"
                                                            //       className="action print"
                                                            //       onClick={() =>
                                                            //           print(item)
                                                            //       }
                                                            //   />
                                                        }
                                                        {item.status ===
                                                            'P' && (
                                                            <IconReact
                                                                type="checkCircle2"
                                                                onClick={() =>
                                                                    confirm(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                            //   <BsCheckAll
                                                            //       title="Confirmar"
                                                            //       className="action confirm"
                                                            //       onClick={() =>
                                                            //           confirm(item)
                                                            //       }
                                                            //   />
                                                        )}
                                                    </div>
                                                </td>
                                                <td
                                                    className={
                                                        styles.showOnlyInMobile
                                                    }
                                                    data-label=""
                                                >
                                                    <div
                                                        className={
                                                            styles.rowIdStatus
                                                        }
                                                    >
                                                        <p>
                                                            <span>
                                                                Pedido:{' '}
                                                            </span>
                                                            #{item.id}
                                                        </p>
                                                        <p>
                                                            <span>
                                                                cotação:{' '}
                                                            </span>
                                                            #{item.quotationId}
                                                        </p>
                                                        <Status
                                                            status={
                                                                item.status ===
                                                                'C'
                                                                    ? STATUS_CONFIRMED
                                                                    : STATUS_NOT_CONFIRMED
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Pedido"
                                                >
                                                    <p>#{item.id}</p>
                                                </td>
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Avaliação"
                                                >
                                                    <Rating
                                                        rate={item.rate}
                                                        onClick={() =>
                                                            open_comments(item)
                                                        }
                                                    />
                                                </td>
                                                {/* <td className={styles.showOnlyInMobile} data-label="">
                          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                            <p style={{ fontWeight: 500, textAlign: 'left' }}>
                              {item.storeName}

                            </p>
                            <span>

                              {DateService.formatToDisplayWithTime(
                                new Date(item.date)
                              )}
                            </span>
                            <Status
                              status={
                                item.status === 'C'
                                  ? STATUS_CONFIRMED
                                  : STATUS_NOT_CONFIRMED
                              }
                            />
                          </div>
                        </td> */}
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Empresa"
                                                >
                                                    {item.storeName}
                                                </td>
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Data"
                                                >
                                                    {DateService.formatToDisplayWithTime(
                                                        new Date(item.date)
                                                    )}
                                                </td>
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Cotação"
                                                >
                                                    <p>#{item.quotationId}</p>
                                                </td>
                                                <td
                                                    style={{ fontWeight: 500 }}
                                                    data-label="Valor do Pedido"
                                                >
                                                    {Utils.formatPrice(
                                                        item.value
                                                    )}
                                                </td>
                                                <td
                                                    className={
                                                        styles.hideOnMobile
                                                    }
                                                    data-label="Status"
                                                >
                                                    <Status
                                                        status={
                                                            item.status === 'C'
                                                                ? STATUS_CONFIRMED
                                                                : STATUS_NOT_CONFIRMED
                                                        }
                                                    />
                                                </td>
                                                <td data-label="Vendedor">
                                                    {item.salesmanName}
                                                </td>
                                                <td
                                                    className={
                                                        styles.showOnlyInMobile
                                                    }
                                                    data-label=""
                                                >
                                                    <div
                                                        style={{
                                                            justifyContent:
                                                                'space-between',
                                                            gap: 4,
                                                        }}
                                                        className={
                                                            styles.actions
                                                        }
                                                    >
                                                        <p>
                                                            {DateService.formatToDisplayWithTime(
                                                                new Date(
                                                                    item.date
                                                                )
                                                            )}
                                                        </p>
                                                        <div
                                                            className={
                                                                styles.ratingOnActions
                                                            }
                                                        >
                                                            <Rating
                                                                size={25}
                                                                rate={item.rate}
                                                                onClick={() =>
                                                                    open_comments(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        {
                                                            //   <IconReact
                                                            //       type='infoCircle'
                                                            //   />
                                                        }
                                                        {
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    seeDetails(
                                                                        item
                                                                    )
                                                                }
                                                                id={
                                                                    'order-d-' +
                                                                    item.id
                                                                }
                                                                data-toggle="modal"
                                                                data-target="#order-details"
                                                            >
                                                                <AiFillInfoCircle
                                                                    title="Ver detalhes do pedido"
                                                                    className="action info"
                                                                />
                                                            </button>
                                                        }
                                                        <GrDocumentPdf
                                                            style={{
                                                                color: 'blue',
                                                            }}
                                                            color={'blue'}
                                                            onClick={() =>
                                                                handleDownloadPdf(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                        <IconReact
                                                            type="printer"
                                                            onClick={() =>
                                                                print(item)
                                                            }
                                                        />
                                                        {
                                                            //   <AiFillPrinter
                                                            //       title="Imprimir"
                                                            //       className="action print"
                                                            //       onClick={() =>
                                                            //           print(item)
                                                            //       }
                                                            //   />
                                                        }
                                                        {item.status ===
                                                            'P' && (
                                                            <IconReact
                                                                type="checkCircle2"
                                                                onClick={() =>
                                                                    confirm(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                            //   <BsCheckAll
                                                            //       title="Confirmar"
                                                            //       className="action confirm"
                                                            //       onClick={() =>
                                                            //           confirm(item)
                                                            //       }
                                                            //   />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <Pagination
                                    page={orders.current_page}
                                    totalPages={orders.last_page}
                                    total={orders.total}
                                    from={orders.from}
                                    to={orders.to}
                                    goToPage={goToPage}
                                />
                            </div>
                        </div>
                    ) : (
                        <FeedbackError
                            message={Constants.MSG_DATA_NOT_FOUND}
                            style={{ zIndex: -1 }}
                        />
                    )
                ) : (
                    <Loader type="inline" />
                )}

                {
                    //   <BaseModal
                    //       title={`Pedido ${(detailsOrder) ? detailsOrder.id : ''}`}
                    //       isVisible={orderDetailModalVisible}
                    //       onCloseCallback={() => setOrderDetailModalVisible(false)}
                    //       children={detailsOrder ?<OrderDetails order={detailsOrder} order_type={current_order?.order_type} /> : <Loader className={styles.loader} type="inline" />}
                    //   />
                }
                {
                    <OrderModalDetails
                        isMobileView={isMobileView}
                        order={detailsOrder}
                        order_type={current_order?.order_type}
                        onCloseCallback={() => setDetailsOrder(null)}
                    />
                }
                {current_order && show_comments && (
                    <RatingCommentsModal
                        onCloseCallback={() => open_comments_modal(false)}
                        id={current_order.id}
                        isVisible={current_order && show_comments}
                    />
                )}

                {printOrder && (
                    <OrderPrint order={printOrder} ref={componentRef} />
                )}
            </div>
            {printOrderpdf && (
                // <div style={{display:'none'}}>
                <OrderDetailsPdf order={printOrderpdf} pdfRef={pdfRef} />

                // </div>
            )}
        </ProjectContainer>
    )
}

const mapStateToProps = (state: any) => ({
    orders: state.orders,
    customers: state.customers,
    salesmen: state.salesmen,
})

const mapDispatchToProps = (dispatch: any) => ({
    findOrders: (params: any) => dispatch(loadOrders(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
