import React, { useCallback, useEffect, useState } from 'react'
import {
    ActionTd,
    ButtonsEdit,
    Container,
    EditTd,
    FiltersContainer,
    Header,
    IconEdit,
    OpenOrdersButton,
} from './styles'
import { ProjectContainer } from 'components/project-container'
import { RoadMap } from 'components/roadmap'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { PaginationCustom } from '../../components/pagination-custom'
import {
    AiOutlineClear,
    AiOutlineSearch,
    AiOutlineCloseCircle,
} from 'react-icons/ai'
import { Datepicker } from 'components/datepicker'
import { Button } from 'components/button'
import ButtonV3 from 'components/Buttonv3'
import { Loader } from 'components/loader'
import FieldControlRoutesService from 'services/v3/fieldControlRoutes.service'
import { Select } from 'components/select'
import DateService from 'services/DateService'
import IconReact from 'components/icons'
import { Input } from 'components/input'
import { ToastContainer, toast } from 'react-toastify'
import DirectSaleOrderService from 'services/DirectSaleOrderService'
import { OrdersModal } from './components/orders-modal'
export default function DeliveryMonitoring() {
    const [routes, setRoutes] = useState<any>()
    const [deliveries, setDeliveries] = useState<any>()
    const [response, setResponse] = useState<any>()

    const defaultData = new Date()
    defaultData.setDate(defaultData.getDate() + 1)

    const [routeFilter, setRouteFilter] = useState<any>(null)
    const [dateFilter, setDateFilter] = useState<any>(defaultData)
    const [editRoute, setEditRoute] = useState<any>(null)
    const [newCapacity, setNewCapacity] = useState<any>(null)
    const [current_route_delivery, setCurrentRouteDelivery] =
        useState<any>(null)
    const [reload, setReload] = useState(false)
    const [params, setParams] = useState<any>({
        page: 1,
        group_by: 'delivery_date',
        order_by: 'delivery_date',
        has_volume: true,
        delivery_date: DateService.formatToRequest(new Date(dateFilter)),
    })

    async function getRoutes(page = 1) {
        await FieldControlRoutesService.getRoutes(page).then((res) => {
            setRoutes(res)
        })
    }

    const getDeliveries = useCallback(
        async function () {
            await FieldControlRoutesService.getDeliveries(params).then(
                (res) => {
                    let date = new Date(dateFilter)
                    let ano = date.getFullYear()
                    let mes = (date.getMonth() + 1).toString().padStart(2, '0')
                    let dia = date.getDate().toString().padStart(2, '0')

                    let formatedDate = ano + '-' + mes + '-' + dia

                    let filteredResponse = res.data.filter(
                        (item) => item.delivery_date == formatedDate
                    )
                    setDeliveries(filteredResponse)
                    // setDeliveries(res.data)
                    setResponse(res)
                }
            )
        },
        [params]
    )
    //? put
    //? 'capacity', 'delivery_date', 'used_volume',
    //? 'status', 'route_id'
    useEffect(() => {
        getRoutes()
    }, [])

    useEffect(() => {
        getDeliveries()
    }, [getDeliveries])

    function orderDate(a, b) {
        const dataA: any = new Date(a.delivery_date)
        const dataB: any = new Date(b.delivery_date)

        return dataA - dataB
    }
    useEffect(() => {
        params.route_id ? setRouteFilter(params.route_id) : setRouteFilter(null)
        // params.delivery_date ? setDateFilter(params.delivery_date) : setDateFilter(null)
    }, [params])

    function cleanEdit() {
        setEditRoute(null)
        setNewCapacity(null)
    }

    async function handleSaveNewCapacity() {
        await FieldControlRoutesService.putNewCapacity(
            editRoute.id,
            newCapacity ? newCapacity : 0
        )
            .then((res) => {
                cleanEdit()
                getDeliveries()
                toast.success('Alterações salvas !', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                })
                //   setReload(!reload)
            })
            .catch((er) =>
                toast.error('Erro ao salvar alterações !', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                })
            )
    }

    function keyEvent(event) {
        if (event.keyCode === 13) {
            handleSaveNewCapacity()
        }
    }

    const show_orders = (route_delivery) => {
        setCurrentRouteDelivery(route_delivery)
    }

    const newFormatDate = (dateString) => {
        const date = new Date(dateString + 'T00:00:00')
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = String(date.getFullYear())

        return `${day}/${month}/${year}`
    }

    return (
        <ProjectContainer>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <RoadMap
                pageLinks={[
                    {
                        title: 'Monitoramento de Entregas',
                        onclick: () => {},
                    },
                ]}
            ></RoadMap>
            <Header>
                <PageHeaderV2 title="Monitoramento de Entregas"></PageHeaderV2>
            </Header>
            <FiltersContainer>
                <section>
                    <div className="DeliveryRouteFilter">
                        <Datepicker
                            callendarIcon
                            description="Data de entrega"
                            value={dateFilter}
                            onChange={(date: string) => {
                                setDateFilter(date),
                                    setParams({
                                        ...params,
                                        delivery_date:
                                            DateService.formatToRequest(
                                                new Date(date)
                                            ),
                                    })
                            }}
                        />
                    </div>
                    {/* {routes && routes.data ? (
                        <div className="DeliveryRouteFilter">
                            <Select
                                style={{ height: 55 }}
                                defaultValue={routeFilter}
                                description="Rota da entrega"
                                value={routeFilter}
                                value_key="id"
                                label_key="descricao"
                                blank_label="Selecione uma rota"
                                options={routes.data}
                                onChange={(e: any) => {
                                    setParams({
                                        ...params,
                                        route_id: e.target.value,
                                    })
                                }}
                            ></Select>
                        </div>
                    ) : null} */}
                </section>

                {/* <div>
                    <ButtonV3
                        Icon={<AiOutlineCloseCircle className="iconButtonV3" />}
                        title="Limpar filtros"
                        onClick={() => {
                            setParams({...params,
                                page: 1
                            })
                        }}
                    />
                </div> */}
            </FiltersContainer>
            <div>
                <div className="NewlistEditCampaign">
                    <table
                        style={{ marginBottom: 10 }}
                        className="newTableEditCampaign"
                    >
                        <thead>
                            <tr>
                                <th>Dia da entrega</th>
                                <th className="text-center">
                                    Volume confirmado
                                </th>
                                <th className="text-center">Volume pendente</th>
                                <th className="text-center">Volume ocupado</th>
                            </tr>
                        </thead>

                        <tbody>
                            {deliveries?.length > 0 &&
                                deliveries.map((i, index) => (
                                    <>
                                        <tr key={index}>
                                            <td
                                                data-label="Dia da entrega"
                                                className=""
                                            >
                                                {newFormatDate(i.delivery_date)}
                                            </td>
                                            <td
                                                data-label="Volume confirmado"
                                                className="text-center"
                                            >
                                                {i.date_siblings.reduce(
                                                    (k, v) =>
                                                        k + v.confirmed_volume,
                                                    0
                                                )}
                                            </td>
                                            <td
                                                data-label="Volume pendente"
                                                className="text-center"
                                            >
                                                {i.date_siblings.reduce(
                                                    (k, v) =>
                                                        k + v.pending_volume,
                                                    0
                                                )}
                                            </td>
                                            <td
                                                data-label="Volumes ocupado"
                                                className="text-center"
                                            >
                                                {i.date_siblings.reduce(
                                                    (k, v) =>
                                                        k +
                                                        v.confirmed_volume +
                                                        v.pending_volume,
                                                    0
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                        </tbody>
                    </table>

                    {deliveries?.length > 0 &&
                        deliveries.map((i, index) => (
                            <>
                                <table className="newTableEditCampaign">
                                    <thead>
                                        <tr>
                                            <th className=""> Nome/Rota </th>
                                            <th className="text-center">
                                                Volume confirmado
                                            </th>
                                            <th className="text-center">
                                                Volume pendente
                                            </th>
                                            <th className="text-center">
                                                Volume ocupado
                                            </th>
                                            <th className="text-center">
                                                N. Pedidos
                                            </th>
                                            <th className="text-center">
                                                {' '}
                                                Capacidade
                                            </th>
                                            <th className="text-center">
                                                Saldo
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {i.date_siblings.map((s, i) => (
                                            <tr key={`sibli-${i}`}>
                                                <td
                                                    style={{ paddingLeft: 8 }}
                                                    data-label="Nome/Rota"
                                                    className=""
                                                >
                                                    {s.route.descricao}
                                                </td>
                                                <td
                                                    data-label="Volume confirmado"
                                                    className="text-center"
                                                >
                                                    {s.confirmed_volume}
                                                </td>
                                                <td
                                                    data-label="Volume pendente"
                                                    className="text-center"
                                                >
                                                    {s.pending_volume}
                                                </td>
                                                <td
                                                    data-label="Volumes ocupado"
                                                    className="text-center"
                                                >
                                                    {s.confirmed_volume +
                                                        s.pending_volume}
                                                </td>
                                                <td
                                                    data-label="N. Pedidos"
                                                    className="text-center"
                                                    style={{ minHeight: 31 }}
                                                >
                                                    <OpenOrdersButton
                                                        onClick={() =>
                                                            show_orders(s.id)
                                                        }
                                                    >
                                                        <span>
                                                            {s.orders_count}
                                                        </span>
                                                    </OpenOrdersButton>
                                                </td>
                                                <ActionTd
                                                    data-label="Capacidade"
                                                    className=""
                                                >
                                                    <EditTd>
                                                        {editRoute !== s ? (
                                                            <p
                                                                style={{
                                                                    width: 35,
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                {s.capacity}
                                                            </p>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {editRoute == s ? (
                                                            <Input
                                                                onKeyDown={
                                                                    keyEvent
                                                                }
                                                                value={
                                                                    newCapacity ==
                                                                    null
                                                                        ? s.capacity
                                                                        : newCapacity
                                                                }
                                                                onChange={(e) =>
                                                                    setNewCapacity(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                type={'number'}
                                                                style={{
                                                                    maxWidth: 60,
                                                                    '-webkit-appearance':
                                                                        'none',
                                                                }}
                                                            ></Input>
                                                        ) : null}
                                                        <ButtonsEdit
                                                            isOdd={
                                                                index % 2 == 0
                                                            }
                                                        >
                                                            {editRoute == s ? (
                                                                <>
                                                                    <section
                                                                        style={{
                                                                            marginLeft: 8,
                                                                        }}
                                                                    >
                                                                        <IconReact
                                                                            onClick={() =>
                                                                                cleanEdit()
                                                                            }
                                                                            color={
                                                                                'red'
                                                                            }
                                                                            type="closeCircle"
                                                                            size={
                                                                                22
                                                                            }
                                                                        ></IconReact>
                                                                    </section>
                                                                    <div
                                                                        style={{
                                                                            borderRight:
                                                                                '1px solid rgba(0,0,0,0.4) ',
                                                                            height: '70%',
                                                                        }}
                                                                    ></div>
                                                                    <section
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                    >
                                                                        <IconReact
                                                                            onClick={() =>
                                                                                handleSaveNewCapacity()
                                                                            }
                                                                            color={
                                                                                'green'
                                                                            }
                                                                            type="checkCircle"
                                                                            size={
                                                                                22
                                                                            }
                                                                        ></IconReact>
                                                                    </section>
                                                                </>
                                                            ) : (
                                                                <IconEdit
                                                                    onClick={() =>
                                                                        setEditRoute(
                                                                            s
                                                                        )
                                                                    }
                                                                    type="edit"
                                                                    size={22}
                                                                />
                                                            )}
                                                        </ButtonsEdit>
                                                    </EditTd>
                                                </ActionTd>
                                                <td
                                                    data-label="Saldo"
                                                    className="text-center"
                                                >
                                                    {s.capacity -
                                                        (s.confirmed_volume +
                                                            s.pending_volume)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ))}

                    {/* <table className="newTableEditCampaign">
            <thead>
              <tr>
                <th>Dia da entrega</th>
                <th className="text-center">
                  Volume confirmado
                </th>
                <th className="text-center">Volume pendente</th>
                <th className="text-center">Volume ocupado</th>
              </tr>
            </thead>
            {false ? (
              <Loader />
            ) : (
              <tbody>
                {deliveries?.length > 0 &&
                  deliveries.map((i, index) => (
                    <>
                      <tr key={index}>
                        <td data-label="Dia da entrega" className="">
                          {newFormatDate(
                            i.delivery_date
                          )}
                        </td>
                        <td data-label="Volume confirmado" className="text-center">
                          {i.date_siblings.reduce((k, v) => k + v.confirmed_volume, 0)}
                        </td>
                        <td data-label="Volume pendente" className="text-center">
                          {i.date_siblings.reduce((k, v) => k + v.pending_volume, 0)}
                        </td>
                        <td data-label="Volumes ocupado" className="text-center" >
                          {i.date_siblings.reduce((k, v) => k + v.confirmed_volume + v.pending_volume, 0)}
                        </td>
                      </tr>
                      <tr key={`dat-${index}`}>
                        <td colSpan={4}>
                          <table className="newTableEditCampaign">
                            <thead>
                              <tr>
                                <th className=""> Nome/Rota </th>
                                <th className="text-center">Volume confirmado
                                </th>
                                <th className="text-center">Volume pendente
                                </th>
                                <th className="text-center">Volume ocupado</th>
                                <th className="text-center">N. Pedidos</th>
                                <th className="text-center"> Capacidade</th>
                                <th className="text-center">Saldo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {i.date_siblings.map((s, i) => (
                                <tr key={`sibli-${i}`} >
                                  <td style={{ paddingLeft: 8, }} data-label="Nome/Rota" className="">
                                    {s.route.descricao}
                                  </td>
                                  <td data-label="Volume confirmado" className="text-center">
                                    {s.confirmed_volume}
                                  </td>
                                  <td data-label="Volume pendente" className="text-center" >
                                    {s.pending_volume}
                                  </td>
                                  <td data-label="Volumes ocupado" className="text-center">
                                    {s.confirmed_volume + s.pending_volume}
                                  </td>
                                  <td data-label="N Pedidos" className="text-center">
                                    <span onClick={() => show_orders(s.id)}>
                                      {s.orders_count}
                                    </span>
                                  </td>
                                  <ActionTd data-label="Capacidade" className="">
                                    <EditTd>
                                      {editRoute !== s ? (
                                        <p style={{ width: 35, textAlign: 'center', }} >
                                          {s.capacity}
                                        </p>
                                      ) : (
                                        <>

                                        </>
                                      )}
                                      {editRoute == s ? (
                                        <Input onKeyDown={keyEvent}
                                          value={newCapacity == null ? s.capacity : newCapacity}
                                          onChange={(e) => setNewCapacity(e.target.value)}
                                          type={'number'}
                                          style={{ maxWidth: 60, '-webkit-appearance': 'none', }}
                                        ></Input>
                                      ) : null}
                                      <ButtonsEdit
                                        isOdd={index % 2 == 0}
                                      >
                                        {editRoute == s ? (
                                          <>
                                            <section
                                              style={{ marginLeft: 8, }}
                                            >
                                              <IconReact onClick={() => cleanEdit()} color={'red'} type="closeCircle" size={22}
                                              ></IconReact>
                                            </section>
                                            <div
                                              style={{ borderRight: '1px solid rgba(0,0,0,0.4) ', height: '70%', }}
                                            ></div>
                                            <section style={{ marginRight: 8, }}>
                                              <IconReact onClick={() => handleSaveNewCapacity()} color={'green'} type="checkCircle" size={22} ></IconReact>
                                            </section>
                                          </>
                                        ) : (
                                          <IconEdit onClick={() => setEditRoute(s)}
                                            type="edit"
                                            size={22}
                                          />
                                        )}
                                      </ButtonsEdit>
                                    </EditTd>
                                  </ActionTd>
                                  <td data-label="Saldo" className="text-center">
                                    {s.capacity - (s.confirmed_volume + s.pending_volume)}
                                  </td>
                                </tr>
                              )
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            )}
          </table> */}
                    {/* <table className="newTableEditCampaign">
            <thead>
              <tr>
                <th>Dia da entrega</th>
                <th className="text-center">
                  Volume confirmado
                </th>
                <th className="text-center">Volume pendente</th>
                <th className="text-center">Volume ocupado</th>
              </tr>
            </thead>
            {false ? (
              <Loader />
            ) : (
              <tbody>
                {deliveries?.length > 0 &&
                  deliveries.map((i, index) => (
                    <>
                      <tr key={index}>
                        <td data-label="Dia da entrega" className="">
                          {newFormatDate(
                            i.delivery_date
                          )}
                        </td>
                        <td data-label="Volume confirmado" className="text-center">
                          {i.date_siblings.reduce((k, v) => k + v.confirmed_volume, 0)}
                        </td>
                        <td data-label="Volume pendente" className="text-center">
                          {i.date_siblings.reduce((k, v) => k + v.pending_volume, 0)}
                        </td>
                        <td data-label="Volumes ocupado" className="text-center" >
                          {i.date_siblings.reduce((k, v) => k + v.confirmed_volume + v.pending_volume, 0)}
                        </td>
                      </tr>
                      <tr key={`dat-${index}`}>
                        <td colSpan={4}>
                          <table className="newTableEditCampaign">
                            <thead>
                              <tr>
                                <th className=""> Nome/Rota </th>
                                <th className="text-center">Volume confirmado
                                </th>
                                <th className="text-center">Volume pendente
                                </th>
                                <th className="text-center">Volume ocupado</th>
                                <th className="text-center">N. Pedidos</th>
                                <th className="text-center"> Capacidade</th>
                                <th className="text-center">Saldo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {i.date_siblings.map((s, i) => (
                                <tr key={`sibli-${i}`} >
                                  <td style={{ paddingLeft: 8, }} data-label="Nome/Rota" className="">
                                    {s.route.descricao}
                                  </td>
                                  <td data-label="Volume confirmado" className="text-center">
                                    {s.confirmed_volume}
                                  </td>
                                  <td data-label="Volume pendente" className="text-center" >
                                    {s.pending_volume}
                                  </td>
                                  <td data-label="Volumes ocupado" className="text-center">
                                    {s.confirmed_volume + s.pending_volume}
                                  </td>
                                  <td data-label="N Pedidos" className="text-center">
                                    <span onClick={() => show_orders(s.id)}>
                                      {s.orders_count}
                                    </span>
                                  </td>
                                  <ActionTd data-label="Capacidade" className="">
                                    <EditTd>
                                      {editRoute !== s ? (
                                        <p style={{ width: 35, textAlign: 'center', }} >
                                          {s.capacity}
                                        </p>
                                      ) : (
                                        <>

                                        </>
                                      )}
                                      {editRoute == s ? (
                                        <Input onKeyDown={keyEvent}
                                          value={newCapacity == null ? s.capacity : newCapacity}
                                          onChange={(e) => setNewCapacity(e.target.value)}
                                          type={'number'}
                                          style={{ maxWidth: 60, '-webkit-appearance': 'none', }}
                                        ></Input>
                                      ) : null}
                                      <ButtonsEdit
                                        isOdd={index % 2 == 0}
                                      >
                                        {editRoute == s ? (
                                          <>
                                            <section
                                              style={{ marginLeft: 8, }}
                                            >
                                              <IconReact onClick={() => cleanEdit()} color={'red'} type="closeCircle" size={22}
                                              ></IconReact>
                                            </section>
                                            <div
                                              style={{ borderRight: '1px solid rgba(0,0,0,0.4) ', height: '70%', }}
                                            ></div>
                                            <section style={{ marginRight: 8, }}>
                                              <IconReact onClick={() => handleSaveNewCapacity()} color={'green'} type="checkCircle" size={22} ></IconReact>
                                            </section>
                                          </>
                                        ) : (
                                          <IconEdit onClick={() => setEditRoute(s)}
                                            type="edit"
                                            size={22}
                                          />
                                        )}
                                      </ButtonsEdit>
                                    </EditTd>
                                  </ActionTd>
                                  <td data-label="Saldo" className="text-center">
                                    {s.capacity - (s.confirmed_volume + s.pending_volume)}
                                  </td>
                                </tr>
                              )
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            )}
          </table> */}
                    {response && (
                        <PaginationCustom
                            total={response.last_page - 1}
                            itemsName={'produtos'}
                            ItemsPorPage="25"
                            currentPage={response.current_page}
                            onPageChange={(page: number) =>
                                setParams({ ...params, page })
                            }
                            totalItems={response.total}
                        />
                    )}
                    <OrdersModal
                        route_delivery_id={current_route_delivery}
                        onClose={() => setCurrentRouteDelivery(null)}
                    />
                </div>
            </div>
        </ProjectContainer>
    )
}
