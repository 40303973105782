import React, { FC, useEffect, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import './index.scss'
import { Loader } from 'components/loader'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { BsSearch } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'
import { EditModal } from '../../../edit-modal'
import { FiEdit2 } from 'react-icons/fi'
import { UserContext } from 'contexts/user'
import DirectSaleProductService from 'services/DirectSaleProductService'
import { CampaignService } from 'services/v2/campaigns.service'
import ButtonV3 from 'components/Buttonv3'
import InputV2 from 'components/InputV2'
import IconReact from 'components/icons'

export const ProductsToCampaign = ({ setShowNav, campaign, nav }: any) => {
    const initialQueryParams = {
        product_name: '',
    }

    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [products, setProducts] = useState<any>([])
    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [params, setParams] = useState<any>()
    const [product, setProduct] = useState<any>()

    const filter = useCallback(async () => {
        if (user) {
            if (campaign) {
                setLoading(true)
                CampaignService.show_itens(campaign.id, queryParams)
                    .then((response: any) => {
                        setProducts(response.data)
                    })
                    .then(() => setLoading(false))
            }
        }
    }, [user, campaign, queryParams])

    useEffect(() => {
        if (user && nav == 'P') {
            filter()
        }
    }, [user, filter, nav])

    const update = (name: any) => {
        setParams({ product_name: name })
    }

    const filterName = () => {
        setQueryParams(params)
    }

    const delete_product = (product: any) => {
        CampaignService.delete_item(campaign.id, product.pro_id).then(
            (response: any) => {
                filter()
            }
        )
    }

    const updateProduct = (product: any, index: number) => {
        product.index = index
        setProduct(product)
    }

    const updateProdutToSave = () => {
        filter()
    }

    const showValueUnit = (packing: any, valuePacking: number) => {
        const unitPacking = packing.split(' ')[1]
        return (valuePacking / unitPacking).toFixed(2)
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-end',
                    gap: 20,
                }}
            >
                <div style={{ width: 300 }}>
                    <InputV2
                        infoIconNull
                        icon={<IconReact type="search"></IconReact>}
                        mask={undefined}
                        id={'Buscar'}
                        label={'Buscar'}
                        value={undefined}
                        type={'text'}
                        placeholder={'Insira o nome do produto'}
                        onChange={(e: any) => update(e.target.value)}
                    />
                </div>
                <div>
                    <ButtonV3
                        title="Buscar Produto"
                        onClick={() => filterName()}
                    />
                    {
                        //  <Button className="btn-large btn-azul" onClick={() => filterName()}>
                        //      <BsSearch />
                        //      Buscar Produto
                        //  </Button>
                    }
                </div>
            </div>

            {!loading ? (
                <div className="listDetailCampaign">
                    <table className="newTableDetailCampaign">
                        <thead>
                            <tr>
                                <th>PRODUTOS CADASTRADOS</th>
                                <th className="text-center">EMBALAGEM</th>
                                <th className="text-center">PREÇO</th>
                                <th className="text-center">PREÇO UNIT</th>
                                <th>OBSERVAÇÕES</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {products
                                .sort((a, b) =>
                                    a.pro_descricao.localeCompare(
                                        b.pro_descricao
                                    )
                                )
                                .map((p, index) => (
                                    <tr key={p.pro_id}>
                                        <td
                                            data-label="PRODUTOS CADASTRADOS"
                                            className="produto"
                                        >
                                            {p.pro_descricao}
                                        </td>
                                        <td
                                            data-label="EMBALAGEM"
                                            className="text-center"
                                        >
                                            {p.pro_embalagem}
                                        </td>
                                        <td
                                            data-label="PREÇO"
                                            className="text-center detalhes-azul"
                                        >
                                            R${' '}
                                            {p.valor_embalagem
                                                ? parseFloat(
                                                      `${p.valor_embalagem}`
                                                  ).toFixed(2)
                                                : '0.00'}
                                        </td>
                                        <td
                                            data-label="PREÇO UNIT"
                                            className="text-center detalhes-azul"
                                        >
                                            R${' '}
                                            {p.valor_embalagem
                                                ? showValueUnit(
                                                      p.pro_embalagem,
                                                      p.valor_embalagem
                                                  )
                                                : '0.00'}
                                        </td>
                                        <td data-label="OBSERVAÇÕES">
                                            {p.observacao ? p.observacao : '--'}
                                        </td>

                                        <td data-label="Ações">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 20,
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {
                                                    //   <button title="deletar" className={p.valor_embalagem ? 'btn-acoes-deletar' : 'btn-acoes-deletar hidden'} onClick={() => delete_product(p)}>
                                                    //       <AiOutlineClose />
                                                    //   </button>
                                                }
                                                {p.valor_embalagem && (
                                                    <IconReact
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        title={'Excluir'}
                                                        onClick={() =>
                                                            delete_product(p)
                                                        }
                                                        size={20}
                                                        color={'var(--primary)'}
                                                        type="trash"
                                                    />
                                                )}

                                                <button
                                                    title="mostrar"
                                                    className="btn-acoes-editar"
                                                    data-toggle="modal"
                                                    data-target="#edit-product-modal"
                                                    onClick={() =>
                                                        updateProduct(p, index)
                                                    }
                                                >
                                                    <IconReact
                                                        size={20}
                                                        color={'#505050'}
                                                        type="edit"
                                                    />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        <EditModal
                            campaign={campaign}
                            productModal={product}
                            onCloseCallback={updateProdutToSave}
                        />
                    </table>
                    {
                        //    <div className="btn-right">
                        //        <Button className="btn-large btn-laranja" onClick={() => setShowNav("I")}>
                        //            Voltar
                        //        </Button>
                        //
                        //        <Button className="btn-large btn-verde" onClick={() => setShowNav("C")}>
                        //            <i className="material-icons">done</i>
                        //            Salvar e Continuar
                        //        </Button>
                        //    </div>
                    }
                </div>
            ) : (
                <Loader type="inline" />
            )}
        </div>
    )
}

export default connect()(ProductsToCampaign)
