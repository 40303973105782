import './index.scss'

import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import { useCallback, useEffect, useState } from 'react'
import { Loader } from 'components/loader'
import ButtonV3 from 'components/Buttonv3'
import { FeedbackError } from 'components/feedback/error'
import CustomerService from 'services/CustomerService'
import { ClientsRepresentative } from 'interfaces/clientsRepresentative'
import RepresentativeService from 'services/RepresentativesService'
import { Representative } from 'interfaces/representative'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PaginationCustom } from 'components/pagination-custom'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { mask_cnpj } from 'helpers/mask.helper'
import ToggleButton from 'react-toggle-button'
import { BaseModal } from 'components/BaseModal'
import { SellerParticipationDetailsModal } from 'components/seller-participationDetailModal'
import { useTheme } from 'contexts/whiteLabel/context'

type RepresentativeClientsProps = {
    isVisible: boolean
    onClose: () => void
    user?: any
    sellerId: any
}

export const RepresentativeClientsModal = ({
    isVisible,
    onClose,
    user,
    sellerId,
}: RepresentativeClientsProps) => {
    const MySwal = withReactContent(Swal)
    const { theme } = useTheme()
    const [loading, setLoading] = useState<boolean>(true)
    const [clients, setClients] = useState<ClientsRepresentative[]>([])
    const [seller, setSeller] = useState<Representative>()
    const [params, setParams] = useState({
        vendedor_id: sellerId,
        attending: true,
        page: 1,
    })

    const [pagination, setPagination] = useState({
        current_page: 1,
        total: 0,
        per_page: 10,
        last_page: 0,
    })

    const [
        show_seller_participation_details,
        setShowSellerParticipationDetails,
    ] = useState<boolean>(false)

    const [currentRequest, setCurrentRequest] =
        useState<ClientsRepresentative>()

    const modal = {
        icon: <GrGroup color="white" />,
        styles: { maxWidth: '80vw' },
        id: 'representative-clients-modal',
        title: `Clientes do vendedor`,
    }
    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible, user])

    const get_seller = useCallback(
        () =>
            RepresentativeService.getById(sellerId as string).then(
                (response: Representative) => setSeller(response)
            ),
        [sellerId]
    )

    useEffect(() => {
        get_seller()
    }, [get_seller, sellerId])

    useEffect(() => {
        fetchClients()
    }, [sellerId, params])

    async function fetchClients() {
        setLoading(true)
        try {
            const response =
                await RepresentativeService.getClientsRepresentative({
                    params,
                })
            setClients(response.data)
            setPagination(response.meta)
        } catch (error) {
            console.error('Erro ao buscar clientes:', error)
        } finally {
            setLoading(false)
        }
    }

    const changeStatus = (client: ClientsRepresentative) => {
        MySwal.fire({
            title: (
                <div>
                    <p>Você irá desassociar este cliente. Deseja continuar?</p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
        }).then((res) => {
            if (res.isConfirmed) {
                CustomerService.associate(
                    parseInt(sellerId as string),
                    client.id as string,
                    client.status
                )
                    .then(() => {
                        setClients((prevClients) =>
                            prevClients.filter((c) => c.id !== client.id)
                        )

                        MySwal.fire(<p>Cliente desassociado com sucesso!</p>)
                        fetchClients()
                    })
                    .catch((error) => {
                        console.error('Erro ao desassociar cliente:', error)
                        MySwal.fire(
                            <p>
                                Ocorreu um erro ao desassociar o cliente. Tente
                                novamente.
                            </p>
                        )
                    })
            }
        })
    }

    const get_participation_class = (participation) => {
        if (participation <= 0.3) {
            return 'low'
        } else if (participation > 0.7) {
            return 'high'
        } else {
            return 'medium'
        }
    }

    function renderParticipationValue(sellers, sellerId) {
        const seller = sellers.find((seller) => seller.id === sellerId)
        return seller?.pivot?.participation ?? 0
    }

    function renderParticipation(sellers, sellerId) {
        const participation = renderParticipationValue(sellers, sellerId)

        if (participation !== undefined) {
            return (
                <span
                    className={[
                        'div_participation',
                        get_participation_class(participation),
                    ].join(' ')}
                >
                    {(participation * 100).toFixed(2)}%
                </span>
            )
        }

        return null
    }

    const open_participation_details = (item) => {
        setCurrentRequest(item)
        setShowSellerParticipationDetails(true)
    }

    return (
        <div
            style={{
                display: 'flex',
                padding: '10px 30px 30px',
                flexDirection: 'column',
                minHeight: '25vh',
                justifyContent: 'space-between',
                margin: '0 auto',
            }}
        >
            {loading ? (
                <div className="newListCLientsRepresentativesModal">
                    <Loader />
                </div>
            ) : clients.length > 0 ? (
                <div className="newListCLientsRepresentativesModal">
                    <table style={{ marginBottom: 30 }} className="newTable">
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>CNPJ</th>
                                <th>Estado/Cidade</th>
                                <th>Segmento</th>
                                <th>Contato</th>
                                <th className="text-center">Telefone</th>
                                <th className="text-center">Participação</th>
                                <th className="text-center">
                                    Ativar / Desativar
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((item: ClientsRepresentative) => (
                                <tr key={item.id}>
                                    <td data-label="Cliente">{item.name}</td>
                                    <td data-label="CNPJ">
                                        {item.cnpj ? mask_cnpj(item.cnpj) : ''}
                                    </td>
                                    <td data-label="Estado/Cidade">
                                        <div style={{ marginBottom: 3 }}>
                                            {item.head_office?.state?.name ||
                                                ''}
                                        </div>
                                        <div>
                                            {item.head_office?.city.name || ''}
                                        </div>
                                    </td>
                                    <td data-label="Segmento">
                                        {item.sectors?.[0]?.name || ''}
                                    </td>
                                    <td data-label="Nome Contato">
                                        {item.contact
                                            ? item.contact.split(' ').length ===
                                              1
                                                ? item.contact
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  item.contact.slice(1)
                                                : item.contact
                                                      .split(' ')
                                                      .slice(1, 3)
                                                      .map((word, index) =>
                                                          index === 0
                                                              ? word
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                word.slice(1)
                                                              : word
                                                      )
                                                      .join(' ')
                                            : 'N/A'}
                                    </td>
                                    <td
                                        data-label="Telefone"
                                        className="td_telefone"
                                    >
                                        <div>
                                            <PhoneWithWhats
                                                phone={item.phone || ''}
                                            />
                                        </div>
                                    </td>
                                    <td
                                        data-label="Participação"
                                        className="td_Participacao"
                                    >
                                        <div
                                            onClick={() => {
                                                if (
                                                    renderParticipationValue(
                                                        item.sellers,
                                                        sellerId
                                                    ) !== 0
                                                ) {
                                                    open_participation_details(
                                                        item
                                                    )
                                                }
                                            }}
                                            title={
                                                renderParticipationValue(
                                                    item.sellers,
                                                    sellerId
                                                ) === 0
                                                    ? 'Não possui histórico de participação'
                                                    : 'Ver histórico de participação'
                                            }
                                            style={{
                                                cursor:
                                                    renderParticipationValue(
                                                        item.sellers,
                                                        sellerId
                                                    ) === 0
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                            }}
                                        >
                                            {renderParticipation(
                                                item.sellers,
                                                sellerId
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        data-label="Ativar/Desativar"
                                        className="text-center"
                                    >
                                        <div
                                            title="Desassociar este cliente"
                                            className={'tooltip-icon'}
                                        >
                                            <ToggleButton
                                                inactiveLabel={''}
                                                activeLabel={null}
                                                colors={{
                                                    activeThumb: {
                                                        base: 'white',
                                                    },
                                                    inactiveThumb: {
                                                        base: 'white',
                                                    },
                                                    active: {
                                                        base: `${
                                                            theme.colors
                                                                ?.primary ||
                                                            '#FF7110'
                                                        }`,
                                                        hover: `${
                                                            theme.colors
                                                                ?.primary ||
                                                            '#FF7110'
                                                        }`,
                                                    },
                                                    inactive: {
                                                        base: '#e2e2e2',
                                                        hover: '#d9d9d9',
                                                    },
                                                }}
                                                value={item.status}
                                                onToggle={() => {
                                                    changeStatus(item)
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <PaginationCustom
                        total={
                            pagination?.last_page
                                ? Math.max(1, pagination.last_page - 1)
                                : 1
                        }
                        currentPage={pagination?.current_page || 1}
                        onPageChange={(newPage) =>
                            setParams((prev) => ({
                                ...prev,
                                page: newPage,
                            }))
                        }
                        totalItems={pagination?.total}
                        ItemsPorPage={pagination?.per_page}
                        itemsName={'clientes'}
                    />
                </div>
            ) : (
                <div style={{ marginTop: 15 }}>
                    <FeedbackError message="Nenhum cliente associado foi encontrado no momento." />
                </div>
            )}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '98%',
                    marginTop: 10,
                }}
            >
                <ButtonV3
                    filled
                    title="Voltar"
                    onClick={() => {
                        onClose()
                    }}
                />
            </div>
            {show_seller_participation_details && (
                <BaseModal
                    modalBodyStyle={{ height: 'unset' }}
                    title={`#${seller?.name} - ${currentRequest?.group_name}`}
                    isVisible={show_seller_participation_details}
                    onCloseCallback={() =>
                        setShowSellerParticipationDetails(false)
                    }
                    children={
                        <SellerParticipationDetailsModal
                            sellerId={seller?.id}
                            clientName={currentRequest?.group_name}
                            clientId={currentRequest?.id}
                            sellerName={seller?.name}
                            setShowSellerParticipationDetails={
                                setShowSellerParticipationDetails
                            }
                        />
                    }
                />
            )}
        </div>
    )
}
