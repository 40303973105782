import './index.scss'

import React, {
    useEffect,
    useState,
    FunctionComponent,
    useLayoutEffect,
} from 'react'

import { IoMdCloseCircle } from 'react-icons/io'

import { Modal } from 'react-bootstrap'
import './index.scss'

export const BaseModal = ({
    children,
    isVisible,
    data,
    onCloseCallback,
    submit,
    title,
    modalBodyStyle,
    dialogClassName,
    icon,
}: any) => {
    const [modal, setModal] = useState<any>({})

    useEffect(() => {
        setModal({
            title: title,
            //id: 'representatives-modal',
            submit: submit,
            styles: {
                maxWidth: '80vw',
            },
            onClose: onCloseCallback,
        })
    }, [data, onCloseCallback])

    return (
        <Modal
            //overflw hidden adicionado pare evitar conflito de scroll no modal aberto
            // style={{overflow:'hidden'}}
            show={isVisible}
            onHide={onCloseCallback}
            animation={true}
            dialogClassName={dialogClassName ? dialogClassName : 'modal-90w'}
        >
            <div className="modalHeader">
                <h2 className="modalTitle">
                    {icon && icon}
                    {modal.title}
                </h2>
                <div>
                    <IoMdCloseCircle
                        className={'closeIcon'}
                        onClick={onCloseCallback}
                    />
                </div>
            </div>

            <div
                style={modalBodyStyle ? modalBodyStyle : {}}
                className="modalBody"
            >
                {children}
            </div>
        </Modal>
    )
}
