import { FC, useEffect, useState } from 'react'
import './index.scss'
import RequestServiceV3 from 'services/v3/request.service'
import { Loader } from 'components/loader'
import { FaBoxes } from 'react-icons/fa'
import { filter_product_description } from 'pages/request/components/requested-products/requested-products.helper'
import { BsCheckAll } from 'react-icons/bs'
import ProductModel from 'models/product.model'
import IconReact from 'components/icons'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import InputV2 from 'components/InputV2'
import { useTheme } from 'contexts/whiteLabel/context'

export const QuotationsProductsPreviewModal: FC<any> = ({
    request,
    setQuotationsProductsPreview,
    sellerId,
}: any) => {
    const [modal, setModal] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState<any>([])
    const [params, setParams] = useState<any>({})
    const { theme } = useTheme()

    useEffect(() => {
        setModal({
            title: (
                <span>
                    Produtos da cotação <b>#{request.id}</b>
                </span>
            ),
            icon: <FaBoxes />,
        })
    }, [request])

    useEffect(() => {
        RequestServiceV3.load_request_products_withoutPaginate(
            request.request_id,
            sellerId
        )
            .then(async (response: any) => {
                response.forEach((p: any) => {
                    p.derivatives = p.derivatives.map(
                        (pp: any) => new ProductModel(pp)
                    )
                })
                const prods: any = response.map((p: any) => new ProductModel(p))
                prods.forEach((p: ProductModel) => (p.visible = true))
                prods
                    .filter((p: ProductModel) => p && !p.is_generic)
                    .forEach(
                        (p: ProductModel) =>
                            (p.derivatives = [
                                new ProductModel({ ...p, main_product: p }),
                            ])
                    )
                return RequestServiceV3.load_request_last_offers_by_seller(
                    request.request_id,
                    sellerId
                ).then((result: any) => {
                    prods.forEach((p: ProductModel) => {
                        p.process_last_offer(result)
                    })
                    setProducts(prods)
                })
            })
            .then(() => setLoading(false))
    }, [request])

    useEffect(() => {
        const prods = products.slice(0)
        products.forEach((p: ProductModel) => (p.visible = false))
        products
            .filter(
                (p: ProductModel) =>
                    (params.checked && p.last_offer_price > 0) ||
                    !params.checked
            )
            .filter((p: ProductModel) =>
                filter_product_description(params.name, p)
            )
            .forEach((p: ProductModel) => (p.visible = true))
        setProducts(prods)
    }, [params])

    const close = () => {
        setQuotationsProductsPreview(false)
    }

    return loading ? (
        <Loader />
    ) : (
        <div id="preview-products">
            <div style={{ padding: 25 }}>
                <div className="divCompanyName">
                    Empresa:{' '}
                    <b className="company-name">{request.client_name}</b>
                </div>
                <div style={{ marginTop: 15 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                            gap: 10,
                            flexWrap: 'wrap',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                gap: 15,
                                flexWrap: 'wrap',
                            }}
                        >
                            <div style={{ minWidth: 300 }}>
                                <InputV2
                                    infoIconNull
                                    icon={
                                        <IconReact
                                            color={'#898989'}
                                            className="iconButtonV3"
                                            type="search"
                                        ></IconReact>
                                    }
                                    mask={undefined}
                                    id={'filtrar'}
                                    type={'text'}
                                    placeholder={'Filtrar produto'}
                                    onChange={(evt: any) =>
                                        setParams({
                                            ...params,
                                            name: evt.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div style={{ minWidth: 165 }}>
                                <CheckBoxCustom
                                    checked={params.checked}
                                    description="Apenas meus produtos"
                                    value={params.checked}
                                    change={(e, stats: any) =>
                                        setParams({ ...params, checked: stats })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="listProductsPreview">
                    <table className="newTableProductsPreview">
                        <thead>
                            <tr>
                                <th>Produto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products &&
                                products
                                    .filter(
                                        (prod: ProductModel) => prod.visible
                                    )
                                    .map((prod: ProductModel) => (
                                        <tr key={prod.id}>
                                            <td>
                                                <div>
                                                    <BsCheckAll
                                                        color={
                                                            theme?.colors
                                                                ?.primary ||
                                                            '#ff7110'
                                                        }
                                                        size={20}
                                                        style={{
                                                            visibility:
                                                                prod.last_offer_price >
                                                                0
                                                                    ? 'visible'
                                                                    : 'hidden',

                                                            marginRight: 5,
                                                        }}
                                                        title="Meus Produtos"
                                                    />
                                                    {prod.name}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
