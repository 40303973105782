import './index.scss'
import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import { useEffect, useState } from 'react'
import ProductService from 'services/ProductService'
import { Loader } from 'components/loader'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import ButtonV3 from 'components/Buttonv3'
import { RiFileExcel2Line } from 'react-icons/ri'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'
import { FaFileDownload } from 'react-icons/fa'

type ExcelByPriceLevelsProps = {
    isVisible: boolean
    onClose: () => void
    user?: any
}

interface ILevelsPrices {
    descricao: string
    nivel: number
}

interface IProducts {
    pro_id: number
    pro_descricao: string
    pro_embalagem: string
    id_nivel: number
    descricao: string
    preco: string
}

export const ExportExcelByPriceLevels = ({
    isVisible,
    onClose,
    user,
}: ExcelByPriceLevelsProps) => {
    const [loading, setLoading] = useState(false)
    const [levels, setLevels] = useState<ILevelsPrices[]>([])
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<Set<number>>(
        new Set()
    )
    const [areCheckboxesDisabled, setAreCheckboxesDisabled] = useState(false)

    const MAX_SELECTION = 5

    const modal = {
        icon: <GrGroup color="white" />,
        styles: { maxWidth: '80vw' },
        id: 'export-excel-to-levels-price',
        title: `Exportar Excel por niveis de preço`,
    }

    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id)
                .removeClass('fadeOutUp')
                .addClass('fadeIn')
            $('#' + modal.id).modal('show')
            loadLevelsProduct()
        } else {
            $('#' + modal.id)
                .removeClass('fadeIn')
                .addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible, user])

    const loadLevelsProduct = () => {
        if (!user) return
        setLoading(true)
        ProductService.getLevelsProduct(user.providerId)
            .then((result) => {
                setLevels(result.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleCheckboxChange = (levelId: number) => {
        setSelectedCheckboxes((prevState) => {
            const updatedSet = new Set(prevState)

            if (updatedSet.has(levelId)) {
                updatedSet.delete(levelId)
                // console.log('Desmarcado e removido do Set:', levelId)
            } else {
                if (updatedSet.size < MAX_SELECTION) {
                    updatedSet.add(levelId)
                    // console.log('Marcado e adicionado ao Set:', levelId)
                } else {
                    console.log(
                        'Limite de seleção atingido, não adicionado:',
                        levelId
                    )
                }
            }

            const selectedCount = updatedSet.size
            setAreCheckboxesDisabled(selectedCount >= MAX_SELECTION)

            return updatedSet
        })
    }

    useEffect(() => {
        handleCheckboxSelectionCount()
    }, [selectedCheckboxes])

    const handleCheckboxSelectionCount = () => {
        const selectedCount = selectedCheckboxes.size
        setAreCheckboxesDisabled(selectedCount >= MAX_SELECTION)
    }

    const handleExport = () => {
        const selectedIds = getSelectedIds()

        if (!selectedIds.length) {
            toast.info('Por favor, selecione ao menos um nível para exportar.')
            return
        }

        fetchProductDataForExport(selectedIds)
            .then((products: IProducts[]) => {
                if (!products || products.length === 0) {
                    toast.info('Nenhum produto encontrado para exportação.')
                    return
                }

                const productMap = groupProductsById(products)
                const header = createHeader(selectedIds)
                const data = createTableData(productMap, selectedIds, header)

                const xlsbin = generateExcelFile(data)
                downloadExcelFile(xlsbin)

                toast.success('Planilha de produtos gerada com sucesso!')
                setTimeout(() => onClose(), 3000)
            })
            .catch((error) => {
                console.error(error)
                toast.error('Erro ao gerar a planilha. Tente novamente.')
            })
    }

    // Função para obter os IDs selecionados
    const getSelectedIds = () => {
        return Array.from(selectedCheckboxes).map(Number)
    }

    // Função para buscar os dados dos produtos
    const fetchProductDataForExport = (selectedIds) => {
        return ProductService.getInfoProductsForExportExcel(
            user.providerId,
            selectedIds.join(',')
        ).then((result) => result.data)
    }

    // Função para agrupar produtos por ID
    const groupProductsById = (products: IProducts[]) => {
        const productMap = new Map()

        products.forEach((product) => {
            const { pro_id, pro_descricao, pro_embalagem, id_nivel, preco } =
                product
            const proIdString = String(pro_id)

            if (!productMap.has(proIdString)) {
                productMap.set(proIdString, {
                    pro_id: proIdString,
                    pro_descricao,
                    pro_embalagem,
                    precos: {},
                })
            }

            productMap.get(proIdString).precos[id_nivel] = parseFloat(preco)
        })

        return productMap
    }

    // Função para criar o cabeçalho da tabela
    const createHeader = (selectedIds) => {
        return [
            'CÓDIGO',
            'PRODUTO',
            'TIPO EMBALAGEM',
            ...selectedIds.map((id) => {
                const level = levels.find((l) => l.nivel === id)
                return level ? level.descricao : `Nível ${id}`
            }),
        ]
    }

    // Função para criar os dados da tabela
    const createTableData = (productMap, selectedIds, header) => {
        const data = [header]

        productMap.forEach((product) => {
            const row = [
                product.pro_id,
                product.pro_descricao,
                product.pro_embalagem,
                ...selectedIds.map((id) => product.precos[id] || '--'),
            ]
            data.push(row)
        })

        return data
    }

    // Função para gerar o arquivo Excel
    const generateExcelFile = (data) => {
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.aoa_to_sheet(data)

        // Ajuste de largura das colunas
        worksheet['!cols'] = [
            { wch: 8 },
            { wch: 44 },
            { wch: 17 },
            ...data[0].slice(3).map(() => ({ wch: 20 })),
        ]

        workbook.SheetNames.push('Produtos')
        workbook.Sheets['Produtos'] = worksheet

        return XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'binary',
        })
    }

    // Função para baixar o arquivo Excel
    const downloadExcelFile = (xlsbin) => {
        const buffer = new ArrayBuffer(xlsbin.length)
        const array = new Uint8Array(buffer)
        for (let i = 0; i < xlsbin.length; i++) {
            array[i] = xlsbin.charCodeAt(i) & 0xff
        }

        const xlsblob = new Blob([buffer], { type: 'application/octet-stream' })
        const url = window.URL.createObjectURL(xlsblob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = 'produtos_exportados.xlsx'
        anchor.click()
        window.URL.revokeObjectURL(url)
    }

    return (
        <div
            style={{
                display: 'flex',
                padding: '10px 30px 30px',
                flexDirection: 'column',
                minHeight: '40vh',
                justifyContent: 'space-between',
                margin: '0 auto',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <p className="titleSelectLevels">
                    Selecione os níveis de preços desejados para gerar a
                    planilha no Excel. Observação: é permitido selecionar no
                    máximo 5 níveis.
                </p>
            </div>

            <div
                style={{
                    display: loading ? 'flex' : 'grid',
                    marginLeft: '1%',
                    marginTop: 20,
                    gridTemplateColumns: 'repeat(auto-fit, minmax(33%, 200px))',
                    rowGap: 20,
                    justifyItems: 'flex-start',
                }}
            >
                {loading ? (
                    <div className="loaderSpinner">
                        <Loader type="inline" />
                    </div>
                ) : (
                    levels?.map((level) => (
                        <div key={level.nivel}>
                            <CheckBoxCustom
                                checked={selectedCheckboxes.has(level.nivel)}
                                description={level.descricao}
                                value={level.nivel}
                                disabled={
                                    areCheckboxesDisabled &&
                                    !selectedCheckboxes.has(level.nivel)
                                }
                                change={() => handleCheckboxChange(level.nivel)}
                            />
                        </div>
                    ))
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: 5,
                    width: '97%',
                }}
            >
                <ButtonV3
                    title="Exportar"
                    onClick={handleExport}
                    Icon={
                        <FaFileDownload
                            className="iconButtonV3"
                            // color="green"
                        />
                    }
                />
                <ButtonV3 filled title="Voltar" onClick={onClose} />
            </div>
        </div>
    )
}
