import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { FeedbackError } from 'components/feedback/error'
import { FeedbackSuccess } from 'components/feedback/success'
import { loadEditLoggedProviderData } from 'store/providers'
import './index.scss'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { useContext } from 'react'
import { UserContext } from 'contexts/user'
import { Textarea } from 'components/textarea'
import { Datepicker } from 'components/datepicker'
import { AiOutlineClose } from 'react-icons/ai'
import Constants from '../../../../../../constants'
import { Link } from 'react-router-dom'
import { CampaignService } from 'services/v2/campaigns.service'
import { Snackbar } from 'components/snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { useSnackbar } from 'react-simple-snackbar'
import DateService from 'services/DateService'
import { Loader } from 'components/loader'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import ButtonV3 from 'components/Buttonv3'
import IconReact from 'components/icons'
import { useTheme } from 'contexts/whiteLabel/context'

export const Information = ({
    setShowNav,
    setCampaignLoad,
    campaignEdit,
}: any) => {
    const initialCampaign = {
        titulo: '',
        observacao: '',
        entrega: '',
        data_final: new Date(),
    }
    const { theme } = useTheme()
    const { register } = useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { user } = useContext(UserContext)
    const [params, setParams] = useState<any>(initialCampaign)
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [campaign, setCampaign] = useState<any>()

    const updateDate = (date: string) => {
        if (date) {
            setParams({
                ...params,
                data_final: new Date(date),
            })
        }
    }

    const clearCampaign = () => {
        setParams(initialCampaign)
        if (campaign) {
            setLoading(true)
            CampaignService.delete(campaign.id)
                .then(async (response: any) => {
                    setLoading(false)
                })
                .catch((error) => {
                    openSnackbar(
                        <Snackbar icon="sticky_note_2">
                            {'Ocorreu um erro ao descartar a campanha!'}
                        </Snackbar>
                    )
                    setLoading(false)
                })
        }
    }

    const save = () => {
        const campaign = {
            titulo: params.titulo,
            for_id: user.providerId,
            obs: params.observacao,
            entrega: params.entrega.toUpperCase(),
            data_final: DateService.formatToRequest(params.data_final),
        }
        setLoading(true)
        CampaignService.save(campaign)
            .then((response: any) => {
                setCampaign(response.data)
                setCampaignLoad(response.data)
                setLoading(false)
                openSnackbar(
                    <Snackbar icon="sticky_note_2">
                        Campanha salva com sucesso!
                    </Snackbar>
                )
                setShowNav('D')
            })
            .catch((error) => {
                openSnackbar(
                    <Snackbar icon="sticky_note_2">
                        {error.response && error.response.data
                            ? error.response.data.message
                            : 'Ocorreu um erro ao salvar a campanha!'}
                    </Snackbar>
                )
                setLoading(false)
            })
    }

    useEffect(() => {
        if (campaignEdit) {
            setParams({
                ...params,
                titulo: campaignEdit.titulo,
                data_final: campaignEdit.data_final,
                observacao: campaignEdit.obs,
                entrega: campaignEdit.entrega,
            })
        }
    }, [campaignEdit])

    return (
        <div className="form">
            {!loading ? (
                <div className="row">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: '5px 16px',
                        }}
                    >
                        <p className="InformationTitle">Informações Gerais</p>
                        <div>
                            <Link to={`${Constants.ROUTE_CAMPAIGNS}`}>
                                {
                                    //  <Button className="btn-large btn-laranja">
                                    //      Voltar
                                    //  </Button>
                                }
                                <ButtonV3
                                    style={{ height: 45, padding: '13px 30px' }}
                                    title="Voltar"
                                    Icon={
                                        <IconReact
                                            className={'iconButtonV3'}
                                            type="arrowLeft"
                                        />
                                    }
                                ></ButtonV3>
                            </Link>
                        </div>
                    </div>
                    <div className="feedbacks col-12">
                        {errorMessage && (
                            <FeedbackError message={errorMessage} />
                        )}
                        {successMessage && (
                            <FeedbackSuccess message={successMessage} />
                        )}
                        <br />
                    </div>

                    <div className="form-group col-12 col-md-6">
                        <Input
                            id="titulo"
                            style={{ height: '47px' }}
                            name="titulo"
                            description="Título da Campanha"
                            placeholder="Insira o título de sua campanha"
                            register={register({ required: true })}
                            value={params.titulo}
                            onChange={(e: any) =>
                                setParams({ ...params, titulo: e.target.value })
                            }
                        />
                    </div>

                    <div className="form-group col-12 col-md-6">
                        <Datepicker
                            callendarIcon
                            value={params.data_final}
                            description="Data Final"
                            min={new Date()}
                            onChange={(date: string) => updateDate(date)}
                        />
                    </div>

                    <div className="form-group col-12 col-md-6">
                        <label
                            style={{ marginBottom: 5 }}
                            className="c-label"
                            htmlFor="additionalInformations"
                        >
                            Observações
                        </label>
                        <Textarea
                            id="additionalInformations"
                            name="additionalInformations"
                            rows={4}
                            placeholder="Digite suas Observações"
                            register={register()}
                            value={params.observacao}
                            onChange={(e: any) =>
                                setParams({
                                    ...params,
                                    observacao: e.target.value,
                                })
                            }
                        />
                    </div>

                    <div className="form-group col-12 col-md-6">
                        <label className="c-label">
                            Selecione o tipo de entrega
                        </label>

                        <div
                            style={{ display: 'flex', gap: 40, marginTop: 30 }}
                        >
                            <CheckBoxCustom
                                value={'CIF'}
                                description="Entrega"
                                checked={params.entrega === 'CIF'}
                                change={(e: any, stats) =>
                                    setParams({ ...params, entrega: e })
                                }
                            ></CheckBoxCustom>

                            <CheckBoxCustom
                                value="FOB"
                                description="Retirada"
                                checked={params.entrega === 'FOB'}
                                change={(e: any, stats) =>
                                    setParams({ ...params, entrega: e })
                                }
                            ></CheckBoxCustom>
                            {
                                //  <div className="radio">
                                //      <label className="label">
                                //          <input name="entrega" value="CIF" type="radio" checked={params.entrega === "CIF"}
                                //              onChange={(e: any) =>
                                //                  setParams({ ...params, entrega: e.target.value })
                                //              } /> CIF
                                //      </label>
                                //      <label>
                                //          <input name="entrega" value="FOB" type="radio"
                                //              checked={params.entrega === "FOB"}
                                //              onChange={(e: any) => setParams({ ...params, entrega: e.target.value })} /> Retirar Produto
                                //      </label>
                                //  </div>
                            }
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            gap: 20,
                            flexWrap: 'wrap',
                        }}
                    >
                        <div>
                            {
                                //  <Button className="btn-large btn-vermelho" onClick={() => clearCampaign()}>
                                //      <AiOutlineClose /> Descartar
                                //  </Button>
                            }
                            <ButtonV3
                                style={{
                                    border: `1px solid ${
                                        theme?.colors?.primary || '#ff7110'
                                    }`,
                                    height: 45,
                                    padding: '13px 30px',
                                }}
                                textStyle={{
                                    color: `${
                                        theme?.colors?.primary || '#ff7110'
                                    }`,
                                }}
                                title="Descartar"
                                onClick={() => clearCampaign()}
                            ></ButtonV3>
                        </div>
                        <div>
                            {
                                // <Button className="btn-large btn-verde" onClick={() => save()}>
                                //     <i className="material-icons">done</i>Salvar e Continuar
                                // </Button>
                            }
                            <ButtonV3
                                style={{ height: 45, padding: '13px 30px' }}
                                title="Salvar e Continuar"
                                onClick={() => save()}
                                filled
                            ></ButtonV3>
                        </div>
                    </div>
                </div>
            ) : (
                <Loader type="inline" />
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    editLoggedProviderData: state.editProvider,
})

const mapDispatchToProps = (dispatch: any) => ({
    loadEditLoggedProviderData: () => dispatch(loadEditLoggedProviderData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Information)
