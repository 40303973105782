import styled from 'styled-components'

interface props {
    isActive: boolean
}

export const DropDownButton = styled.div<props>`
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--primary);
    svg {
        transition: 200ms ease-out;
        font-size: 1.6rem;
        /* color: #ff7110; */
        color: #fff;
        rotate: ${(props) => (props.isActive ? '-180deg' : 'unset')};
    }

    &:active {
        // background-color: ;
        // box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        opacity: 0.7;
        translate: 0px 1px;
    }
`
