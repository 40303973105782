/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import * as $ from 'jquery'
import React, { useEffect } from 'react'
import { GrGroup } from 'react-icons/gr'
import { useForm } from 'react-hook-form'

import { Input } from 'components/input'
import { Button } from 'components/button'
import ModalComponent from 'components/modal-v2'
import { FeedbackError } from 'components/feedback/error'
import ProductService from 'services/ProductService'
import InputV2 from 'components/InputV2'
import ButtonV3 from 'components/Buttonv3'
import { useTheme } from 'contexts/whiteLabel/context'

type Props = {
    user: any
    group?: any
    isVisible?: boolean
    onClose: (is_submitted?: boolean) => void
}

export const ProductGroupFormModal = ({
    isVisible,
    onClose,
    group,
    user,
}: Props) => {
    const { theme } = useTheme()
    const modal = {
        //icon: <GrGroup color='white' />,
        styles: { maxWidth: '50vw' },
        id: 'product-group-modal-mine',
        title: `Grupo de produtos`,
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible])

    const onSubmit = (data: any) => {
        if (group) {
            data.id = group?.id
            ProductService.update_group(user.providerId, data)
        } else {
            ProductService.create_group(user.providerId, data)
        }

        onClose(true)
    }

    return (
        <ModalComponent
            modal={modal}
            close={onClose}
            show={isVisible}
            className={'modal-dialog'}
            footerNull
        >
            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="LabelInputGroupModal">Grupo de produtos</p>
                    <div>
                        {
                            <Input
                                type="text"
                                name="name"
                                defaultValue={group?.name}
                                register={register({ required: true })}
                                placeholder="Digite aqui o nome do grupo de produtos"
                            />
                        }

                        {errors.name && (
                            <FeedbackError
                                message={'Este campo é obrigatório'}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            marginTop: 15,
                            display: 'flex',
                            gap: 15,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonV3
                            style={{
                                padding: '6px 30px',
                                height: 40,
                                borderColor: `${
                                    theme.colors?.primary || '#ff7110'
                                }`,
                            }}
                            textStyle={{
                                color: `${theme.colors?.primary || '#ff7110'}`,
                            }}
                            //type="submit"
                            title={'Cancelar'}
                            onClick={() => {
                                onClose()
                            }}
                        />
                        <ButtonV3
                            style={{ padding: '6px 30px', height: 40 }}
                            filled
                            type="submit"
                            title={'Salvar'}
                            onClick={() => {}}
                        />
                    </div>
                </form>
            </div>
        </ModalComponent>
    )

    // return (
    //
    //     <div className="container-fluid">
    //         <div className="row header">
    //             <div className="col m-4 p-3 shadow">
    //                 <form onSubmit={handleSubmit(onSubmit)}>
    //                     <p className={`title-weekday`}>
    //                         Grupo de produtos
    //                     </p>
    //
    //                     <table className={`table`}>
    //                         <tbody>
    //                             <tr>
    //                                 <td className={`td-weekday`}>
    //                                     <Input
    //                                         type="text"
    //                                         name="name"
    //                                         defaultValue={group?.name}
    //                                         register={register({ required: true })}
    //                                         placeholder="Digite aqui o nome do grupo de produtos"
    //                                     />
    //                                     {errors.name && (
    //                                         <FeedbackError
    //                                             message={'Este campo é obrigatório'}
    //                                         />
    //                                     )}
    //                                 </td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //
    //                     <Button variant="primary" type="submit">
    //                         Salvar
    //                     </Button>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    //
    // )
}
