import './index.scss'
import { FC, useContext, useEffect, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { Select } from '../../../../components/select'
import { UserContext } from 'contexts/user'
import ButtonV3 from 'components/Buttonv3'
import { IoMdTrash } from 'react-icons/io'
import { Datepicker } from 'components/datepicker'
import { InputAutoComplete } from 'components/inputAutoComplete'
import TacticalPanelService from 'services/v3/tacticalPanel.service'
import { DatePickerMonthYear } from 'pages/tactical-panel/components/datePickerMonthYear'

interface FilterProps {
    onChange: (params: any) => void
    cleanFilters: () => void
    viewFilters: boolean
}

export const TacticalPanelFilterComponent: FC<FilterProps> = ({
    onChange,
    viewFilters,
    cleanFilters,
}: FilterProps) => {
    const { user } = useContext(UserContext)
    const [salesmen, setSalesmen] = useState<any>([])
    const [states, setStates] = useState<any>(null)
    const [cityOptions, setCityOptions] = useState([])
    const [selectedState, setSelectedState] = useState<string | null>(null)
    const [listProducts, setListProducts] = useState<any>([])
    const [listClients, setListClients] = useState<any>([])
    const [cleanInputAutoComplete, setCleanInputAutoComplete] = useState(false)

    const [selectedDate, setSelectedDate] = useState<{
        year: number
        month: number
    }>(() => {
        const today = new Date()
        return { year: today.getFullYear(), month: today.getMonth() + 1 }
    })

    const initialParams = {
        page: 1,
        year: selectedDate.year,
        month: selectedDate.month,
        productId: '',
        sellerId: '',
        clientId: '',
        state: '',
        city: '',
    }
    const [params, setParams] = useState(initialParams)

    useEffect(() => {
        if (user?.providerId) {
            loadListProducts()
            loadListClients()
            loadListSellers()
            loadStates()
        }
    }, [user?.providerId])

    useEffect(() => {
        if (selectedState) {
            loadCity(selectedState)
        } else {
            setParams({ ...params, city: '' })
        }
    }, [selectedState])

    useEffect(() => {
        if (cleanInputAutoComplete) {
            setCleanInputAutoComplete(false)
        }
    }, [cleanInputAutoComplete])

    const loadListProducts = async () => {
        try {
            const response = await TacticalPanelService.getListProducts(
                user.providerId
            )
            setListProducts(response.data)
        } catch (error) {
            console.error('Erro ao carregar produtos', error)
        }
    }
    const loadListClients = async () => {
        try {
            const response = await TacticalPanelService.getListClients(
                user.providerId
            )
            setListClients(response.data)
        } catch (error) {
            console.error('Erro ao carregar clientes', error)
        }
    }
    const loadListSellers = async () => {
        try {
            const response = await TacticalPanelService.getListSellers(
                user.providerId
            )
            setSalesmen(response.data)
        } catch (error) {
            console.error('Erro ao carregar clientes', error)
        }
    }

    const loadStates = async () => {
        try {
            const response = await TacticalPanelService.getStates(
                user.providerId
            )
            setStates(response.data)
        } catch (error) {
            console.error('Erro ao carregar estados', error)
        }
    }

    const loadCity = async (UF: string) => {
        try {
            const response = await TacticalPanelService.getCity(
                user.providerId,
                UF
            )
            setCityOptions(response.data)
        } catch (error) {
            console.error('Erro ao carregar cidades', error)
        }
    }

    const handleFilterChange = (key: string, value: any) => {
        setParams((prev: any) => ({ ...prev, [key]: value }))
    }

    const handleDateChange = (date: { year: number; month: number }) => {
        setSelectedDate(date)

        setParams((prev) => ({
            ...prev,
            year: date.year,
            month: date.month,
        }))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (onChange) {
            onChange(params)
        }
    }

    const handleClearFilters = () => {
        setParams(initialParams)

        setSelectedState(null)
        setCityOptions([])
        setCleanInputAutoComplete(true)

        setSelectedDate({
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
        })

        if (cleanFilters) cleanFilters()
    }

    const handleStateChange = (e: any) => {
        let selectedValue = null

        if (e?.target?.value) {
            selectedValue = e.target.value // Para selects baseados no <select> nativo
        } else if (typeof e === 'object' && e !== null) {
            selectedValue = e.state || e.value // Para selects customizados
        }

        handleFilterChange('state', selectedValue)
        setSelectedState(selectedValue || null)
    }

    return (
        <form onSubmit={handleSubmit}>
            {viewFilters && (
                <>
                    <div className="filtersContainer2">
                        <div className="firstColumnFilters">
                            <div className="filterItem">
                                <InputAutoComplete
                                    description="Produto"
                                    value_key="product_code"
                                    label_key="product_name"
                                    blank_label="Buscar produto por nome"
                                    placeholder="Buscar produto por nome"
                                    options={listProducts}
                                    onChange={(e: any) => {
                                        const value = e?.target
                                            ? e.target.value
                                            : e?.value
                                        handleFilterChange(
                                            'productId',
                                            value || ''
                                        )
                                    }}
                                    clear={cleanInputAutoComplete}
                                />
                            </div>

                            <div className="filterItem">
                                <InputAutoComplete
                                    description="Cliente"
                                    value_key="client_code"
                                    label_key="client_name"
                                    blank_label="Buscar cliente por nome"
                                    placeholder="Buscar cliente por nome"
                                    options={listClients}
                                    onChange={(e: any) => {
                                        const value = e?.target
                                            ? e.target.value
                                            : e?.value
                                        handleFilterChange(
                                            'clientId',
                                            value || ''
                                        )
                                    }}
                                    clear={cleanInputAutoComplete}
                                />
                            </div>

                            <div className="filterItem">
                                <Select
                                    description="Vendedor"
                                    value={params.sellerId || ''}
                                    value_key="seller_code"
                                    label_key="seller_name"
                                    blank_label="Selecione o vendedor"
                                    options={salesmen}
                                    onChange={(e: any) => {
                                        const value = e?.target
                                            ? e.target.value
                                            : e?.value
                                        handleFilterChange(
                                            'sellerId',
                                            value || ''
                                        )
                                    }}
                                />
                            </div>

                            <div className="filterItem">
                                <DatePickerMonthYear
                                    label="Data da cotação"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                />
                            </div>

                            <div className="filterItem">
                                <Select
                                    name="state"
                                    description="Estado"
                                    value={params.state || ''}
                                    options={states}
                                    blank_label="Selecione um estado"
                                    value_key="state"
                                    label_key="state"
                                    onChange={handleStateChange}
                                />
                            </div>

                            {/* {selectedState && ( */}
                            <div className="filterItem">
                                <Select
                                    name="city"
                                    description="Cidade"
                                    value={params.city || ''}
                                    value_key="city"
                                    label_key="city"
                                    options={cityOptions}
                                    blank_label="Selecione uma cidade"
                                    disabled={!selectedState}
                                    onChange={(e: any) => {
                                        const value = e?.target
                                            ? e.target.value
                                            : e?.value
                                        handleFilterChange('city', value || '')
                                    }}
                                />
                            </div>
                            {/* )} */}
                        </div>
                    </div>

                    <div className="buttonsContent">
                        <ButtonV3
                            type="button"
                            title="Limpar filtros"
                            onClick={handleClearFilters}
                            Icon={<IoMdTrash className="iconButtonV3" />}
                        />
                        <ButtonV3
                            onClick={handleSubmit}
                            type="submit"
                            title="Filtrar"
                            filled
                            Icon={
                                <BsSearch
                                    className="iconButtonV3"
                                    color="#fff"
                                    size={16}
                                />
                            }
                        />
                    </div>
                </>
            )}
        </form>
    )
}
