import './index.scss'

import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import { useEffect, useState } from 'react'
import { Loader } from 'components/loader'
import ButtonV3 from 'components/Buttonv3'
import { FeedbackError } from 'components/feedback/error'
import TacticalPanelService from 'services/v3/tacticalPanel.service'

type QuotationModalProps = {
    isVisible: boolean
    onClose: () => void
    productId?: number
    user?: any
    hash: string
}

interface IQuotationResponse {
    product_code: string
    product_name: string
    client_id: string
    client_name: string
    seller_id: string
    seller_name: string
    id_dim_status: number
    status_name: string
    quotation_code: string
    date: string
    quotation_price: number
    winner_price: number
}

export const QuotationModal = ({
    isVisible,
    onClose,
    productId,
    user,
    hash,
}: QuotationModalProps) => {
    const [loadingTable, setLoadingTable] = useState(false)
    const [quotation, setQuotation] = useState<IQuotationResponse[] | null>(
        null
    )

    const modal = {
        icon: <GrGroup color="white" />,
        styles: { maxWidth: '80vw' },
        id: 'info-cotacoes',
        title: `Cotações`,
    }
    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')

            loadListProductQuotes()
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible, user, productId])

    const loadListProductQuotes = () => {
        if (!user || !productId || !hash) return

        setLoadingTable(true)

        TacticalPanelService.ListProductQuotes(user.providerId, productId, hash)
            .then((result) => {
                setQuotation(result.data)
            })
            .catch((error) => {
                setQuotation(null)
                console.error(error)
            })
            .finally(() => {
                setLoadingTable(false)
            })
    }

    const convertStatus = (status: string): string => {
        const statusMap: Record<string, string> = {
            WINNER: 'VENCEDOR',
            LOSER: 'PERDEDOR',
            PENDING: 'PENDENTE',
            ORDERED: 'PEDIDO GERADO',
            NOT_OFFERED: 'NÃO OFERTADO',
            NOT_ORDERED: 'SEM PEDIDO',
            NOT_TYPED: 'NÃO DIGITADO',
            TYPED: 'DIGITADO',
        }

        return statusMap[status] || status
    }

    return (
        <div
            style={{
                display: 'flex',
                padding: '10px 30px 30px',
                flexDirection: 'column',
                minHeight: '25vh',
                justifyContent: 'space-between',
                margin: '0 auto',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {!loadingTable && <h2 className="titleQuotation">COTAÇÕES</h2>}
            </div>

            <div className="NewListCotacoes">
                {loadingTable ? (
                    <Loader type="inline" />
                ) : (
                    <>
                        {quotation && quotation.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cotação</th>
                                        <th>Data</th>
                                        <th>Cliente</th>
                                        <th>Vendedor</th>
                                        <th>Status</th>
                                        <th>Preço Digitado</th>
                                        <th>Preço Vencedor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quotation.map((item) => (
                                        <tr key={item.quotation_code}>
                                            <td
                                                data-label="Cotação"
                                                style={{ fontWeight: 500 }}
                                            >
                                                <span>#</span>
                                                {item.quotation_code}
                                            </td>
                                            <td data-label="Data">
                                                {item.date
                                                    ? new Date(
                                                          item.date
                                                      ).toLocaleDateString(
                                                          'pt-BR'
                                                      )
                                                    : ''}
                                            </td>

                                            <td data-label="Cliente">
                                                {item.client_name ?? ''}
                                            </td>
                                            <td data-label="Vendedor">
                                                {item.seller_name ?? ''}
                                            </td>
                                            <td data-label="Status">
                                                {item.status_name
                                                    ? convertStatus(
                                                          item.status_name
                                                      )
                                                    : ''}
                                            </td>

                                            <td data-label="Preço Digitado">
                                                {item.quotation_price?.toLocaleString(
                                                    'pt-BR',
                                                    {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }
                                                )}
                                            </td>
                                            <td data-label="Preço Ganhador">
                                                {item.winner_price?.toLocaleString(
                                                    'pt-BR',
                                                    {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }
                                                ) ?? ''}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <FeedbackError
                                message={
                                    'Erro ao acessar cotações, tente novamente mais tarde...'
                                }
                            />
                        )}
                    </>
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '98%',
                    marginTop: 10,
                }}
            >
                <ButtonV3
                    filled
                    title="Voltar"
                    onClick={() => {
                        onClose()
                    }}
                />
            </div>
        </div>
    )
}
