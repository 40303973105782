import { Checkbox } from 'components/checkbox'
import { Input } from 'components/input'
import ModalComponent from 'components/modal-v2'
import { UserContext } from 'contexts/user'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { AiOutlineLock, AiOutlineUnlock } from 'react-icons/ai'
import { BiSave } from 'react-icons/bi'
import { BsInfoCircle, BsPlusCircleDotted, BsShieldLock } from 'react-icons/bs'
import ProfileService from 'services/v3/profile.service'
import styles from './index.module.scss'
import SnackBarErrortyle from 'styles/snackbar-error.style'
import { Snackbar } from 'components/snackbar'
import { useSnackbar } from 'react-simple-snackbar'
import snackbarSuccessStyle from 'styles/snackbar-success.style'
import { Toggle } from 'components/toggle'
import { CheckBoxCustom } from 'components/checkBoxCustom'

import { Modal } from 'react-bootstrap'
import { IoMdCloseCircle, IoMdInformationCircle } from 'react-icons/io'
import { MdEdit, MdSave, MdOutlineModeEditOutline } from 'react-icons/md'
import InputV2 from 'components/InputV2'
import ButtonV3 from 'components/Buttonv3'
import { useTheme } from 'contexts/whiteLabel/context'

export const EditProfileModal: FC<any> = ({
    show,
    profile,
    close,
    load,
}: any) => {
    const { theme } = useTheme()
    const [modal, setModal] = useState<any>({})
    const [isEditingName, setIsEditingName] = useState(false)
    const [nameFocused, setNameFocused] = useState(false)
    const { modules } = useContext(UserContext)
    const [available_modules, setAvailableModules] = useState<any>()
    const [openSnackbar] = useSnackbar(SnackBarErrortyle)
    const [openSnackbarSuccess] = useSnackbar(snackbarSuccessStyle)
    const [currentProfile, setCurrentProfile] = useState(profile)
    const [allToggled, toggleAll] = useState(false)
    const [toggling, setToggling] = useState(false)

    const nameInput = React.useRef(null)

    const process_permissions = useCallback(() => {
        const mods = [...modules]

        mods.forEach(
            (m) =>
                m.permissions &&
                m.permissions.forEach((p) => {
                    p.profile_id = null
                    p.id = null
                })
        )
        profile?.permissions &&
            mods.forEach(
                (m) =>
                    m.permissions &&
                    m.permissions.forEach((p) => {
                        profile.permissions.forEach((pp) => {
                            if (
                                pp.resource == p.resource &&
                                pp.action == p.action
                            ) {
                                p.profile_id = pp.profile_id
                                p.id = pp.id
                                p.subject_ids = pp.subject_ids
                            }
                        })
                    })
            )
        setCurrentProfile(profile)
        setAvailableModules(mods)
        toggleAll(mods && !mods.some((m) => m.permissions.some((p) => !p.id)))
    }, [modules, profile])

    useEffect(() => {
        setModal({
            title: profile?.id ? `Perfil ${profile?.name}` : 'Novo perfil',
            id: 'order-details',
            icon: <BsShieldLock />,
            styles: {
                maxWidth: '70vw',
            },
        })
        process_permissions()
    }, [profile, process_permissions])

    const toggle_permission = (evt, perm) => {
        const to_remove = !evt
        const data = { ...perm, profile_id: currentProfile.id }
        const func = to_remove
            ? ProfileService.remove_permission
            : ProfileService.create_permission
        return func(data)
            .then((resp) => {
                const mods = [...available_modules]
                const pp = mods
                    .map((md) => md.permissions)
                    .flat()
                    .filter((p) => p)
                    .find(
                        (p) =>
                            p.resource == perm.resource &&
                            p.action == perm.action
                    )
                if (to_remove) {
                    pp.id = null
                    pp.profile_id = null
                    pp.subject_ids = null
                } else {
                    pp.id = resp.id
                    pp.profile_id = null
                }
                setAvailableModules(mods)
            })
            .catch(() => {
                openSnackbar(
                    <Snackbar icon="error_outline">
                        Ops! Um erro ocorreu! Tente novamente mais tarde.
                    </Snackbar>
                )
            })

        //   const to_remove = !evt.target.checked
        //   const data = { ...perm, profile_id: currentProfile.id }
        //   const func = to_remove ? ProfileService.remove_permission : ProfileService.create_permission
        //   return func(data).then(resp => {
        //       const mods = [...available_modules]
        //       const pp = mods
        //           .map(md => md.permissions)
        //           .flat()
        //           .filter(p => p)
        //           .find(p => p.resource == perm.resource && p.action == perm.action)
        //       if (to_remove) {
        //           pp.id = null
        //           pp.profile_id = null
        //           pp.subject_ids = null
        //       } else {
        //           pp.id = resp.id
        //           pp.profile_id = null
        //       }
        //       setAvailableModules(mods)
        //   }).catch(() => {
        //       openSnackbar(
        //           <Snackbar icon="error_outline">
        //               Ops! Um erro ocorreu! Tente novamente mais tarde.
        //           </Snackbar>
        //       )
        //   })
    }

    const update_subject_ids = (perm) => {
        const mods = [...available_modules]
        const pp = mods
            .map((md) => md.permissions)
            .flat()
            .filter((p) => p)
            .find((p) => p.resource == perm.resource && p.action == perm.action)
        pp.subject_ids = perm.subject_ids
        setAvailableModules(mods)
    }

    const save = () => {
        const func = currentProfile.id
            ? ProfileService.update
            : ProfileService.save
        func(currentProfile)
            .then((resp) => {
                if (!currentProfile.id) {
                    setCurrentProfile({ ...resp, permissions: [] })
                }
                load()
                openSnackbarSuccess(
                    <Snackbar icon="error_outline">
                        Perfil atualizado com sucesso!
                    </Snackbar>
                )
            })
            .then((res) => setNameFocused(false))
    }

    const select_all = () => {
        const flag = !allToggled

        if (toggling) return

        toggleAll(flag)
        const mods = [...available_modules]
        setToggling(true)
        const calls = mods
            .map((m) =>
                m.permissions.map((p) => {
                    //return toggle_permission({ target: { checked: flag } }, p)
                    return toggle_permission(flag, p)
                })
            )
            .flat()

        Promise.all(calls)
            .then(() => setToggling(false))
            .catch(() => setToggling(false))
    }

    const edit_name = () => {
        setIsEditingName(!isEditingName)
    }

    const is_pricing_perm = (p: any) => {
        return p.resource == 'PRODUCT' && p.action == 'PRICING'
    }

    const save_subjects = (p) => {
        toggle_permission({ target: { checked: true } }, p).then(() => {
            openSnackbarSuccess(
                <Snackbar icon="error_outline">
                    Informações extras salvas com sucesso!
                </Snackbar>
            )
        })
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={close}
            animation={true}
            dialogClassName={undefined}
        >
            <div className="modalHeader">
                <h2 className="modalTitle">{modal.title}</h2>
                <IoMdCloseCircle className={'closeIcon'} onClick={close} />
            </div>

            <div className="modalBody">
                <div style={{ padding: '15px 30px' }}>
                    {currentProfile && (
                        <div style={{}}>
                            <div>
                                <div></div>
                                <div>
                                    {
                                        //   <Input
                                        //       id="name"
                                        //       name="name"
                                        //       className={"text-center"}
                                        //       readOnly={!isEditingName && currentProfile?.id}
                                        //       onChange={(evt) => setCurrentProfile({ ...currentProfile, name: evt.target.value })}
                                        //       value={currentProfile?.name}
                                        //       placeholder="Name"
                                        //   />
                                    }
                                    <InputV2
                                        register={nameInput}
                                        onFocus={() => setNameFocused(true)}
                                        infoIconNull={true}
                                        mask={undefined}
                                        icon={
                                            !nameFocused ? (
                                                <MdOutlineModeEditOutline
                                                    color={
                                                        nameFocused
                                                            ? `${
                                                                  theme.colors
                                                                      ?.primary ||
                                                                  '#FF7110'
                                                              }`
                                                            : '#707070'
                                                    }
                                                    style={
                                                        nameFocused
                                                            ? {
                                                                  cursor: 'pointer',
                                                              }
                                                            : {
                                                                  cursor: 'not-allowed',
                                                              }
                                                    }
                                                />
                                            ) : (
                                                <MdSave
                                                    color={
                                                        nameFocused
                                                            ? `${
                                                                  theme.colors
                                                                      ?.primary ||
                                                                  '#FF7110'
                                                              }`
                                                            : '#707070'
                                                    }
                                                    style={
                                                        nameFocused
                                                            ? {
                                                                  cursor: 'pointer',
                                                              }
                                                            : {
                                                                  cursor: 'not-allowed',
                                                              }
                                                    }
                                                    onClick={save}
                                                />
                                            )
                                        }
                                        id="name"
                                        value={currentProfile?.name}
                                        type={'text'}
                                        placeholder={'Nome'}
                                        onChange={(evt) =>
                                            setCurrentProfile({
                                                ...currentProfile,
                                                name: evt.target.value,
                                            })
                                        }
                                    />
                                </div>
                                {
                                    //    <div >
                                    //        <div >
                                    //            {!isEditingName && <AiOutlineLock
                                    //                className={styles.lock}
                                    //                onClick={edit_name} />}
                                    //            {isEditingName && <AiOutlineUnlock
                                    //                className={styles.lock}
                                    //                onClick={edit_name} />}
                                    //            {(isEditingName || !currentProfile?.id) && <BiSave
                                    //                title="Salvar"
                                    //                className={styles.save}
                                    //                onClick={save} />}
                                    //        </div>
                                    //    </div>
                                }
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginTop: 15,
                                        }}
                                    >
                                        <small
                                            className={
                                                styles.SelectAllLabeToggle
                                            }
                                        >
                                            Selecionar todos
                                        </small>
                                        <Toggle
                                            onToggle={select_all}
                                            value={allToggled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {
                        currentProfile && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {currentProfile?.id &&
                                    available_modules.map((m) => {
                                        return (
                                            <div key={m.resource}>
                                                <div>
                                                    <div
                                                        style={{
                                                            marginBottom: 30,
                                                            marginTop: 30,
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                styles.resourceNameTitle
                                                            }
                                                        >
                                                            {m.name}
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'grid',
                                                            gridTemplateColumns:
                                                                '1fr 1fr 1fr',
                                                        }}
                                                    >
                                                        {' '}
                                                        {m.permissions &&
                                                            m.permissions.map(
                                                                (p) => {
                                                                    return (
                                                                        <div
                                                                            key={`${m.resource}${p.action}`}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    marginBottom: 10,
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <IoMdInformationCircle
                                                                                        title={
                                                                                            p.description
                                                                                        }
                                                                                        className={
                                                                                            styles.resourceNameInfoIcon
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        <CheckBoxCustom
                                                                                            value={{}}
                                                                                            change={(
                                                                                                evt,
                                                                                                stats
                                                                                            ) =>
                                                                                                toggle_permission(
                                                                                                    stats,
                                                                                                    p
                                                                                                )
                                                                                            }
                                                                                            checked={
                                                                                                !!p.id
                                                                                            }
                                                                                            description={
                                                                                                p.name
                                                                                            }
                                                                                        ></CheckBoxCustom>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    //   <Checkbox
                                                                                    //       description={p.name}
                                                                                    //       checked={!!p.id}
                                                                                    //       onChange={(evt) => toggle_permission(evt.target.checked, p)}
                                                                                    //   />
                                                                                }
                                                                            </div>
                                                                            {
                                                                                is_pricing_perm(
                                                                                    p
                                                                                ) && (
                                                                                    <div
                                                                                        style={{
                                                                                            minWidth: 300,
                                                                                            marginBottom: 10,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            //is_pricing_perm(p) && <BiSave
                                                                                            //    onClick={() => save_subjects(p)}
                                                                                            //    title="Salvar" className={styles['save-subject']} />
                                                                                        }
                                                                                        {
                                                                                            //   <Input
                                                                                            //       onChange={(evt) => update_subject_ids({ ...p, subject_ids: evt.target.value })}
                                                                                            //       placeholder="Add ref. Ex: 1,3,8.."
                                                                                            //       disabled={!p.id}
                                                                                            //       defaultValue={p.subject_ids} />
                                                                                        }
                                                                                        <InputV2
                                                                                            disabled={
                                                                                                !p.id
                                                                                            }
                                                                                            defaultValue={
                                                                                                p.subject_ids
                                                                                            }
                                                                                            infoIconNull={
                                                                                                true
                                                                                            }
                                                                                            mask={
                                                                                                undefined
                                                                                            }
                                                                                            icon={
                                                                                                <MdSave
                                                                                                    color={
                                                                                                        p.id
                                                                                                            ? `${
                                                                                                                  theme
                                                                                                                      .colors
                                                                                                                      ?.primary ||
                                                                                                                  '#FF7110'
                                                                                                              }`
                                                                                                            : '#A1A1A1'
                                                                                                    }
                                                                                                    title={
                                                                                                        'salvar'
                                                                                                    }
                                                                                                    onClick={() =>
                                                                                                        save_subjects(
                                                                                                            p
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            id={
                                                                                                'none'
                                                                                            }
                                                                                            label={
                                                                                                'Adicionar Informação'
                                                                                            }
                                                                                            value={
                                                                                                p.subject_ids
                                                                                            }
                                                                                            type={
                                                                                                'text'
                                                                                            }
                                                                                            placeholder={
                                                                                                'Add ref. Ex: 1,3,8..'
                                                                                            }
                                                                                            onChange={(
                                                                                                evt
                                                                                            ) =>
                                                                                                update_subject_ids(
                                                                                                    {
                                                                                                        ...p,
                                                                                                        subject_ids:
                                                                                                            evt
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                                //is_pricing_perm(p) &&
                                                                                //    <div style={{'backgroundColor':'red'}} className={styles['subjects-field']}>
                                                                                //        {is_pricing_perm(p) && <BiSave
                                                                                //            onClick={() => save_subjects(p)}
                                                                                //            title="Salvar" className={styles['save-subject']} />}
                                                                                //        <Input
                                                                                //            onChange={(evt) => update_subject_ids({ ...p, subject_ids: evt.target.value })}
                                                                                //            placeholder="Add ref. Ex: 1,3,8.."
                                                                                //            disabled={!p.id}
                                                                                //            defaultValue={p.subject_ids} />
                                                                                //    </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )

                        //     currentProfile && <div className={`row ${styles.permissions} mt-4`}>
                        //     {currentProfile?.id && available_modules.map(m => {
                        //         return <div key={m.resource} className="col col-12 px-0">
                        //             <div className="row m-2 shadow my-1 py-2">
                        //                 <div className={`${styles.perm} col col-12`}>
                        //                     {m.name}
                        //                 </div>
                        //                 {m.permissions && m.permissions.map(p => {
                        //                     return <div key={`${m.resource}${p.action}`} className={`${styles.permission} col col-4 text-nowrap d-flex align-items-start flex-column`}>
                        //                         <div className={styles['toggle-wrapper']}>
                        //                             <BsInfoCircle title={p.description} className={styles['perm-desc']} />
                        //                             <Checkbox
                        //                                 description={p.name}
                        //                                 checked={!!p.id}
                        //                                 onChange={(evt) => toggle_permission(evt, p)}
                        //                             />
                        //                         </div>
                        //                         {is_pricing_perm(p) && <div className={styles['subjects-field']}>
                        //                             {is_pricing_perm(p) && <BiSave
                        //                                 onClick={() => save_subjects(p)}
                        //                                 title="Salvar" className={styles['save-subject']} />}
                        //                             <Input
                        //                                 onChange={(evt) => update_subject_ids({ ...p, subject_ids: evt.target.value })}
                        //                                 placeholder="Add ref. Ex: 1,3,8.."
                        //                                 disabled={!p.id}
                        //                                 defaultValue={p.subject_ids} />
                        //                         </div>}
                        //                     </div>
                        //                 })}
                        //             </div>
                        //         </div>
                        //     })}
                        // </div>
                    }
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: '15px 40px 40px 0px',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonV3
                            style={{
                                borderColor: `${
                                    theme.colors?.primary || '#FF7110'
                                }`,
                            }}
                            textStyle={{
                                color: `${theme.colors?.primary || '#FF7110'}`,
                                margin: '0px 15px',
                            }}
                            title="Voltar"
                            onClick={close}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )

    // return (
    //     <ModalComponent size="sm" modal={modal} show={show} close={close} className={styles.modal}>
    //
    //         {currentProfile && <div className="col">
    //             <div className="row">
    //                 <div className="col col-2">
    //                 </div>
    //                 <div className="col col-8">
    //                     <Input id="name"
    //                         name="name"
    //                         className={"text-center"}
    //                         readOnly={!isEditingName && currentProfile?.id}
    //                         onChange={(evt) => setCurrentProfile({ ...currentProfile, name: evt.target.value })}
    //                         value={currentProfile?.name}
    //                         placeholder="Name"
    //                     />
    //                 </div>
    //                 <div className="col col-2">
    //                     <div className={`${styles.controls} h-100 d-flex justify-content-around align-items-center`}>
    //                         {!isEditingName && <AiOutlineLock
    //                             className={styles.lock}
    //                             onClick={edit_name} />}
    //                         {isEditingName && <AiOutlineUnlock
    //                             className={styles.lock}
    //                             onClick={edit_name} />}
    //                         {(isEditingName || !currentProfile?.id) && <BiSave
    //                             title="Salvar"
    //                             className={styles.save}
    //                             onClick={save} />}
    //                     </div>
    //                 </div>
    //                 <div className="col col-12">
    //                     <div className={`${styles.toggle} d-flex justify-content-start align-items-center`}>
    //                         <small>Selecionar todos</small>
    //                         <Toggle
    //                             onToggle={select_all}
    //                             value={allToggled} />
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>}
    //         {
    //
    //             currentProfile && <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
    //                 {currentProfile?.id && available_modules.map(m => {
    //                     return <div key={m.resource}
    //
    //                     >
    //                         <div  >
    //                             <div >
    //                                 {m.name}
    //                             </div>
    //                             <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-start' }}> {m.permissions && m.permissions.map(p => {
    //                                 return <div key={`${m.resource}${p.action}`}  >
    //                                     <div >
    //                                         <BsInfoCircle title={p.description} className={styles['perm-desc']} />
    //                                         {
    //                                             <CheckBoxCustom
    //                                                 value={{}}
    //                                                 change={(evt, stats) => toggle_permission(stats, p)}
    //                                                 checked={!!p.id}
    //                                                 description={p.name}
    //                                             ></CheckBoxCustom>
    //                                         }
    //                                         {
    //                                             //   <Checkbox
    //                                             //       description={p.name}
    //                                             //       checked={!!p.id}
    //                                             //       onChange={(evt) => toggle_permission(evt.target.checked, p)}
    //                                             //   />
    //                                         }
    //                                     </div>
    //                                     {is_pricing_perm(p) && <div className={styles['subjects-field']}>
    //                                         {is_pricing_perm(p) && <BiSave
    //                                             onClick={() => save_subjects(p)}
    //                                             title="Salvar" className={styles['save-subject']} />}
    //                                         <Input
    //                                             onChange={(evt) => update_subject_ids({ ...p, subject_ids: evt.target.value })}
    //                                             placeholder="Add ref. Ex: 1,3,8.."
    //                                             disabled={!p.id}
    //                                             defaultValue={p.subject_ids} />
    //                                     </div>}
    //                                 </div>
    //                             })}
    //                             </div>
    //
    //                         </div>
    //                     </div>
    //                 })}
    //             </div>
    //
    //
    //             //     currentProfile && <div className={`row ${styles.permissions} mt-4`}>
    //             //     {currentProfile?.id && available_modules.map(m => {
    //             //         return <div key={m.resource} className="col col-12 px-0">
    //             //             <div className="row m-2 shadow my-1 py-2">
    //             //                 <div className={`${styles.perm} col col-12`}>
    //             //                     {m.name}
    //             //                 </div>
    //             //                 {m.permissions && m.permissions.map(p => {
    //             //                     return <div key={`${m.resource}${p.action}`} className={`${styles.permission} col col-4 text-nowrap d-flex align-items-start flex-column`}>
    //             //                         <div className={styles['toggle-wrapper']}>
    //             //                             <BsInfoCircle title={p.description} className={styles['perm-desc']} />
    //             //                             <Checkbox
    //             //                                 description={p.name}
    //             //                                 checked={!!p.id}
    //             //                                 onChange={(evt) => toggle_permission(evt, p)}
    //             //                             />
    //             //                         </div>
    //             //                         {is_pricing_perm(p) && <div className={styles['subjects-field']}>
    //             //                             {is_pricing_perm(p) && <BiSave
    //             //                                 onClick={() => save_subjects(p)}
    //             //                                 title="Salvar" className={styles['save-subject']} />}
    //             //                             <Input
    //             //                                 onChange={(evt) => update_subject_ids({ ...p, subject_ids: evt.target.value })}
    //             //                                 placeholder="Add ref. Ex: 1,3,8.."
    //             //                                 disabled={!p.id}
    //             //                                 defaultValue={p.subject_ids} />
    //             //                         </div>}
    //             //                     </div>
    //             //                 })}
    //             //             </div>
    //             //         </div>
    //             //     })}
    //             // </div>
    //
    //
    //
    //
    //         }
    //     </ModalComponent>
    // )
}
