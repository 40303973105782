import Constants from '../constants'
import HttpClient from './HttpClient'
import ProductService from './ProductService'
import { Product } from '../interfaces/product'
import { Request } from '../interfaces/request'
import { RequestDetail } from '../interfaces/request-detail.model'
import DeliveryService from './DeliveryService'
import StoreService from './StoreService'

export default class RequestService {
    static parse = (orderFromApi: any): Request => ({
        id: orderFromApi.cot_id,
        status: orderFromApi.status,
        client: orderFromApi.cli_nome_grupo,
        date: orderFromApi.cot_dataExpiracao,
        products_count: orderFromApi.cot_qtd_produtos,
        delivery_method: orderFromApi.tpc_nome,
        seller: orderFromApi.usu_nome,
        seller_id: orderFromApi.usu_id,
        offered_products_count: orderFromApi.offered_products_count,
        is_provider_included: orderFromApi.my,
        state: orderFromApi.est_sigla,
        created_at: orderFromApi.cot_dataCadastro,
        client_id: orderFromApi.cli_id,
        client_cnpj: orderFromApi.cli_cnpj,
        client_contact: orderFromApi.cli_contato,
        client_email: orderFromApi.cli_email,
        client_name: orderFromApi.cli_nome,
        client_fantasy_name: orderFromApi.cli_nomeFantasia,
        client_store_neighborhood: orderFromApi.est_nome,
        client_store_cep: orderFromApi.loj_cep,
        client_store_street: orderFromApi.loj_endereco,
        client_state: orderFromApi.est_nome,
        client_city: orderFromApi.cid_nome,
        client_phone: orderFromApi.cli_telefone,
        blocked: orderFromApi.blocked,
        is_public: orderFromApi.is_public,
        blacklist_obs: orderFromApi.obs,
        participation: orderFromApi.participation,
        cot_status: orderFromApi.cot_status,
        ramo: orderFromApi.ramo,
    })

    static parse_v1 = (orderFromApi: any): Request => ({
        id: orderFromApi.id_cotacao,
        status: orderFromApi.status_nome,
        client: orderFromApi.grupo_cliente,
        owner_type: orderFromApi.owner_type,
        date: orderFromApi.data_expiracao,
        products_count: orderFromApi.cot_qtd_produtos,
        delivery_method: orderFromApi.tipo_entrega,
        seller: orderFromApi.nome_vendedor,
        seller_id: orderFromApi.id_vendedor,
        client_id: orderFromApi.id_cliente,
        provider_id: orderFromApi.id_fornecedor,
        client_obs: orderFromApi.cot_observacao,
        categories: orderFromApi.categorias
            ? orderFromApi.categorias.split(',')
            : [],
        stores: orderFromApi.lojas
            ? orderFromApi.lojas.map((st: any) => StoreService.parse(st))
            : null,
        participation: orderFromApi.participation,
        cot_status: orderFromApi.cot_status,
    })

    static parse_others_v1 = (orderFromApi: any): Request => ({
        id: orderFromApi.cot_id,
        status: orderFromApi.status_nome,
        client: orderFromApi.grupo_cliente,
        date: orderFromApi.dataExpiracao,
        products_count: orderFromApi.cot_qtd_produtos,
        delivery_method: orderFromApi.tipo_entrega,
        seller: orderFromApi.nome_vendedor,
        seller_id: orderFromApi.id_vendedor,
        client_id: orderFromApi.id_cliente,
        provider_id: orderFromApi.id_fornecedor,
        client_obs: orderFromApi.cot_observacao,
        categories: orderFromApi.categorias
            ? orderFromApi.categorias.split(',')
            : [],
        stores: orderFromApi.lojas
            ? orderFromApi.lojas.map((st: any) => StoreService.parse(st))
            : null,
        participation: orderFromApi.participation,
        cot_status: orderFromApi.cot_status,
    })

    static parse_request_detail = (data: any): RequestDetail => ({
        district: data.bairro,
        street: data.endereco,
        email: data.email,
        contact: data.contato,
        state: data.estado,
        city: data.cidade,
        phone: data.telefone,
        social_reason: data.razao_social,
        cnpj: data.cnpj,
        cep: data.cep,
        concurrents: data.concorrentes,
        products: null,
    })

    static getAll = async (params: any): Promise<unknown> => {
        params.just_mine = params.just_mine || ''

        try {
            const response = await HttpClient.get(
                Constants.URL_API_REQUESTS,
                params
            )

            const orders = response.data.data.map((req: any) =>
                RequestService.parse(req)
            )
            const total = response.data.size
            const currentPage = params.page || 1
            const lastPage = Math.ceil(total / 20)

            return {
                ...response,
                data: orders,
                total,
                current_page: currentPage,
                last_page: lastPage,
            }
        } catch (error) {
            console.error('Error fetching requests:', error)
            throw error
        }
    }

    static load_others_request = async (id: number): Promise<Request> => {
        return HttpClient.get(Constants.URL_V1_API_OTHERS_REQUESTS, {
            numerocotacao: id,
        })
            .then((response) => response.cotacoes.data)
            .then((response) => response[0])
            .then((response) => RequestService.parse_others_v1(response))
    }

    static getById = (id: string): Promise<Request> =>
        HttpClient.get(`${Constants.URL_API_ORDER_DETAILS}${id}`).then(
            (response) => {
                response.cliente.cid_nome = response.cliente.cidade
                response.cliente.cli_bairro = response.cliente.bairro
                response.cliente.est_nome = response.cliente.estado

                const order = RequestService.parse(response.pedido)
                const products: Product[] = []
                response.produtos.forEach((productFromApi: any) => {
                    products.push(ProductService.parse(productFromApi))
                })
                return order
            }
        )

    static load_request_products = async (
        id: number,
        seller_id: number
    ): Promise<RequestDetail> => {
        const query = {
            vendedor: seller_id,
            cotacao: id,
            header: true,
            idpedido: null,
        }
        return HttpClient.get(
            Constants.URL_API_REQUEST_PRODUCTS_AND_DETAILS,
            query
        ).then((response) => {
            const request = RequestService.parse_request_detail(
                response.cabecalho
            )
            const products: Product[] = []
            response.produtos.forEach((productFromApi: any) => {
                products.push(
                    ProductService.parse_request_detail_product(productFromApi)
                )
            })
            request.id = id
            request.products = products
            if (response.entrega || response.entregaOld) {
                request.delivery = DeliveryService.parse(
                    response.entrega || response.entregaOld
                )
                request.delivery.old = response.entrega ? false : true
            }
            return request
        })
    }

    static load_request = async (id: number): Promise<Request> => {
        return HttpClient.get(Constants.URL_V1_API_REQUESTS, {
            numerocotacao: id,
            others: true,
        })
            .then((response) => response.cotacoes.data)
            .then((response) => response[0])
            .then((response) => RequestService.parse_v1(response))
    }

    static dissociate = async (id: number, seller_id: number) => {
        return HttpClient.delete(
            `/v2/requests/${id}/sellers/${seller_id}/dissociate`
        )
    }

    static add_seller_to_request = (id: any, seller_id: any) => {
        return HttpClient.put(`/v2/requests/${id}/sellers/add`, {
            vendedor_id: seller_id,
            cot_id: id,
        })
    }

    static confirm = (orderId: string): Promise<any> =>
        HttpClient.post(Constants.URL_API_ORDER_CONFIRM, { pedido: orderId })

    static mark_no_offers = (data: any): Promise<any> =>
        HttpClient.post('/naotrabalharcotacao', data)

    static ExportProductsExcel = (cot: number): Promise<any> => {
        return HttpClient.get(`v2/requests/${cot}/products`)
    }

    static ImportPricesExcel = (data: any): Promise<any> => {
        return HttpClient.put(`v2/offerImport/`, data)
    }
}
