import { spawn } from 'child_process'
import './index.scss'
import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6'

interface Metric {
    percentage: number
    description: string
    sign: '+' | '-' | '='
}

interface CardInfosProps {
    title: string
    total?: number
    totalPrice?: number
    percentage?: number
    metrics: Metric[]
}

export const CardInfos: React.FC<CardInfosProps> = ({
    title,
    total,
    totalPrice,
    percentage,
    metrics,
}) => {
    const getMetricStyle = (sign: string) => {
        switch (sign) {
            case '+':
                return { color: '#4caf50', icon: <FaArrowTrendUp /> }
            case '-':
                return { color: '#f44336', icon: <FaArrowTrendDown /> }
            case '=':
            default:
                return { color: '#2196f3', icon: <FaLongArrowAltRight /> }
        }
    }

    return (
        <div className="card">
            <h3 className="card-title">{title}</h3>
            {total && (
                <p className="card-total">
                    {total?.toLocaleString()}{' '}
                    {percentage && <span>({percentage.toFixed()}%)</span>}
                </p>
            )}
            {totalPrice && (
                <p className="card-totalPrice">
                    R$ {totalPrice?.toLocaleString()}
                </p>
            )}
            <div className="card-metrics">
                {metrics.map((metric, index) => {
                    const { color, icon } = getMetricStyle(metric.sign)

                    return (
                        <p key={index} className="metric">
                            <span className="metric-icon" style={{ color }}>
                                {icon}
                            </span>
                            <span
                                style={{ color, marginRight: 6 }}
                            >{` ${metric.percentage.toFixed(1)}% `}</span>
                            {metric.description}.
                        </p>
                    )
                })}
            </div>
        </div>
    )
}
