import './index.scss'

type RankingPositionProps = {
    position: number
}

export const RankingPosition: React.FC<RankingPositionProps> = ({
    position,
}) => {
    return (
        <div className="ranking-container">
            <span className="ranking-number">{position}°</span>
        </div>
    )
}
