import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity'
import styled from 'styled-components'

interface props {
    isActive
}

export const Container = styled.div<props>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: ${({ isActive }) =>
        isActive ? 'var(--primary-opacity)' : '#fff'};
    height: 45px;
    padding: 0 30px;
    cursor: pointer;

    font-size: 14px;
    font-family: 'Rubik';
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
    color: ${({ isActive }) => (isActive ? '#404040' : '#605F5F')};

    svg {
        color: var(--primary);
        font-size: 20px;
        margin-right: 8px;
    }
`
