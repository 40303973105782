import './index.scss'

import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import { useEffect, useState } from 'react'
import ProductService from 'services/ProductService'
import { DirectSaleProduct } from 'interfaces/directSaleProduct'
import { Loader } from 'components/loader'
import ButtonV3 from 'components/Buttonv3'
import { FeedbackError } from 'components/feedback/error'

type InfoAnalyticsProps = {
    isVisible: boolean
    onClose: () => void
    product?: DirectSaleProduct
    user?: any
    date: any
}

interface IResponseAnalytics {
    totalQuantity: number
    totalAmount: number
    data: DataItemAnalytics[]
}

type DataItemAnalytics = {
    pedido_id: number
    loj_description: string
    cli_id: number
    cli_cnpj: string
    cli_nome: string
    status: string
    tipo_recebimento: string
    total_quantidade: number
    total: number
}

export const InfoAnalytics = ({
    isVisible,
    onClose,
    product,
    user,
    date,
}: InfoAnalyticsProps) => {
    const [loadingTable, setLoadingTable] = useState(false)
    const [itemExtract, setItemExtract] = useState<any>([])
    const [itemInfoAnalytics, setItemInfoAnalytics] =
        useState<IResponseAnalytics>()

    const modal = {
        icon: <GrGroup color="white" />,
        styles: { maxWidth: '80vw' },
        id: 'info-analytics',
        title: `Informações Analíticas`,
    }
    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')

            loadInfoanalytics()
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible, user, product])

    const loadInfoanalytics = () => {
        if (!user || !product || !date) return

        const formattedDate = date.split('/').reverse().join('-')

        setLoadingTable(true)

        ProductService.getInfoAnalytics(
            user.providerId,
            product.id,
            formattedDate
        )
            .then((result) => {
                setItemInfoAnalytics(result)
                setLoadingTable(false)
            })
            .catch((error) => {
                console.error('Erro ao carregar analítica:', error)
                setLoadingTable(false)
            })
    }

    return (
        <div
            style={{
                display: 'flex',
                padding: '10px 30px 30px',
                flexDirection: 'column',
                minHeight: '25vh',
                justifyContent: 'space-between',
                margin: '0 auto',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <p className={'titleProductOnGroupsChoose'}>
                    #{product?.ean} - {product?.description}
                </p>
            </div>

            <div className="NewListItemAnalytics">
                {loadingTable ? (
                    <Loader type="inline" />
                ) : (
                    <>
                        {itemInfoAnalytics &&
                        itemInfoAnalytics.data &&
                        itemInfoAnalytics.data.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Pedido</th>
                                        <th style={{ textAlign: 'left' }}>
                                            Cliente
                                        </th>
                                        <th className="center-text">Status</th>
                                        <th className="center-text">
                                            Tipo Recebimento
                                        </th>
                                        <th className="center-text">
                                            Quant. Total
                                        </th>

                                        <th>Valor Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemInfoAnalytics.data.map(
                                        (item, index) => (
                                            <tr key={index}>
                                                <td data-label="Pedido">
                                                    <span>#</span>
                                                    {item.pedido_id}
                                                </td>
                                                <td data-label="Cliente">
                                                    {item.cli_nome}
                                                </td>
                                                <td
                                                    data-label="Status"
                                                    style={{
                                                        textAlign: 'center',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {item.status}
                                                </td>
                                                <td
                                                    data-label="Tipo Recebimento"
                                                    style={{
                                                        textAlign: 'center',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {item.tipo_recebimento}
                                                </td>
                                                <td
                                                    data-label="Quant. Total"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {item.total_quantidade}
                                                </td>
                                                <td
                                                    data-label="Valor Total"
                                                    align="center"
                                                >
                                                    R$ {item.total.toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td
                                            style={{ fontWeight: 'bold' }}
                                            colSpan={1}
                                        >
                                            Totais:
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {itemInfoAnalytics.data.reduce(
                                                (sum, item) =>
                                                    sum + item.total_quantidade,
                                                0
                                            )}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {/* Soma total dos valores */}
                                            R${' '}
                                            {itemInfoAnalytics.data
                                                .reduce(
                                                    (sum, item) =>
                                                        sum + item.total,
                                                    0
                                                )
                                                .toFixed(2)}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        ) : (
                            <FeedbackError
                                message={
                                    'Não foi encontrado nenhuma informação analítica!'
                                }
                            />
                        )}
                    </>
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '98%',
                    marginTop: 10,
                }}
            >
                <ButtonV3
                    filled
                    title="Voltar"
                    onClick={() => {
                        onClose()
                    }}
                />
            </div>
        </div>
    )
}
