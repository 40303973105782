import { BaseModal } from 'components/BaseModal'
import InputV2 from 'components/InputV2'
import { Button } from 'components/button'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import IconReact from 'components/icons'
import { Loader } from 'components/loader'
import { PaginationCustom } from 'components/pagination-custom'
import { SelectV2 } from 'components/selectV2'
import { Snackbar } from 'components/snackbar'
import { RequestContext } from 'contexts/request'
import OfferModel from 'models/offer.model'
import ProductModel from 'models/product.model'
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import OfferService from 'services/offer.service'
import ClientService from 'services/v3/clients.service'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DerivativeProductsModalComponent } from './components/derivative-products-modal'
import { RequestProductsFilterComponent } from './components/filters'
import { OfferItemRowComponent } from './components/offer-item'
import item_styles from './components/offer-item/index.module.scss'
import { OfferItemRowComponentMobile } from './components/offer-itemMobile'
import { ProductPreviousOffersModal } from './components/product-previous-offers'
import {
    filter_category,
    filter_just_mine,
    filter_offered_only,
    filter_product_description,
    is_visible,
    link_products,
    navigate,
    offer_confirmation,
} from './requested-products.helper'
import ButtonV3 from 'components/Buttonv3'
import { MdCloudUpload } from 'react-icons/md'
import RequestService from 'services/RequestService'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import readXlsxFile from 'read-excel-file'
import UploadButton from 'components/UploadButton'
import { FaFileDownload } from 'react-icons/fa'
import './index.scss'

export const RequestedProductsComponent: FC<any> = ({
    isSafari,
    showFilters,
    others,
    request,
    data,
    onChange,
    seller_id,
    paginationData,
    reloadProducts,
    visible,
}: any) => {
    const defaultFilter = {
        name: '',
        category: '',
        digitados: false,
        meusProdutos: false,
    }

    const [products, setProducts] = useState<ProductModel[]>(data)
    const [filteredProducts, setFilteredProducts] =
        useState<ProductModel[]>(data)
    const [product_show_previous, setProductShowPrevious] =
        useState<ProductModel>()
    const [current_product, setCurrentProduct] = useState<ProductModel>()
    const [current_offer, setCurrentOffer] = useState<OfferModel>()
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [is_filter_visible, show_filters] = useState(false)
    const MySwal = withReactContent(Swal)
    const [processing, set_processing] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const list = useRef<any>({})
    const [filters, setFilters] = useState<any>(defaultFilter)
    const [searchText, setSearchText] = useState('')
    const [categories, setCategories] = useState<any>([])
    const [derivativeProductsModalVisible, setDerivativeProductsModalVisible] =
        useState(false)
    const [
        showPreviousProductModalVisible,
        setShowPreviousProductModalVisible,
    ] = useState(false)
    const containerRef = useRef<any>()
    const { request_products_pagination } = useContext(RequestContext)

    useEffect(() => {
        if (data) {
            const prods = data.filter((p: ProductModel) => p.visible)
            prods
                .filter((p: ProductModel) => p && !p.is_generic)
                .forEach((p: ProductModel) =>
                    p.derivatives.forEach(
                        (pp: ProductModel) => (pp.main_product = p)
                    )
                )
            const derivatives = prods
                .map((p: ProductModel) => p.derivatives)
                .flat()
                .filter(is_visible)
            link_products(derivatives)
            setProducts(prods)
            setFilteredProducts(data.filter((p: ProductModel) => p.visible))
        }
    }, [data])

    const check_offer_pricing = (offer: OfferModel, callback: any) => {
        const perc = 0.3
        if (
            offer.last_offer_price > 0 &&
            offer.price > 0 &&
            (offer.last_offer_price * (1 - perc) >= offer.price ||
                offer.price >= offer.last_offer_price * (1 + perc))
        ) {
            MySwal.fire({
                title: 'Confirmar digitação?',
                html: offer_confirmation(offer),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    callback()
                }
            })
        } else {
            callback()
        }
    }

    const filter = useCallback(
        (filters: any) => {
            const prods = products.slice(0)
            prods.forEach((p: ProductModel) => (p.visible = false))
            prods
                .filter((p: ProductModel) =>
                    filter_product_description(filters.product, p)
                )
                .filter((p: ProductModel) =>
                    filter_offered_only(filters.offered_only, p)
                )
                .filter((p: ProductModel) =>
                    filter_just_mine(filters.just_mine, p)
                )
                .filter((p: ProductModel) =>
                    filter_category(filters.category, p)
                )
                .forEach((p: ProductModel) => (p.visible = true))
            setProducts(prods)
            reprocess()
        },
        [products]
    )

    useEffect(() => {
        if (current_product == null && products) {
            onChange(products)
        }
    }, [current_product])

    const open_previous_offers_modal = (product: ProductModel) => {
        setProductShowPrevious(product)
    }

    const remove_offer = (item: ProductModel) => {
        MySwal.fire({
            title: 'Confirma remoção da digitação?',
            text: 'Esta digitação será removida, mas poderá ser feita novamente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await OfferService.save_quantity(
                    OfferModel.from_product(item, request).to_request({
                        quantidadeDigitacao: 0,
                        alteraEmbalagem: 0,
                    })
                ).then(async () => {
                    return await ClientService.discontinue_product(
                        request.client_id,
                        item.id,
                        seller_id
                    ).then(() => {
                        item.offer.quantity = undefined
                        item.last_offer_price = 0
                        item.is_discontinued = true
                        item.new_offer_price = 0
                        reprocess()
                        Swal.fire(
                            'Removido!',
                            'Sua digitação foi removida com sucesso.',
                            'success'
                        )
                    })
                })
            }
        })
    }

    const continue_product = async (item: ProductModel) => {
        if (item.is_discontinued) {
            item.is_discontinued = false
            await ClientService.continue_product(
                request.client_id,
                item.id,
                seller_id
            )
        }
    }

    const setup_offer = ({
        maskedvalue,
        floatValue,
        deriv,
        main,
    }: {
        maskedvalue?
        floatValue?
        deriv
        main?
        obs?
        new_quantity?
    }) => {
        console.log('--', floatValue)
        const offer = OfferModel.from_product(deriv, request).with_price(
            floatValue
        )

        offer.display_price = maskedvalue
        offer.generic = main && main.is_generic
        offer.offer_quantity = offer.generic ? offer.offer_quantity : 1
        offer.purchase_unity = main?.purchase_unity
        offer.seller_id = seller_id
        offer.others = others ? true : false
        offer.changed = true

        setCurrentOffer(offer)
    }

    const reprocess = () => {
        if (list.current && list.current.resetAfterIndex) {
            list.current.resetAfterIndex(0)
        }
    }

    const save = (callback: any = null) => {
        if (current_offer?.changed) {
            check_offer_pricing(current_offer, () => {
                OfferService.save(current_offer?.to_request()).then(() => {
                    setCurrentOffer(undefined)

                    if (callback) callback()

                    link_products(products)

                    openSnackbar(
                        <Snackbar icon="attach_money">
                            Digitação salva com sucesso{' '}
                            {current_offer?.display_price}
                        </Snackbar>
                    )
                })
            })
        }
    }

    const save_quantity = (callback: any = null) => {
        if (current_offer?.changed) {
            OfferService.save_quantity(
                current_offer?.to_request({ alteraEmbalagem: 1 })
            )
                .then(() => {
                    set_processing(false)

                    if (callback) callback()
                    openSnackbar(
                        <Snackbar icon="tune">
                            Quantidade atualizada com sucesso!
                        </Snackbar>
                    )
                })
                .then(() => set_processing(false))
        }
    }

    const save_obs = (callback: any = null) => {
        if (current_offer?.changed) {
            check_offer_pricing(current_offer, () => {
                OfferService.save_obs(
                    current_offer?.to_request({ observacao: current_offer.obs })
                ).then(() => {
                    setCurrentOffer(undefined)

                    if (callback) callback()

                    openSnackbar(
                        <Snackbar icon="sticky_note_2">
                            Observação salva com sucesso
                        </Snackbar>
                    )
                })
            })
        }
    }

    const getItemSize = (index: number) => {
        const DEFAULT_HEIGHT = 40
        const prod = products.filter((p) => p.visible)[index]
        return prod && prod.derivatives
            ? prod.derivatives.filter(is_visible).length * DEFAULT_HEIGHT +
                  DEFAULT_HEIGHT
            : DEFAULT_HEIGHT
    }

    useEffect(() => {
        if (request.categories) {
            setCategories(
                request.categories.map((cat: any) => {
                    return { label: cat, value: cat }
                })
            )
        }
    }, [request])

    const clearFilters = () => {
        setFilters({ product: '' })
    }

    function loadrequest() {
        // RequestService.load_request_products()
    }
    const { id } = useParams<{ id: any }>()

    // async function changePage(page) {
    //     setLoading(true)
    //     await reloadProducts(id, seller_id, page)
    //     setLoading(false)
    //     // setLoading(false)
    // }
    // useEffect(() => {
    //     // filter(filters)
    //     // setFilteredProducts(data.filter((p: ProductModel) => p.visible))
    //     handleAplyFilters(page,filters)
    // }, [filters])
    useEffect(() => {
        handleAplyFilters(page, filters, searchText)
    }, [page, filters])

    function clearFilter() {
        setFilters(defaultFilter)
        setSearchText('')
    }

    // useEffect(() => {
    //     handleSetSearchText(searchText)
    // }, [searchText])

    function handleSetSearchText() {
        if (filters.name != searchText) {
            setFilters({ ...filters, name: searchText })
        }
    }

    async function handleAplyFilters(page, filters, searchText) {
        setLoading(true)
        let name = searchText

        let digitados = filters.digitados ? 1 : 0
        let meusProdutos = filters.meusProdutos ? 1 : 0

        await reloadProducts(
            id,
            seller_id,
            page,
            filters.category,
            name,
            digitados,
            meusProdutos
        )
        //?
        setTimeout(() => {
            scrolltoTOp()
        }, 500)
        setLoading(false)
    }

    function scrolltoTOp() {
        window.scrollTo({
            top: containerRef.current.offsetTop,
            behavior: 'smooth',
        })

        // if (scroll2Ref.current) {
        //   console.log('tem current')
        //   scroll2Ref.current.scrollTo({
        //     top: 0,
        //     behavior: 'smooth',
        //   });
        // }
    }

    const is_editable = () => {
        return (
            request &&
            ![
                'CONGELADA',
                'PEDIDO GERADO',
                'COTACAO GERADA',
                'SEM PEDIDO',
            ].includes(request.status) &&
            !is_expired()
        )
    }

    const is_expired = () => {
        return new Date(request.date) <= new Date()
    }

    const exportExcel = async () => {
        const cot = request.id
        if (!cot) return

        try {
            const response = await RequestService.ExportProductsExcel(cot)

            if (!response) {
                toast.error(
                    'Erro ao exportar para Excel, tente novamente mais tarde.'
                )

                return
            } else if (response.length <= 0) {
                toast.info('Nenhum dado disponível para exportação no momento.')

                return
            }

            const formattedData = response.map((item) => ({
                CÓDIGO: item.pro_ean,
                NOME: item.pro_descricao,
                PREÇO: '',
                // EMBALAGEM: item.pro_embalagem,
            }))

            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.json_to_sheet(formattedData)

            // Ajuste da largura das colunas
            worksheet['!cols'] = [
                { wch: 15 }, // CÓDIGO
                { wch: 40 }, // NOME
                { wch: 10 }, // PREÇO
                // { wch: 20 }, // EMBALAGEM
            ]

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Ranking')
            XLSX.writeFile(workbook, `produtos_cotacao_${cot}.xlsx`)

            toast.success('Planilha exportada com sucesso.')
        } catch (error) {
            console.error('Erro ao exportar para Excel:', error)
            toast.error('Erro ao exportar para Excel.')
        }
    }

    const importExcel = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        const file = event.target.files?.[0]

        if (!file) {
            toast.error('Nenhum arquivo selecionado.')
            setLoading(false)
            return
        }

        try {
            const rows = await readXlsxFile(file)

            const payload: {
                preco: number | string
                product_ean: string | number
                seller_id: number
                cot_id: number
                for_id: number
            }[] = []

            for (let i = 1; i < rows.length; i++) {
                const [product_ean, , preco] = rows[i] // Assume que o preço está na terceira coluna

                if (product_ean && preco !== '#ERROR_#N/A') {
                    payload.push({
                        preco: preco as number | string,
                        product_ean: product_ean as string | number,
                        seller_id: request.seller_id as number,
                        cot_id: request.id as number,
                        for_id: request.provider_id as number,
                    })
                }
            }
            if (payload.length === 0) {
                toast.info('Nenhum dado válido encontrado no arquivo.')

                return
            }
            await RequestService.ImportPricesExcel(payload).then(() =>
                reloadProducts(request.id, request.seller_id)
            )
            toast.success('Preços importados com sucesso!')
        } catch (error) {
            console.error('Erro ao importar Excel:', error)
            toast.error(
                'Erro ao importar Excel. Verifique o arquivo e tente novamente.'
            )
        } finally {
            setLoading(false)
            setTimeout(() => {
                scrolltoTOp()
            }, 300)
        }
    }

    return (
        <section ref={containerRef} className="products">
            {isLoading ? <Loader /> : null}
            <header>
                {
                    //   <div className="actions flex items-center">
                    //       <label className="checkbox input-label">
                    //           <ToggleButton
                    //               inactiveLabel={''}
                    //               activeLabel={null}
                    //               colors={{
                    //                   activeThumb: {
                    //                       base: 'white',
                    //                   },
                    //                   inactiveThumb: {
                    //                       base: 'white',
                    //                   },
                    //                   active: {
                    //                       base: '#ff7110',
                    //                       hover: '#ff7110c9',
                    //                   },
                    //                   inactive: {
                    //                       base: '#e2e2e2',
                    //                       hover: '#d9d9d9',
                    //                   }
                    //               }}
                    //               value={filters.just_mine || false}
                    //               onToggle={(value: any) => {
                    //                   setFilters({ ...filters, just_mine: !value })
                    //               }} />
                    //           Apenas meus produtos
                    //       </label>
                    //   </div>
                }

                {false && (
                    <RequestProductsFilterComponent
                        onFilter={(params: any) =>
                            setFilters({ ...filters, ...params })
                        }
                        request={request}
                    />
                )}
                {showFilters ? (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                type="reset"
                                onClick={() => clearFilter()}
                                className="c-btn-outline"
                            >
                                Limpar filtros
                            </Button>
                        </div>
                        <div
                            // style={{
                            //     display: 'flex',
                            //     alignItems: 'flex-end',
                            //     gap: 20,
                            //     flexWrap: 'wrap',
                            // }}
                            className="filtersContainerExcel"
                        >
                            <div className="divInputFilter">
                                <InputV2
                                    infoIconNull
                                    onBlur={() => handleSetSearchText()}
                                    type="text"
                                    id="product-description"
                                    label="Buscar por descrição"
                                    icon={<IconReact type={'search'} />}
                                    placeholder="Descrição..."
                                    value={searchText}
                                    onChange={(e: any) =>
                                        setSearchText(e.target.value)
                                    }
                                />
                            </div>
                            <div className="divInputFilter">
                                <SelectV2
                                    id="category"
                                    description="Categoria"
                                    defaultValue=""
                                    blank_label="Selecione uma categoria"
                                    onChange={(e: any) =>
                                        setFilters({
                                            ...filters,
                                            category: e.target.value,
                                        })
                                    }
                                    options={categories}
                                    value={filters.category}
                                    value_key="value"
                                    label_key="label"
                                />
                            </div>
                            <div className="checkboxInputs">
                                <CheckBoxCustom
                                    checked={filters.meusProdutos}
                                    value={filters.meusProdutos}
                                    change={(value: any) => {
                                        setFilters({
                                            ...filters,
                                            meusProdutos: !value,
                                        })
                                    }}
                                    description="Apenas meus produtos"
                                />
                            </div>

                            <div className="checkboxInputs">
                                <CheckBoxCustom
                                    checked={filters.digitados}
                                    value={filters.digitados}
                                    change={(value: any) => {
                                        setFilters({
                                            ...filters,
                                            digitados: !value,
                                        })
                                    }}
                                    description="Apenas digitados"
                                />
                            </div>
                            <div className="buttonsImportExport">
                                <div>
                                    <ButtonV3
                                        type="button"
                                        title={'Exportar'}
                                        Icon={
                                            <FaFileDownload className="iconButtonFilter" />
                                        }
                                        onClick={() => exportExcel()}
                                    />
                                </div>
                                <div>
                                    <UploadButton
                                        icon={<MdCloudUpload />}
                                        title="Importar"
                                        onFileSelect={importExcel}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </header>
            {!processing && (
                <>
                    <div
                        style={{ marginTop: 25 }}
                        className={'tableContainerRequested'}
                    >
                        <div style={{ minWidth: 1100 }} className="table">
                            <div className={`row t-head ${item_styles.header}`}>
                                <div className="col">CÓDIGO</div>
                                <div className="col"></div>
                                <div className="col">DESCRIÇÃO/MARCA</div>
                                <div className="col"></div>
                                <div className="col">QTD. NA EMBALAGEM</div>
                                <div className="col"></div>
                                <div className="col">PREÇO</div>
                                <div className="col">ULT.PREÇO</div>
                                <div className="col text-center">
                                    OBSERVAÇÃO
                                </div>
                            </div>
                            <div className="t-body">
                                {
                                    // products && <AutoSizer>
                                    //     {({ height, width }) => (
                                    //  <VariableSizeList
                                    //             height={height}
                                    //             width={width}
                                    //             className="bounceInRight"
                                    //             itemCount={(products && products.filter((p: ProductModel) => p.visible).length) || 0}
                                    //             itemSize={getItemSize}
                                    //             ref={list}
                                    //             itemData={{
                                    //                 products: products.filter((p: ProductModel) => p.visible),
                                    //                 request, setCurrentProduct, seller_id,
                                    //                 remove_offer, setup_offer, save_quantity,
                                    //                 setCurrentOffer, save_obs, save, open_previous_offers_modal
                                    //             }}
                                    //         >
                                    //             {OfferItemRowComponent}
                                    //         </VariableSizeList>)}
                                    // </AutoSizer>

                                    filteredProducts &&
                                        filteredProducts.map((item, index) => {
                                            return (
                                                <OfferItemRowComponent
                                                    ref={list}
                                                    isSafari={isSafari}
                                                    length={
                                                        filteredProducts.length
                                                    }
                                                    index={index}
                                                    is_editable={is_editable}
                                                    data={{
                                                        products: item,
                                                        request,
                                                        setCurrentProduct,
                                                        setShowPreviousProductModalVisible,
                                                        seller_id,
                                                        remove_offer,
                                                        setup_offer,
                                                        save_quantity,
                                                        continue_product,
                                                        setCurrentOffer,
                                                        save_obs,
                                                        save,
                                                        open_previous_offers_modal,
                                                        setDerivativeProductsModalVisible,
                                                    }}
                                                />
                                            )
                                        })
                                }

                                {request_products_pagination && visible ? (
                                    <div style={{ width: 'inherit' }}>
                                        <PaginationCustom
                                            total={Number(
                                                request_products_pagination.last_page -
                                                    1
                                            )}
                                            // total={100}
                                            itemsName={'produtos'}
                                            ItemsPorPage={Number(
                                                request_products_pagination.per_page
                                            )}
                                            currentPage={Number(
                                                request_products_pagination.current_page
                                            )}
                                            onPageChange={(page: number) =>
                                                setPage(page)
                                            }
                                            totalItems={
                                                request_products_pagination.total
                                            }
                                            // total={clients.meta.last_page}
                                            // itemsName={'produtos'}
                                            // ItemsPorPage="100"
                                            // currentPage={clients.meta.current_page}
                                            // onPageChange={(page: number) =>
                                            //     setQueryParams({ ...queryParams, page })}
                                            // totalItems={clients.meta.total}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className={'tableContainerRequestedMobile'}>
                        {filteredProducts &&
                            filteredProducts.map((item, index) => {
                                return (
                                    <OfferItemRowComponentMobile
                                        isSafari={isSafari}
                                        index={index}
                                        is_editable={is_editable}
                                        data={{
                                            products: item,
                                            request,
                                            setCurrentProduct,
                                            setShowPreviousProductModalVisible,
                                            seller_id,
                                            remove_offer,
                                            setup_offer,
                                            save_quantity,
                                            setCurrentOffer,
                                            save_obs,
                                            save,
                                            open_previous_offers_modal,
                                            setDerivativeProductsModalVisible,
                                        }}
                                    />
                                )
                            })}
                        {request_products_pagination && visible ? (
                            <div style={{ width: '100%' }}>
                                <PaginationCustom
                                    total={Number(
                                        request_products_pagination.last_page -
                                            1
                                    )}
                                    // maxWidth={250}
                                    // total={100}
                                    itemsName={'produtos'}
                                    ItemsPorPage={Number(
                                        request_products_pagination.per_page
                                    )}
                                    currentPage={Number(
                                        request_products_pagination.current_page
                                    )}
                                    onPageChange={(page: number) =>
                                        setPage(page)
                                    }
                                    totalItems={
                                        request_products_pagination.total
                                    }

                                    // total={clients.meta.last_page}
                                    // itemsName={'produtos'}
                                    // ItemsPorPage="100"
                                    // currentPage={clients.meta.current_page}
                                    // onPageChange={(page: number) =>
                                    //     setQueryParams({ ...queryParams, page })}
                                    // totalItems={clients.meta.total}
                                />
                            </div>
                        ) : null}
                    </div>
                </>
            )}

            {processing && (
                <Loader type="inline" className="prod-list-loader" />
            )}
            {
                product_show_previous && (
                    <BaseModal
                        title={'Digitações Anteriores'}
                        isVisible={showPreviousProductModalVisible}
                        onCloseCallback={() =>
                            setShowPreviousProductModalVisible(false)
                        }
                        children={
                            <ProductPreviousOffersModal
                                request={request}
                                seller_id={seller_id}
                                setProductShowPrevious={setProductShowPrevious}
                                product={product_show_previous}
                            />
                        }
                    />
                )

                //  <ProductPreviousOffersModal
                //      request={request}
                //      seller_id={seller_id}
                //      setProductShowPrevious={setProductShowPrevious}
                //      product={product_show_previous}
                //  />
            }
            {
                current_product && (
                    <BaseModal
                        title={current_product.name}
                        isVisible={derivativeProductsModalVisible}
                        onCloseCallback={() =>
                            setDerivativeProductsModalVisible(false)
                        }
                        children={
                            <DerivativeProductsModalComponent
                                isSafari={isSafari}
                                is_editable={is_editable}
                                navigate={navigate}
                                save={save}
                                save_quantity={save_quantity}
                                save_obs={save_obs}
                                setCurrentOffer={setCurrentOffer}
                                setup_offer={setup_offer}
                                continue_product={continue_product}
                                request={request}
                                onChange={onChange}
                                products={products}
                                reprocess={reprocess}
                                setCurrentProduct={setCurrentProduct}
                                product={current_product}
                            />
                        }
                    />
                )

                //  <DerivativeProductsModalComponent
                //              is_editable={is_editable}
                //              navigate={navigate}
                //              save={save}
                //              save_quantity={save_quantity}
                //              save_obs={save_obs}
                //              setCurrentOffer={setCurrentOffer}
                //              setup_offer={setup_offer}
                //              request={request}
                //             onChange={onChange}
                //              products={products}
                //              reprocess={reprocess}
                //              setCurrentProduct={setCurrentProduct}
                //              product={current_product}
                //          />
            }
        </section>
    )
    {
        // <section className="products">
        // <header className="flex items-center justify-space-between mb-2">
        //     {
        //      //   <p className="title">Lista de Produtos</p>
        //     }
        //     <div className="actions flex items-center">
        //         <Button className="c-btn-outline" onClick={() => show_filters(!is_filter_visible)}>
        //             <FiFilter />Filtros
        //         </Button>
        //         <label className="checkbox input-label">
        //             <ToggleButton
        //                 inactiveLabel={''}
        //                 activeLabel={null}
        //                 colors={{
        //                     activeThumb: {
        //                         base: 'white',
        //                     },
        //                     inactiveThumb: {
        //                         base: 'white',
        //                     },
        //                     active: {
        //                         base: '#ff7110',
        //                         hover: '#ff7110c9',
        //                     },
        //                     inactive: {
        //                         base: '#e2e2e2',
        //                         hover: '#d9d9d9',
        //                     }
        //                 }}
        //                 value={filters.just_mine || false}
        //                 onToggle={(value: any) => {
        //                     setFilters({ ...filters, just_mine: !value })
        //                 }} />
        //             Apenas meus produtos
        //         </label>
        //     </div>
        // </header>
        // {is_filter_visible && <RequestProductsFilterComponent
        //     onFilter={(params: any) => setFilters({ ...filters, ...params })}
        //     request={request} />}
        // {!processing && <div className="table container-fluid rounded">
        //     <div className={`row t-head ${item_styles.header}`}>
        //         <div className="col">CÓDIGO</div>
        //         <div className="col">DESCRIÇÃO/MARCA</div>
        //         <div className="col">QTD. NA EMBALAGEM</div>
        //         <div className="col">PREÇO</div>
        //         <div className="col">ULT.PREÇO</div>
        //         <div className="col text-center">OBSERVAÇÃO</div>
        //     </div>
        //     <div className="t-body">
        //         {products && <AutoSizer>
        //             {({ height, width }) => (
        //                 <VariableSizeList
        //                     height={height}
        //                     width={width}
        //                     className="bounceInRight"
        //                     itemCount={(products && products.filter((p: ProductModel) => p.visible).length) || 0}
        //                     itemSize={getItemSize}
        //                     ref={list}
        //                     itemData={{
        //                         products: products.filter((p: ProductModel) => p.visible),
        //                         request, setCurrentProduct, seller_id,
        //                         remove_offer, setup_offer, save_quantity,
        //                         setCurrentOffer, save_obs, save, open_previous_offers_modal
        //                     }}
        //                 >
        //                     {OfferItemRowComponent}
        //                 </VariableSizeList>)}
        //         </AutoSizer>}
        //     </div>
        // </div>}
        // {processing && <Loader type="inline" className="prod-list-loader" />}
        // {product_show_previous && <ProductPreviousOffersModal
        //     request={request}
        //     seller_id={seller_id}
        //     setProductShowPrevious={setProductShowPrevious}
        //     product={product_show_previous} />}
        // {current_product && <DerivativeProductsModalComponent
        //     is_editable={is_editable}
        //     navigate={navigate}
        //     save={save}
        //     save_quantity={save_quantity}
        //     save_obs={save_obs}
        //     setCurrentOffer={setCurrentOffer}
        //     setup_offer={setup_offer}
        //     request={request}
        //     onChange={onChange}
        //     products={products}
        //     reprocess={reprocess}
        //     setCurrentProduct={setCurrentProduct}
        //     product={current_product} />}
        //</section>
    }
}
