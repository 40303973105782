import React, { useState } from 'react'
import DateService from 'services/DateService'
import { FilterContainer, FilterTitle, FilterValue } from './styles'
import Logo from '../../../assets/Logo-BRANCA.svg'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import {
    ChecksContainer,
    CustomCheckContainer,
    TableContainer,
} from '../styles'
import { Loader } from 'components/loader'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { CiFaceSmile } from 'react-icons/ci'
import { CiFaceMeh } from 'react-icons/ci'
import { CiFaceFrown } from 'react-icons/ci'
import { BsQuestion } from 'react-icons/bs'
import { ThemeContext } from 'contexts/whiteLabel/context'
interface Props {
    contentTable: any
    params: any
    salesmen?: any
    consolidarEan: boolean
}

export class PrintReport extends React.PureComponent<Props> {
    static contextType = ThemeContext
    render() {
        const { theme } = this.context
        let vendedor = this.props.params.vendedor
            ? this.props.salesmen.filter(
                  (elem) => elem.id == this.props.params.vendedor
              )[0].name
            : null
        const formatedParams = {
            ...this.props.params,
            vendedor: vendedor,
            dataini: DateService.formatToDisplay(
                new Date(this.props.params.dataini)
            ),
            datafim: DateService.formatToDisplay(
                new Date(this.props.params.datafim)
            ),
        }

        function validarProduto(produto) {
            if (produto.count_solicitacoes > 0) {
                if (
                    produto.quantidade_solicitacao != null &&
                    produto.quantidade_solicitacao > 0
                ) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        }
        function formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
        function handleReturnIcon(iconClass) {
            switch (iconClass) {
                case 'cc-color-05':
                    return <CiFaceSmile size={24} />
                    break
                case 'cc-color-510':
                    return <CiFaceMeh size={24} />
                    break
                case 'cc-color-10':
                    return <CiFaceFrown size={24} />
                    break
                case 'cc-color-blue':
                    return <BsQuestion size={24} />
                    break

                default:
                    return <></>
                    break
            }
        }

        return (
            <div style={{ padding: 20 }}>
                <div style={{ marginBottom: 20 }}>
                    <PageHeaderV2 title="Relatório de Vendas de Produto"></PageHeaderV2>
                </div>

                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {formatedParams && (
                        <div
                            style={{
                                display: 'flex',
                                gap: 7,
                                flexWrap: 'wrap',
                            }}
                        >
                            {formatedParams.dataini && (
                                <FilterContainer style={{ display: 'flex' }}>
                                    <FilterTitle>Data Inicio</FilterTitle>
                                    <FilterValue>
                                        {formatedParams.dataini}
                                    </FilterValue>
                                </FilterContainer>
                            )}
                            {formatedParams.datafim && (
                                <FilterContainer style={{ display: 'flex' }}>
                                    <FilterTitle>Data Fim</FilterTitle>
                                    <FilterValue>
                                        {formatedParams.datafim}
                                    </FilterValue>
                                </FilterContainer>
                            )}
                            {formatedParams.numerocotacao && (
                                <FilterContainer style={{ display: 'flex' }}>
                                    <FilterTitle>Cotação</FilterTitle>
                                    <FilterValue>
                                        {formatedParams.numerocotacao}
                                    </FilterValue>
                                </FilterContainer>
                            )}
                            {formatedParams.vendedor && (
                                <FilterContainer style={{ display: 'flex' }}>
                                    <FilterTitle>Vendedor</FilterTitle>
                                    <FilterValue>
                                        {formatedParams.vendedor}
                                    </FilterValue>
                                </FilterContainer>
                            )}
                            {formatedParams.cliente && (
                                <FilterContainer style={{ display: 'flex' }}>
                                    <FilterTitle>Cliente</FilterTitle>
                                    <FilterValue>
                                        {formatedParams.cliente}
                                    </FilterValue>
                                </FilterContainer>
                            )}
                        </div>
                    )}
                    <div
                        style={{
                            padding: '7px 20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 10,
                            backgroundColor: `${
                                theme?.colors?.primary || '#f77110'
                            }`,
                        }}
                    >
                        <img
                            src={theme?.imgs?.img_logo || Logo}
                            color={'red'}
                            alt="logo do club da cotação"
                            className="HeaderLogo"
                        />
                    </div>
                </div>
                <ChecksContainer style={{ marginTop: 10 }}>
                    <CustomCheckContainer
                        bg={'rgba(48, 170, 76, 0.1)'}
                        color={'#30AA4C'}
                    >
                        {/* <CheckBoxCustom checked={selectedStatus.includes('cc-color-05')} change={() => handleSetStatus('cc-color-05')} description=''></CheckBo> */}
                        <section>
                            <CiFaceSmile size={24} />
                        </section>
                        <p>Competitivo</p>
                    </CustomCheckContainer>
                    <CustomCheckContainer
                        bg={'rgba(255, 113, 16, 0.1)'}
                        color={'#FF7110'}
                    >
                        {/* <CheckBoxCustom checked={selectedStatus.includes('cc-color-510')} change={() => handleSetStatus('cc-color-510')} description=''></CheckBoxCustom> */}
                        <section>
                            <CiFaceMeh size={24} />
                        </section>
                        <p>Intermediário</p>
                    </CustomCheckContainer>
                    <CustomCheckContainer
                        bg={'rgba(96, 96, 96, 0.1)'}
                        color={'#505050'}
                    >
                        {/* <CheckBoxCustom checked={selectedStatus.includes('cc-color-10')} change={() => handleSetStatus('cc-color-10')} description=''></CheckBoxCustom> */}
                        <section>
                            <CiFaceFrown size={24} />
                        </section>
                        <p>Não Competitivo</p>
                    </CustomCheckContainer>
                    <CustomCheckContainer
                        bg={'rgba(23, 162, 184, 0.1)'}
                        color={'#17A2B8'}
                    >
                        {/* <CheckBoxCustom checked={selectedStatus.includes('cc-color-blue')} change={() => handleSetStatus('cc-color-blue')} description=''></CheckBoxCustom> */}
                        <section>
                            <BsQuestion size={24} />
                        </section>
                        <p>Outros</p>
                    </CustomCheckContainer>
                </ChecksContainer>
                <TableContainer style={{ width: '100%' }}>
                    {!this.props.consolidarEan ? (
                        <>
                            <div className="NewlistEditCampaign">
                                <table className="newTableEditCampaign">
                                    <thead>
                                        <tr>
                                            <th className="">Descrição</th>
                                            <th>Qtd</th>
                                            <th className="text-center">
                                                Val. digitado
                                            </th>
                                            <th className="text-center">
                                                Embalagem
                                            </th>
                                            <th className="text-center">
                                                Estado
                                            </th>
                                            <th className="text-center">
                                                Cidade
                                            </th>
                                            <th className="">Cliente </th>
                                            <th className="text-center">
                                                Solicitação
                                            </th>
                                            <th className="text-center">
                                                Data
                                            </th>
                                            <th className="text-center">
                                                Vendedor
                                            </th>
                                        </tr>
                                    </thead>
                                    {false ? (
                                        <Loader />
                                    ) : (
                                        <tbody>
                                            {
                                                this.props.contentTable
                                                    ?.length > 0 ? (
                                                    this.props.contentTable.map(
                                                        (item, index) => (
                                                            <>
                                                                {item.class !=
                                                                0 ? (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Descrição"
                                                                            className=""
                                                                        >
                                                                            {
                                                                                item.pro_descricao
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Qtd"
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.quantidade
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Val. digitado"
                                                                            className={`text-center `}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                    gap: 4,
                                                                                    alignItems:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                {handleReturnIcon(
                                                                                    item.class
                                                                                )}
                                                                                <div
                                                                                    className={
                                                                                        item.class
                                                                                    }
                                                                                >
                                                                                    R${' '}
                                                                                    {item.valor
                                                                                        ? item.valor
                                                                                        : 0}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Embalagem"
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.pro_embalagem
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Estado"
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.est_sigla
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Cidade"
                                                                            className=""
                                                                        >
                                                                            {
                                                                                item.cid_nome
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Cliente"
                                                                            className=""
                                                                        >
                                                                            {
                                                                                item.loj_razao
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Solicitação"
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.id_cotacao
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Data"
                                                                            className="text-center"
                                                                        >
                                                                            {formatDate(
                                                                                item.data_cotacao.substring(
                                                                                    0,
                                                                                    10
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Vendedor"
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.nome_vendedor
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ) : null}
                                                            </>
                                                        )
                                                    )
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <h6
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        Nenhum
                                                                        registro
                                                                        encontrado!
                                                                    </h6>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                                // ))
                                            }
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* //! --------------------------------tabela consolidar ean */}
                            <div className="NewlistEditCampaign">
                                <table className="newTableEditCampaign">
                                    <thead>
                                        <tr>
                                            <th className="">Descrição</th>
                                            <th>EAN</th>
                                            <th className="text-center">
                                                Total Qtd.
                                            </th>
                                            <th className="text-center">
                                                Total solicitações
                                            </th>
                                        </tr>
                                    </thead>
                                    {false ? (
                                        <Loader />
                                    ) : (
                                        <tbody>
                                            {
                                                this.props.contentTable.length >
                                                0 ? (
                                                    this.props.contentTable.map(
                                                        (item, index) => (
                                                            <>
                                                                {validarProduto(
                                                                    item
                                                                ) ? (
                                                                    <tr
                                                                        // onClick={() => item.itensEan.length > 0 ? handleSetExpandedItem(item.pro_ean) : {}}
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            cursor:
                                                                                item
                                                                                    .itensEan
                                                                                    .length >
                                                                                0
                                                                                    ? 'pointer'
                                                                                    : 'default',
                                                                        }}
                                                                    >
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Descrição"
                                                                            className=""
                                                                        >
                                                                            {/* <FaAngleDown color={item.itensEan.length > 0 ? '#ff7110' : '#c7c7c7'} className='chevronDownEan'></FaAngleDown> */}

                                                                            {
                                                                                item.pro_descricao
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="EAN"
                                                                            className=""
                                                                        >
                                                                            {
                                                                                item.pro_ean
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Total Qtd."
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.quantidade_solicitacao
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{}}
                                                                            data-label="Total solicitações"
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                item.quantidade_solicitacoes
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ) : null}
                                                                {true ? (
                                                                    <tr
                                                                        style={{
                                                                            transition:
                                                                                '200ms',
                                                                        }}
                                                                        className="trAccordion"
                                                                    >
                                                                        <td
                                                                            colSpan={
                                                                                7
                                                                            }
                                                                        >
                                                                            <table className="newTableEditCampaign">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            Qtd
                                                                                        </th>
                                                                                        <th>
                                                                                            Vlr
                                                                                            Digitado
                                                                                        </th>
                                                                                        <th>
                                                                                            Embalagem
                                                                                        </th>
                                                                                        <th>
                                                                                            Estado
                                                                                        </th>
                                                                                        <th>
                                                                                            Cidade
                                                                                        </th>
                                                                                        <th>
                                                                                            Cliente
                                                                                        </th>
                                                                                        <th>
                                                                                            Solicitação
                                                                                        </th>
                                                                                        <th>
                                                                                            Data
                                                                                        </th>
                                                                                        <th>
                                                                                            Vendedor
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {item
                                                                                        .itensEan
                                                                                        ?.length >
                                                                                        0 &&
                                                                                        item.itensEan.map(
                                                                                            (
                                                                                                itemP,
                                                                                                index
                                                                                            ) => (
                                                                                                <>
                                                                                                    <tr
                                                                                                        key={
                                                                                                            index +
                                                                                                            'ean'
                                                                                                        }
                                                                                                    >
                                                                                                        <td
                                                                                                            data-label="Qtd"
                                                                                                            className="text-center"
                                                                                                        >
                                                                                                            {itemP.quantidade_solicitacao
                                                                                                                ? itemP.quantidade_solicitacao
                                                                                                                : itemP.quantidade}
                                                                                                        </td>
                                                                                                        <td data-label="Vlr Digitado">
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display:
                                                                                                                        'flex',
                                                                                                                    flexDirection:
                                                                                                                        'row',
                                                                                                                    gap: 4,
                                                                                                                    alignItems:
                                                                                                                        'center',
                                                                                                                }}
                                                                                                            >
                                                                                                                {handleReturnIcon(
                                                                                                                    itemP.class
                                                                                                                )}
                                                                                                                <div
                                                                                                                    className={
                                                                                                                        itemP.class
                                                                                                                    }
                                                                                                                >
                                                                                                                    {' '}
                                                                                                                    R${' '}
                                                                                                                    {item.tipoCliente ==
                                                                                                                        1 &&
                                                                                                                    item.tipoPedido ==
                                                                                                                        0
                                                                                                                        ? itemP.valor
                                                                                                                            ? itemP.valor.toFixed(
                                                                                                                                  2
                                                                                                                              )
                                                                                                                            : 0
                                                                                                                        : itemP.valor_embalgem.toFixed(
                                                                                                                              2
                                                                                                                          )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            data-label="Embalagem"
                                                                                                            className="text-center"
                                                                                                        >
                                                                                                            {
                                                                                                                itemP.pro_embalagem
                                                                                                            }
                                                                                                        </td>

                                                                                                        <td data-label="Estado">
                                                                                                            {
                                                                                                                itemP.est_sigla
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td data-label="Cidade">
                                                                                                            {
                                                                                                                itemP.cid_nome
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td data-label="Cliente">
                                                                                                            {itemP.loj_razao
                                                                                                                ? itemP.loj_razao
                                                                                                                : itemP.nome_cliente}
                                                                                                        </td>
                                                                                                        <td data-label="Solicitação">
                                                                                                            {
                                                                                                                itemP.id_cotacao
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td data-label="Data">
                                                                                                            {formatDate(
                                                                                                                itemP.data_cotacao.substring(
                                                                                                                    0,
                                                                                                                    10
                                                                                                                )
                                                                                                            )}
                                                                                                        </td>
                                                                                                        <td data-label="Vendedor">
                                                                                                            {
                                                                                                                itemP.nome_vendedor
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )
                                                                                        )}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                ) : null}
                                                            </>
                                                        )
                                                    )
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <h6
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        Nenhum
                                                                        registro
                                                                        encontrado!
                                                                    </h6>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                                // ))
                                            }
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </>
                    )}
                </TableContainer>
            </div>
        )
    }
}
